import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VColorPicker } from 'vuetify/lib/components/VColorPicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSlider } from 'vuetify/lib/components/VSlider';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"field"},[_c('div',{staticClass:"control",staticStyle:{"margin-bottom":"15px"}},[_c('label',{staticClass:"label",staticStyle:{"display":"block","margin-bottom":"15px"}},[_vm._v("Write your loader text")]),_c('Editor',{attrs:{"api-key":"kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh","init":_vm.options},model:{value:(_vm.landing.branding.loading_langs.text),callback:function ($$v) {_vm.$set(_vm.landing.branding.loading_langs, "text", $$v)},expression:"landing.branding.loading_langs.text"}})],1),_c('div',[_c('label',{staticClass:"label",staticStyle:{"font-weight":"bold","font-size":"20px","display":"block","margin-bottom":"15px"}},[_vm._v("Icon settings")]),_c('div',{staticStyle:{"padding":"0 15px"}},[_c('div',{staticStyle:{"margin-bottom":"15px","display":"flex","align-items":"center"}},[_c('label',{staticClass:"label",staticStyle:{"width":"200px","margin-bottom":"0","margin-right":"8px","display":"block"}},[_vm._v("Loading icon")]),_c(VSelect,{staticClass:"canvas__select",staticStyle:{"max-width":"150px"},attrs:{"flat":"","dense":"","outlined":"","hide-details":"","items":_vm.icons},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"height":"50px","width":"50px","fill":"#000"},style:(("-webkit-mask: url(" + item + ") no-repeat center;mask: url(" + item + ") no-repeat center;background-size:100% 100%;background-color:" + (_vm.landing.branding.loading_langs.icon_color) + ";"))})]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"height":"50px","width":"50px","fill":"#000","margin-bottom":"15px"},style:(("-webkit-mask: url(" + item + ") no-repeat center;mask: url(" + item + ") no-repeat center;background-color:" + (_vm.landing.branding.loading_langs.icon_color) + ";"))})]}}]),model:{value:(_vm.landing.branding.loading_langs.icon),callback:function ($$v) {_vm.$set(_vm.landing.branding.loading_langs, "icon", $$v)},expression:"landing.branding.loading_langs.icon"}})],1),_c('div',{staticStyle:{"display":"flex","align-items":"center","margin-bottom":"15px"}},[_c('label',{staticClass:"label",staticStyle:{"min-width":"200px","display":"block","margin":"0"}},[_vm._v("Icon size "),_c('strong',[_vm._v(_vm._s(_vm.landing.branding.loading_langs.size)+" px")])]),_c('div',{staticClass:"slider",staticStyle:{"width":"100%"}},[_c(VSlider,{attrs:{"min":"10","hide-details":"","max":"200"},model:{value:(_vm.landing.branding.loading_langs.size),callback:function ($$v) {_vm.$set(_vm.landing.branding.loading_langs, "size", $$v)},expression:"landing.branding.loading_langs.size"}})],1)]),_c('div',{staticStyle:{"display":"flex","align-items":"center","margin-bottom":"15px"}},[_c('label',{staticClass:"label",staticStyle:{"width":"200px","margin-bottom":"0","margin-right":"8px","display":"block"}},[_vm._v("Icon color")]),_c(VMenu,{attrs:{"top":"","nudge-left":"16","nudge-bottom":"98","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"colorpicker",style:(("background-color:" + (_vm.landing.branding.loading_langs.icon_color)))},on))]}}]),model:{value:(_vm.showIconColor),callback:function ($$v) {_vm.showIconColor=$$v},expression:"showIconColor"}},[_c(VCard,[_c(VCardText,{staticClass:"pa-0"},[_c(VColorPicker,{attrs:{"flat":""},model:{value:(_vm.landing.branding.loading_langs.icon_color),callback:function ($$v) {_vm.$set(_vm.landing.branding.loading_langs, "icon_color", $$v)},expression:"landing.branding.loading_langs.icon_color"}})],1)],1)],1)],1)])]),_c('div',[_c('label',{staticClass:"label",staticStyle:{"font-weight":"bold","font-size":"20px","display":"block","margin-bottom":"15px"}},[_vm._v("Background settings")]),_c('div',{staticStyle:{"padding":"0 15px"}},[_c('label',{staticClass:"label",staticStyle:{"display":"block","margin-bottom":"15px"}},[_vm._v("Upload image")]),_c('div',{staticClass:"control",staticStyle:{"margin-bottom":"15px"}},[_c('vue-dropzone',{ref:"upload",staticStyle:{"margin-bottom":"15px"},attrs:{"id":"upload","options":_vm.dropzoneOptions,"destroyDropzone":false},on:{"vdropzone-mounted":_vm.mountDropzone,"vdropzone-sending":_vm.customEvent,"vdropzone-removed-file":_vm.handleRemove,"vdropzone-success":_vm.handleSuccess}}),_c('div',{staticStyle:{"display":"flex","align-items":"center","margin-bottom":"15px"}},[_c('label',{staticClass:"label",staticStyle:{"width":"200px","margin-bottom":"0","margin-right":"8px","display":"block"}},[_vm._v("Background color")]),_c(VMenu,{attrs:{"top":"","nudge-left":"16","nudge-bottom":"98","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"colorpicker",style:(("background-color:" + (_vm.landing.branding.loading_langs.background)))},on))]}}]),model:{value:(_vm.showBgColor),callback:function ($$v) {_vm.showBgColor=$$v},expression:"showBgColor"}},[_c(VCard,[_c(VCardText,{staticClass:"pa-0"},[_c(VColorPicker,{attrs:{"flat":""},on:{"input":function($event){_vm.showBgColor = false}},model:{value:(_vm.landing.branding.loading_langs.background),callback:function ($$v) {_vm.$set(_vm.landing.branding.loading_langs, "background", $$v)},expression:"landing.branding.loading_langs.background"}})],1)],1)],1)],1)],1)])]),_c('PopupCrop',{ref:"PopupCrop",attrs:{"title":"Change image before upload?"},on:{"confirm":_vm.openEditor,"save":_vm.saveImg,"close":_vm.closeCrop}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }