<template>

  <v-dialog max-width="600px"
            v-model="dialog"
  >
    <div class="popup__content">
      <v-btn class="popup__close" small icon @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <h5 class="popup__title">
        {{$t('popup["У вас есть несохраненные изменения, вы уверены, что хотите перейти без сохранения?"]')}}
      </h5>
              <div class="popup__actions">
                <v-btn class="popup__action btn btn_primary"
                       color="primary"
                       depressed
                       @click="move"
                >
                  {{$t('popup["Да"]')}}
        </v-btn>
        <v-btn class="popup__action btn btn_primary"
               color="primary"
               depressed
               @click="save"
        >
          {{$t('popup["Сохранить и перейти"]')}}
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  props: {
    dialog: Boolean,
    to: Object,
  },
  computed: {
    modal: {
      get() {
        return this.dialog;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  methods: {
    ...mapMutations("landing", ["setIsDirty", "setIsAbleToWatch"]),
    close() {
      this.dialog = false;
    },
      async move() {
      this.$emit("close");
      this.setIsAbleToWatch(false);
      this.setIsDirty(false);
      await this.$router.push(this.to);
    },
      async save() {
      await this.$store.dispatch("landing/saveLanding", this.$route.params.id);
      this.$emit("close");
      this.setIsAbleToWatch(false);
      this.setIsDirty(false);
      await this.$router.push(this.to);
    },
  },
};
</script>
<style lang="scss" scoped>
.popup__title {
  margin-bottom: 50px;
  padding: 0 40px;
}
.popup__actions {
  display: flex;
  margin: 0 -10px;
}
.popup__action {
  width: calc(50% - 20px);
  margin: 0 10px;
}
</style>