import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VColorPicker } from 'vuetify/lib/components/VColorPicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSlider } from 'vuetify/lib/components/VSlider';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('label',{staticClass:"label",staticStyle:{"font-weight":"bold","font-size":"20px","display":"block","margin-bottom":"15px"}},[_vm._v("Top menu settings")]),_c('div',{staticStyle:{"display":"flex","align-items":"center","margin-bottom":"15px"}},[_c('label',{staticClass:"label",staticStyle:{"display":"block","margin-bottom":"0","min-width":"200px"}},[_vm._v("Icon size "),_c('b',[_vm._v(_vm._s(_vm.landing.branding.topMenu.styles.size)+" px")])]),_c('div',{staticClass:"slider",staticStyle:{"width":"100%"}},[_c(VSlider,{attrs:{"hide-details":"","max":40,"min":2},model:{value:(_vm.landing.branding.topMenu.styles.size),callback:function ($$v) {_vm.$set(_vm.landing.branding.topMenu.styles, "size", $$v)},expression:"landing.branding.topMenu.styles.size"}})],1)]),_c('div',{staticStyle:{"display":"flex","align-items":"center","margin-bottom":"15px"}},[_c('label',{staticClass:"label",staticStyle:{"display":"block","margin-bottom":"0","min-width":"200px"}},[_vm._v("Border radius "),_c('b',[_vm._v(_vm._s(_vm.landing.branding.topMenu.styles.radius)+" px")])]),_c('div',{staticClass:"slider",staticStyle:{"width":"100%"}},[_c(VSlider,{attrs:{"hide-details":"","max":100,"min":0},model:{value:(_vm.landing.branding.topMenu.styles.radius),callback:function ($$v) {_vm.$set(_vm.landing.branding.topMenu.styles, "radius", $$v)},expression:"landing.branding.topMenu.styles.radius"}})],1)]),_c('div',{staticStyle:{"display":"flex","align-items":"center","margin-bottom":"15px"}},[_c('label',{staticClass:"label",staticStyle:{"margin-bottom":"0","margin-right":"8px","display":"block","min-width":"200px"}},[_vm._v(" Icon color ")]),_c('div',{staticClass:"control"},[_c(VMenu,{attrs:{"top":"","nudge-left":"16","nudge-bottom":"98","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"custom__menu--color",style:(("background-color:" + (_vm.landing.branding.topMenu.styles.color)))},on))]}}]),model:{value:(_vm.show_text_color),callback:function ($$v) {_vm.show_text_color=$$v},expression:"show_text_color"}},[_c(VCard,[_c(VCardText,{staticClass:"pa-0"},[_c(VColorPicker,{attrs:{"flat":""},model:{value:(_vm.landing.branding.topMenu.styles.color),callback:function ($$v) {_vm.$set(_vm.landing.branding.topMenu.styles, "color", $$v)},expression:"landing.branding.topMenu.styles.color"}})],1)],1)],1)],1)]),_c('div',{staticStyle:{"display":"flex","align-items":"center","margin-bottom":"30px"}},[_c('label',{staticClass:"label",staticStyle:{"margin-bottom":"0","margin-right":"8px","min-width":"200px","display":"block"}},[_vm._v(" Background color ")]),_c('div',{staticClass:"control"},[_c(VMenu,{attrs:{"top":"","nudge-left":"16","nudge-bottom":"98","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"custom__menu--color",style:(("background-color:" + (_vm.landing.branding.topMenu.styles.background)))},on))]}}]),model:{value:(_vm.show_background_color),callback:function ($$v) {_vm.show_background_color=$$v},expression:"show_background_color"}},[_c(VCard,[_c(VCardText,{staticClass:"pa-0"},[_c(VColorPicker,{attrs:{"flat":""},model:{value:(_vm.landing.branding.topMenu.styles.background),callback:function ($$v) {_vm.$set(_vm.landing.branding.topMenu.styles, "background", $$v)},expression:"landing.branding.topMenu.styles.background"}})],1)],1)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }