<template>
<div style="height:570px;width:100%;display:flex;">
  <div style="width:60%;">
    <v-list nav dense :style="`background:${landing.branding.sidebar_menu.background};`">
      <v-list-item-group>
        <v-list-item
            v-for="menu in landing.menu.list.filter(x => x.active)"
            :key="menu.id"
            style="border-radius: 0;"
            :style="`border-bottom: 0.5px solid ${landing.branding.sidebar_menu.border_color};`"
        >
          <v-list-item-icon v-if="menu.styles.show_icon&&landing.branding.sidebar_menu.show_icons">
<!--            <img :src="menu.icon" alt="menu" />-->
            <div :style="`width:24px;height:24px;background-color:${landing.branding.sidebar_menu.icon_colors};mask: url(${
                menu.icon[landing.advanced.default_lang]
              }) no-repeat center / contain;-webkit-mask: url(${
                menu.icon[landing.advanced.default_lang]
              }) no-repeat center / contain;`">

            </div>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title style="font-size: 1rem; line-height: 1.5rem">
              {{ menu.text[landing.current_lang] }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
  <div style="width:40%;min-height:100%;
    opacity: 0.46;
    background-color: rgb(33, 33, 33);">

  </div>
</div>
</template>

<script>
import {mapState} from "vuex"
export default {
  name: "SidebarPreview",
  computed:{
    ...mapState(['landing'])
  }
}
</script>

<style scoped>

</style>