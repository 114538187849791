import $axios from "../plugins/axios";
import axios from "axios";
const HotelService = {

  async togglePwa(id){
    try{
      const {data} = await $axios.get(`set-pwa/${id}`);
      return data;
    }catch (e) {
      return e;
    }
  },
  async getHotelTemplates(){
    try{
      const {data} = await $axios.get(`templates`);
      return data;
    }catch (e){
      return e;
    }
  },
  async getHotelTemplateSettings(id){
    try{
      const {data} = await $axios.get(`template/${id}`);
      return data.content;
    }catch (e){
      return e;
    }
  },
  async getHotelCurrency(id) {
    try {
      const { data } = await $axios.get("get-filtered-settings", {
        params: {
          hotel_id: id,
          columns: ["advanced", "global", "currency"],
        },
      });
      return data;
    } catch (e) {
      return e;
    }
  },
  async addUniqStatistic(payload) {
    try {
      const { data } = await $axios.post("add-unique-statistic", {
        hotel_id: +payload.hotel_id,
        browser: payload.browser,
        browser_lang: payload.browser_lang,
        platform: payload.platform,
        user_agent: payload.user_agent,
      });
      return data;
    } catch (e) {
      return e;
    }
  },
  async checkBlockedUser(hotel_id) {
    try {
      const response = await $axios.get(
        `/check-user-blocked-status/${hotel_id}`
      );
      return response;
    } catch (e) {
      return e;
    }
  },
  async registerUser(payload) {
    try {
      const response = await $axios.post("/mobi/register", payload);
      return response;
    } catch (e) {
      return e;
    }
  },
  async forgotPassword(email) {
    try {
      const payload = {
        email: email,
      };
      const { data } = await $axios.post("/mobi/recovery-password", payload);
      return data;
    } catch (e) {
      return e;
    }
  },
  async getUserTokenByCode(provider, code, hotel_id) {
    try {
      const response = await $axios.get(
        `/mobi/oauth-callback/${provider}/${hotel_id}?code=${code}`
      );
      return response;
    } catch (e) {
      return e;
    }
  },
  async loginUser(payload) {
    try {
      const response = await $axios.post("/mobi/login", payload);
      return response;
    } catch (e) {
      return e;
    }
  },
  async loginUserDriver(driver, hotel_id) {
    try {
      const response = await $axios.get(
        `/mobi/oauth-hotel/${hotel_id}/${driver}`
      );
      return response.data;
    } catch (e) {
      return e;
    }
  },
  async getUserInfo(token) {
    try {
      const response = await axios.get(
        process.env.VUE_APP_API_URL + "mobi/get-profile",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response;
    } catch (e) {
      return e;
    }
  },
  async downloadStatistics(payload) {
    try {
      const { data } = await $axios.get(
        `export-hotel-statistic-for-period?hotel_id=${payload.hotel_id}&date_start=${payload.date_start}&date_end=${payload.date_end}`
      );
      return data;
    } catch (e) {
      return e;
    }
  },
  async getAppVersion(hotel_id){
    try{
      const {data} = await axios.get(`${process.env.VUE_APP_API_URL}get-version?id=${hotel_id}`);
      return data;
    }catch(e){
      return e;
    }
  }
};
export default HotelService;
