<template>
  <div style="overflow: hidden;padding:15px;height: auto;position:relative;">
    <div   style="display:flex;margin-bottom:30px;flex-direction:column;"
           @click="categoryOpen(category)"
           v-for="category in showCategories"
           :key="category.id">
      <div style="display:flex;align-items:center;margin-bottom:15px;">
        <div
            v-if="landing.branding.segment_products_styles.category.show_icon && category.image[landing.current_lang]" :style="`
          height:${landing.branding.segment_products_styles.category.categorySlider.icon.height}px;
          width:${landing.branding.segment_products_styles.category.categorySlider.icon.width}px;
          `" style="margin-right:15px;"
        >
          <img
              :src="category.image[landing.current_lang]"
              alt=""
              style="height: 100%; width: 100%"
          />
        </div>
        <div :style="`
            font-size:${landing.branding.segment_products_styles.category.categorySlider.name.font_size};
            color:${landing.branding.segment_products_styles.category.categorySlider.name.color};
            font-weight:${landing.branding.segment_products_styles.category.categorySlider.name.font_weight};
            font-family:${landing.branding.segment_products_styles.category.categorySlider.name.font_family};
          `">

          {{ category.name[landing.current_lang] }}
        </div>
      </div>
      <swiper
          :ref="`mySwiper_${category.id}`"
          :direction="'horizontal'"
          :pagination="{ clickable: true }"
          :options="swiperOptions"

      >
        <swiper-slide :style="`
                          width:${landing.branding.segment_products_styles.category.categorySlider.block.width}px;
                  height:${landing.branding.segment_products_styles.category.categorySlider.block.height}px;
                          `"
                      v-for="product in category.products" :key="product.id">
          <div
              :style="`
                  width:${landing.branding.segment_products_styles.category.categorySlider.block.width}px;
                  height:${landing.branding.segment_products_styles.category.categorySlider.block.height}px;
                  `"
              class="productProductPreviewMainItem"
              @click.stop="productOpen(product)"
              style="position:relative;"
          >
            <div v-if="product.active_tab_text" style="position:absolute;top:0;left:0;background:rgba(255,255,255,0.5);padding:5px;display:flex;align-items: center;">
              <span style="font-size:13px;">{{product.tab_text[landing.current_lang]}}</span>
            </div>

            <div
                class="productProductPreviewMainItemImage"
                :style="`
      width:${landing.branding.segment_products_styles.product.img_width}%;
        height:${landing.branding.segment_products_styles.product.img_height}px;
      `"
                v-if="landing.branding.segment_products_styles.product.show_icon"
            >
              <img
                  :src="
                  product.main_image
                    ? product.main_image
                    : require('@/assets/none.png')
                "
                  alt=""
                  style="width:100%;height:100%;"
              />
            </div>
            <div class="productProductPreviewMainItemText" style="text-align:center;" :style="`
        color:${landing.branding.segment_products_styles.product.color};
        font-size:${landing.branding.segment_products_styles.product.fontSize}px;
        font-weight:${landing.branding.segment_products_styles.product.fontWeight};
        font-style:${landing.branding.segment_products_styles.product.fontStyle};
        font-family:${landing.branding.segment_products_styles.product.fontFamily};
        justify-content:${landing.branding.segment_products_styles.product.positionName};
      `">
              {{ product.name[landing.current_lang] }}
            </div>
            <div style="display:flex;width:100%;justify-content:space-between;padding:0 10px;align-items:center;">
              <div class="productProductPreviewMainItemText" :style="`
        color:${landing.branding.segment_products_styles.product.colorPrice};
        font-size:${landing.branding.segment_products_styles.product.fontSizePrice}px;
        font-weight:${landing.branding.segment_products_styles.product.fontWeightPrice};
        font-style:${landing.branding.segment_products_styles.product.fontStylePrice};
        font-family:${landing.branding.segment_products_styles.product.fontFamilyPrice};
        justify-content:${landing.branding.segment_products_styles.product.positionPrice};
      `" style="flex:1;display:flex;text-align:end;">
                {{ product.price }} {{ product.currency }}
              </div>
            </div>
            <div
                style="flex:1;padding:0 10px;"
                class="productProductPreviewMainItemText"
                v-html="product.small_description[landing.current_lang]"
            ></div>
            <div
                style="
                display: flex;
                width: 100%;
                margin: 15px 0;
                justify-content: space-around;
              "
            >
                <v-btn
                    outlined
                    small
                    dense
                    :style="`background:${landing.branding.segment_products_styles.productDetail.btnBgCart};color:${landing.branding.segment_products_styles.productDetail.btnColorCart};border-color:${landing.branding.segment_products_styles.productDetail.btnBorderCart};`"
                >
                  {{
                  landing.branding.segment_products_styles.productDetail.btnNameCart[
                  landing.current_lang
                  ]
                  }}
                </v-btn>
                <v-btn
                    outlined
                    small
                    dense
                    :style="`background:${landing.branding.segment_products_styles.productDetail.btnBgMore};color:${landing.branding.segment_products_styles.productDetail.btnColorMore};border-color:${landing.branding.segment_products_styles.productDetail.btnBorderMore};`"
                >
                  {{
                  landing.branding.segment_products_styles.productDetail.btnNameMore[
                  landing.current_lang
                  ]
                  }}
                </v-btn>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
  import {mapState,mapMutations} from 'vuex'
  import "swiper/swiper-bundle.css";
  import "swiper/modules/pagination/pagination.min.css";
  import SwiperCore, { Pagination } from "swiper";
  import { Swiper, SwiperSlide } from "vue-awesome-swiper";
  SwiperCore.use([Pagination]);
  export default {
    name: "SegmentCategoryListPreview",
    components:{Swiper, SwiperSlide},
    data(){
      return{
        swiperOptions: {
          grabCursor: true,
          slidesPerView: "auto",
          spaceBetween: 15,
        },
      }
    },
    computed:{
      ...mapState('segmentProducts',['segmentProductCategories']),
      ...mapState(['landing']),
      showCategories() {
        return this.segmentProductCategories.filter((x) => x.active);
      },
    },
    methods:{
      ...mapMutations('segmentProducts',['setSegmentProductCategory','setSegmentProduct']),
      productOpen(product){
        console.log(product);
        this.setSegmentProduct(product);
        this.$store.commit("setPage", "marketing");
      },
      categoryOpen(category){
        this.setSegmentProductCategory(category);
        this.$store.commit("setPage", "marketing");
      }
    }
  }
</script>

<style scoped>

</style>
