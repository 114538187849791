<template>
  <div>
    <div v-if="page === 'settings'">
      <v-tabs v-model="tabs">
        <v-tab>General</v-tab>
        <v-tab>Guest</v-tab>
        <v-tab>Booking source</v-tab>
        <v-tab>Reservation Type</v-tab>
        <v-tab-item style="padding-top: 15px;">
            <div style="display: flex;flex-direction: column;padding:15px;">
              <div style="display: flex;align-items: center;margin-bottom: 15px;">
                <label class="label" style="height:40px;margin-bottom: 0;display: block;width:150px;">Name</label>
                <v-text-field :error="error_name" :error-messages="error_name_message" placeholder="Write your segment name" outlined dense v-model="segment.name" style="max-width:300px;"></v-text-field>
              </div>
              <div style="display: flex;align-items: center;margin-bottom: 15px;">
                <label class="label" style="height:40px;margin-bottom: 0;display: block;width:150px;">Small description</label>
                <v-text-field  placeholder="Write your segment small description" outlined dense v-model="segment.small_description" style="max-width:300px;"></v-text-field>
              </div>
              <div style="display: flex;align-items: center;margin-bottom: 15px;">
                <label class="label" style="height:40px;margin-bottom: 0;display: block;width:150px;">Key</label>
                <v-text-field placeholder="Write your segment hidden key" outlined dense v-model="segment.key" style="max-width:300px;"></v-text-field>
              </div>
            </div>
        </v-tab-item>
        <v-tab-item style="padding-top:15px;">
          <div style="display: flex;flex-direction: column">
            <div style="font-size:20px;font-weight: bold">
              Number of adults
            </div>
            <div style="padding:15px;">
              <div style="display: flex;align-items: center;margin-bottom: 15px;">
                <label class="label" style="margin-bottom: 0;display: block;width:150px;">Single</label>
                <v-switch hide-details style="padding: 0;margin:0;" v-model="segment.keys.guest.single"></v-switch>
              </div>
              <div style="display: flex;align-items: center;margin-bottom: 15px;">
                <label class="label" style="margin-bottom: 0;display: block;width:150px;">Couple</label>
                <v-switch hide-details style="padding: 0;margin:0;" v-model="segment.keys.guest.couple"></v-switch>
              </div>
              <div style="display: flex;align-items: center;margin-bottom: 15px;">
                <label class="label" style="margin-bottom: 0;display: block;width:150px;">Group</label>
                <v-switch hide-details style="padding: 0;margin:0;" v-model="segment.keys.guest.group"></v-switch>
              </div>
            </div>
            <div style="font-size:20px;font-weight: bold">
             Options
            </div>
            <div style="padding: 15px;">
              <div style="display: flex;align-items: center;margin-bottom: 15px;">
                <label class="label" style="margin-bottom: 0;display: block;width:150px;">Childs</label>
                <v-switch hide-details style="padding: 0;margin:0;" v-model="segment.keys.guest.childs"></v-switch>
              </div>
              <div style="display: flex;align-items: center;margin-bottom: 15px;">
                <label class="label" style="margin-bottom: 0;display: block;width:150px;">Infants</label>
                <v-switch hide-details style="padding: 0;margin:0;" v-model="segment.keys.guest.infant"></v-switch>
              </div>
              <div style="display: flex;align-items: center;margin-bottom: 15px;">
                <label class="label" style="margin-bottom: 0;display: block;width:150px;">Dogs</label>
                <v-switch hide-details style="padding: 0;margin:0;" v-model="segment.keys.guest.d"></v-switch>
              </div>
              <div style="display: flex;align-items: center;margin-bottom: 15px;">
                <label class="label" style="margin-bottom: 0;display: block;width:150px;">Cats</label>
                <v-switch hide-details style="padding: 0;margin:0;" v-model="segment.keys.guest.cats"></v-switch>
              </div>
            </div>
          </div>
        </v-tab-item>
        <v-tab-item style="padding-top:15px;">
          <div style="display: flex;flex-direction: column;margin-bottom: 15px;">
            <div style="font-size:20px;font-weight: bold;margin-bottom: 15px;">
              Custom keys
            </div>
            <div style="padding:15px;" v-if="segment.keys.booking_source.custom_keys.length">
              <div v-for="(k,index) in segment.keys.booking_source.custom_keys" :key="index" style="display: flex;align-items: center;margin-bottom: 15px;">
                <label class="label" style="margin-bottom: 0;display: block;width:150px;">Key</label>
                <v-text-field placeholder="Write your custom key" outlined dense v-model="k.key" hide-details style="max-width:300px;margin-right:15px;"></v-text-field>
                <div v-if="k.type === 'equals'" style="font-size:20px;font-weight: bold;cursor:pointer;margin-right: 15px;" @click="changeTypeCustomKey(k,'less')">=</div>
                <div v-if="k.type === 'less'" style="font-size:20px;font-weight: bold;cursor:pointer;margin-right: 15px;" @click="changeTypeCustomKey(k,'more')"> &#10095; </div>
                <div v-if="k.type === 'more'" style="font-size:20px;font-weight: bold;cursor:pointer;margin-right: 15px;" @click="changeTypeCustomKey(k,'equals')"> &#10094; </div>
                <label class="label" style="margin-bottom: 0;display: block;width:150px;">Value</label>
                <v-text-field :type="k.type !== 'equals'?'number':'text'" placeholder="Write your custom key" outlined dense v-model="k.value" hide-details style="max-width:300px;margin-right:15px;"></v-text-field>
                <v-icon @click="deleteString(segment.keys.booking_source.custom_keys,index)" >mdi-close-circle-outline</v-icon>
              </div>
            </div>
            <div>
              <v-btn small outlined color="primary" @click="addObj(segment.keys.booking_source.custom_keys)"><v-icon>mdi-plus</v-icon> Add key</v-btn>
            </div>
          </div>
        </v-tab-item>
        <v-tab-item style="padding-top:15px;">
          <div style="display: flex;flex-direction: column;margin-bottom: 15px;">
            <div style="font-size:20px;font-weight: bold;margin-bottom: 15px;">
              Rooms numbers range
            </div>
            <div style="padding:15px;" v-if="ranges.length">
              <div style="display: flex;align-items: center;margin-bottom: 15px;" v-for="(range,index) in ranges" :key="index">
                <label class="label" style="margin-bottom: 0;display: block;width:50px;">From</label>
                <v-text-field type="number" placeholder="Range start" outlined dense @input="changeFromRange(range)" v-model="range.from" hide-details style="max-width:150px;margin-right: 15px;"></v-text-field>
                <label class="label" style="margin-bottom: 0;display: block;width:50px;margin-right: 15px;">To</label>
                <v-text-field :min="range.from" type="number" placeholder="Range end" outlined dense  v-model="range.to" hide-details style="max-width:150px;"></v-text-field>
              </div>
            </div>
            <div>
              <v-btn small outlined color="primary" @click="addRange(ranges)"><v-icon>mdi-plus</v-icon> Add range</v-btn>
            </div>
          </div>
          <div style="display: flex;flex-direction: column;margin-bottom: 15px;">
            <div style="font-size:20px;font-weight: bold;margin-bottom: 15px;">
              Rooms prefix
            </div>
            <div style="padding:15px;" v-if="segment.keys.reservation_type.room_prefix.length">
              <div v-for="(k,index) in segment.keys.reservation_type.room_prefix" :key="index" style="display: flex;align-items: center;margin-bottom: 15px;">
                <label class="label" style="margin-bottom: 0;display: block;width:150px;">Key</label>
                <v-text-field placeholder="Write your rooms prefix key" outlined dense v-model="segment.keys.reservation_type.room_prefix[index]" hide-details style="max-width:300px;margin-right:15px;"></v-text-field>
                <v-icon @click="deleteString(segment.keys.reservation_type.room_prefix,index)" >mdi-close-circle-outline</v-icon>
              </div>
            </div>
            <div>
              <v-btn small outlined color="primary" @click="addString(segment.keys.reservation_type.room_prefix)"><v-icon>mdi-plus</v-icon> Add key</v-btn>
            </div>
          </div>
          <div style="display: flex;align-items: center;justify-content: space-evenly">
            <div style="display: flex;flex-direction: column;margin-bottom: 15px;">
              <div style="font-size:20px;font-weight: bold">
                Сheck-in
              </div>
              <div style="padding:15px;">
                <div style="display: flex;align-items: center;margin-bottom: 15px;">
                  <label class="label" style="margin-bottom: 0;display: block;width:150px;">Monday</label>
                  <v-switch hide-details style="padding: 0;margin:0;" v-model="segment.keys.reservation_type.day_in_week_from.mon"></v-switch>
                </div>
                <div style="display: flex;align-items: center;margin-bottom: 15px;">
                  <label class="label" style="margin-bottom: 0;display: block;width:150px;">Tuesday</label>
                  <v-switch hide-details style="padding: 0;margin:0;" v-model="segment.keys.reservation_type.day_in_week_from.tue"></v-switch>
                </div>
                <div style="display: flex;align-items: center;margin-bottom: 15px;">
                  <label class="label" style="margin-bottom: 0;display: block;width:150px;">Wednesday</label>
                  <v-switch hide-details style="padding: 0;margin:0;" v-model="segment.keys.reservation_type.day_in_week_from.wed"></v-switch>
                </div>
                <div style="display: flex;align-items: center;margin-bottom: 15px;">
                  <label class="label" style="margin-bottom: 0;display: block;width:150px;">Thursday</label>
                  <v-switch hide-details style="padding: 0;margin:0;" v-model="segment.keys.reservation_type.day_in_week_from.thu"></v-switch>
                </div>
                <div style="display: flex;align-items: center;margin-bottom: 15px;">
                  <label class="label" style="margin-bottom: 0;display: block;width:150px;">Friday</label>
                  <v-switch hide-details style="padding: 0;margin:0;" v-model="segment.keys.reservation_type.day_in_week_from.fri"></v-switch>
                </div>
                <div style="display: flex;align-items: center;margin-bottom: 15px;">
                  <label class="label" style="margin-bottom: 0;display: block;width:150px;">Saturday</label>
                  <v-switch hide-details style="padding: 0;margin:0;" v-model="segment.keys.reservation_type.day_in_week_from.sat"></v-switch>
                </div>
                <div style="display: flex;align-items: center;margin-bottom: 15px;">
                  <label class="label" style="margin-bottom: 0;display: block;width:150px;">Sunday</label>
                  <v-switch hide-details style="padding: 0;margin:0;" v-model="segment.keys.reservation_type.day_in_week_from.sun"></v-switch>
                </div>
              </div>
            </div>
            <div style="display: flex;flex-direction: column;margin-bottom: 15px;">
              <div style="font-size:20px;font-weight: bold">
                Check-out
              </div>
              <div style="padding:15px;">
                <div style="display: flex;align-items: center;margin-bottom: 15px;">
                  <label class="label" style="margin-bottom: 0;display: block;width:150px;">Monday</label>
                  <v-switch hide-details style="padding: 0;margin:0;" v-model="segment.keys.reservation_type.day_in_week_to.mon"></v-switch>
                </div>
                <div style="display: flex;align-items: center;margin-bottom: 15px;">
                  <label class="label" style="margin-bottom: 0;display: block;width:150px;">Tuesday</label>
                  <v-switch hide-details style="padding: 0;margin:0;" v-model="segment.keys.reservation_type.day_in_week_to.tue"></v-switch>
                </div>
                <div style="display: flex;align-items: center;margin-bottom: 15px;">
                  <label class="label" style="margin-bottom: 0;display: block;width:150px;">Wednesday</label>
                  <v-switch hide-details style="padding: 0;margin:0;" v-model="segment.keys.reservation_type.day_in_week_to.wed"></v-switch>
                </div>
                <div style="display: flex;align-items: center;margin-bottom: 15px;">
                  <label class="label" style="margin-bottom: 0;display: block;width:150px;">Thursday</label>
                  <v-switch hide-details style="padding: 0;margin:0;" v-model="segment.keys.reservation_type.day_in_week_to.thu"></v-switch>
                </div>
                <div style="display: flex;align-items: center;margin-bottom: 15px;">
                  <label class="label" style="margin-bottom: 0;display: block;width:150px;">Friday</label>
                  <v-switch hide-details style="padding: 0;margin:0;" v-model="segment.keys.reservation_type.day_in_week_to.fri"></v-switch>
                </div>
                <div style="display: flex;align-items: center;margin-bottom: 15px;">
                  <label class="label" style="margin-bottom: 0;display: block;width:150px;">Saturday</label>
                  <v-switch hide-details style="padding: 0;margin:0;" v-model="segment.keys.reservation_type.day_in_week_to.sat"></v-switch>
                </div>
                <div style="display: flex;align-items: center;margin-bottom: 15px;">
                  <label class="label" style="margin-bottom: 0;display: block;width:150px;">Sunday</label>
                  <v-switch hide-details style="padding: 0;margin:0;" v-model="segment.keys.reservation_type.day_in_week_to.sun"></v-switch>
                </div>
              </div>
            </div>
          </div>
        </v-tab-item>
      </v-tabs>
      <div style="display: flex;justify-content: flex-end">
        <v-btn outlined small color="primary" @click="createSegmentConfirm">Create segment</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapActions} from 'vuex'
  export default {
    name: "CreateGuestSegment",
    props:[
      'segment'
    ],
    mounted() {
      console.log('mounted');
      this.ranges = [];
    },
    data(){
      return{
        tabs:0,
        error_name:false,
        error_name_message:"",
        page:'settings',
        ranges:[],
        defaultRange:{
          from:0,
          to:0
        }
      }
    },
    methods:{
      ...mapActions('segments',['postSegment']),
      async createSegmentConfirm(){
        if(!this.segment.name){
          this.error_name = true;
          this.error_name_message = 'Name is required';
          this.tabs = 0;
          return
        }else{
          this.error_name = false;
          this.error_name_message = ""
        }
        for(let i = 0;i<this.ranges.length;i++){
          for(let index = +this.ranges[i].from;index<=this.ranges[i].to;index++){
            this.segment.keys.reservation_type.room_number.push(index);
          }
        }
        if(this.segment.active){
          this.segment.active = 1;
        }
        await this.postSegment(this.segment);
        this.$emit('createSuccess');
      },
      changeFromRange(range){
        if(range.to < range.from){
          range.to = range.from;
        }
      },
      nextSettings(){
        this.page = 'settings'
      },
      addString(arr){
        arr.push('');
      },
      addObj(arr){
        arr.push({
          key:"",
          value:"",
          type:"equals"
        });
      },
      changeTypeCustomKey(obj,type){
        obj.type = type;
        if(isNaN(+obj.value)){
          obj.value = "";
        }
      },
      deleteString(arr,index){
        arr.splice(index,1);
      },
      addRange(arr){
        arr.push(JSON.parse(JSON.stringify(this.defaultRange)))
      },
    }
  }
</script>

<style scoped>

</style>
