import axios from "../plugins/axios";

const TelegramUserService = {
    async getUserList(id){
        try{
            const {data} = await axios.get(`${process.env.VUE_APP_API_URL}order-users/${id}`);
            return data;
        }catch (e){
            return e;
        }
    },
    async updateUser(user){
        try{
            const {data} = await axios.put(`${process.env.VUE_APP_API_URL}order-users/${user.id}`,user);
            return data;
        }catch (e) {
            return e;
        }
    },
    async updateUserToken(user){
      try{
          const {data} = await axios.put(`${process.env.VUE_APP_API_URL}update-user-firebase/${user.id}`,{firebase_token:user.firebase_token})
      }  catch (e) {
          return e;
      }
    },
    async getUserInfoByKey(key){
        try{
            const {data} = await axios.get(`${process.env.VUE_APP_API_URL}order-user-by-key/${key}`)
            return data;
        }catch (e) {
            return e;
        }
    }
};
export default TelegramUserService;
