<template>
  <div class="constructor__main" style="height:100%">
    <div class="constructor__header">
      <h2 class="constructor__heading">
        Marketing Options
      </h2>
      <p class="constructor__text">
        Have more control over your marketing campaign with these options.
      </p>
    </div>
    <div class="constructor__content"  :style="`height: ${isMobile?'auto':'calc(100% - 97px)'}`">

      <div style="display:flex;height: 100%">
        <div
            style="
            flex: 1 0 auto;
            display: flex;
            flex-direction: column;
            border-right: 1px solid #e4e4e4;
          "
        >
          <div
              :class="{ active_tab: activeMenu(item.id) }"
              v-for="item in marketingPages"
              :key="item.id"
              class="tab"
              @click="changeMarketingTab(item)"
          >
            {{item.name}}
          </div>
        </div>
        <div style="flex: 0 1 100%;" v-if="active_marketing_id === 0">
          <ConstructorGuestSettings />
        </div>
        <div style="flex: 0 1 100%;" v-if="active_marketing_id === 1">
          <PromoSettings />
        </div>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapActions,mapState} from 'vuex';
  import ConstructorGuestSettings from "@/components/Constructor/Marketing/ConstructorGuestSettings.vue"
  import ConstructorSegmentationOptions from "@/components/Constructor/Marketing/ConstructorSegmentationOptions.vue"
  import PromoSettings from "./Marketing/PromoSettings";
  export default {
    name: "ConstructorMarketing",
    computed:{
      ...mapState('segments',['segments']),
    },
    created(){
      this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    },
    components:{
      PromoSettings,
      ConstructorGuestSettings
    },
    data(){
      return{
        isMobile:false,
        active_marketing_id:0,
        marketingPages:[
          {
            id:0,page:'no_preview',name:'Segments',
          },
          {
            id:1,page:'home',name:'Promo',
          },
        ],
      }
    },
    methods:{
      ...mapActions('segments',['getSegments']),
      changeMarketingTab(item){
        console.log(item.page);
        this.active_marketing_id = item.id;
        this.$store.commit("setPage", item.page);
      },
      activeMenu(id){
        return id === this.active_marketing_id
      },
    },
    async mounted() {
      await this.getSegments(this.$route.params.id);
      this.$store.commit("setPage", 'no_preview');
    }
  }
</script>

<style scoped>

</style>
