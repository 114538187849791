<template>
<div>
  SegmentProductListPreview
</div>
</template>

<script>
  export default {
    name: "SegmentProductListPreview"
  }
</script>

<style scoped>

</style>
