<template>
  <div style="display: flex;flex-direction: column;">
    <v-alert type="success" class="copiedTelegram" v-if="copied">
      Telegram key copied to your clipboard
    </v-alert>
    <label class="label">You can activate sending order notifications to your telegram account or to a special mobile dashboard, which is available here: <a href="https://my.guest.mobi">my.guest.mobi</a></label>
    <div style="display: flex;margin-bottom: 15px;align-items: center;justify-content: space-between;">
      <label class="label" style="margin:0;">Enable telegram notifications</label>
      <v-checkbox @change="setTelegramEnable" :input-value="objects.item.telegram_enable"></v-checkbox>
    </div>
    <div v-if="objects.item.telegram_enable">

      <div style="display: flex;margin-bottom: 15px;">
        <h3>Telegram Users</h3>
        <v-spacer></v-spacer>
        <v-btn color="primary" outlined dense small @click="openAddUserModal">
          + Add user
        </v-btn>
      </div>
      <div>
        <v-data-table :headers="headers"
                      class="table" :items="$store.state.telegramUsers" hide-default-footer>
          <template v-slot:body="{ items }">
            <tbody v-if="items.length">
            <tr v-for="item in items" :key="item.id">
              <td class="d-block d-sm-table-cell">
                <div class="d-flex align-center justify-center">
                  <v-avatar class="avatar">
                    <img
                            :src="item.avatar ? `${item.avatar}` : require('@/assets/icons/user_placeholder.svg')"
                            alt="John"

                    >
                  </v-avatar>
                </div>
              </td>
              <td class="d-block d-sm-table-cell">
                <div class="d-flex align-center justify-center">
                  {{item.first_name}}
                </div>
              </td>
              <td class="d-block d-sm-table-cell">
                <div class="d-flex align-center justify-center">
                  {{item.last_name}}
                </div>
              </td>
              <td class="d-block d-sm-table-cell">
                <div class="d-flex align-center justify-center">
                  {{item.profession}}
                </div>
              </td>
              <td class="d-block d-sm-table-cell">
                <div class="d-flex align-center justify-center">
                  <v-icon v-if="!telegramKeyShow.includes(item.id)" @click="showKey(item.id)">
                    mdi-eye
                  </v-icon>
                  <div v-else @click="copyKey(item.telegram_key)" style="cursor:pointer;">
                    {{ item.telegram_key }} <v-icon small>mdi-content-copy</v-icon>
                  </div>
                </div>
              </td>
              <td class="d-block d-sm-table-cell">
                <div class="d-flex align-center justify-center">
                  <v-menu offset-y open-on-hover bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" outlined small v-bind="attrs" v-on="on">
                        <v-icon>mdi-cog</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                              style="cursor:pointer;"
                              @click="openEditUserModal(item)"
                      >
                        <v-list-item-title>Edit user</v-list-item-title>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title
                                style="cursor:pointer;color:red;"
                                @click="openDeleteModal(item.id)"
                        >Delete</v-list-item-title
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu></div>
              </td>
            </tr>
            </tbody>
            <tbody v-else>
            <tr>
              <td class="d-block d-sm-table-cell"
                      colspan="6"
                      style="
                      text-align: center;
                      padding: 0 15px;
                      font-weight: normal;
                    "
              >
                You don't have users yet, it's time to create them
              </td>
            </tr>
            </tbody>
          </template>
        </v-data-table>
      </div>

        <div style="margin-top: 45px;font-size: small;">
            <p>If you want to activate the sending of messages about orders to the Telegram messenger</p>
            <p>1. Follow <a href="https://t.me/guestmobi_bot" target="_blank">the link</a> or find @guestmobi_bot in your contact list</p>
            <p>2. Click /start button</p>
            <p>3. Using the /register Telegram_key command, connect notifications to your telegram</p>
            <p>4. Everything is ready, if you still have questions, visit the Help</p>
        </div>
      <div style="margin-top: 15px;font-size: small;">
        Or you can also manage orders through <a href="https://my.guest.mobi">my.guest.mobi</a> using telegram key as a password
      </div>
    </div>

    <v-dialog
        v-model="addUserModal"
        max-width="500px"
        id="popup-registration1"
        class="popup-registration popup mfp-hide"
    >
      <div class="popup__form popup__content">
        <v-icon
            style="top: 10px; right: 10px; position: absolute"
            class="close__modal"
            @click="closeAddUserModal"
        >mdi-close</v-icon
        >
        <v-form
            class="form"
            style="
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          "
            ref="form_register"
            @submit="saveAddUserModal"
            novalidate
        >
          <h2 class="popup__title">Register user</h2>
          <div style="padding:15px;width:100%">
            <div style="margin-bottom: 15px;">
              <div class="label" style="margin-bottom: 10px;">
                Choose your user avatar
              </div>
              <vue-dropzone
                  id="createDropzone"
                  :options="dropzoneOptions"
                  :destroyDropzone="false"
                  ref="createDropzone"
                  @vdropzone-sending="customEvent"
                  @vdropzone-success="handleSuccessAddUser"
              >
              </vue-dropzone>
              <div class="label" style="margin: 15px 0;">User name</div>
              <v-text-field
                  outlined
                  dense
                  hide-details
                  v-model="addUser.first_name"
                  placeholder="Your user name"
              ></v-text-field>
              <div class="label" style="margin: 15px 0;">User last name</div>
              <v-text-field
                  outlined
                  dense
                  hide-details
                  v-model="addUser.last_name"
                  placeholder="Your user last name"
              ></v-text-field>
              <div class="label" style="margin: 15px 0;">User profession</div>
              <v-text-field
                  outlined
                  dense
                  hide-details
                  v-model="addUser.profession"
                  placeholder="Your user profession"
              ></v-text-field>
            </div>
          </div>
          <div
              style="
              display: flex;
              margin-top: 15px;
              justify-content: flex-end;
              align-items: center;
              width: 100%;
            "
          >
            <v-btn
                color="primary"
                outlined
                small type="submit" class="btn_form"
            >Register</v-btn
            >
          </div>
        </v-form>
      </div>
    </v-dialog>
    <v-dialog
        v-model="editUserModal"
        max-width="500px"
        id="popup-registration"
        class="popup-registration popup mfp-hide"
    >
      <div class="popup__form popup__content">
        <v-icon
            style="top: 10px; right: 10px; position: absolute"
            class="close__modal"
            @click="closeEditUserModal"
        >mdi-close</v-icon
        >
        <v-form
            class="form"
            style="
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          "
            ref="form_register"
            @submit="saveEditUserModal"
            novalidate
        >
          <h2 class="popup__title">Edit user</h2>
          <div style="padding:15px;width: 100%;">
            <div style="margin-bottom: 15px;">
              <div class="label" style="margin-bottom: 10px;">
                Choose your user avatar
              </div>
              <vue-dropzone
                  id="createDropzone123"
                  :options="dropzoneOptions"
                  :destroyDropzone="false"
                  ref="createDropzone"
                  @vdropzone-sending="customEvent"
                  @vdropzone-success="handleSuccessEditUser"
              >
              </vue-dropzone>
              <div class="label" style="margin: 15px 0;">User name</div>
              <v-text-field
                  outlined
                  dense
                  hide-details
                  v-model="editUser.first_name"
                  placeholder="Your user name"
              ></v-text-field>
              <div class="label" style="margin: 15px 0;">User last name</div>
              <v-text-field
                  outlined
                  dense
                  hide-details
                  v-model="editUser.last_name"
                  placeholder="Your user last name"
              ></v-text-field>
              <div class="label" style="margin: 15px 0;">User profession</div>
              <v-text-field
                  outlined
                  dense
                  hide-details
                  v-model="editUser.profession"
                  placeholder="Your user profession"
              ></v-text-field>
            </div>
          </div>
          <div
              style="
              display: flex;
              margin-top: 15px;
              justify-content: flex-end;
              align-items: center;
              width: 100%;
            "
          >
            <v-btn
                color="primary"
                outlined
                small type="submit" class="btn_form"
            >Change</v-btn
            >
          </div>
        </v-form>
      </div>
    </v-dialog>
    <PopupSuccess ref="PopupSuccess" :title="'Thank you!'" :content="successMessage"></PopupSuccess>
    <PopupDelete ref="PopupDelete" title="Are you sure about delete user?" @confirm="deleteUserConfirm"/>
  </div>
</template>

<script>
import ApiService from "@/services/api.services";
import {mapState} from "vuex";
import vueDropzone from "vue2-dropzone";
import PopupSuccess from "@/components/Popup/PopupSuccess";
import PopupDelete from "@/components/Popup/PopupDelete";
import TelegramUserService from "../../../services/telegramUser.service";
export default {
  name: "TelegramUsers",
  components:{
    PopupDelete,
      vueDropzone,
    PopupSuccess
  },
  data() {
    return {
      dropzoneOptions: {
        url: () => {
          return process.env.VUE_APP_API_URL + "upload-image";
        },
        maxfiles: 3,
        thumbnailMethod: "contain",
        acceptedfiles: "image/*",
        addRemoveLinks: true,
      },
      addUserModal:false,
      editUserModal:false,
      headers:[
        { text: "Avatar", value: "avatar", align: "center"},
        { text: "First name", value: "first_name", align: "center"},
        { text: "Last name", value: "last_name", align: "center"},
        { text: "Profession", value: "profession", align: "center" },
        { text: "Telegram key", align: "center"},
        { text:'Options',align:'center' }
        ],
      defaultUser:{
        hotel_id:0,
        first_name:"default name",
        last_name:"default last name",
        avatar:"",
        profession:"default profession",
      },
      addUser:{
        hotel_id:0,
        first_name:"default name",
        last_name:"default last name",
        avatar:"",
        profession:"default profession",
      },
      editUser:{
        hotel_id:0,
        first_name:"default name",
        last_name:"default last name",
        avatar:"",
        profession:"default profession",
      },
      userList:[],
      successMessage:"",
      deleteUserID:0,
      telegramUserID:0,
      telegramKeyShow:[],
      telegramKeys:"",
      copied:false
    };
  },
  async mounted() {
    this.$store.state.telegramUsers = await TelegramUserService.getUserList(this.$route.params.id);
  },
  computed:{
      ...mapState(['landing']),
      ...mapState(['objects'])
  },
  methods:{
    setTelegramEnable(){
      if(this.objects.item.telegram_enable){
        this.objects.item.telegram_enable = 0;
      }else{
        this.objects.item.telegram_enable = 1;
      }
      this.$store.dispatch('objects/edit',this.objects.item);
    },
    copyKey(key){
      this.copied = true;
      navigator.clipboard.writeText(key);
      setTimeout(() => {
        this.copied = false;
      },2000)
    },
    async showKey(id){
      try{
        const {data} = await ApiService.get(`/order-user/${id}`);
        const candidate = this.$store.state.telegramUsers.find(x => x.id === data.id);
        this.telegramKeyShow.push(id);
        if(candidate){
          const candidateIndex = this.$store.state.telegramUsers.indexOf(candidate);
          this.$store.state.telegramUsers.splice(candidateIndex,1,data);
        }
      }catch (e){
        return e;
      }
    },
    openDeleteModal(id){
      this.deleteUserID = id;
      this.$refs.PopupDelete.open();
    },
    async deleteUserConfirm(){
      try{
        for(let i = 0;i<this.landing.menu.list.length;i++){
          const menu = this.landing.menu.list[i];
          for(let lang in menu.content){
            const content = menu.content[lang];
            for(let index = 0;index<content.length;index++){
              if(content[index].form){
                if(content[index].form.telegrams.length){
                  const candidate = content[index].telegrams.find(x => x.id === this.deleteUserID);
                  if(candidate){
                    const candidateIndex = content[index].telegrams.indexOf(candidate);
                    content[index].telegrams.splice(candidateIndex,1);
                  }
                }
              }
              if(content[index].type === 4){
                //
              }
              if(content[index].type === 5){
                //
              }
            }
          }
        }
        // const {data} = await ApiService.delete(`/order-users/${this.deleteUserID}`);
        // const candidate = this.$store.state.telegramUsers.find(x => x.id === data.id);
        // if(candidate){
        //   const candidateIndex = this.$store.state.telegramUsers.indexOf(candidate);
        //   this.$store.state.telegramUsers.splice(candidateIndex,1);
        //   this.successMessage = 'User deleted successfully!';
        //   this.$refs.PopupSuccess.open();
        // }
      }catch (e){
        return e;
      }
    },
    openEditUserModal(user){
      this.editUser = JSON.parse(JSON.stringify(user));
      this.editUserModal = true;
    },
    closeEditUserModal(){
      this.editUserModal = false;
      this.editUser = JSON.parse(JSON.stringify(this.defaultUser));
    },
    async saveEditUserModal(){
      try{
        this.editUser.hotel_id = +this.$route.params.id;
        const {data} = await ApiService.put(`/order-users/${this.editUser.id}`,this.editUser);
        const candidate = this.$store.state.telegramUsers.find(x => x.id === data.id);
        if(candidate){
          const candidateIndex = this.$store.state.telegramUsers.indexOf(candidate);
          this.$store.state.telegramUsers.splice(candidateIndex,1,data);
        }
        this.editUserModal = false;
        this.editUser = JSON.parse(JSON.stringify(this.defaultUser));
        this.successMessage = "User changes successfully!";
        this.$refs.PopupSuccess.open();
      }catch (e) {
        return e;
      }

    },
    openAddUserModal(){
      this.addUserModal = true;
    },
    closeAddUserModal(){
        this.addUserModal = false;
    },
    async saveAddUserModal(e){
      e.preventDefault();
      try{
        this.addUser.hotel_id = +this.$route.params.id;
        const {data} = await ApiService.post(`/order-users`,this.addUser);
        this.$store.state.telegramUsers.push(data);
        this.addUserModal = false;
        this.addUser = JSON.parse(JSON.stringify(this.defaultUser));
        this.successMessage = "New user created successfully!";
        this.$refs.PopupSuccess.open();
      }catch (e){
        return e;
      }
    },
    async customEvent(_file, xhr, formData) {
      xhr.setRequestHeader(
          "Authorization",
          `Bearer ${this.$store.state.token}`
      );
      formData.append("lending_settings_id", this.landing.settings_id);
      formData.append("image", _file);
    },
    handleSuccessAddUser(file) {
      this.addUser.avatar = file.xhr.response;
    },
    handleSuccessEditUser(file) {
      this.editUser.avatar = file.xhr.response;
    },
  }
};
</script>

<style lang="scss" scoped>
.greyColor {
  color: grey;
}

.copiedTelegram{
  position: fixed;
  left:calc(50% - 185px);
  top:5px;
  z-index: 10;
  width:370px;
}
</style>