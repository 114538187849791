<template>
  <div>
    <label class="label" style="font-weight:bold;font-size:20px;display:block;margin-bottom:15px;">Loader settings</label>
    <div
        class="constructor__builder"
        :style="`width:100%;`"
    >
      <div
          ref="”tab”"
          id="header_slider"
          class="constructor__tabs"
          style="
              font-family: Inter, sans-serif;
              cursor: pointer;
              text-transform: uppercase;
              font-size: 0.875rem;
              font-weight: 500;
              letter-spacing: 0.0892857143em;
              color: rgba(0, 0, 0, 0.54);
              display: flex;
              margin-bottom:15px
            "
      >
        <div
            @click="changePage('main')"
            class="header__tab"
            :class="{ activeTab: selectedPage === 'main' }"
        >
          Main
        </div>
        <div
            @click="changePage('lang')"
            class="header__tab"
            :class="{ activeTab: selectedPage === 'lang' }"
        >
          Language
        </div>
      </div>
      <div class="form" v-if="selectedPage === 'main'">
        <div class="field">
          <div class="control" style="display:flex;align-items:center;margin-bottom:15px;">
            <label class="label" style="margin-right:15px;">
              Enable loader on hotel pages
            </label>
            <v-switch hide-details v-model="loaderState.show"></v-switch>
          </div>
          <div v-if="loaderState.show">
            <div class="control" style="margin-bottom:15px;">
              <label class="label" style="display:block;margin-bottom:15px;">Write your loader text</label>
              <Editor
                  api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
                  :init="options"
                  v-model="loaderState.text"
              >
              </Editor>
            </div>
            <div>
              <label class="label" style="font-weight:bold;font-size:20px;display:block;margin-bottom:15px;">Icon settings</label>
              <div style="padding:0 15px;">
                <div style="margin-bottom:15px;display:flex;align-items:center">
                  <label class="label" style="width:200px;margin-bottom:0;margin-right:8px;display:block">Loading icon</label>
                  <v-select
                      style="max-width:150px;"
                      flat
                      dense
                      outlined
                      hide-details
                      :items="icons"
                      v-model="loaderState.icon"
                      class="canvas__select"
                  >
                    <template v-slot:selection="{ item }">
                      <div style="height: 50px; width: 50px; fill:#000;" :style="`-webkit-mask: url(${item}) no-repeat center;mask: url(${item}) no-repeat center;background-size:100% 100%;background-color:${loaderState.icon_color};`" ></div>
                    </template>
                    <template v-slot:item="{ item }">
                      <div style="height: 50px; width: 50px; fill:#000;margin-bottom: 15px;" :style="`-webkit-mask: url(${item}) no-repeat center;mask: url(${item}) no-repeat center;background-color:${loaderState.icon_color};`"></div>
                    </template>
                  </v-select>
                </div>
                <div style="display:flex;align-items:center;margin-bottom:15px;">
                  <label class="label" style="min-width:200px;display:block;margin:0;"
                  >Icon size
                    <strong>{{ loaderState.size }} px</strong></label
                  >
                  <div class="slider" style="width:100%;">
                    <v-slider
                        min="10"
                        hide-details
                        max="200"
                        v-model="loaderState.size"
                    ></v-slider>
                  </div>
                </div>
                <div style="display: flex;align-items: center;margin-bottom:15px;">
                  <label
                      class="label"
                      style="width:200px;margin-bottom:0;margin-right:8px;display:block"
                  >Icon color</label
                  >
                  <v-menu
                      top
                      nudge-left="16"
                      nudge-bottom="98"
                      :close-on-content-click="false"
                      v-model="showIconColor"
                  >
                    <template v-slot:activator="{ on }">
                      <div
                          class="colorpicker"
                          :style="
                      `background-color:${loaderState.icon_color}`
                    "
                          v-on="on"
                      />
                    </template>
                    <v-card>
                      <v-card-text class="pa-0">
                        <v-color-picker
                            v-model="loaderState.icon_color"
                            flat
                        />
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </div>
              </div>
            </div>
            <div>
              <label class="label" style="font-weight:bold;font-size:20px;display:block;margin-bottom:15px;">Background settings</label>
              <div style="padding:0 15px;">
                <label class="label" style="display:block;margin-bottom:15px;">Upload image</label>
                <div class="control" style="margin-bottom: 15px">
                  <vue-dropzone
                      id="uploadBg"
                      ref="uploadBg"
                      :options="dropzoneOptions"
                      :destroyDropzone="false"
                      style="margin-bottom: 15px"
                      @vdropzone-mounted="mountDropzoneBg"
                      @vdropzone-max-files-exceeded="maxFileSexceededBg"
                      @vdropzone-sending="customEvent"
                      @vdropzone-removed-file="handleBgRemove"
                      @vdropzone-success="handleBgSuccess"
                  >
                  </vue-dropzone>
                  <div style="display: flex;align-items: center;margin-bottom:15px;">
                    <label
                        class="label"
                        style="width:200px;margin-bottom:0;margin-right:8px;display:block"
                    >Background color</label
                    >
                    <v-menu
                        top
                        nudge-left="16"
                        nudge-bottom="98"
                        :close-on-content-click="false"
                        v-model="showBgColor"
                    >
                      <template v-slot:activator="{ on }">
                        <div
                            class="colorpicker"
                            :style="
                      `background-color:${loaderState.background}`
                    "
                            v-on="on"
                        />
                      </template>
                      <v-card>
                        <v-card-text class="pa-0">
                          <v-color-picker
                              v-model="loaderState.background"
                              @input="showBgColor = false"
                              flat
                          />
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form" v-if="selectedPage === 'lang'">
        <LoaderLanguage/>
      </div>
    </div>
    <PopupSuccess ref="PopupSuccess"  title="Success!" content="Loader saved successfully!"/>
    <PopupCrop
        title="Change image before upload?"
        ref="PopupCrop"
        @confirm="openEditor"
        @save="saveImg"
        @close="closeCrop"
    />
  </div>
</template>

<script>
import "../../../../node_modules/cropperjs/dist/cropper.min.css";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import vueDropzone from "vue2-dropzone";
import { mapActions, mapState,mapMutations } from 'vuex';

import PopupSuccess from "@/components/Popup/PopupSuccess.vue"
import Compressor from "compressorjs";
import Cropper from "cropperjs";
import LoaderLanguage from "@/components/Constructor/Branding/LoaderLanguage";
export default {
  name: "Loader",
  components: {
    LoaderLanguage,
      vueDropzone,
    PopupCrop:() => import("@/components/Popup/PopupCrop"),
      PopupSuccess,
  },
  computed:{
      ...mapState('loader',['loaderState','selectedPage']),
      ...mapState(['landing']),
      icons(){
          let icons = [];
          for(let i = 0;i<11;i++){
              icons.push(`${process.env.VUE_APP_LINK}/loaders/loader${i+1}.svg`);
          }
          return icons;
      }
  },
  data() {
    return {
        showIconColor:false,
        icon:process.env.VUE_APP_LINK+"/loaders/loader1.svg",
        options: {
          images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
          images_file_types: 'jpg,svg,webp,gif,png',
          images_upload_handler: async  (blobInfo, success, failure) => {
            let formData = new FormData();
            let _file = new File([blobInfo.blob()], blobInfo.filename(), { lastModified: new Date().getTime(), type: blobInfo.blob().type })
            formData.append("lending_settings_id", this.$store.state.landing.settings_id);
            formData.append("image", _file);
            formData.append("file", _file);
            const {data} = await this.$axios.post(`${process.env.VUE_APP_API_URL}upload-image`,formData);
            success(data);
          },
          plugins:
            "print preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount  imagetools textpattern noneditable help formatpainter permanentpen pageembed charmap quickbars emoticons advtable export",

          mobile: {
            images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
          images_file_types: 'jpg,svg,webp,gif,png',
          images_upload_handler: async  (blobInfo, success, failure) => {
            let formData = new FormData();
            let _file = new File([blobInfo.blob()], blobInfo.filename(), { lastModified: new Date().getTime(), type: blobInfo.blob().type })
            formData.append("lending_settings_id", this.$store.state.landing.settings_id);
            formData.append("image", _file);
            formData.append("file", _file);
            const {data} = await this.$axios.post(`${process.env.VUE_APP_API_URL}upload-image`,formData);
            success(data);
          },
          plugins:
              "print preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount textpattern noneditable help formatpainter pageembed charmap quickbars emoticons advtable",
          },
          menu: {
            tc: {
              title: "Comments",
              items: "addcomment showcomments deleteallconversations",
            },
          },
          menubar: "file edit view insert format tools table tc help",
          toolbar:
            "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | numlist bullist checklist | forecolor backcolor casechange   | charmap emoticons | insertfile image | custom_button",
          autosave_ask_before_unload: true,min_height:200,
          autosave_interval: "30s",
          autosave_prefix: "{path}{query}-{id}-",
          autosave_restore_when_empty: false,
          autosave_retention: "2m",
          image_advtab: true,
          link_list: [
            { title: "My page 1", value: "https://www.tiny.cloud" },
            { title: "My page 2", value: "http://www.moxiecode.com" },
          ],
          image_list: [
            { title: "My page 1", value: "https://www.tiny.cloud" },
            { title: "My page 2", value: "http://www.moxiecode.com" },
          ],
          image_class_list: [
            { title: "None", value: "" },
            { title: "Some class", value: "class-name" },
          ],
          importcss_append: true,
          templates: [
            {
              title: "New Table",
              description: "creates a new table",
              content:
                '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td class="d-block d-sm-table-cell"> </td><td class="d-block d-sm-table-cell"> </td></tr></table></div>',
            },
            {
              title: "Starting my story",
              description: "A cure for writers block",
              content: "Once upon a time...",
            },
            {
              title: "New list with dates",
              description: "New List with dates",
              content:
                '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
            },
          ],
          template_cdate_format: "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
          template_mdate_format: "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
          height: 400,
          image_caption: true,
          quickbars_selection_toolbar:
            "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
          noneditable_noneditable_class: "mceNonEditable",
          toolbar_mode: "sliding",
          content_style: ".mymention{ color: gray; }",
          contextmenu: "link image imagetools table configurepermanentpen",
          skin: "oxide",

            setup:function(editor){
                editor.ui.registry.addButton('custom_button', {
                    text: 'Add Button',
                    onAction: function() {
                        // Open a Dialog
                        editor.windowManager.open({
                            title: 'Add custom button',
                            body: {
                                type: 'panel',
                                items: [{
                                    type: 'input',
                                    name: 'button_label',
                                    label: 'Button label',
                                    flex: true
                                },{
                                    type: 'input',
                                    name: 'button_href',
                                    label: 'Button href',
                                    flex: true
                                },{
                                    type: 'selectbox',
                                    name: 'button_target',
                                    label: 'Target',
                                    items: [
                                        {text: 'None', value: ''},
                                        {text: 'New window', value: '_blank'},
                                        {text: 'Self', value: '_self'},
                                        {text: 'Parent', value: '_parent'}
                                    ],
                                    flex: true
                                },{
                                    type: 'selectbox',
                                    name: 'button_rel',
                                    label: 'Rel',
                                    items: [
                                        {text: 'No value', value: ''},
                                        {text: 'Alternate', value: 'alternate'},
                                        {text: 'Help', value: 'help'},
                                        {text: 'Manifest', value: 'manifest'},
                                        {text: 'No follow', value: 'nofollow'},
                                        {text: 'No opener', value: 'noopener'},
                                        {text: 'No referrer', value: 'noreferrer'},
                                        {text: 'Opener', value: 'opener'}
                                    ],
                                    flex: true
                                },{
                                    type: 'selectbox',
                                    name: 'button_style',
                                    label: 'Style',
                                    items: [
                                        {text: 'Success', value: 'success'},
                                        {text: 'Info', value: 'info'},
                                        {text: 'Warning', value: 'warning'},
                                        {text: 'Error', value: 'error'}
                                    ],
                                    flex: true
                                }]
                            },
                            onSubmit: function (api) {

                                var html = '<a href="'+api.getData().button_href+'" class="v-btn v-btn--outlined theme--light v-size--small '+api.getData().button_style+'--text" rel="'+api.getData().button_rel+'" target="'+api.getData().button_target+'">'+api.getData().button_label+'</a>';

                                // insert markup
                                editor.insertContent(html);

                                // close the dialog
                                api.close();
                            },
                            buttons: [
                                {
                                    text: 'Close',
                                    type: 'cancel',
                                    onclick: 'close'
                                },
                                {
                                    text: 'Insert',
                                    type: 'submit',
                                    primary: true,
                                    enabled: false
                                }
                            ]
                        });
                    }
                });
            },
        content_css: ["/custom_tinymce.css"],
        },
        dropzoneOptions: {
          url: () => {
            return process.env.VUE_APP_API_URL + "upload-image";
          },
          thumbnailMethod: "contain",
          acceptedFiles: "image/*",
          addRemoveLinks: true,
          maxFiles: 2,
          maxFilesize: 3,
          dictFileTooBig: "File is too big ({{filesize}}mb). Max file size: 3mb",
          autoProcessQueue: false,
          destroyDropzone: false,
          init: function() {
            this.handleFiles = function(files) {
              var file, _i, _len, _results;
              _results = [];
              for (_i = 0, _len = files.length; _i < _len; _i++) {
                file = files[_i];
                _results.push(this.addFile(file));
                break;
              }
              return _results;
            };
            this._addFilesFromItems = function(items) {
              var entry, item, _i, _len, _results;
              _results = [];
              for (_i = 0, _len = items.length; _i < _len; _i++) {
                item = items[_i];
                if ((item.webkitGetAsEntry != null) && (entry = item.webkitGetAsEntry())) {
                  if (entry.isFile) {
                    _results.push(this.addFile(item.getAsFile()));
                  } else if (entry.isDirectory) {
                    _results.push(this._addFilesFromDirectory(entry, entry.name));
                  } else {
                    _results.push(void 0);
                  }
                } else if (item.getAsFile != null) {
                  if ((item.kind == null) || item.kind === "file") {
                    _results.push(this.addFile(item.getAsFile()));
                  } else {
                    _results.push(void 0);
                  }
                } else {
                  _results.push(void 0);
                }
                break;
              }
              return _results;
            };
          },
          thumbnail: (file, dataUrl) => {
            if (file.cropped) {
              return;
            } else {
              this.compress(file);
            }
          },
        },
        color:"#000",
        dropzoneOptionsBG: {
          url: () => {
            return process.env.VUE_APP_API_URL + "upload-image";
          },
          thumbnailMethod: "contain",
          acceptedFiles: "image/*",
          addRemoveLinks: true,
          maxFiles: 1,
          maxFilesize: 3,
          dictFileTooBig: "File is too big ({{filesize}}mb). Max file size: 3mb",
          autoProcessQueue: true,
          destroyDropzone: false,
        },
        showBgColor:false,
    };
  },
  methods:{
      ...mapActions('loader',['getLoader','toServerLoader']),
      ...mapMutations('loader',['setSelectedPage']),
    openEditor(file){
      let editor = document.createElement("div");
      editor.style.position = "fixed";
      editor.style.left = 0;
      editor.style.right = 0;
      editor.style.top = 0;
      editor.style.bottom = 0;
      editor.style.width = "66%";
      editor.style.zIndex = 9999;
      editor.style.backgroundColor = "#000";
      document.body.appendChild(editor);
      let buttonConfirm = document.createElement("button");
      buttonConfirm.style.position = "absolute";
      buttonConfirm.style.right = "10px";
      buttonConfirm.style.top = "10px";
      buttonConfirm.style.zIndex = 9999;
      buttonConfirm.textContent = "Save image";
      buttonConfirm.className = "v-btn v-size--small confirm-control";
      editor.appendChild(buttonConfirm);
      let buttonCancel = document.createElement("button");
      buttonCancel.style.position = "absolute";
      buttonCancel.style.right = "135px";
      buttonCancel.style.top = "10px";
      buttonCancel.style.zIndex = 9999;
      buttonCancel.textContent = "Cancel";
      buttonCancel.className = "v-btn v-size--small confirm-control";
      editor.appendChild(buttonCancel);
      buttonCancel.addEventListener("click", () => {
        this.$store.state.loader.previewBg = false;
        this.$refs.uploadBg.dropzone.removeAllFiles();
        document.body.removeChild(editor);
        cropper.destroy();
      });
      let buttonRotateLeft = document.createElement("button");
      buttonRotateLeft.className = "cropper-controls";
      buttonRotateLeft.style.left = "24px";
      buttonRotateLeft.innerHTML = `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M13,4.07V1L8.45,5.55L13,10V6.09C15.84,6.57 18,9.03 18,12C18,14.97 15.84,17.43 13,17.91V19.93C16.95,19.44 20,16.08 20,12C20,7.92 16.95,4.56 13,4.07M7.1,18.32C8.26,19.22 9.61,19.76 11,19.93V17.9C10.13,17.75 9.29,17.41 8.54,16.87L7.1,18.32M6.09,13H4.07C4.24,14.39 4.79,15.73 5.69,16.89L7.1,15.47C6.58,14.72 6.23,13.88 6.09,13M7.11,8.53L5.7,7.11C4.8,8.27 4.24,9.61 4.07,11H6.09C6.23,10.13 6.58,9.28 7.11,8.53Z" />
</svg>`;
      editor.appendChild(buttonRotateLeft);
      let buttonRotateRight = document.createElement("button");
      buttonRotateRight.className = "cropper-controls";
      buttonRotateRight.style.left = "64px";
      buttonRotateRight.innerHTML = `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M16.89,15.5L18.31,16.89C19.21,15.73 19.76,14.39 19.93,13H17.91C17.77,13.87 17.43,14.72 16.89,15.5M13,17.9V19.92C14.39,19.75 15.74,19.21 16.9,18.31L15.46,16.87C14.71,17.41 13.87,17.76 13,17.9M19.93,11C19.76,9.61 19.21,8.27 18.31,7.11L16.89,8.53C17.43,9.28 17.77,10.13 17.91,11M15.55,5.55L11,1V4.07C7.06,4.56 4,7.92 4,12C4,16.08 7.05,19.44 11,19.93V17.91C8.16,17.43 6,14.97 6,12C6,9.03 8.16,6.57 11,6.09V10L15.55,5.55Z" />
</svg>`;
      editor.appendChild(buttonRotateRight);
      let buttonCrop = document.createElement("button");
      buttonCrop.className = "cropper-controls active-control";
      buttonCrop.style.left = "104px";
      buttonCrop.innerHTML = `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M7,17V1H5V5H1V7H5V17A2,2 0 0,0 7,19H17V23H19V19H23V17M17,15H19V7C19,5.89 18.1,5 17,5H9V7H17V15Z" />
</svg>`;
      editor.appendChild(buttonCrop);
      let buttonZoomPlus = document.createElement("button");
      buttonZoomPlus.className = "cropper-controls";
      buttonZoomPlus.style.left = "144px";
      buttonZoomPlus.innerHTML = `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M15.5,14L20.5,19L19,20.5L14,15.5V14.71L13.73,14.43C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.43,13.73L14.71,14H15.5M9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14M12,10H10V12H9V10H7V9H9V7H10V9H12V10Z" />
</svg>`;
      editor.appendChild(buttonZoomPlus);
      let buttonZoomMinus = document.createElement("button");
      buttonZoomMinus.className = "cropper-controls";
      buttonZoomMinus.style.left = "184px";
      buttonZoomMinus.innerHTML = `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M15.5,14H14.71L14.43,13.73C15.41,12.59 16,11.11 16,9.5A6.5,6.5 0 0,0 9.5,3A6.5,6.5 0 0,0 3,9.5A6.5,6.5 0 0,0 9.5,16C11.11,16 12.59,15.41 13.73,14.43L14,14.71V15.5L19,20.5L20.5,19L15.5,14M9.5,14C7,14 5,12 5,9.5C5,7 7,5 9.5,5C12,5 14,7 14,9.5C14,12 12,14 9.5,14M7,9H12V10H7V9Z" />
</svg>`;
      editor.appendChild(buttonZoomMinus);
      let buttonLeftRight = document.createElement("button");
      buttonLeftRight.className = "cropper-controls";
      buttonLeftRight.style.left = "224px";
      buttonLeftRight.innerHTML = `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M6.45,17.45L1,12L6.45,6.55L7.86,7.96L4.83,11H19.17L16.14,7.96L17.55,6.55L23,12L17.55,17.45L16.14,16.04L19.17,13H4.83L7.86,16.04L6.45,17.45Z" />
</svg>`;
      editor.appendChild(buttonLeftRight);
      let buttonUpDown = document.createElement("button");
      buttonUpDown.className = "cropper-controls";
      buttonUpDown.style.left = "264px";
      buttonUpDown.innerHTML = `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M17.45,17.55L12,23L6.55,17.55L7.96,16.14L11,19.17V4.83L7.96,7.86L6.55,6.45L12,1L17.45,6.45L16.04,7.86L13,4.83V19.17L16.04,16.14L17.45,17.55Z" />
</svg>`;
      editor.appendChild(buttonUpDown);
      buttonConfirm.addEventListener("click", () => {
        let canvas = cropper.getCroppedCanvas();
        let newFile = this.dataURItoBlob(canvas.toDataURL(file.type));
        newFile.cropped = true;
        newFile.name = file.name;
        this.$refs.uploadBg.dropzone.removeAllFiles();
        this.$refs.uploadBg.dropzone.addFile(newFile);
        this.$refs.uploadBg.dropzone.processQueue();
        this.$refs.uploadBg.dropzone[
            "previewsContainer"
            ].children[1].children[0].children[0].src = canvas.toDataURL(file.type);
        this.$refs.uploadBg.dropzone[
            "previewsContainer"
            ].children[1].children[0].style.borderRadius = 0;
        this.$refs.uploadBg.dropzone[
            "previewsContainer"
            ].children[1].children[1].style.background = "none";
        document.body.removeChild(editor);
        cropper.destroy();
        this.$store.state.loader.previewBg = false;
      });
      buttonRotateRight.addEventListener("click", () => {
        if (!buttonRotateRight.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonRotateRight.classList.add("active-control");
        }
        cropper.rotate(45);
      });
      buttonRotateLeft.addEventListener("click", () => {
        if (!buttonRotateLeft.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonRotateLeft.classList.add("active-control");
        }
        cropper.rotate(-45);
      });
      buttonCrop.addEventListener("click", () => {
        if (!buttonCrop.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonCrop.classList.add("active-control");
        }
        cropper.setDragMode("crop");
      });
      buttonZoomPlus.addEventListener("click", () => {
        if (!buttonZoomPlus.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonZoomPlus.classList.add("active-control");
        }
        cropper.zoom(0.1);
      });
      buttonZoomMinus.addEventListener("click", () => {
        if (!buttonZoomMinus.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonZoomMinus.classList.add("active-control");
        }
        cropper.zoom(-0.1);
      });
      buttonLeftRight.addEventListener("click", () => {
        if (!buttonLeftRight.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonLeftRight.classList.add("active-control");
        }
        if (cropper.getData().scaleX === 1) {
          cropper.scaleX(-1);
        } else {
          cropper.scaleX(1);
        }
      });
      buttonUpDown.addEventListener("click", () => {
        if (!buttonUpDown.classList.contains("active-control")) {
          let prevBtn = editor.getElementsByClassName("active-control");
          prevBtn[0].classList.remove("active-control");
          buttonUpDown.classList.add("active-control");
        }
        if (cropper.getData().scaleY === 1) {
          cropper.scaleY(-1);
        } else {
          cropper.scaleY(1);
        }
      });
      let image = new Image();
      image.src = URL.createObjectURL(file);
      editor.appendChild(image);
      this.$store.state.loader.previewBg = true;
      let cropper = new Cropper(image, {
        preview: ".preview_background",
        aspectRatio: 345 / 570,
      });

    },
    async saveImg(file){
      file.cropped = true;
      this.$refs.uploadBg.dropzone.removeAllFiles();
      this.$refs.uploadBg.dropzone.addFile(file);
      this.$refs.uploadBg.dropzone.processQueue();
      this.$refs.uploadBg.dropzone[
          "previewsContainer"
          ].children[1].children[0].children[0].src = await this.blobToDataURL(file);
      this.$refs.uploadBg.dropzone[
          "previewsContainer"
          ].children[1].children[0].style.borderRadius = 0;
      this.$refs.uploadBg.dropzone[
          "previewsContainer"
          ].children[1].children[1].style.background = "none";
    },
    closeCrop(){
      this.$refs.uploadBg.dropzone.removeAllFiles();
    },
    compress(file){
      new Compressor(file, {
        quality: 0.6,
        mimeType:'image/webp',
        convertSize:0,
        convertTypes:['image/jpeg','image/png','image/jpg'],
        success:(result) => {
          this.$refs.PopupCrop.open(result)
        },
        error(e) {
          console.log(e);
        }
      })
    },
    blobToDataURL(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = _e => resolve(reader.result);
        reader.onerror = _e => reject(reader.error);
        reader.onabort = _e => reject(new Error("Read aborted"));
        reader.readAsDataURL(blob);
      });
    },
      changePage(page){
          this.setSelectedPage(page) ;
      },
      async saveLoader(){
          await this.toServerLoader(this.loaderState);
          this.$refs.PopupSuccess.open();
      },
      setIcon(){
      this.loaderState.icon = this.icon;

      },
    mountDropzone(){
        if(this.loaderState.icon){
          let mockFile = {
            accepted: true,
            cropped: true,
            kind: "image",
          };
          this.$refs.uploadBg.manuallyAddFile(mockFile, this.loaderState.icon);
          this.$refs.uploadBg.dropzone[
          "previewsContainer"
          ].children[1].children[0].children[0].src = this.loaderState.icon;
          this.$refs.uploadBg.dropzone[
          "previewsContainer"
          ].children[1].children[0].style.borderRadius = 0;
          this.$refs.uploadBg.dropzone[
          "previewsContainer"
          ].children[1].children[1].style.background = "none";
          this.$refs.uploadBg.dropzone.emit("complete", mockFile);
        }
    },
    maxFileSexceeded(file) {
      this.$refs.uploadIcon.removeAllFiles();
      this.$refs.uploadIcon.addFile(file);
    },
    maxFileSexceededBg(file) {
      this.$refs.uploadBg.removeAllFiles();
      this.$refs.uploadBg.addFile(file);
    },
    mountDropzoneBg(){
          if(this.loaderState.background){
              if(this.loaderState.background[0] !== '#'){
                let mockFile = {
                accepted: true,
                cropped: true,
                kind: "image",
                };
                
                this.$refs.uploadBg.manuallyAddFile(mockFile, this.loaderState.background);
                this.$refs.uploadBg.dropzone[
                "previewsContainer"
                ].children[1].children[0].children[0].src = this.loaderState.background;
                this.$refs.uploadBg.dropzone[
                "previewsContainer"
                ].children[1].children[0].style.borderRadius = 0;
                this.$refs.uploadBg.dropzone[
                "previewsContainer"
                ].children[1].children[1].style.background = "none";
                this.$refs.uploadBg.dropzone.emit("complete", mockFile);
              }  
          }
  },
    async customEvent(_file, xhr, formData) {
      xhr.setRequestHeader(
        "Authorization",
        `Bearer ${this.$store.state.token}`
      );
      formData.append("lending_settings_id", this.landing.settings_id);
      formData.append("image", _file);
    },
    handleRemove() {
      this.loaderState.icon = null;
    },
    handleSuccess(file) {
      this.loaderState.icon = file.xhr.response;
    },
    handleBgRemove() {
      this.loaderState.background = "#fff";
    },
    handleBgSuccess(file) {
      this.loaderState.background = file.xhr.response;
    },
    dataURItoBlob(dataURI) {
      let byteString = atob(dataURI.split(",")[1]);
      let mimeString = dataURI
          .split(",")[0]
          .split(":")[1]
          .split(";")[0];
      let ab = new ArrayBuffer(byteString.length);
      let ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    },
  },
  async created(){
    await this.getLoader(this.$route.params.id);
    if(!this.loaderState.hotel_id){
        this.loaderState.hotel_id = this.$route.params.id;
    }
  } 
};
</script>

<style lang="scss">
    .v-menu__content theme--light menuable__content__active{
        z-index: 15;
    }
</style>