<template>
<div>
  <div style="display: flex;align-items: center;justify-content: space-between">
    <div style="display: flex;align-items: center;">
      <label class="label" style="display: block;margin-right: 15px;margin-bottom: 0">Activate push notifications</label>
      <v-switch hide-details style="margin:0;padding: 0" v-model="status" @change="toggleFirebaseStatus"></v-switch>
    </div>
  </div>
</div>
</template>

<script>
  import NotificationService from "../../../services/notification.service";

  export default {
    name: "FirebaseNotifications",
    data(){
      return{
        no_auth:false,
        firebase_status:false,
        message:{
          title:"Default title",
          body:"Default message"
        },
        users:[]
      }
    },
    methods:{
      async getFirebaseStatus(){
        const data = await NotificationService.getHotelFireBaseStatus(this.$route.params.id)
        this.firebase_status = !!data;
      },
      async toggleFirebaseStatus(){
        await NotificationService.toggleFirebaseStatus(this.$route.params.id);
      },
      async getAuthUsers(){
        const data = await NotificationService.getFirebaseClients(this.$route.params.id);
        for(let i = 0;i<data.length;i++){
          const item = data[i];
          item.active = false;
          this.users.push(item);
        }
      },
      async sendMessage(){
        const payload = {
          title:this.message.title,
          message:this.message.body,
          hotel_id:+this.$route.params.id,
          is_auth:this.no_auth
        }
        const data = await NotificationService.sendFirebaseNotification(payload);
        console.log(data);
      }
    },
    async mounted() {
      await this.getFirebaseStatus();
      await this.getAuthUsers();

    }
  }
</script>

<style scoped lang="scss">

</style>
