<template>
  <div style="display: flex;flex-direction:column;padding:15px 15px 0 15px;">
    <div style="display: flex;justify-content: space-between">
      <div style="display: flex;justify-content: flex-end">
        <h2 class="constructor__heading">
          Guest Segments
        </h2>
        <v-btn small outlined color="primary">
          <v-icon>mdi-plus</v-icon> New guest segment
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "ConstructorSegmentationOptions.vue"
  }
</script>

<style scoped>

</style>
