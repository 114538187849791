<template>
    <div v-if="content&&listing_item">
        <div
            class="listing-full"
            :style="
          liningStyles(listing_item) +
          `margin-top:${listing_item.img.length ? '0' : `${+landing.branding.topMenu.styles.size * 2.5}px`};min-height:300px;`
        "
            style="
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          overflow: hidden;
        "
    >
        <div
                :style="`width:100%;height:${item.styles.detail_listing_img_height}px;overflow:hidden;`"
                v-show="listing_item.img.includes('1')"
        >
            <div
                    style="height: 100%; overflow: hidden"
                    :style="`width:${
              landing.branding.lining_margin ? '330px' : '345px'
            };`"
                    class="preview_detail"
            ></div>
        </div>
        <swiper
                ref="mySwiper"
                :direction="'horizontal'"
                :pagination="{ clickable: true }"
                :options="swiperOptionsListing"
                style="width: 100%; overflow: hidden;position: relative"
                :style="`height:${item.styles.detail_listing_img_height}px;`"
                v-if="listing_item.img.length && !listing_item.img.includes('1')"
                @slideChange="changeSwiperIndex"
        >
            <swiper-slide v-for="img in listing_item.img" :key="img">
                <img
                        :src="img"
                        alt=""
                        :style="`height:${
                item.styles.detail_listing_img_height
              }px;width:100%;`"
                />
            </swiper-slide>
            <div
                    class="swiper-pagination"
                    style="bottom: 10px"
                    slot="pagination"
            ></div>
        </swiper>
        <div
                class="listing__play_video listing__play_video_with_img"
                :style="`top:${
            +item.styles.detail_listing_img_height / 2 - 25
          }px;position:absolute;z-index:100;`"
                v-if="
            listing_item.video_link &&
            listing_item.img.length &&
            activeIndex === 0
          "
                @click="openVideo(listing_item.video_link)"
        ></div>
        <div
                class="listing__play_video listing__play_video_without_img"
                v-if="listing_item.video_link && listing_item.img.length === 0"
                @click="openVideo(listing_item.video_link)"
        ></div>
        <!-- <h1 class="listing-full__name">
          {{ listing_item.name }}
        </h1> -->
        <div
                class="detail_description"
                style="height: auto; min-height: 100px; width: 100%; padding: 10px 20px;"
                v-html="htmlData(listing_item.full_descr)"
        ></div>
        <div
                class="canvas-socials"
                style="
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      "
        >
            <a
                    class="canvas-socials__item"
                    :href="
          `http://maps.google.com/maps?q=${listing_item.map.lat},${listing_item.map.lng}`
        "
                    target="_blank"
                    :style="
          `color:#000 !important;background-color:#eeeeee;border-radius: 15px;`
        "
            >
        <span class="canvas-socials__icon"
        ><v-icon>mdi-google-maps</v-icon></span
        >
                Directions with google maps
            </a>
        </div>
        <div v-if="listing_item.facilities.length" style="border-radius: 16px;padding:15px;margin-bottom: 15px;display: flex;flex-direction: column;align-items: flex-start;justify-content: flex-start;width: 100%;">
            <div style="font-size: 22px;font-weight: 800;line-height: 26px;margin-bottom: 15px;">What this place offers</div>
            <div style="display: flex;justify-content: space-between;align-items:center;margin-bottom:15px;" v-for="(facility) in listing_item.facilities.slice(0,4)" :key="facility.id">
                <div style="display: flex;align-items: center;">
                    <img v-if="!facility.icon" :src="require('@/assets/none.png')" alt="" style="width: 24px;height:24px;margin-right:15px;">
                    <div style="margin-right: 15px;" v-else v-html="facility.icon"></div>
                    <div style="    color: #222222 !important;
                        font-weight: 400 !important;
                        font-size: 16px !important;
                        line-height: 20px !important;">{{facility.name}}</div>
                </div>
            </div>
            <div style="cursor:pointer;color:#222;text-decoration: underline;font-size: 16px;font-weight: 600;" @click="all_facities_show = true">
                Show all {{listing_item.facilities.length}} facilities
            </div>
        </div>
        <div style="border-radius: 16px;padding:15px;margin-bottom: 15px;display: flex;flex-direction: column;align-items: flex-start;justify-content: flex-start;width: 100%;">
            <div style="font-size: 22px;font-weight: 800;line-height: 26px;margin-bottom: 15px;">Where you'll be</div>
            <div style="font-size: 16px;font-weight: 400;line-height: 26px;margin-bottom: 15px;" v-if="listing_item.city || listing_item.address"><v-icon>mdi-map-marker</v-icon>{{listing_item.city}} {{listing_item.address}}</div>
            <div style="width:100%;height:400px;border-radius:16px;overflow:hidden;" v-if="typeof center.lat === 'number' && typeof center.lng === 'number'">
                <GmapMap
                        ref="gmap"
                        :zoom="12"
                        :center="center"
                        map-type-id="terrain"
                        style="width: 100%; height: 100%"
                        :options="mapStyle"
                >

                    <GmapMarker
                            :position="{
                                    lat: +center.lat,
                                    lng: +center.lng,
                                  }"
                            :icon="{
                                        path: mapIcons.shapes['SQUARE_ROUNDED'],
                                        fillColor: '#fff',
                                        fillOpacity: 1,
                                        strokeColor: '',
                                        strokeWeight: 0,
                                      }"
                            :clickable="true"
                            @click="listing_item.openInfo = true"
                    >

                        <gmap-info-window
                                :position="{
                      lat: +center.lat,
                      lng: +center.lng,
                    }"
                                :options="{
                                      pixelOffset: {
                                        width: 0,
                                        height: 0,
                                      },
                                    }"
                                :opened="listing_item.openInfo"
                                @closeclick="listing_item.openInfo = false"
                        >
                            <div
                                    class="infoWindow"
                                    style="cursor:pointer;display: flex; flex-direction: column;align-items: center;justify-content: center;"
                            >
                                <img :src="listing_item.main_img" alt="" style="width: 100%;max-height: 150px;margin-bottom: 10px;">
                                <p style="
                                            font-size: 15px;
                                            font-weight: 400;
                                            font-family: Inter, sans-serif">{{ listing_item.name }}</p>
                            </div>
                        </gmap-info-window>
                        <!-- @click="openDetailPoint(point)" -->
                    </GmapMarker>
                    <GmapCustomMarker
                            :marker="{
                                    lat: +center.lat,
                                    lng: +center.lng,
                                  }"
                            :offsetY="-15"
                            @click="listing_item.openInfo = true"
                    >
                        <v-icon
                                @click="listing_item.openInfo = true"
                        >mdi-home</v-icon>
                    </GmapCustomMarker>
                </GmapMap>
            </div>
        </div>
        <div v-if="listing_item.messengers.length" style="border-radius: 16px;padding:15px;margin-bottom: 15px;display: flex;flex-direction: column;align-items: flex-start;justify-content: flex-start;width: 100%;">
            <div style="font-size: 22px;font-weight: 800;line-height: 26px;">Socials</div>
            <ul class="hotel-socials"
            >
                <li class="hotel-socials__item"
                    v-for="item in listing_item.messengers"
                    :key="item.id"
                    :style="`width:100%;background-color: ${item.color};
                        border-radius: 32px`"
                    target="_blank"
                >
                    <a class="hotel-socials__link" rel="noopener noreferrer" target="_blank" :href="item.link">
                        <span class="hotel-socials__icon" v-html="item.icon"></span>
                        {{item.text}}
                    </a>
                </li>
            </ul>
        </div>
        <div v-if="listing_item.socials.length" style="border-radius: 16px;padding:15px;margin-bottom: 15px;display: flex;flex-direction: column;align-items: flex-start;justify-content: flex-start;width: 100%;">
            <div style="font-size: 22px;font-weight: 800;line-height: 26px;">Share</div>
            <ul class="hotel-socials"
            >
                <li class="hotel-socials__item"
                    v-for="item in listing_item.socials"
                    :key="item.id"
                    :style="`width:100%;background-color: ${item.color};
                        border-radius: 32px`"
                    target="_blank"
                >
                    <a class="hotel-socials__link" rel="noopener noreferrer" target="_blank" :href="item.link">
                        <span class="hotel-socials__icon" v-html="item.icon"></span>
                        {{item.text}}
                    </a>
                </li>
            </ul>
        </div>
    </div>
    </div>
</template>

<script>
  import { mapState } from "vuex";
  import moment from "moment";
  import "swiper/swiper-bundle.css";
  import "swiper/modules/pagination/pagination.min.css";
  import SwiperCore, { Pagination } from "swiper";
  import { Swiper, SwiperSlide } from "vue-awesome-swiper";
  import "@/assets/map-icons/fonts/map-icons.ttf";
  import "@/assets/map-icons/css/map-icons.min.css";
  import mapIcons from "@/assets/map-icons/js/map-icons.js";
  import GmapCustomMarker from "vue2-gmap-custom-marker";
  SwiperCore.use([Pagination]);
  export default {
    name: "typeRealEstateDetailPreview",
    components: {
      Swiper,
      SwiperSlide,
      GmapCustomMarker
    },
    computed: {
      ...mapState(["landing"]),
      ...mapState("realEstate",['realEstates','realEstate']),
      mySwiper: () => {
        return this.$refs.mySwiper.swiper;
      },
      marginTop(){
        if(this.content.images.length){
          return '0'
        }
        if(this.landing.advanced.global.active_header_inside ||
          this.landing.advanced.global.active_logo_inside){
          return '20px'
        }
        return '60px'
      }
    },
    mounted(){
      const candidate = this.realEstates.find(x => x.id === +this.$store.state.realEstate.previewPage.slice(6));
      if(candidate){
        this.content = candidate;
      }
      this.mapIcons = mapIcons;
      this.checkData(this.$store.state.realEstate.realEstates);
    },
    props:['item'],
    data() {
      return {
        mapStyle: {
          disableDefaultUi: true,
          zoomControl: true,
          streetViewControl: false,
          mapTypeControl: false,
          fullscreenControl: true,
          styles: [
            {
              featureType: 'poi',
              stylers: [{visibility: "off"}]
            },
          ]
        },

        center:null,
        mapIcons:null,
        listing_item:null,
        activeIndex: 0,
        swiperOptionsListing: {
          grabCursor: true,
          centeredSlides: true,
          pagination: {
            el: ".swiper-pagination",
            type: "bullets",
            clickable: true,
          },
        },
        defaultStylesDetail:{
        },
        content:null
      };
    },
    methods: {
      async checkData(data){
        this.listings = [];
        for(let i =0;i<data.length;i++) {
          let item = data[i];
          let defaultListingGroup = {
            title: item.category,
            hide: false,
            content: [
              {
                socials:item.booking_links,
                messengers:item.messengers,
                facilities:item.facilities,
                options:{
                  bed:item.beds_count,
                  bathroom:item.wc_count,
                  room_size:item.room_size,
                  max_guest:item.max_guest
                },
                map: {
                  lng: item.coordinates.lng,
                  lat: item.coordinates.lat,
                  location: item.city
                },
                reviews: {
                  count: item.review_count?item.review_count:0,
                  score: item.review_score?item.review_score:0
                },
                price:item.price,
                sale_price:item.sale_price&&+item.sale_price!==+item.price?item.sale_price:null,
                address: item.address,
                detail_link: "",
                img: item.images.length ? item.images : ['https://tourist.hr/images/default/item.jpg'],
                video_link: item.video_link,
                name: item.name[this.landing.advanced.default_lang],
                descr: "",
                main_img: item.main_image ? item.main_image : 'https://tourist.hr/images/default/item.jpg',
                preview: false,
                full_descr: item.description[this.landing.advanced.default_lang],
                hide: true,
                show_price: true,
                show_descr: true,
                name_color: "#000000",
                favorite: false,
                position: "center center",
                background_color: "rgba(255,255,255,1)",
                descr_color: "#000000",
                show_name_color: false,
                show_descr_color: false,
                id: item.id,
                openInfo:false,
              },
            ],
          };
          if(+this.$store.state.realEstate.previewPage.slice(6) === item.id){
            this.listing_item = defaultListingGroup.content[0];
            this.center = { lat: +this.listing_item.map.lat, lng: +this.listing_item.map.lng };
          }
          const candidate = this.listings.find(x => x.title === defaultListingGroup.title);
          if (candidate) {
            candidate.content.push(defaultListingGroup.content[0]);
          } else {
            this.listings.push(defaultListingGroup);
          }
        }

      },
      liningStyles(listing_item) {
        let styles;
        if (this.landing.branding.bg_lining_img) {
          styles = `background-image:url(${this.landing.branding.bg_lining_img});background-size:cover;background-repeat:no-repeat;`;
        } else {
          styles = `background-color:${this.landing.branding.bg_lining_color};`;
        }
        console.log(listing_item);
        if (listing_item) {
          if (listing_item.img.length) {
            styles += `border-bottom-left-radius:${this.landing.branding.lining_radius}px;border-bottom-right-radius:${this.landing.branding.lining_radius}px;`;
          } else {
            styles += `border-radius:${this.landing.branding.lining_radius}px;`;
          }
        }
        return styles;
      },
      changeSwiperIndex() {
        this.activeIndex = this.$refs.mySwiper.$swiper.activeIndex;
      },
      openVideo(link) {
        window.open(link, "_blank");
      },
      timeNow() {
        this.datenow = moment().format("HH:mm");
        setInterval(() => {
          this.datenow = moment().format("HH:mm");
        }, 1000);
      },
      htmlData(data, type) {
        if (data) {
          const weatherInd = data.split(" ").indexOf(":weather:");
          let result = data.split(" ");
          if (weatherInd && this.landing.advanced.global.show_weather) {
            const newArr = result;
            newArr[weatherInd] =
              this.landing.advanced.global.weather.week.daily[0].temp.day.toFixed(
                0
              ) + " ℃";
            result = newArr;
          }
          const timeInd = data.split(" ").indexOf(":time:");
          if (timeInd) {
            const newArr = result;
            newArr[timeInd] = this.datenow;
            result = newArr;
          }
          const dateInd = data.split(" ").indexOf(":date:");
          if (dateInd) {
            const newArr = result;
            newArr[dateInd] = this.dateToday;
            result = newArr;
          }
          const user_name = data.split(" ").indexOf(":name:");
          if (
            user_name &&
            this.landing.advanced.global.auth &&
            this.landing.advanced.auth.name &&
            type === "title"
          ) {
            const newArr = result;
            newArr[user_name] = "Agent";
            result = newArr;
          }
          const user_surname = data.split(" ").indexOf(":surname:");
          if (
            user_surname &&
            this.landing.advanced.global.auth &&
            this.landing.advanced.auth.name &&
            type === "title"
          ) {
            const newArr = result;
            newArr[user_surname] = "Smith";
            result = newArr;
          }
          const user_birthday = data.split(" ").indexOf(":birthday:");
          if (
            user_surname &&
            this.landing.advanced.global.auth &&
            this.landing.advanced.auth.name &&
            type === "title"
          ) {
            const newArr = result;
            newArr[user_birthday] = "24.03.1999";
            result = newArr;
          }
          const user_room = data.split(" ").indexOf(":room:");
          if (
            user_surname &&
            this.landing.advanced.global.auth &&
            this.landing.advanced.auth.name &&
            type === "title"
          ) {
            const newArr = result;
            newArr[user_room] = "707";
            result = newArr;
          }

          const user_auth = data.split(" ").indexOf(":auth:");
          if (user_auth && this.landing.advanced.global.auth && !type) {
            const newArr = result;
            newArr[user_auth] = `<a href="#">Login</a>`;
            result = newArr;
          }
          const user_logout = data.split(" ").indexOf(":logout:");
          if (user_logout && this.landing.advanced.global.auth && !type) {
            const newArr = result;
            newArr[user_logout] = `<a href="#">Logout</a>`;
            result = newArr;
          }
          return result.join(" ");
        }
      },
    },
  };
</script>

<style lang="scss">
    #typeGalleryPreview ul{
        padding-left: 15px;
    }
    .OptionsContentItem{
        margin-bottom: 15px;
        &Icon{
            margin-right:15px;
            font-size:24px;
        }
        &Text{
            font-size:16px;
        }
    }
</style>
