<template>
  <div class="form">
    <div class="field">
      <label style="display:block;margin-bottom:15px;font-weight:bold;font-size:20px;" class="label">Footer settings</label>
      <label class="label" style="display:block;margin-bottom:15px;">Upload image</label>
      <div class="control" style="padding-bottom: 15px">
        <vue-dropzone
          id="upload"
          ref="upload"
          :options="dropzoneOptions"
          @vdropzone-sending="customEvent"
          @vdropzone-removed-file="removeFile"
          @vdropzone-success="onSuccess"
          @vdropzone-mounted="mountDropzone"
        />
      </div>
    </div>
    <div class="field" v-if="branding.footer_img"  style="display:flex;align-items:center;margin-bottom: 15px">
      <label class="label" style="display:block;margin-bottom:0;min-width:200px;"
        >Width <b>{{ branding.footer_size.width }} %</b></label
      >
        <div class="slider" style="width:100%">
          <v-slider
            hide-details
            :min="10"
            :max="100"
            v-model="branding.footer_size.width"
            @input="setCanvas"
          ></v-slider>
        </div>
    </div>
    <div class="field" v-if="branding.footer_img"  style="display:flex;align-items:center;margin-bottom: 15px">
      <label class="label" style="display:block;margin-bottom:0;min-width:200px;"
        >Height <b>{{ branding.footer_size.height }} px</b></label
      >
        <div class="slider" style="width:100%">
          <v-slider
            hide-details
            :min="100"
            :max="400"
            v-model="branding.footer_size.height"
            @input="setCanvas"
          ></v-slider>
        </div>
    </div>
    <div class="field" v-if="branding.footer_img"  style="display:flex;align-items:center;margin-bottom: 15px">
      <label class="label" style="display:block;margin-bottom:0;min-width:200px;"
        >Border radius <b>{{ branding.footer_size.radius }} px</b></label
      >
        <div class="slider" style="width:100%">
          <v-slider
            hide-details
            :min="0"
            :max="50"
            v-model="branding.footer_size.radius"
            @input="setCanvas"
          ></v-slider>
        </div>
    </div>
    <PopupCrop title='Change image before upload?' ref="PopupCrop" @confirm="openEditor" @save="saveImg"/>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Cropper from "cropperjs";
import "../../../../node_modules/cropperjs/dist/cropper.min.css";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import vueDropzone from "vue2-dropzone";
import PopupCrop from "@/components/Popup/PopupCrop.vue";
import Compressor from "compressorjs";
export default {
  name: "FooterBranding",
  components: {
    vueDropzone,
    PopupCrop
  },
  mounted(){
    let canvas = document.getElementsByClassName('canvas');
    if(canvas[0]) {
      canvas[0].scrollTop = canvas[0].scrollHeight;
    }
  },
  data() {
    return {
      dropzoneOptions: {
        url: () => {
          return process.env.VUE_APP_API_URL + "upload-image";
        },
        thumbnailMethod: "contain",
        acceptedFiles: "image/*",
        addRemoveLinks: true,
        maxFiles: 2,
        maxFilesize: 3,
        dictFileTooBig:"File is too big ({{filesize}}mb). Max file size: 3mb",
        autoProcessQueue: false,
        destroyDropzone: false,
        init: function() {
          this.handleFiles = function(files) {
            var file, _i, _len, _results;
            _results = [];
            for (_i = 0, _len = files.length; _i < _len; _i++) {
              file = files[_i];
              _results.push(this.addFile(file));
              break;
            }
            return _results;
          };
          this._addFilesFromItems = function(items) {
            var entry, item, _i, _len, _results;
            _results = [];
            for (_i = 0, _len = items.length; _i < _len; _i++) {
              item = items[_i];
              if ((item.webkitGetAsEntry != null) && (entry = item.webkitGetAsEntry())) {
                if (entry.isFile) {
                  _results.push(this.addFile(item.getAsFile()));
                } else if (entry.isDirectory) {
                  _results.push(this._addFilesFromDirectory(entry, entry.name));
                } else {
                  _results.push(void 0);
                }
              } else if (item.getAsFile != null) {
                if ((item.kind == null) || item.kind === "file") {
                  _results.push(this.addFile(item.getAsFile()));
                } else {
                  _results.push(void 0);
                }
              } else {
                _results.push(void 0);
              }
              break;
            }
            return _results;
          };
        },
        thumbnail: (file, dataUrl) => {
          if (file.cropped) {
            return;
          }else{
            this.compress(file)
          }
        },
      },
    };
  },
  methods: {
    compress(file){
      new Compressor(file, {
        quality: 0.6,
        mimeType:'image/webp',
        convertSize:0,
        convertTypes:['image/jpeg','image/png','image/jpg'],
        success:(result) => {
          this.$refs.PopupCrop.open(result)
        },
        error(e) {
          console.log(e);
        }
      })
    },
    blobToDataURL(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = _e => resolve(reader.result);
        reader.onerror = _e => reject(reader.error);
        reader.onabort = _e => reject(new Error("Read aborted"));
        reader.readAsDataURL(blob);
      });
    },
    async saveImg(file){
      file.cropped = true;
      this.$refs.upload.dropzone.removeAllFiles();
      this.$refs.upload.dropzone.addFile(file);
      this.$refs.upload.dropzone.processQueue();
      this.$refs.upload.dropzone[
        "previewsContainer"
      ].children[1].children[0].children[0].src = await this.blobToDataURL(file);
      this.$refs.upload.dropzone[
        "previewsContainer"
      ].children[1].children[0].style.borderRadius = 0;
      this.$refs.upload.dropzone[
        "previewsContainer"
      ].children[1].children[1].style.background = "none";
    },
    openEditor(file){
          let editor = document.createElement("div");
          editor.style.position = "fixed";
          editor.style.left = 0;
          editor.style.right = 0;
          editor.style.top = 0;
          editor.style.bottom = 0;
          editor.style.width = "66%";
          editor.style.zIndex = 9999;
          editor.style.backgroundColor = "#000";
          document.body.appendChild(editor);

          if (this.branding.header_image) {
            this.$refs.upload.dropzone.removeFile(file);
          }
          let buttonConfirm = document.createElement("button");
          buttonConfirm.style.position = "absolute";
          buttonConfirm.style.right = "10px";
          buttonConfirm.style.top = "10px";
          buttonConfirm.style.zIndex = 9999;
          buttonConfirm.textContent = "Save image";
          buttonConfirm.className =
            "v-btn v-size--small confirm-control";
          editor.appendChild(buttonConfirm);
          let sliderRadius = document.createElement('div')
          sliderRadius.style.position = 'absolute';
          sliderRadius.style.bottom = '20px';
          sliderRadius.style.left = "24px"
          sliderRadius.style.zIndex = '10';
          sliderRadius.innerHTML += ' <div class="slider-component"><span class="sliderRadiusText" style="color:#fff;font-size:18px; font-family: Inter, sans-serif;">Change radius '+this.branding.footer_size.radius+' px</span><div class="slidecontainer"><input style="width:300px;" ref="input" type="range" min="0" max="50" value="'+this.branding.footer_size.radius+'" class="slider"></div></div>'
          let sliderInputRadius = sliderRadius.getElementsByClassName('slider')[0];
           sliderInputRadius.addEventListener("input", (e) => {
            this.branding.footer_size.radius = e.target.value;
            let sliderRadiusText = sliderRadius.getElementsByClassName('sliderRadiusText')[0];
            sliderRadiusText.innerHTML = 'Change radius '+this.branding.footer_size.radius+' px';
            this.setCanvas();
          });
          editor.append(sliderRadius);
          let sliderWidth = document.createElement('div')
          sliderWidth.style.position = 'absolute';
          sliderWidth.style.bottom = '20px';
          sliderWidth.style.left = "350px"
          sliderWidth.style.zIndex = '10';
          sliderWidth.innerHTML += ' <div class="slider-component"><span class="sliderWidthText" style="color:#fff;font-size:18px; font-family: Inter, sans-serif;">Change width '+this.branding.footer_size.width+' %</span><div class="slidecontainer"><input style="width:300px;" ref="input" type="range" min="10" max="100" value="'+this.branding.footer_size.width+'" class="slider"></div></div>'
          let sliderInputWidth = sliderWidth.getElementsByClassName('slider')[0];
           sliderInputWidth.addEventListener("input", (e) => {
            this.branding.footer_size.width = e.target.value;
            let ratio = (345/100*+this.branding.footer_size.width) / +this.branding.footer_size.height;
            cropper.setAspectRatio(ratio);
            document.getElementsByClassName('canvas-footer_preview')[0].style.height = '150px'
            cropper.previews[0].cropperPreview.width = (345/100*+this.branding.footer_size.width);
            cropper.previews[0].cropperPreview.height = +this.branding.footer_size.height;
            let sliderWidthText = sliderWidth.getElementsByClassName('sliderWidthText')[0];
            sliderWidthText.innerHTML = 'Change width '+this.branding.footer_size.width+' %';
            this.setCanvas();
          });
          editor.appendChild(sliderWidth);
          let sliderHeight = document.createElement('div')
          sliderHeight.style.position = 'absolute';
          sliderHeight.style.bottom = '20px';
          sliderHeight.style.left = "680px"
          sliderHeight.style.zIndex = '10';
          sliderHeight.innerHTML += ' <div class="slider-component"><span class="sliderHeightText" style="color:#fff;font-size:18px; font-family: Inter, sans-serif;">Change height '+this.branding.footer_size.height+' px</span><div class="slidecontainer"><input style="width:300px;" ref="input" type="range" min="100" max="400" value="'+this.branding.footer_size.height+'" class="slider"></div></div>'
          let sliderInputHeight = sliderHeight.getElementsByClassName('slider')[0];
           sliderInputHeight.addEventListener("input", (e) => {
            this.branding.footer_size.height = e.target.value;
            let ratio = (345/100*+this.branding.footer_size.width) / this.branding.footer_size.height
            cropper.setAspectRatio(ratio);
            document.getElementsByClassName('canvas-footer_preview')[0].style.height = this.branding.footer_size.height+'px'
            cropper.previews[0].cropperPreview.width = (345/100*+this.branding.footer_size.width);
            cropper.previews[0].cropperPreview.height = this.branding.footer_size.height;
            cropper.previews[0].style.height = this.branding.footer_size.height+'px';
            let sliderHeightText = sliderHeight.getElementsByClassName('sliderHeightText')[0];
            sliderHeightText.innerHTML = 'Change height '+this.branding.footer_size.height+' px';
            this.setCanvas();
          });
          editor.appendChild(sliderHeight);
          let buttonCancel = document.createElement("button");
          buttonCancel.style.position = "absolute";
          buttonCancel.style.right = "135px";
          buttonCancel.style.top = "10px";
          buttonCancel.style.zIndex = 9999;
          buttonCancel.textContent = "Cancel";
          buttonCancel.className =
            "v-btn v-size--small confirm-control";
          editor.appendChild(buttonCancel);
          buttonCancel.addEventListener("click", () => {
            this.$refs.upload.dropzone.removeAllFiles();
            document.body.removeChild(editor);
            cropper.destroy();
          });
          let buttonRotateLeft = document.createElement("button");
          buttonRotateLeft.className = "cropper-controls";
          buttonRotateLeft.style.left = "24px";
          buttonRotateLeft.innerHTML =
            `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M13,4.07V1L8.45,5.55L13,10V6.09C15.84,6.57 18,9.03 18,12C18,14.97 15.84,17.43 13,17.91V19.93C16.95,19.44 20,16.08 20,12C20,7.92 16.95,4.56 13,4.07M7.1,18.32C8.26,19.22 9.61,19.76 11,19.93V17.9C10.13,17.75 9.29,17.41 8.54,16.87L7.1,18.32M6.09,13H4.07C4.24,14.39 4.79,15.73 5.69,16.89L7.1,15.47C6.58,14.72 6.23,13.88 6.09,13M7.11,8.53L5.7,7.11C4.8,8.27 4.24,9.61 4.07,11H6.09C6.23,10.13 6.58,9.28 7.11,8.53Z" />
</svg>`;
          editor.appendChild(buttonRotateLeft);
          let buttonRotateRight = document.createElement("button");
          buttonRotateRight.className = "cropper-controls";
          buttonRotateRight.style.left = "64px";
          buttonRotateRight.innerHTML =
            `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M16.89,15.5L18.31,16.89C19.21,15.73 19.76,14.39 19.93,13H17.91C17.77,13.87 17.43,14.72 16.89,15.5M13,17.9V19.92C14.39,19.75 15.74,19.21 16.9,18.31L15.46,16.87C14.71,17.41 13.87,17.76 13,17.9M19.93,11C19.76,9.61 19.21,8.27 18.31,7.11L16.89,8.53C17.43,9.28 17.77,10.13 17.91,11M15.55,5.55L11,1V4.07C7.06,4.56 4,7.92 4,12C4,16.08 7.05,19.44 11,19.93V17.91C8.16,17.43 6,14.97 6,12C6,9.03 8.16,6.57 11,6.09V10L15.55,5.55Z" />
</svg>`;
          editor.appendChild(buttonRotateRight);
          let buttonCrop = document.createElement("button");
          buttonCrop.className = "cropper-controls active-control";
          buttonCrop.style.left = "104px";
          buttonCrop.innerHTML =
            `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M7,17V1H5V5H1V7H5V17A2,2 0 0,0 7,19H17V23H19V19H23V17M17,15H19V7C19,5.89 18.1,5 17,5H9V7H17V15Z" />
</svg>`;
          editor.appendChild(buttonCrop);
          let buttonZoomPlus = document.createElement("button");
          buttonZoomPlus.className = "cropper-controls";
          buttonZoomPlus.style.left = "144px";
          buttonZoomPlus.innerHTML =`<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M15.5,14L20.5,19L19,20.5L14,15.5V14.71L13.73,14.43C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.43,13.73L14.71,14H15.5M9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14M12,10H10V12H9V10H7V9H9V7H10V9H12V10Z" />
</svg>`;
          editor.appendChild(buttonZoomPlus);
          let buttonZoomMinus = document.createElement("button");
          buttonZoomMinus.className = "cropper-controls";
          buttonZoomMinus.style.left = "184px";
          buttonZoomMinus.innerHTML =`<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M15.5,14H14.71L14.43,13.73C15.41,12.59 16,11.11 16,9.5A6.5,6.5 0 0,0 9.5,3A6.5,6.5 0 0,0 3,9.5A6.5,6.5 0 0,0 9.5,16C11.11,16 12.59,15.41 13.73,14.43L14,14.71V15.5L19,20.5L20.5,19L15.5,14M9.5,14C7,14 5,12 5,9.5C5,7 7,5 9.5,5C12,5 14,7 14,9.5C14,12 12,14 9.5,14M7,9H12V10H7V9Z" />
</svg>`;
          editor.appendChild(buttonZoomMinus);
          let buttonLeftRight = document.createElement("button");
          buttonLeftRight.className = "cropper-controls";
          buttonLeftRight.style.left = "224px";
          buttonLeftRight.innerHTML =`<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M6.45,17.45L1,12L6.45,6.55L7.86,7.96L4.83,11H19.17L16.14,7.96L17.55,6.55L23,12L17.55,17.45L16.14,16.04L19.17,13H4.83L7.86,16.04L6.45,17.45Z" />
</svg>`;
          editor.appendChild(buttonLeftRight);
          let buttonUpDown = document.createElement("button");
          buttonUpDown.className = "cropper-controls";
          buttonUpDown.style.left = "264px";
          buttonUpDown.innerHTML =`<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#007db5" d="M17.45,17.55L12,23L6.55,17.55L7.96,16.14L11,19.17V4.83L7.96,7.86L6.55,6.45L12,1L17.45,6.45L16.04,7.86L13,4.83V19.17L16.04,16.14L17.45,17.55Z" />
</svg>`;
          editor.appendChild(buttonUpDown);
          buttonConfirm.addEventListener("click", () => {
            let canvas = cropper.getCroppedCanvas();
                  let newFile = this.dataURItoBlob(canvas.toDataURL(file.type));
                  newFile.cropped = true;
                  newFile.name = file.name;
                  this.$refs.upload.dropzone.removeAllFiles();
                  this.$refs.upload.dropzone.addFile(newFile);
                  this.$refs.upload.dropzone.processQueue();
                  this.$refs.upload.dropzone[
                    "previewsContainer"
                  ].children[1].children[0].children[0].src = canvas.toDataURL(file.type);
                  this.$refs.upload.dropzone[
                    "previewsContainer"
                  ].children[1].children[0].style.borderRadius = 0;
                  this.$refs.upload.dropzone[
                    "previewsContainer"
                  ].children[1].children[1].style.background = "none";
                  document.body.removeChild(editor);
                  cropper.destroy();
                  this.branding.footer_preview = false;
                  this.setCanvas();
          });
          buttonRotateRight.addEventListener("click", () => {
            if (!buttonRotateRight.classList.contains("active-control")) {
              let prevBtn = editor.getElementsByClassName("active-control");
              prevBtn[0].classList.remove("active-control");
              buttonRotateRight.classList.add("active-control");
            }
            cropper.rotate(45);
          });
          buttonRotateLeft.addEventListener("click", () => {
            if (!buttonRotateLeft.classList.contains("active-control")) {
              let prevBtn = editor.getElementsByClassName("active-control");
              prevBtn[0].classList.remove("active-control");
              buttonRotateLeft.classList.add("active-control");
            }
            cropper.rotate(-45);
          });

          buttonCrop.addEventListener("click", () => {
            if (!buttonCrop.classList.contains("active-control")) {
              let prevBtn = editor.getElementsByClassName("active-control");
              prevBtn[0].classList.remove("active-control");
              buttonCrop.classList.add("active-control");
            }
            cropper.setDragMode("crop");
          });
          buttonZoomPlus.addEventListener("click", () => {
            if (!buttonZoomPlus.classList.contains("active-control")) {
              let prevBtn = editor.getElementsByClassName("active-control");
              prevBtn[0].classList.remove("active-control");
              buttonZoomPlus.classList.add("active-control");
            }
            cropper.zoom(0.1);
          });
          buttonZoomMinus.addEventListener("click", () => {
            if (!buttonZoomMinus.classList.contains("active-control")) {
              let prevBtn = editor.getElementsByClassName("active-control");
              prevBtn[0].classList.remove("active-control");
              buttonZoomMinus.classList.add("active-control");
            }
            cropper.zoom(-0.1);
          });
          buttonLeftRight.addEventListener("click", () => {
            if (!buttonLeftRight.classList.contains("active-control")) {
              let prevBtn = editor.getElementsByClassName("active-control");
              prevBtn[0].classList.remove("active-control");
              buttonLeftRight.classList.add("active-control");
            }
            if (cropper.getData().scaleX === 1) {
              cropper.scaleX(-1);
            } else {
              cropper.scaleX(1);
            }
          });
          buttonUpDown.addEventListener("click", () => {
            if (!buttonUpDown.classList.contains("active-control")) {
              let prevBtn = editor.getElementsByClassName("active-control");
              prevBtn[0].classList.remove("active-control");
              buttonUpDown.classList.add("active-control");
            }
            if (cropper.getData().scaleY === 1) {
              cropper.scaleY(-1);
            } else {
              cropper.scaleY(1);
            }
          });
          let image = new Image();
          image.src = URL.createObjectURL(file);
          editor.appendChild(image);
          this.branding.footer_preview = true;
          this.setCanvas();
          let cropper = new Cropper(image, {
            preview: ".canvas-footer_preview",
            aspectRatio: (343*(+this.branding.footer_size.width/100)/+this.branding.footer_size.height),
          });
    },
    setImgFooter(url){
      if (this.landing.branding.footer_img === url) {
        this.landing.branding.footer_img = null;
        this.setCanvas();
      } else {
        this.landing.branding.footer_img = url;
        this.setCanvas();
      }
    },
    setCanvas() {
      this.$store.commit("landing/setBranding", this.branding, true);
    },
    async customEvent(_file, xhr, formData) {
      xhr.setRequestHeader(
        "Authorization",
        `Bearer ${this.$store.state.token}`
      );
      formData.append("lending_settings_id", this.landing.settings_id);
      formData.append("image", _file);
    },
    mountDropzone() {
      if (this.landing.branding.footer_img) {
        let mockFile = {
          accepted: true,
          cropped: true,
          kind: "image",
          ...this.landing.branding,
        };
        this.$refs.upload.manuallyAddFile(
          mockFile,
          this.landing.branding.footer_img
        );
        this.$refs.upload.dropzone[
          "previewsContainer"
        ].children[1].children[0].children[0].src =
          this.landing.branding.footer_img;
        this.$refs.upload.dropzone[
          "previewsContainer"
        ].children[1].children[0].style.borderRadius = 0;
        this.$refs.upload.dropzone[
          "previewsContainer"
        ].children[1].children[1].style.background = "none";
        this.$refs.upload.dropzone.emit("complete", mockFile);
      }
    },
    dataURItoBlob(dataURI) {
      let byteString = atob(dataURI.split(",")[1]);
      let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
      let ab = new ArrayBuffer(byteString.length);
      let ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    },
    onSuccess(file) {
      this.landing.branding.footer_img = file.xhr.response;
    },
    removeFile() {
      this.landing.branding.footer_img = null;
    },
  },
  computed: {
    ...mapState(["landing"]),
    branding() {
      return JSON.parse(JSON.stringify(this.landing.branding));
    },
    VUE_APP_LINK(){
      return process.env.VUE_APP_LINK
    }
  },
};
</script>

<style>
</style>
