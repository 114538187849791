import $axios from "../plugins/axios";
const END_POINT = 'banner-pwa';
const PWAService = {
  async getBannerPWAByHotelID(hotel_id){
    try{
      const {data} = await $axios.get(`banner-pws/${hotel_id}`);
      return data;
    }catch (e) {
      return e;
    }
  },
  async getBannerPWAByID(hotel_id){
    try{
      const {data} = await $axios.get(`${END_POINT}/${hotel_id}`);
      return data;
    }catch (e) {
      return e;
    }
  },
  async postBannerPWA(payload){
    try{
      const {data} = await $axios.post(END_POINT,payload);
      return data;
    }catch (e) {
      return e;
    }
  },
  async putBannerPWA(payload){
    try{
      const {data} = await $axios.put(`${END_POINT}/${payload.id}`,payload);
      return data;
    }catch (e) {
      return e;
    }
  },
  async deleteBannerPWA(id){
    try{
      const {data} = await $axios.delete(`${END_POINT}/${id}`);
      return data;
    }catch (e) {
      return e;
    }
  },
  async getPwaStatistcs(payload){
    try{
      const {data} = await $axios.get(`get-pwa-statistic/${payload.hotel_id}?from=${payload.date_start}&to=${payload.date_end}`);
      return data;
    }catch (e) {
      return e;
    }
  }
};
export default PWAService;
