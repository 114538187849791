<template>
  <div v-if="bottomBanner" style="position:relative;overflow:hidden;display: flex;align-items:center;justify-content: center;" :style="`height:${bottomBanner.styles.height}px;width:100%;`">
     <div class="preview_bottom_banner" v-if="preview" :style="`position:absolute;top:0;left:0;height:${bottomBanner.styles.height}px;width:${bottomBanner.styles.width}%;`">
    </div>
    <img v-if="!preview&&bottomBanner.type !== 3" @click="openLinkAddBanner(bottomBanner.link[landing.current_lang])" :src="bottomBanner.image[landing.current_lang] ? bottomBanner.image[landing.current_lang] : require('@/assets/none.png')" :style="`cursor:${bottomBanner.type === 1 && bottomBanner.link[landing.current_lang] ? 'pointer' : 'normal'};height:${bottomBanner.styles.height}px;width:${bottomBanner.styles.width}%;`" alt="">
    <div @click="openLinkAddBanner(bottomBanner.video_link[landing.current_lang])" v-if="bottomBanner.type === 2 && bottomBanner.video_link[landing.current_lang]" class="play-video" :style="styleObjectPlay"></div>
    <video
        v-if="bottomBanner.type === 3 && bottomBanner.video[landing.current_lang]"
        :src="bottomBanner.video[landing.current_lang]"
        playsinline
        autoplay
        muted
        loop
        :height="`${bottomBanner.styles.height}%`"
        :width="`${bottomBanner.styles.width}%`"
        style="
                      position: absolute;
                      object-fit: cover;
                      z-index: 250;
                    "
    ></video>
  </div>
</template>

<script>
import { mapActions,mapMutations,mapState} from "vuex";

export default {
  name: "BottomBannerPreview",
  computed:{
    ...mapState('bottomBanner',['bottomBanner','preview','bottomBannersList']),
    ...mapState(['landing']),
    styleObjectPlay() {
      return {
        "--color":
        this.addBottomBannerModel.styles.banner_video_icon_color,
        "--color-hover": this.addBottomBannerModel.styles.banner_video_icon_color_hover,
        width: this.addBottomBannerModel.styles.banner_video_icon_size + "px",
        height: this.addBottomBannerModel.styles.banner_video_icon_size + "px",
        left: `calc(50% - ${this.addBottomBannerModel.styles.banner_video_icon_size / 2}px)`,
        top: `calc(50% - ${this.addBottomBannerModel.styles.banner_video_icon_size / 2}px)`,
      };
    },
  },
  methods:{
    ...mapActions('bottomBanner',['getBottomBanners']),
    ...mapMutations('bottomBanner',['setBottomBanner']),
    openLinkAddBanner(link){
      if(link){
        window.open(link,'_blank')
      }
    },
  },
  async mounted(){
    if(!this.bottomBanner){
      await this.getBottomBanners(this.$route.params.id);
      if(this.bottomBannersList.length){
        this.setBottomBanner(this.bottomBannersList[0]);
      }
    }
  }
}
</script>

<style scoped>

</style>