<template>
  <div
    class="preview"
    :style="`position:sticky;`"
  >
    <div class="preview__header"
         v-if="!isMobile">
      <v-btn
        class="preview__btn"
        :class="{ preview__btn_active: view === 'mobile' }"
        fab
        tile
        small
        depressed
        :color="'#e8e8e8'"
        @click="setView('mobile')"
      >
        <v-icon>mdi-cellphone</v-icon>
      </v-btn>

<!--      <v-btn-->
<!--        class="preview__btn"-->
<!--        :class="{ preview__btn_active: view === 'web' }"-->
<!--        fab-->
<!--        tile-->
<!--        small-->
<!--        depressed-->
<!--        :color="'#e8e8e8'"-->
<!--        @click="setView('web')"-->
<!--      >-->
<!--        <v-icon>mdi-laptop</v-icon>-->
<!--      </v-btn>-->
    </div>
    <div class="preview__content">
      <div
        style="display: flex; width: 360px; justify-content: space-evenly"
        v-if="showOpen"
      >
        <v-btn
          class="preview__open"
          small
          depressed
          outlined
          style="background: #fff; margin-bottom: 10px; max-width: 360px"
          color="primary"
          @click="openLanding()"
        >
          {{ $t('constructor["Открыть лендинг"]') }}
        </v-btn>

        <v-btn
          v-if="showOpen"
          @click="copyLinkLanding"
          class="preview__open"
          small
          style="
            min-width: 40px;
            padding: 0 10px;
            background: #fff;
            margin-bottom: 10px;
            max-width: 360px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
          "
          rounded
          outlined
          color="primary"
        >
          <v-icon color="primary" style="margin-right: 0"
            >mdi-content-copy</v-icon
          >
        </v-btn>
      </div>
      <div
        v-if="page === 'start'"
        ref="canvas__scroll"
        class="canvas"
        style="min-height: 570px; display: flex; flex-direction: column"
      >
        <template>
          <StartPreview />
        </template>
      </div>
      <div
          v-if="page === 'passcode'"
          ref="canvas__scroll"
          class="canvas"
          style="min-height: 570px; display: flex; flex-direction: column"
      >
        <template>
          <PasscodePreview />
        </template>
      </div>

      <div
        v-if="page !== 'start' && page !== 'passcode' && $store.state.renderComponent"
        ref="canvas__scroll"
        class="canvas"
        style="min-height: 570px; display: flex; flex-direction: column;"
        :class="[
          { canvas_web: canvas.view === 'web' },
          { canvas_lang: landing.advanced.show_lang },
        ]"
        :style="canvasStyle"
      >
        <template v-if="page === 'loader'">
          <LoaderPreview />
        </template>

        <div
          class="backgroundPreview"
          v-if="landing.branding.background_preview"
          :style="`height:${previewHeight}px;`"
          style="
            position: absolute;
            top: 0;
            left: 0;
            width: 360px;
            overflow: hidden;
            background: #fff;
            z-index: -1;
          "
        ></div>
        <template v-if="page === 'listing_preview'">
          <div style="margin-bottom: 15px">
            <div
              style="height: auto; min-height: auto"
              v-html="`<p>Listing Group Title</p>`"
            ></div>
            <div
              class="listing listing__item"
              style="margin-bottom: 15px; flex-direction: column"
            >
              <swiper
                ref="mySwiper"
                :options="swiperOptionsListing"
                style="max-width: 300px"
                :style="`height:${landing.advanced.global.listing_img_height}px;`"
              >
                <swiper-slide>
                  <img
                    src="@/assets/uploads/banner.png"
                    alt=""
                    :style="`height:${landing.advanced.global.listing_img_height}px;`"
                    style="width: 100%; border-radius: 8px"
                  />
                </swiper-slide>
              </swiper>

              <div class="listing__descr">
                <p class="listing__name" :style="`color:#000`">
                  default listing name
                </p>
                <p class="listing__text" :style="`color:#000`">
                  default listing description
                </p>
                <!--                <p class="listing__price" >100 <span v-if="listing.price">{{landing.advanced.global.currency}}</span></p>-->
              </div>
            </div>
          </div>
        </template>
        <template v-if="page === 'auth'">
          <div
            v-if="landing.advanced.global.auth"
            :style="`background:${backgroundAuth} no-repeat center / cover;`"
            style="
              width: 100%;
              min-height: 640px;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
            "
          >
            <div v-if="!landing.advanced.auth.required_auth">
              <v-btn
                class="popup__close"
                :style="`color:${landing.advanced.auth.text_color};`"
                small
                icon
                style="position: absolute; top: 10px; right: 10px"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
            <div
              v-if="landing.advanced.auth.logo_img.show"
              :style="`width:${landing.advanced.auth.logo_img.width}px;height:${landing.advanced.auth.logo_img.height}px;`"
            >
              <img
                :src="landing.advanced.auth.logo_img.src"
                style="height: 100%; width: 100%; object-fit: fill"
                alt=""
              />
            </div>
            <div
              style="min-height: auto; height: auto; align-self: normal"
              v-html="landing.advanced.auth.logo_text[current_lang]"
            ></div>
            <div v-if="landing.advanced.auth.name">
              <label :style="`color:${landing.advanced.auth.text_color};`"
                >Name</label
              >
              <v-text-field
                outlined
                class="auth_input"
                solo
                ref="name"
                flat
                dense
              ></v-text-field>
            </div>
            <div v-if="landing.advanced.auth.last_name">
              <label :style="`color:${landing.advanced.auth.text_color};`"
                >Last Name</label
              >
              <v-text-field
                outlined
                class="auth_input"
                solo
                ref="name"
                flat
                dense
              ></v-text-field>
            </div>
            <div v-if="landing.advanced.auth.birthday">
              <label :style="`color:${landing.advanced.auth.text_color};`"
                >Birthday</label
              >
              <v-text-field
                outlined
                class="auth_input"
                solo
                ref="name"
                flat
                dense
              ></v-text-field>
            </div>
            <div v-if="landing.advanced.auth.room">
              <label :style="`color:${landing.advanced.auth.text_color};`"
                >Room number</label
              >
              <v-text-field
                outlined
                class="auth_input"
                solo
                ref="name"
                flat
                dense
              ></v-text-field>
            </div>
            <div>
              <v-btn
                :color="landing.advanced.auth.login_btn_bg_color"
                :style="`color:${landing.advanced.auth.login_btn_text_color};`"
                depressed
                >Login</v-btn
              >
            </div>
            <div
              v-if="!landing.advanced.auth.required_auth"
              style="margin: 20px 0"
              :style="`color:${landing.advanced.auth.text_color};`"
            >
              OR
            </div>
            <div v-if="!landing.advanced.auth.required_auth">
              <v-btn
                :color="landing.advanced.auth.login_btn_bg_color"
                :style="`color:${landing.advanced.auth.login_btn_text_color};`"
                depressed
                >Open without login</v-btn
              >
            </div>
          </div>
        </template>
        <template v-if="page === 'favorite'">
          <div
            style="flex: 1 1 auto"
            class="listing__list"
            :style="
              liningStyles +
              `margin-top:${
                landing.advanced.global.active_header_inside ||
                landing.advanced.global.active_logo_inside
                  ? '20px'
                  : `${+landing.branding.topMenu.styles.size * 2.5}px`
              };margin-left:${
                landing.branding.lining_margin ? '10px' : '0'
              };margin-right:${
                landing.branding.lining_margin ? '10px' : '0'
              };margin-bottom:40px;min-height:300px;`
            "
          >
            <div style="margin-bottom: 15px">
              <div
                style="height: auto; min-height: auto"
                v-html="
                  landing.advanced.global.favorite_btn[landing.current_lang]
                    .title
                "
              ></div>
              <div
                class="listing listing__item"
                style="margin-bottom: 15px; min-height: 110px; padding: 5px"
                :style="
                  displayTypeFavorite(
                    landing.advanced.global.favorite_btn.styles
                  )
                "
              >
                <div
                  v-if="
                    landing.advanced.global.favorite_btn.styles.displayType !==
                    'inside'
                  "
                  style="display: flex"
                  :style="`width:${
                    landing.advanced.global.favorite_btn.styles.displayType ===
                    'horizontal'
                      ? landing.advanced.global.favorite_btn.styles
                          .listing_img_width + 'px'
                      : '100%'
                  };border-bottom-left-radius: ${
                    landing.advanced.global.favorite_btn.styles.displayType ===
                    'horizontal'
                      ? '10px'
                      : '0'
                  };border-top-left-radius: 10px;border-top-right-radius: ${
                    landing.advanced.global.favorite_btn.styles.displayType ===
                    'default'
                      ? '10px'
                      : '0'
                  };height:${landing.advanced.global.favorite_btn.height}px;`"
                >
                  <img
                    :src="`${VUE_APP_STORAGE_URL}default-landing-icons/Icons_40-43.svg`"
                    alt=""
                    :style="`width:${
                      landing.advanced.global.favorite_btn.styles
                        .displayType === 'horizontal'
                        ? landing.advanced.global.favorite_btn.styles
                            .listing_img_width + 'px'
                        : '100%'
                    };border-bottom-left-radius: ${
                      landing.advanced.global.favorite_btn.styles
                        .displayType === 'horizontal'
                        ? '10px'
                        : '0'
                    };border-top-left-radius: 10px;border-top-right-radius: ${
                      landing.advanced.global.favorite_btn.styles
                        .displayType === 'default'
                        ? '10px'
                        : '0'
                    };`"
                    style="width: 100%"
                  />
                </div>
                <div
                  class="listing__descr"
                  style=""
                  :style="`text-align:${
                    landing.advanced.global.favorite_btn.styles.displayType !==
                    'horizontal'
                      ? 'center'
                      : 'left'
                  };padding-left: ${
                    landing.advanced.global.favorite_btn.styles.displayType !==
                    'horizontal'
                      ? '0'
                      : '10px'
                  };margin-left: ${
                    landing.advanced.global.favorite_btn.styles.displayType !==
                    'horizontal'
                      ? '0'
                      : '10px'
                  };`"
                >
                  <p
                    class="listing__name"
                    :style="`color:${landing.advanced.global.favorite_btn.name_color}`"
                  >
                    default name
                  </p>
                  <br />
                  <p
                    class="listing__text"
                    :style="`color:${landing.advanced.global.favorite_btn.descr_color}`"
                  >
                    default description
                  </p>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-if="page === 'cart'">
          <CartPreview :page="page" />
        </template>
        <template
          v-if="
            page === 'intro'
          "
        >
          <IntroPreview/>
        </template>
        <template v-if="page === 'sidebar'">
          <SidebarPreview/>
        </template>
        <div
          class="canvas__lang__lite"
          style="padding-top: 20px"
          v-if="
              (landing.advanced.show_lang &&
              !landing.branding.header_image &&
              landing.advanced.global.lang_style === 'lite' &&
              !landing.branding.header_preview &&
              page === 'advanced' &&
              landing.advanced.tab !== 0 &&
              landing.advanced.tab !== 2 &&
              landing.advanced.tab !== 3 &&
              landing.advanced.tab !== 4 &&
              landing.advanced.tab !== 6 &&
              landing.advanced.tab !== 7 &&
              landing.advanced.tab !== 8 &&
              landing.advanced.tab !== 9) ||
              (page === 'home' &&
              !landing.branding.header_preview &&
              landing.advanced.show_lang &&
              !landing.branding.header_image &&
              landing.advanced.global.lang_style === 'lite')
          "
        >
          <v-select
            style="flex: 0.5 1 10%"
            flat
            dense
            outlined
            hide-details
            placeholder="Select language"
            :items="langs"
            item-text="name"
            item-value="value"
            :color="landing.branding.font_color"
            :item-color="landing.branding.font_color"
            :background-color="landing.branding.bg_color"
            class="canvas__select"
            v-model="landing.current_lang"
          >
            <template v-slot:selection="{ item }">
              <img
                style="height: 20px; width: 30px; margin-right: 10px"
                :src="item.flag"
                :alt="item.name"
              />
              <div>{{ item.name }}</div>
            </template>
            <template v-slot:item="{ item }">
              <img
                style="height: 20px; margin-right: 10px"
                :src="item.flag"
                :alt="item.name"
              />
              <div>{{ item.name }}</div>
            </template>
          </v-select>
        </div>
        <div
          class="canvas__lang__pro"
          style="padding-top: 20px"
          v-if="
            (landing.advanced.show_lang &&
              !landing.branding.header_image &&
              landing.advanced.global.lang_style === 'pro' &&
              page === 'advanced' &&
              !landing.branding.header_preview &&
              landing.advanced.tab !== 0 &&
              landing.advanced.tab !== 2 &&
              landing.advanced.tab !== 3 &&
              landing.advanced.tab !== 4 &&
              landing.advanced.tab !== 6 &&
              landing.advanced.tab !== 7 &&
              landing.advanced.tab !== 8 &&
              landing.advanced.tab !== 9 &&
              page !== 'topMenu') ||
            (page === 'home' &&
              !landing.branding.header_preview &&
              landing.advanced.show_lang &&
              !landing.branding.header_image &&
              landing.advanced.global.lang_style === 'pro' &&
              page !== 'auth')
          "
        >
          <img
            style="width: 30px; height: 20px; cursor: pointer"
            :src="flag"
            alt="flag"
          />
        </div>
        <div
          class="canvas__lang__pro"
          v-if="
            (page !== 'underConstructor' &&
              page !== 'home' &&
              !landing.advanced.global.active_header_inside &&
              page !== 'advanced' &&
              page !== 'lining' &&
              page !== 'intro' &&
              page !== 'sidebar' &&
              page !== 'reviews' &&
              page !== 'order' &&
              page !== 'loader' &&
              page !== 'cart' &&
              page !== 'profile' &&
              page !== 'menuStyles' &&
              page !== 'auth') ||
            page === 'topMenu' ||
            $store.state.listing.listingGroup !== null ||
            $store.state.events.page === 'detail' ||
            (page === 'order' && order && order.tab === 5) ||
            (page === 'order' && order && order.tab === 1) ||
            (page === 'reviews' && landing.reviews.tab !== 4)
          "
          :style="`border-radius:${
            landing.branding.topMenu.styles.radius
          }px;width:${+landing.branding.topMenu.styles.size * 1.5}px;height:${
            +landing.branding.topMenu.styles.size * 1.5
          }px;background:${landing.branding.topMenu.styles.background};`"
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            z-index: 9;
            left: 20px;
            top: 20px;
            padding: 0;
          "
        >
          <v-icon
            :size="landing.branding.topMenu.styles.size"
            :style="`width: ${landing.branding.topMenu.styles.size}px;height:${landing.branding.topMenu.styles.size}px;`"
            :color="landing.branding.topMenu.styles.color"
            @click="setPage(page)"
            >mdi-chevron-left</v-icon
          >
        </div>
        <div
          class="canvas__lang__pro"
          v-if="showFavoriteIcon"
          :style="`border-radius:${
            landing.branding.topMenu.styles.radius
          }px;width:${+landing.branding.topMenu.styles.size * 1.5}px;height:${
            +landing.branding.topMenu.styles.size * 1.5
          }px;background:${landing.branding.topMenu.styles.background};
            right: ${+landing.branding.topMenu.styles.size + 50}px;`"
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            z-index: 9;
            top: 20px;
            padding: 0;
          "
        >
          <v-icon
            :size="landing.branding.topMenu.styles.size"
            :style="`width: ${landing.branding.topMenu.styles.size}px;height:${landing.branding.topMenu.styles.size}px;`"
            :color="landing.branding.topMenu.styles.color"
            >mdi-heart-outline</v-icon
          >
        </div>
        <div
                  class="canvas__lang__pro"
                  v-if="showShare"
                  :style="`border-radius:${
            landing.branding.topMenu.styles.radius
          }px;width:${+landing.branding.topMenu.styles.size * 1.5}px;height:${
            +landing.branding.topMenu.styles.size * 1.5
          }px;background:${landing.branding.topMenu.styles.background};
            right: ${showFavoriteIcon?+landing.branding.topMenu.styles.size*2 + 75:+landing.branding.topMenu.styles.size + 50}px;'`"
                  style="
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            z-index: 9;
            top: 20px;
            padding: 0;
          "
          >
              <v-icon
                      :size="landing.branding.topMenu.styles.size"
                      :style="`width: ${landing.branding.topMenu.styles.size}px;height:${landing.branding.topMenu.styles.size}px;`"
                      :color="landing.branding.topMenu.styles.color"
              >mdi-share-outline</v-icon
              >
          </div>
        <div
          class="canvas__lang__pro"
          v-if="
            (page !== 'underConstructor' &&
              page !== 'home' &&
              !landing.advanced.global.active_header_inside &&
              page !== 'advanced' &&
              page !== 'lining' &&
              page !== 'intro' &&
              page !== 'sidebar' &&
              page !== 'reviews' &&
              page !== 'order' &&
              page !== 'loader' &&
              page !== 'menuStyles' &&
              page !== 'cart' &&
              page !== 'profile' &&
              page !== 'auth') ||
            page === 'topMenu' ||
            $store.state.listing.listingGroup !== null ||
            $store.state.events.page === 'detail' ||
            (page === 'order' && order && order.tab === 5) ||
            (page === 'order' && order && order.tab === 1) ||
            (page === 'reviews' && landing.reviews.tab !== 4)
          "
          :style="`border-radius:${
            landing.branding.topMenu.styles.radius
          }px;width:${+landing.branding.topMenu.styles.size * 1.5}px;height:${
            +landing.branding.topMenu.styles.size * 1.5
          }px;background:${landing.branding.topMenu.styles.background};`"
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            z-index: 9;
            right: 20px;
            top: 20px;
            padding: 0;
          "
        >
          <v-icon
            :size="landing.branding.topMenu.styles.size"
            :style="`width: ${landing.branding.topMenu.styles.size}px;height:${landing.branding.topMenu.styles.size}px;`"
            :color="landing.branding.topMenu.styles.color"
            >mdi-menu</v-icon
          >
        </div>
        <HeaderPreview :page="page" />
        <LogoPreview :page="page" />
        <TextLogoPreview :page="page" />
        <div
          class="app"
          :class="period"
          v-if="
            page === 'home' ||
            page === 'menuStyles' ||
            (page === 'advanced' &&
            landing.advanced.tab !== 0 &&
            landing.advanced.tab !== 2 &&
            landing.advanced.tab !== 3 &&
            landing.advanced.tab !== 4 &&
            landing.advanced.tab !== 5 &&
            landing.advanced.tab !== 7 &&
            landing.advanced.tab !== 8 &&
            landing.advanced.tab !== 9 &&
            landing.advanced.tab !== 12)
          "
        >
          <WeatherApp :class="period"></WeatherApp>
        </div>
        <template
          v-if="
            (order_form && order.tab === 0 && page === 'order') ||
            (order_form && order.tab === 2 && page === 'order')
          "
        >
          <v-form
            v-if="!landing.free"
            class="hotel-feedback__form"
            style="
              padding: 10px 10px 30px 10px;
              position: absolute;
              top: 0;
              min-height: 100%;
              width: 100%;
              background-color: #fff;
            "
          >
            <div
              style="min-height: 50px; max-height: 50px"
              v-html="htmlData(order_form.content[landing.current_lang].title)"
            ></div>
            <div v-if="order_form.content[landing.current_lang].delivery">
              <label class="label">Delivery time</label>
              <v-text-field
                outlined
                dense
                v-model="now_deliver"
                background-color="#fff"
              ></v-text-field>
            </div>
            <div
              v-if="
                (!order_form.content[landing.current_lang].guest &&
                  order_form.content[landing.current_lang].input_1) ||
                (!order_form.content[landing.current_lang].guest &&
                  order_form.content[landing.current_lang].input_2) ||
                (!order_form.content[landing.current_lang].guest &&
                  order_form.content[landing.current_lang].input_3) ||
                (!order_form.content[landing.current_lang].guest &&
                  order_form.content[landing.current_lang].textarea)
              "
            >
              <label class="label">Contact information</label>
              <div
                class="hotel-feedback__item"
                v-if="order_form.content[landing.current_lang].input_1"
              >
                <p class="hotel-feedback__label">
                  {{ order_form.content[landing.current_lang].input_1 }}
                </p>
                <v-text-field
                  outlined
                  solo
                  flat
                  dense
                  maxlength="255"
                ></v-text-field>
              </div>
              <div
                class="hotel-feedback__item"
                v-if="order_form.content[landing.current_lang].input_2"
              >
                <p class="hotel-feedback__label">
                  {{ order_form.content[landing.current_lang].input_2 }}
                </p>
                <v-text-field
                  outlined
                  solo
                  flat
                  dense
                  maxlength="255"
                ></v-text-field>
              </div>
              <div
                class="hotel-feedback__item"
                v-if="order_form.content[landing.current_lang].input_3"
              >
                <p class="hotel-feedback__label">
                  {{ order_form.content[landing.current_lang].input_3 }}
                </p>
                <v-text-field
                  outlined
                  solo
                  flat
                  dense
                  maxlength="255"
                ></v-text-field>
              </div>
              <div
                class="hotel-feedback__item"
                v-if="order_form.content[landing.current_lang].textarea"
              >
                <p class="hotel-feedback__label">
                  {{ order_form.content[landing.current_lang].textarea }}
                </p>
                <v-textarea
                  outlined
                  solo
                  flat
                  dense
                  maxlength="255"
                  background-color="#fff"
                ></v-textarea>
              </div>
            </div>
            <div
              v-if="order_form.content[landing.current_lang].payment"
              style="height: auto; min-height: auto"
              v-html="
                htmlData(order_form.content[landing.current_lang].payment_label)
              "
            ></div>
            <div
              v-if="
                order_form.content[landing.current_lang].guest &&
                !order_form.content[landing.current_lang].only_resident
              "
            >
              <v-tabs
                :color="order_form.styles.tabs_color"
                v-if="order_form.content[landing.current_lang].guest"
                v-model="order_form.content[landing.current_lang].person_type"
              >
                <v-tab
                  @click="
                    order_form.content[landing.current_lang].person_type = 0
                  "
                >
                  Resident
                </v-tab>
                <v-tab
                  @click="
                    order_form.content[landing.current_lang].person_type = 1
                  "
                  >Guest</v-tab
                >
                <v-tab-item style="background: none">
                  <div
                    v-if="order_form.content[landing.current_lang].payment"
                    style="margin: 15px 0 45px 0"
                  >
                    <v-slide-group show-arrows>
                      <v-slide-item
                        v-for="payment_method_resident in payment_resident"
                        :key="payment_method_resident.id"
                        v-slot="{ active, toggle }"
                      >
                        <v-btn
                          class="mx-2"
                          :input-value="active"
                          style="border-radius: 12px"
                          :style="`color:${
                            active
                              ? order_form.styles.payment_active_color
                              : order_form.styles.payment_color
                          };background:${
                            active
                              ? order_form.styles.payment_bg_active
                              : order_form.styles.payment_bg
                          };border: 1px solid ${
                            active
                              ? order_form.styles.payment_bg_active
                              : order_form.styles.payment_bg
                          };`"
                          depressed
                          small
                          @click="toggle"
                        >
                          {{ payment_method_resident.name }}
                        </v-btn>
                      </v-slide-item>
                    </v-slide-group>
                  </div>

                  <label class="label" style="display: block; margin-top: 15px"
                    >Contact information</label
                  >
                  <div
                    class="control"
                    style="margin-top: 15px"
                    v-if="
                      order_form.content[landing.current_lang].hotel_input_show
                    "
                  >
                    <label class="label">{{
                      order_form.content[landing.current_lang].hotel_input
                    }}</label>
                    <v-text-field outlined solo flat dense></v-text-field>
                  </div>
                  <div
                    class="control"
                    v-if="
                      order_form.content[landing.current_lang]
                        .hotel_first_name_input_show
                    "
                  >
                    <label class="label">{{
                      order_form.content[landing.current_lang]
                        .hotel_first_name_input
                    }}</label>
                    <v-text-field outlined solo flat dense></v-text-field>
                  </div>
                  <div
                    class="control"
                    v-if="
                      order_form.content[landing.current_lang]
                        .hotel_last_name_input_show
                    "
                  >
                    <label class="label">{{
                      order_form.content[landing.current_lang]
                        .hotel_last_name_input
                    }}</label>
                    <v-text-field outlined solo flat dense></v-text-field>
                  </div>
                  <div
                    class="control"
                    v-if="
                      order_form.content[landing.current_lang]
                        .hotel_phone_input_show
                    "
                  >
                    <label class="label">{{
                      order_form.content[landing.current_lang].hotel_phone_input
                    }}</label>
                    <v-text-field
                      outlined
                      solo
                      flat
                      dense
                      type="number"
                    ></v-text-field>
                  </div>
                  <div
                    class="control"
                    v-if="
                      order_form.content[landing.current_lang]
                        .email_resident_show
                    "
                  >
                    <label class="label">{{
                      order_form.content[landing.current_lang].email_resident
                    }}</label>
                    <v-text-field outlined solo flat dense></v-text-field>
                  </div>
                  <div
                    class="control"
                    v-if="
                      order_form.content[landing.current_lang]
                        .hotel_special_request_show
                    "
                  >
                    <label class="label">{{
                      order_form.content[landing.current_lang]
                        .hotel_special_request
                    }}</label>
                    <v-textarea
                      outlined
                      rows="5"
                      background-color="#fff"
                    ></v-textarea>
                  </div>
                </v-tab-item>
                <v-tab-item style="background: none">
                  <div
                    v-if="order_form.content[landing.current_lang].payment"
                    style="margin: 15px 0 45px 0"
                  >
                    <v-slide-group show-arrows center-active>
                      <v-slide-item
                        v-for="payment_method in payment_guest"
                        :key="payment_method.id"
                        v-slot="{ active, toggle }"
                      >
                        <v-btn
                          class="mx-1"
                          :input-value="active"
                          :style="`color:${
                            active
                              ? order_form.styles.payment_active_color
                              : order_form.styles.payment_color
                          };background:${
                            active
                              ? order_form.styles.payment_bg_active
                              : order_form.styles.payment_bg
                          };border: 1px solid ${
                            active
                              ? order_form.styles.payment_bg_active
                              : order_form.styles.payment_bg
                          };`"
                          depressed
                          small
                          style="border-radius: 12px"
                          @click="toggle"
                        >
                          {{ payment_method.name }}
                        </v-btn>
                      </v-slide-item>
                    </v-slide-group>
                  </div>
                  <label class="label" style="display: block; margin-top: 15px"
                    >Contact information</label
                  >
                  <div
                    class="control"
                    style="margin-top: 15px"
                    v-if="
                      order_form.content[landing.current_lang].guest_phone_show
                    "
                  >
                    <label class="label">{{
                      order_form.content[landing.current_lang].guest_phone
                    }}</label>
                    <v-text-field
                      outlined
                      solo
                      flat
                      dense
                      type="number"
                    ></v-text-field>
                  </div>
                  <div
                    class="control"
                    style="margin-top: 15px"
                    v-if="
                      order_form.content[landing.current_lang].email_guest_show
                    "
                  >
                    <label class="label">{{
                      order_form.content[landing.current_lang].email_guest
                    }}</label>
                    <v-text-field outlined solo flat dense></v-text-field>
                  </div>
                  <div
                    class="control"
                    v-if="
                      order_form.content[landing.current_lang]
                        .guest_special_request_show
                    "
                  >
                    <label class="label">{{
                      order_form.content[landing.current_lang]
                        .guest_special_request
                    }}</label>
                    <v-textarea
                      outlined
                      rows="5"
                      background-color="#fff"
                    ></v-textarea></div
                ></v-tab-item>
              </v-tabs>
            </div>
            <div v-if="order_form.content[landing.current_lang].only_resident">
              <div
                v-if="order_form.content[landing.current_lang].payment"
                style="margin: 15px 0 45px 0"
              >
                <v-slide-group show-arrows center-active>
                  <v-slide-item
                    v-for="payment_method_resident in payment_resident"
                    :key="payment_method_resident.id"
                    v-slot="{ active, toggle }"
                  >
                    <v-btn
                      class="mx-2"
                      :style="`color:${
                        active
                          ? order_form.styles.payment_active_color
                          : order_form.styles.payment_color
                      };background:${
                        active
                          ? order_form.styles.payment_bg_active
                          : order_form.styles.payment_bg
                      };border: 1px solid ${
                        active
                          ? order_form.styles.payment_bg_active
                          : order_form.styles.payment_bg
                      };`"
                      depressed
                      style="border-radius: 12px"
                      small
                      :input-value="active"
                      @click="toggle"
                    >
                      {{ payment_method_resident.name }}
                    </v-btn>
                  </v-slide-item>
                </v-slide-group>
              </div>
              <label class="label">Contact information</label>
              <div class="control">
                <label class="label">{{
                  order_form.content[landing.current_lang].hotel_input
                }}</label>
                <v-text-field outlined solo flat dense></v-text-field>
              </div>
              <div class="control">
                <label class="label">{{
                  order_form.content[landing.current_lang].hotel_last_name_input
                }}</label>
                <v-text-field outlined solo flat dense></v-text-field>
              </div>
              <div class="control" style="margin-top: 15px">
                <label class="label">{{
                  order_form.content[landing.current_lang].email_guest
                }}</label>
                <v-text-field outlined solo flat dense></v-text-field>
              </div>
              <div class="control">
                <label class="label">{{
                  order_form.content[landing.current_lang].hotel_special_request
                }}</label>
                <v-textarea outlined rows="5"></v-textarea>
              </div>
            </div>
            <div
              class="hotel-feedback__item"
              v-if="
                order_form.content[landing.current_lang].input_1 ||
                order_form.content[landing.current_lang].input_2 ||
                order_form.content[landing.current_lang].input_3 ||
                order_form.content[landing.current_lang].textarea ||
                order_form.content[landing.current_lang].guest
              "
            >
              <v-btn
                class="hotel-reviews__submit"
                type="button"
                depressed
                outlined
                small
                style="width: 100%; margin-bottom: 30px"
                @click="order.tab = 1"
                :style="`color:${
                  order_form.content[landing.current_lang].btn_color
                };background-color:${order_form.styles.btn_bg};border-color:${
                  order_form.styles.btn_border
                };`"
              >
                {{ order_form.content[landing.current_lang].btn_second_name }}
              </v-btn>
            </div>
          </v-form>
          <div
            v-else
            style="
              display: flex;
              padding-top: 80px;
              justify-content: center;
              align-items: center;
            "
          >
            <v-icon color="#cccccc" size="200px" style="margin-right: 20px"
              >mdi-lock</v-icon
            >
          </div>
        </template>
        <template
          v-if="order_form && order && order.tab === 4 && page === 'order'"
        >
          <email-notifications-preview />
        </template>

        <template
          v-if="order_form && order && order.tab === 5 && page === 'order'"
        >
          <cartPreview />
        </template>
        <template v-if="page === 'order' && order && order.tab === 1">
          <div
            :style="`margin-top: ${
              landing.advanced.global.active_header_inside ||
              landing.advanced.global.active_logo_inside
                ? '20px'
                : `${+landing.branding.topMenu.styles.size * 2.5}px`
            };padding:${landing.branding.lining_margin ? '10px' : '0'}`"
          >
            <div style="min-height: 300px; padding: 15px" :style="liningStyles">
              <div
                v-if="$store.state.orderSuccessPageShow === 'items'"
                v-html="htmlData(order.success_page[current_lang].content)"
              />
              <div
                v-if="$store.state.orderSuccessPageShow === 'request'"
                v-html="
                  htmlData(order.success_page[current_lang].second_content)
                "
              />
              <div
                v-if="$store.state.orderSuccessPageShow === 'table'"
                v-html="
                  htmlData(order.success_page[current_lang].table_content)
                "
              />
              <div v-if="socialsOrder.length">
                <!--              <h2 class="hotel__links"  v-html="order.success_page[current_lang].second_content +' '+ socialsOrder.map((item) => item.text).join(', ')" />-->
              </div>
            </div>
          </div>
        </template>
        <template v-if="page === 'advanced' && landing.advanced.tab !== 12">
          <preview-advanced
            :page="page"
            :landing="landing"
            @error="handleImgError"
          />
        </template>
        <template v-if="page === 'marketing'">
          <SegmentProductListPreview v-if="$store.state.segmentProducts.segmentProductCategory&&!$store.state.segmentProducts.segmentProduct" />
          <SegmentProductDetailPreview v-if="$store.state.segmentProducts.segmentProduct"/>
        </template>

        <template>
          <LiningPreview :page="page" />
        </template>
        <div v-if="page === 'menuStyles'">
          <MainPageStylesPreview/>
        </div>
        <div v-if="page === 'home' && landing.menu">
          <div class="banner_modal">
            <div
              class="banner__modal--shadow"
              v-if="
                selectedBanner &&
                landing.branding.active_tab === 8 &&
                !bannerPreview
              "
            >
              <div
                class="banner__modal"
                :style="`overflow:hidden;height:${
                  selectedBanner.styles.height
                }%;width:${selectedBanner.styles.width}%;left:${
                  (100 - selectedBanner.styles.width) / 2
                }%;top:${
                  (100 - selectedBanner.styles.height) / 2
                }%;position: relative;`"
              >
                <v-icon
                  :size="selectedBanner.styles.banner_close_icon_size"
                  class="banner__modal--close"
                  @click="closeBanner"
                  :color="selectedBanner.styles.banner_icon_color"
                  >{{ selectedBanner.styles.banner_icon }}</v-icon
                >
                <div
                  class="banner__modal--main banner__modal--main"
                  style="overflow: hidden"
                  v-if="selectedBanner.type === 0"
                >
                  <img
                    class="banner_img"
                    :src="
                      selectedBanner.image[landing.current_lang]
                        ? selectedBanner.image[landing.current_lang]
                        : require('@/assets/none.png')
                    "
                    alt="banner"
                  />
                </div>
                <a
                  class="banner__modal--main banner__modal--main--type2"
                  :href="selectedBanner.link[landing.current_lang]"
                  target="_blank"
                  v-if="selectedBanner.type === 1"
                >
                  <img
                    :src="
                      selectedBanner.image[landing.current_lang]
                        ? selectedBanner.image[landing.current_lang]
                        : require('@/assets/none.png')
                    "
                    alt="banner"
                  />
                </a>
                <a
                  class="banner__modal--main banner__modal--main--type3"
                  :href="
                    selectedBanner.video_link[landing.current_lang]
                  "
                  target="_blank"
                  v-if="selectedBanner.type === 2"
                >
                  <img
                    :src="
                      selectedBanner.image[landing.current_lang]
                        ? selectedBanner.image[landing.current_lang]
                        : require('@/assets/none.png')
                    "
                    alt="banner"
                  />
                  <div class="play-video" :style="styleObjectPlay"></div>
                </a>
                <div v-if="selectedBanner.type === 3">
                  <video
                    onloadedmetadata="this.muted = true"
                    :src="selectedBanner.video[landing.current_lang]"
                    playsinline
                    autoplay
                    muted
                    loop
                    :height="`100%`"
                    :width="`100%`"
                    style="position: absolute; object-fit: fill; z-index: -1"
                  ></video>
                </div>
              </div>
            </div>
            <div
              class="banner__modal--shadow shadow--preview"
              v-if="bannerPreview"
            >
              <div
                class="banner__modal modal__banner_preview"
                :style="`overflow:hidden;height:${
                  selectedBanner.styles.height
                }%;width:${selectedBanner.styles.width}%;left:${
                  (100 - selectedBanner.styles.width) / 2
                }%;top:${
                  (100 - selectedBanner.styles.height) / 2
                }%;position: relative;`"
              ></div>
            </div>
          </div>
          <template>
            <SliderPreview :slides="topSlider" :position="'top'" />
          </template>
          <template>
            <SegmentCategoryListPreview v-if="$store.state.segmentProducts.segmentProductCategories.length&&!$store.state.segmentProducts.segmentProductCategory&&!$store.state.segmentProducts.segmentProduct"/>
          </template>
          <template v-if="landing.branding.event_main_page.active && landing.branding.event_main_page.show_type === 'top'">
              <EventsPreview :event_settings="landing.branding.event_main_page"/>
          </template>
            <div v-if="landing.menu.style === 'swipe'" style="margin-left:5px;">
            <swiper
                ref="mySwiper"
                :direction="'horizontal'"
                :pagination="{ clickable: true }"
                :options="swiperOptionsSwipeMenu"
                style="overflow: hidden"
                :style="`max-width: 600px; width: 100%;height:300px;`"
            >
              <swiper-slide
                  v-for="item in landing.menu.list"
                  :key="item.id"
                  :style="`max-width: 600px; width: ${item.styles.width}%;`"
              >
                <div
                    v-if="item.styl2es.show_name === 'top'"
                    :style="`margin-bottom:10px;height:${
            item.styles.lining_height
          }px;width:${item.styles.lining_width}%;border-radius: ${
            landing.menu.radius
          }px;padding:${item.styles.lining_padding}px;background-color:${
            item.styles.main_menu_lining
          };display:flex;align-items:center;justify-content:center;flex-direction:${
            item.styles.show_icon_up ? 'column' : 'row'
          };`"
                >
                  <img
                      alt="image"
                      v-if="item.styles.show_icon && item.icon && item.styles.disable_icon_color"
                      :src="item.icon[landing.current_lang]"
                      class="canvas-menu__icon"
                      :style="`height:${item.styles.icon_size}px;width:${
              item.styles.icon_size
            }px;min-height:${item.styles.icon_size}px;min-width:${
              item.styles.icon_size
            }px;margin-right:${!item.styles.show_icon_up ? '15px' : '0'}`"
                  />
                  <div
                      v-if="item.styles.show_icon && item.icon && !item.styles.disable_icon_color"
                      class="canvas-menu__icon"
                      :style="`margin-right:${
              !item.styles.show_icon_up ? '15px' : '0'
            };height:${item.styles.icon_size}px;width:${
              item.styles.icon_size
            }px;min-height:${item.styles.icon_size}px;min-width:${
              item.styles.icon_size
            }px;background-color: ${item.styles.icon_color};mask: url(${
              item.icon[landing.current_lang]
            }) no-repeat center / contain;-webkit-mask: url(${
              item.icon[landing.current_lang]
            }) no-repeat center / contain;`"
                  ></div>

                  <div style="text-align: center">
                    {{ item.text["en"] }}
                  </div>
                </div>
                <div
                    class="canvas-menu__link"
                    :style="`position:relative;z-index:1;height:${
            item.styles.height
          }px;color: ${item.styles.color};
                      border-radius: ${landing.menu.radius}px;box-shadow:${
            item.styles.background_submenu_preview
              ? 'none'
              : '0 ' +
                landing.menu.shadow_size +
                'px ' +
                '6px ' +
                landing.menu.shadow_color
          };background-color:${
            !item.styles.background_submenu_preview
              ? item.styles.background_main_color
              : 'transparent'
          };text-align:unset;
                    background-image: url('${item.styles.background}');
                    justify-content:${item.styles.position.split(' ')[0]};
      align-items:${item.styles.position.split(' ')[1]};
      background-repeat: no-repeat;
      background-size: cover;
      background-position:center;`"
                >
                  <div
                      v-if="item.styles.show_name === 'inside'"
                      :style="`height:${item.styles.lining_height}px;width:${
              item.styles.lining_width
            }%;padding:${
              item.styles.lining_padding
            }px;background-color:${
              item.styles.main_menu_lining
            };display:flex;align-items:center;justify-content:center;flex-direction:${
              item.styles.show_icon_up ? 'column' : 'row'
            };`+checkRadius(item,landing.menu)"
                  >
                    <img
                        alt="image"
                        v-if="item.styles.show_icon && item.icon && item.styles.disable_icon_color"
                        :src="item.icon[landing.current_lang]"
                        class="canvas-menu__icon"
                        :style="`height:${item.styles.icon_size}px;width:${
              item.styles.icon_size
            }px;min-height:${item.styles.icon_size}px;min-width:${
                item.styles.icon_size
              }px;margin-right:${!item.styles.show_icon_up ? '15px' : '0'}`"
                    />
                    <div
                        v-if="item.styles.show_icon && item.icon && !item.styles.disable_icon_color"
                        class="canvas-menu__icon"
                        :style="`margin-right:${
                !item.styles.show_icon_up ? '15px' : '0'
              };height:${item.styles.icon_size}px;width:${
              item.styles.icon_size
            }px;min-height:${item.styles.icon_size}px;min-width:${
                item.styles.icon_size
              }px;background-color: ${item.styles.icon_color};mask: url(${
                item.icon[landing.current_lang]
              }) no-repeat center / contain;-webkit-mask: url(${
                item.icon[landing.current_lang]
              }) no-repeat center / contain;`"
                    ></div>

                    <div style="text-align: center">
                      {{ item.text["en"] }}
                    </div>
                  </div>
                </div>
                <div
                    v-if="item.styles.show_name === 'bottom'"
                    :style="`margin-top:10px;height:${item.styles.lining_height}px;width:${
            item.styles.lining_width
          }%;margin-top:10px;border-radius: ${landing.menu.radius}px;padding:${
            item.styles.lining_padding
          }px;background-color:${
            item.styles.main_menu_lining
          };display:flex;align-items:center;justify-content:center;flex-direction:${
            item.styles.show_icon_up ? 'column' : 'row'
          };`"
                >
                  <img
                      alt="image"
                      v-if="item.styles.show_icon && item.icon && item.styles.disable_icon_color"
                      :src="item.icon[landing.current_lang]"
                      class="canvas-menu__icon"
                      :style="`height:${item.styles.icon_size}px;width:${
              item.styles.icon_size
            }px;min-height:${item.styles.icon_size}px;min-width:${
              item.styles.icon_size
            }px;margin-right:${!item.styles.show_icon_up ? '15px' : '0'}`"
                  />
                  <div
                      v-if="item.styles.show_icon && item.icon && !item.styles.disable_icon_color"
                      class="canvas-menu__icon"
                      :style="`margin-right:${
              !item.styles.show_icon_up ? '15px' : '0'
            };height:${item.styles.icon_size}px;width:${
              item.styles.icon_size
            }px;min-height:${item.styles.icon_size}px;min-width:${
              item.styles.icon_size
            }px;background-color: ${item.styles.icon_color};mask: url(${
              item.icon[landing.current_lang]
            }) no-repeat center / contain;-webkit-mask: url(${
              item.icon[landing.current_lang]
            }) no-repeat center / contain;`"
                  ></div>

                  <div style="text-align: center">
                    {{ item.text["en"] }}
                  </div>
                </div>
              </swiper-slide>
            </swiper>
          </div>
            <draggable
              v-if="landing.menu.style !== 'swipe'"
              tag="ul"
              class="canvas-menu"
              style="flex-direction: row;padding:10px 5px;"
              v-model="landing.menu.list"
              draggable=".item"
            >
              <li
                class="canvas-menu__item canvas-menu-custom"
                :class="`${item.active?'item':'d-none'}`"
                v-for="item in landing.menu.list"
                :key="item.id"
                :style="
                  `margin-bottom:${+landing.menu.shadowSize + 10}px;` +
                  customMenuStyles(landing.menu, item)
                "
              >
                <div
                  v-if="item.styles.show_name === 'top'"
                  :style="`margin-bottom:10px;height:${
                    item.styles.lining_height
                  }px;width:${item.styles.lining_width}%;border-radius: ${
                    landing.menu.radius
                  }px;padding:${
                    item.styles.lining_padding
                  }px;background-color:${
                    item.styles.main_menu_lining
                  };display:flex;align-items:center;justify-content:center;flex-direction:${
                    item.styles.show_icon_up ? 'column' : 'row'
                  };`"
                >
                  <img
                    alt="image"
                    v-if="
                      item.styles.show_icon && item.icon && item.styles.disable_icon_color
                    "
                    @error="handleImgError"
                    :src="item.icon[landing.current_lang]"
                    class="canvas-menu__icon"
                    :style="`height:${item.styles.icon_size}px;width:${
                      item.styles.icon_size
                    }px;margin-right:${
                      !item.styles.show_icon_up ? '15px' : '0'
                    }`"
                  />
                  <div
                    v-if="
                      item.styles.show_icon && item.icon && !item.styles.disable_icon_color
                    "
                    @error="handleImgError"
                    class="canvas-menu__icon"
                    :style="`margin-right:${
                      !item.styles.show_icon_up ? '15px' : '0'
                    };height:${item.styles.icon_size}px;width:${
                      item.styles.icon_size
                    }px;background-color: ${item.styles.icon_color};mask: url(${
                      item.icon[landing.current_lang]
                    }) no-repeat center / contain;-webkit-mask: url(${
                      item.icon[landing.current_lang]
                    }) no-repeat center / contain;`"
                  ></div>

                  <div style="text-align: center">
                    {{ item.text[landing.current_lang] }}
                  </div>
                </div>
                <a
                  class="canvas-menu__link"
                  href="#"
                  :style="
                    ` height:${item.styles.height}px;color: ${item.styles.color};
                    border-radius: ${landing.menu.radius}px;box-shadow:0 ${landing.menu.shadowSize}px 6px ${landing.menu.shadowColor};` +
                    customMenuLinkStyles(landing.menu, item)
                  "
                >
                  <div
                    v-if="item.styles.show_name === 'inside'"
                    :style="`height:${item.styles.lining_height}px;width:${
                      item.styles.lining_width
                    }%;padding:${
                      item.styles.lining_padding
                    }px;background-color:${
                      item.styles.main_menu_lining
                    };display:flex;align-items:center;justify-content:center;flex-direction:${
                      item.styles.show_icon_up ? 'column' : 'row'
                    };`+checkRadius(item,landing.menu)"
                  >
                    <img
                      alt="image"
                      v-if="
                        item.styles.show_icon && item.icon && item.styles.disable_icon_color
                      "
                      @error="handleImgError"
                      :src="item.icon[landing.current_lang]"
                      class="canvas-menu__icon"
                      :style="`height:${item.styles.icon_size}px;width:${
                        item.styles.icon_size
                      }px;margin-right:${
                        !item.styles.show_icon_up ? '15px' : '0'
                      }`"
                    />
                    <div
                      v-if="
                        item.styles.show_icon && item.icon && !item.styles.disable_icon_color
                      "
                      @error="handleImgError"
                      class="canvas-menu__icon"
                      :style="`margin-right:${
                        !item.styles.show_icon_up ? '15px' : '0'
                      };height:${item.styles.icon_size}px;width:${
                        item.styles.icon_size
                      }px;background-color: ${
                        item.styles.icon_color
                      };mask: url(${
                        item.icon[landing.current_lang]
                      }) no-repeat center / contain;-webkit-mask: url(${
                        item.icon[landing.current_lang]
                      }) no-repeat center / contain;`"
                    ></div>

                    <div style="text-align: center">
                      {{ item.text[landing.current_lang] }}
                    </div>
                  </div>
                </a>
                <div
                  v-if="item.styles.show_name === 'bottom'"
                  :style="`margin-top:10px;height:${
                    item.styles.lining_height
                  }px;width:${
                    item.styles.lining_width
                  }%;margin-top:10px;border-radius: ${
                    landing.menu.radius
                  }px;padding:${
                    item.styles.lining_padding
                  }px;background-color:${
                    item.styles.main_menu_lining
                  };display:flex;align-items:center;justify-content:center;flex-direction:${
                    item.styles.show_icon_up ? 'column' : 'row'
                  };`"
                >
                  <img
                    alt="image"
                    v-if="
                      item.styles.show_icon && item.icon && item.styles.disable_icon_color
                    "
                    @error="handleImgError"
                    :src="item.icon[landing.current_lang]"
                    class="canvas-menu__icon"
                    :style="`height:${item.styles.icon_size}px;width:${
                      item.styles.icon_size
                    }px;margin-right:${
                      !item.styles.show_icon_up ? '15px' : '0'
                    }`"
                  />
                  <div
                    v-if="
                      item.styles.show_icon && item.icon && !item.styles.disable_icon_color
                    "
                    @error="handleImgError"
                    class="canvas-menu__icon"
                    :style="`margin-right:${
                      !item.styles.show_icon_up ? '15px' : '0'
                    };height:${item.styles.icon_size}px;width:${
                      item.styles.icon_size
                    }px;background-color: ${item.styles.icon_color};mask: url(${
                      item.icon[landing.current_lang]
                    }) no-repeat center / contain;-webkit-mask: url(${
                      item.icon[landing.current_lang]
                    }) no-repeat center / contain;`"
                  ></div>

                  <div style="text-align: center">
                    {{ item.text[landing.current_lang] }}
                  </div>
                </div>
              </li>
            </draggable>

            <template v-if="landing.branding.event_main_page.active && landing.branding.event_main_page.show_type === 'bottom'">
                <EventsPreview :event_settings="landing.branding.event_main_page"/>
            </template>
          <template>
            <SliderPreview :slides="bottomSlider" :position="'bottom'" />
          </template>
        </div>
        <div class="bottom_banner" v-if="page === 'home'">
          <BottomBannerPreview/>
        </div>
        <div
          class="canvas__page"
          style="flex: 1 1 auto"
          v-if="
            list.length &&
            page !== 'reviews' &&
            page !== 'cart' &&
            page !== 'favorite' &&
            page !== 'profile' &&
            selectedMenuItem
          "
        >
          <template>
            <div
              v-for="(content, index) in selectedMenuItem.content[
                landing.current_lang
              ]"
              :key="index"
            >
              <template
                v-if="
                  selectedMenuItem.page === page &&
                  content.type === 1 &&
                  selectedMenuItem.type === 1
                "
              >
                <div
                  :style="`margin-top: ${
                    landing.advanced.global.active_header_inside ||
                    landing.advanced.global.active_logo_inside
                      ? '20px'
                      : `${+landing.branding.topMenu.styles.size * 2.5}px`
                  };margin-left:${landing.branding.lining_margin ? '10px' : '0'};margin-right:${landing.branding.lining_margin ? '10px' : '0'}`"
                >
                  <div :style="liningStyles" style="min-height:300px;padding:15px;">
                    <typeHtmlPreview :content="content" />
                    <FormOrderPreview :formOrder="content.form" />
                  </div>
                  <SliderMenuPreview
                      :active_slider="content.active_slider"
                      :slider_menu="content.slider"
                  />
                </div>
              </template>
              <template
                v-if="
                  selectedMenuItem.page === page &&
                  content.type === 4 &&
                  selectedMenuItem.type === 4
                "
              >
                <template v-if="listing.page">
                  <div
                    :style="`padding:${
                      landing.branding.lining_margin ? '0 15px' : '0'
                    };`"
                  >
                    <typeListingDetailPreview
                      :item="selectedMenuItem"
                      :content="content"
                      :listing="listing"
                    />
                  </div>
                </template>
                <template v-else>
                  <div
                    class="listing__list"
                    :style="
                      liningStylesListing(selectedMenuItem.styles) +
                      `margin-top:${
                        landing.advanced.global.active_header_inside ||
                        landing.advanced.global.active_logo_inside
                          ? '20px'
                          : `${+landing.branding.topMenu.styles.size * 2.5}px`
                      };margin-left:${
                        landing.branding.lining_margin ? '10px' : '0'
                      };margin-right:${
                        landing.branding.lining_margin ? '10px' : '0'
                      };position:relative;min-height:300px;`
                    "
                  >
                    <div class="listing_list_preview" style="overflow:hidden;position:absolute;top:0;left:0;height:100%;width:100%;" v-if="selectedMenuItem.styles.lining_global_image_preview">

                    </div>
                    <typeListingListPreview
                      :item="selectedMenuItem"
                      :content="content"
                      :type="'listing'"
                    />
                  </div>
                  <SliderMenuPreview
                      :active_slider="content.active_slider"
                      :slider_menu="content.slider"
                  />
                </template>
              </template>

              <template

                v-if="
                  selectedMenuItem.page === page &&
                  content.type === 5 &&
                  selectedMenuItem.type === 5 &&
                  content.content.menu.length
                "
              >
                <div
                    class="submenu_list"
                  style="padding: 10px;overflow:hidden;"
                  :style="
                      liningStylesListing(content) +`min-height:300px;margin-top:${
                    landing.advanced.global.active_header_inside ||
                    landing.advanced.global.active_logo_inside
                      ? '20px'
                      : `${+landing.branding.topMenu.styles.size * 2.5}px`
                  };position:relative;flex:1 auto;margin-left:${landing.branding.lining_margin ? '10px' : '0'};margin-right:${landing.branding.lining_margin ? '10px' : '0'}`"
                >
                  <div class="submenu_list_preview" style="overflow:hidden;position:absolute;top:0;left:0;height:100%;width:100%;" v-if="content.lining_global_image_preview">
                  </div>
                    <div
                    v-if="content.topTextShow"
                    v-html="content.topText"
                    style="min-height: auto; height: auto"
                  ></div>

                  <div v-if="content.style === 'swipe'" style="margin-left:5px;">
                    <swiper
                        ref="mySwiper"
                        :direction="'horizontal'"
                        :pagination="{ clickable: true }"
                        :options="swiperOptionsSwipeMenu"
                        style="overflow: hidden"
                        :style="`max-width: 600px; width: 100%;height:300px;`"
                    >
                      <swiper-slide
                          v-for="item in content.content.menu"
                          :key="item.id"
                          :style="`max-width: 600px; width: ${item.styles.width}%;color: ${
                          item.styles.color
                        };font-family:'${item.styles.fontFamily}',Inter,sans-serif;font-style:${
                          item.styles.fontStyle
                        };font-weight:${item.styles.fontWeight};font-size:${
                          item.styles.fontSize
                        }px;`"
                      >
                        <div
                            v-if="item.styles.show_name === 'top'"
                            :style="`margin-bottom:10px;height:${
            item.styles.lining_height
          }px;width:${item.styles.lining_width}%;border-radius: ${
            content.radius
          }px;padding:${item.styles.lining_padding}px;background-color:${
            item.styles.main_menu_lining
          };display:flex;align-items:center;justify-content:center;flex-direction:${
            item.styles.show_icon_up ? 'column' : 'row'
          };`"
                        >
                          <img
                              alt="image"
                              v-if="item.styles.show_icon && item.icon && item.styles.disable_icon_color"
                              :src="item.icon"
                              class="canvas-menu__icon"
                              :style="`height:${item.styles.icon_size}px;width:${
              item.styles.icon_size
            }px;min-height:${item.styles.icon_size}px;min-width:${
              item.styles.icon_size
            }px;margin-right:${!item.styles.show_icon_up ? '15px' : '0'}`"
                          />
                          <div
                              v-if="item.styles.show_icon && item.icon && !item.styles.disable_icon_color"
                              class="canvas-menu__icon"
                              :style="`margin-right:${
              !item.styles.show_icon_up ? '15px' : '0'
            };height:${item.styles.icon_size}px;width:${
              item.styles.icon_size
            }px;min-height:${item.styles.icon_size}px;min-width:${
              item.styles.icon_size
            }px;background-color: ${item.styles.icon_color};mask: url(${
              item.icon
            }) no-repeat center / contain;-webkit-mask: url(${
              item.icon
            }) no-repeat center / contain;`"
                          ></div>

                          <div style="text-align: center">
                            {{ item.name }}
                          </div>
                        </div>
                        <div
                            class="canvas-menu__link"
                            :style="`position:relative;z-index:1;height:${
            item.styles.height
          }px;color: ${item.styles.color};
                      border-radius: ${landing.menu.radius}px;box-shadow:${
            item.styles.background_submenu_preview
              ? 'none'
              : '0 ' +
                content.shadow_size +
                'px ' +
                '6px ' +
                content.shadow_color
          };background-color:${
            !item.styles.background_submenu_preview
              ? item.styles.background_main_color
              : 'transparent'
          };text-align:unset;
                    background-image: url('${item.styles.background}');
                    justify-content:${item.styles.position.split(' ')[0]};
      align-items:${item.styles.position.split(' ')[1]};
      background-repeat: no-repeat;
      background-size: cover;
      background-position:center;`"
                        >
                          <div
                              v-if="item.styles.show_name === 'inside'"
                              :style="`height:${item.styles.lining_height}px;width:${
              item.styles.lining_width
            }%;padding:${
              item.styles.lining_padding
            }px;background-color:${
              item.styles.main_menu_lining
            };display:flex;align-items:center;justify-content:center;flex-direction:${
              item.styles.show_icon_up ? 'column' : 'row'
            };`+checkRadius(item,content)"
                          >
                            <img
                                alt="image"
                                v-if="item.styles.show_icon && item.icon && item.styles.disable_icon_color"
                                :src="item.icon"
                                class="canvas-menu__icon"
                                :style="`height:${item.styles.icon_size}px;width:${
              item.styles.icon_size
            }px;min-height:${item.styles.icon_size}px;min-width:${
                item.styles.icon_size
              }px;margin-right:${!item.styles.show_icon_up ? '15px' : '0'}`"
                            />
                            <div
                                v-if="item.styles.show_icon && item.icon && !item.styles.disable_icon_color"
                                class="canvas-menu__icon"
                                :style="`margin-right:${
                !item.styles.show_icon_up ? '15px' : '0'
              };height:${item.styles.icon_size}px;width:${
              item.styles.icon_size
            }px;min-height:${item.styles.icon_size}px;min-width:${
                item.styles.icon_size
              }px;background-color: ${item.styles.icon_color};mask: url(${
                item.icon
              }) no-repeat center / contain;-webkit-mask: url(${
                item.icon
              }) no-repeat center / contain;`"
                            ></div>

                            <div style="text-align: center">
                              {{ item.name }}
                            </div>
                          </div>
                        </div>
                        <div
                            v-if="item.styles.show_name === 'bottom'"
                            :style="`margin-top:10px;height:${item.styles.lining_height}px;width:${
            item.styles.lining_width
          }%;margin-top:10px;border-radius: ${landing.menu.radius}px;padding:${
            item.styles.lining_padding
          }px;background-color:${
            item.styles.main_menu_lining
          };display:flex;align-items:center;justify-content:center;flex-direction:${
            item.styles.show_icon_up ? 'column' : 'row'
          };`"
                        >
                          <img
                              alt="image"
                              v-if="item.styles.show_icon && item.icon && item.styles.disable_icon_color"
                              :src="item.icon"
                              class="canvas-menu__icon"
                              :style="`height:${item.styles.icon_size}px;width:${
              item.styles.icon_size
            }px;min-height:${item.styles.icon_size}px;min-width:${
              item.styles.icon_size
            }px;margin-right:${!item.styles.show_icon_up ? '15px' : '0'}`"
                          />
                          <div
                              v-if="item.styles.show_icon && item.icon && !item.styles.disable_icon_color"
                              class="canvas-menu__icon"
                              :style="`margin-right:${
              !item.styles.show_icon_up ? '15px' : '0'
            };height:${item.styles.icon_size}px;width:${
              item.styles.icon_size
            }px;min-height:${item.styles.icon_size}px;min-width:${
              item.styles.icon_size
            }px;background-color: ${item.styles.icon_color};mask: url(${
              item.icon
            }) no-repeat center / contain;-webkit-mask: url(${
              item.icon
            }) no-repeat center / contain;`"
                          ></div>

                          <div style="text-align: center">
                            {{ item.name }}
                          </div>
                        </div>
                      </swiper-slide>
                    </swiper>
                  </div>

                  <div v-else class="canvas-menu" >
                    <div
                        class="canvas-menu__item canvas-menu-custom"
                        v-for="item in content.content.menu"
                        :key="item.id"
                        :style="`overflow:${
                          item.styles.background_submenu_preview ? 'hidden' : 'unset'
                        };margin-bottom:${+content.shadow_size + 10}px;color: ${
                          item.styles.color
                        };font-family:'${item.styles.fontFamily}',Inter,sans-serif;font-style:${
                          item.styles.fontStyle
                        };font-weight:${item.styles.fontWeight};font-size:${
                          item.styles.fontSize
                        }px;width:calc(${
                          item.styles.width
                        }% - 5px);margin-right: 5px;position:relative;`"
                    >
                      <div
                          :class="`menu_${item.id}`"
                          :style="`width:${360 * (item.styles.width / 100) - 5}px;height:${
                          item.styles.height
                        }px;overflow:hidden;z-index:0;position:absolute;border-radius: ${
                          content.radius
                        }px;`"
                          v-if="item.styles.background_submenu_preview"
                      ></div>
                      <div
                          v-if="item.styles.show_name === 'top'"
                          :style="`margin-bottom:10px;height:${
                                item.styles.lining_height
                              }px;width:${item.styles.lining_width}%;border-radius: ${
                                content.radius
                              }px;padding:${item.styles.lining_padding}px;background-color:${
                                item.styles.main_menu_lining
                              };display:flex;align-items:center;justify-content:center;flex-direction:${
                                item.styles.show_icon_up ? 'column' : 'row'
                              };`"
                      >
                        <img
                            alt="image"
                            v-if="item.styles.show_icon && item.icon && item.styles.disable_icon_color"
                            :src="item.icon"
                            class="canvas-menu__icon"
                            :style="`height:${item.styles.icon_size}px;width:${
            item.styles.icon_size
          }px;min-height:${item.styles.icon_size}px;min-width:${
            item.styles.icon_size
          }px;margin-right:${!item.styles.show_icon_up ? '15px' : '0'}`"
                        />
                        <div
                            v-if="item.styles.show_icon && item.icon && !item.styles.disable_icon_color"
                            class="canvas-menu__icon"
                            :style="`margin-right:${
            !item.styles.show_icon_up ? '15px' : '0'
          };height:${item.styles.icon_size}px;width:${
            item.styles.icon_size
          }px;min-height:${item.styles.icon_size}px;min-width:${
            item.styles.icon_size
          }px;background-color: ${item.styles.icon_color};mask: url(${
            item.icon
          }) no-repeat center / contain;-webkit-mask: url(${
            item.icon
          }) no-repeat center / contain;`"
                        ></div>

                        <div style="text-align: center">
                          {{ item.name }}
                        </div>
                      </div>
                      <a
                          class="canvas-menu__link"
                          href="#"
                          :style="`position:relative;z-index:1;height:${
          item.styles.height
        }px;color: ${item.styles.color};
                    border-radius: ${content.radius}px;box-shadow:${
          item.styles.background_submenu_preview
            ? 'none'
            : '0 ' +
              content.shadow_size +
              'px ' +
              '6px ' +
              content.shadowColor
        };background-color:${
          !item.styles.background_submenu_preview
            ? item.styles.background_main_color
            : 'transparent'
        };text-align:unset;
                  background-image: url('${item.styles.background}');
                  justify-content:${item.styles.position.split(' ')[0]};
    align-items:${item.styles.position.split(' ')[1]};
    background-repeat: no-repeat;
    background-size: cover;
    background-position:center;`"
                      >
                        <div
                            v-if="item.styles.show_name === 'inside'"
                            :style="`height:${item.styles.lining_height}px;width:${
            item.styles.lining_width
          }%;padding:${
            item.styles.lining_padding
          }px;background-color:${
            item.styles.main_menu_lining
          };display:flex;align-items:center;justify-content:center;flex-direction:${
            item.styles.show_icon_up ? 'column' : 'row'
          };`+checkRadius(item,content)"
                        >
                          <img
                              alt="image"
                              v-if="item.styles.show_icon && item.icon && item.styles.disable_icon_color"
                              :src="item.icon"
                              class="canvas-menu__icon"
                              :style="`height:${item.styles.icon_size}px;width:${
            item.styles.icon_size
          }px;min-height:${item.styles.icon_size}px;min-width:${
              item.styles.icon_size
            }px;margin-right:${!item.styles.show_icon_up ? '15px' : '0'}`"
                          />
                          <div
                              v-if="item.styles.show_icon && item.icon && !item.styles.disable_icon_color"
                              class="canvas-menu__icon"
                              :style="`margin-right:${
              !item.styles.show_icon_up ? '15px' : '0'
            };height:${item.styles.icon_size}px;width:${
            item.styles.icon_size
          }px;min-height:${item.styles.icon_size}px;min-width:${
              item.styles.icon_size
            }px;background-color: ${item.styles.icon_color};mask: url(${
              item.icon
            }) no-repeat center / contain;-webkit-mask: url(${
              item.icon
            }) no-repeat center / contain;`"
                          ></div>

                          <div style="text-align: center">
                            {{ item.name }}
                          </div>
                        </div>
                      </a>
                      <div
                          v-if="item.styles.show_name === 'bottom'"
                          :style="`margin-top:10px;height:${item.styles.lining_height}px;width:${
          item.styles.lining_width
        }%;margin-top:10px;border-radius: ${content.radius}px;padding:${
          item.styles.lining_padding
        }px;background-color:${
          item.styles.main_menu_lining
        };display:flex;align-items:center;justify-content:center;flex-direction:${
          item.styles.show_icon_up ? 'column' : 'row'
        };`"
                      >
                        <img
                            alt="image"
                            v-if="item.styles.show_icon && item.icon && item.styles.disable_icon_color"
                            :src="item.icon"
                            class="canvas-menu__icon"
                            :style="`height:${item.styles.icon_size}px;width:${
            item.styles.icon_size
          }px;min-height:${item.styles.icon_size}px;min-width:${
            item.styles.icon_size
          }px;margin-right:${!item.styles.show_icon_up ? '15px' : '0'}`"
                        />
                        <div
                            v-if="item.styles.show_icon && item.icon && !item.styles.disable_icon_color"
                            class="canvas-menu__icon"
                            :style="`margin-right:${
            !item.styles.show_icon_up ? '15px' : '0'
          };height:${item.styles.icon_size}px;width:${
            item.styles.icon_size
          }px;min-height:${item.styles.icon_size}px;min-width:${
            item.styles.icon_size
          }px;background-color: ${item.styles.icon_color};mask: url(${
            item.icon
          }) no-repeat center / contain;-webkit-mask: url(${
            item.icon
          }) no-repeat center / contain;`"
                        ></div>

                        <div style="text-align: center">
                          {{ item.name }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <SliderMenuPreview
                  :active_slider="content.active_slider"
                  :slider_menu="content.slider"
                />
              </template>
              <template
                v-if="
                  selectedMenuItem.page === page &&
                  content.type === 6 &&
                  selectedMenuItem.type === 6
                "
              >
                  <div
                      :style="`margin-top: ${
                    landing.advanced.global.active_header_inside ||
                    landing.advanced.global.active_logo_inside
                      ? '20px'
                      : `${+landing.branding.topMenu.styles.size * 2.5}px`
                  };margin-left:${landing.branding.lining_margin ? '10px' : '0'};margin-right:${landing.branding.lining_margin ? '10px' : '0'}`"
                  >
                    <div :style="liningStyles" style="min-height:300px;padding:15px;">
                  <typeFaqPreview :content="content" />
                    </div>
                </div>
                <SliderMenuPreview
                    :active_slider="content.active_slider"
                    :slider_menu="content.slider"
                />
              </template>
              <template
                v-if="
                  selectedMenuItem.page === page &&
                  content.type === 8 &&
                  selectedMenuItem.type === 8
                "
              >
                <div>
                  <typeEventsPreview :content="content" />
                </div>
                <SliderMenuPreview
                    :active_slider="content.active_slider"
                    :slider_menu="content.slider"
                />
              </template>
              <template
                v-if="
                  selectedMenuItem.page === page &&
                  content.type === 9 &&
                  selectedMenuItem.type === 9
                "
              >
                <typeProductPreview
                  :item="selectedMenuItem"
                  :page="page"
                  :content="content"
                />
              </template>
              <template
                v-if="
                  selectedMenuItem.page === page &&
                  content.type === 10 &&
                  selectedMenuItem.type === 10
                "
              >
                <typeWeatherPreview :content="content.content" />
                <SliderMenuPreview
                  :active_slider="content.active_slider"
                  :slider_menu="content.slider"
                />
              </template>
              <template
                v-if="
                  selectedMenuItem.page === page &&
                  selectedMenuItem.type === 11 &&
                  content.type === 11
                "
              >
                                <typeMapPreview :content="content" :item="selectedMenuItem" />
              </template>
              <template
                v-if="
                  selectedMenuItem.page === page &&
                  selectedMenuItem.type === 12 &&
                  content.type === 12
                "
              >
                <typeDynamicPreview
                  :content="content"
                  :item="selectedMenuItem"
                />
              </template>
              <template
                v-if="
                  selectedMenuItem.page === page &&
                  selectedMenuItem.type === 13 &&
                  content.type === 13
                "
              >
                <TypeGalleryPreview :content="content" />
              </template>
              <template
                v-if="
                  selectedMenuItem.page === page &&
                  selectedMenuItem.type === 14 &&
                  content.type === 14
                "
              >
                <div>
                  <TypeTrailsPreview
                    :content="content"
                    :item="selectedMenuItem"
                  />
                </div>
              </template>
              <template
                v-if="
                  selectedMenuItem.page === page &&
                  selectedMenuItem.type === 15 &&
                  content.type === 15
                "
              >
                <div>
                  <TypeRadioPreview
                    :content="content"
                    :item="selectedMenuItem"
                  />
                </div>
              </template>
                <template
                        v-if="
                  selectedMenuItem.page === page &&
                  selectedMenuItem.type === 16 &&
                  content.type === 16
                "
                >
                    <div :style="`padding:${
                      landing.branding.lining_margin ? '0 10px' : '0'
                    };`" v-if="typeof $store.state.touristListingDetail.group === 'number'&&typeof $store.state.touristListingDetail.item=== 'number'">
                        <TouristListingDetailPreview
                                :content="content"
                                :item="selectedMenuItem"/>
                    </div>
                    <div v-else
                            :style="`margin-top: ${
                    landing.advanced.global.active_header_inside ||
                    landing.advanced.global.active_logo_inside
                      ? '20px'
                      : `${+landing.branding.topMenu.styles.size * 2.5}px`
                  };margin-left:${landing.branding.lining_margin ? '10px' : '0'};margin-right:${landing.branding.lining_margin ? '10px' : '0'}`"
                    >
                        <div :style="liningStyles" style="min-height:300px;padding:15px;">
                            <TouristListingListPreview
                                    :content="content"
                                    :item="selectedMenuItem"
                            />
                        </div>
                    </div>
                </template>
                <template
                        v-if="
                  selectedMenuItem.page === page &&
                  selectedMenuItem.type === 17 &&
                  content.type === 17
                "
                >
                    <div :style="`padding:${
                      landing.branding.lining_margin ? '0 10px' : '0'
                    };`" v-if="$store.state.realEstate.previewPage === 'list'">
                        <typeRealEstateListPreview
                                :content="content"
                                :item="selectedMenuItem"/>
                    </div>
                    <type-real-estate-detail-preview v-if="$store.state.realEstate.previewPage.includes('detail')" :content="content" :item="selectedMenuItem">

                    </type-real-estate-detail-preview>
                </template>
              <div
                v-if="
                  selectedMenuItem.page === page &&
                  content.type === 7 &&
                  selectedMenuItem.type === 7
                "
              >
                <div
                  :style="
                    liningStyles +
                    `min-height:300px;margin-top:${
                      landing.advanced.global.active_header_inside ||
                      landing.advanced.global.active_logo_inside
                        ? '20px'
                        : `${+landing.branding.topMenu.styles.size * 2.5}px`
                    };margin-left:${
                      landing.branding.lining_margin ? '10px' : '0'
                    };margin-right:${
                      landing.branding.lining_margin ? '10px' : '0'
                    };`
                  "
                >
                  <typeContactPreview :content="content" />
                </div>
                <SliderMenuPreview
                    :active_slider="content.active_slider"
                    :slider_menu="content.slider"
                />
              </div>
            </div>
          </template>
          <template
            v-if="
              selectedMenuItem.content[landing.current_lang][4].content.menu
                .length &&
              page.split('/')[0] === selectedMenuItem.page &&
              +page.split('/')[1] ===
                +selectedMenuItem.content[landing.current_lang][4].content.menu[
                  selectedMenuItem.content[landing.current_lang][4].selectedMenu
                ].id &&
              selectedMenuItem.type === 5 &&
              selectedMenuItem.content[landing.current_lang][4].content.menu[
                selectedMenuItem.content[landing.current_lang][4].selectedMenu
              ].type === 1
            "
          >
            <div
              :style="`margin-top:${
                landing.advanced.global.active_header_inside ||
                landing.advanced.global.active_logo_inside
                  ? '20px'
                  : `${+landing.branding.topMenu.styles.size * 2.5}px`
              };margin-left:${landing.branding.lining_margin ? '10px' : '0'};margin-right:${landing.branding.lining_margin ? '10px' : '0'}`"
            >
              <div :style="liningStyles" style="min-height:300px;padding:15px;">
                <typeHtmlPreview
                  :content="
                    selectedMenuItem.content[landing.current_lang][4].content
                      .menu[
                      selectedMenuItem.content[landing.current_lang][4]
                        .selectedMenu
                    ].content[0]
                  "
                />
                <FormOrderPreview
                  :formOrder="
                    selectedMenuItem.content[landing.current_lang][4].content
                      .menu[
                      selectedMenuItem.content[landing.current_lang][4]
                        .selectedMenu
                    ].content[0].form
                  "
                />
              </div>
            </div>
            <SliderMenuPreview
                :active_slider="
                    selectedMenuItem.content[landing.current_lang][4].content
                      .menu[
                      selectedMenuItem.content[landing.current_lang][4]
                        .selectedMenu
                    ].content[0].active_slider
                  "
                :slider_menu="
                    selectedMenuItem.content[landing.current_lang][4].content
                      .menu[
                      selectedMenuItem.content[landing.current_lang][4]
                        .selectedMenu
                    ].content[0].slider
                  "
            />
          </template>
          <template
            v-if="
              selectedMenuItem.content[landing.current_lang][4].content.menu
                .length &&
              page.split('/')[0] === selectedMenuItem.page &&
              +page.split('/')[1] ===
                selectedMenuItem.content[landing.current_lang][4].content.menu[
                  selectedMenuItem.content[landing.current_lang][4].selectedMenu
                ].id &&
              selectedMenuItem.type === 5 &&
              selectedMenuItem.content[landing.current_lang][4].content.menu[
                selectedMenuItem.content[landing.current_lang][4].selectedMenu
              ].type === 4 &&
              $store.state.listing.subListing === null
            "
          >
            <div
              class="listing__list"
              :style="
                liningStyles +
                `margin-top:${
                  landing.advanced.global.active_header_inside ||
                  landing.advanced.global.active_logo_inside
                    ? '20px'
                    : `${+landing.branding.topMenu.styles.size * 2.5}px`
                };margin-left:${
                  landing.branding.lining_margin ? '10px' : '0'
                };margin-right:${
                  landing.branding.lining_margin ? '10px' : '0'
                };min-height:300px;`
              "
            >
              <typeListingListPreview
                :item="
                  selectedMenuItem.content[landing.current_lang][4].content
                    .menu[
                    selectedMenuItem.content[landing.current_lang][4]
                      .selectedMenu
                  ]
                "
                :content="
                  selectedMenuItem.content[landing.current_lang][4].content
                    .menu[
                    selectedMenuItem.content[landing.current_lang][4]
                      .selectedMenu
                  ].content[3]
                "
                :type="'sublisting'"
              />
            </div>
            <SliderMenuPreview
                :active_slider="
                  selectedMenuItem.content[landing.current_lang][4].content
                    .menu[
                    selectedMenuItem.content[landing.current_lang][4]
                      .selectedMenu
                  ].content[3].active_slider
                "
                :slider_menu="
                  selectedMenuItem.content[landing.current_lang][4].content
                    .menu[
                    selectedMenuItem.content[landing.current_lang][4]
                      .selectedMenu
                  ].content[3].slider
                "
            />
          </template>
          <template
            v-if="
              selectedMenuItem.content[landing.current_lang][4].content.menu
                .length &&
              page.split('/')[0] === selectedMenuItem.page &&
              +page.split('/')[1] ===
                selectedMenuItem.content[landing.current_lang][4].content.menu[
                  selectedMenuItem.content[landing.current_lang][4].selectedMenu
                ].id &&
              selectedMenuItem.type === 5 &&
              selectedMenuItem.content[landing.current_lang][4].content.menu[
                selectedMenuItem.content[landing.current_lang][4].selectedMenu
              ].type === 4 &&
              $store.state.listing.subListing !== null &&
              $store.state.listing.listingGroup !== null
            "
          >
            <div style="padding: 0 10px">
              <typeListingDetailPreview
                :item="
                  selectedMenuItem.content[landing.current_lang][4].content
                    .menu[
                    selectedMenuItem.content[landing.current_lang][4]
                      .selectedMenu
                  ]
                "
                :content="
                  selectedMenuItem.content[landing.current_lang][4].content
                    .menu[
                    selectedMenuItem.content[landing.current_lang][4]
                      .selectedMenu
                  ].content[3]
                "
                :listing="listing"
              />
            </div>
          </template>
          <template
            v-if="
              selectedMenuItem.content[landing.current_lang][4].content.menu
                .length &&
              page.split('/')[0] === selectedMenuItem.page &&
              +page.split('/')[1] ===
                selectedMenuItem.content[landing.current_lang][4].content.menu[
                  selectedMenuItem.content[landing.current_lang][4].selectedMenu
                ].id &&
              selectedMenuItem.type === 5 &&
              selectedMenuItem.content[landing.current_lang][4].content.menu[
                selectedMenuItem.content[landing.current_lang][4].selectedMenu
              ].type === 5
            "
          >
            <div
              :style="
                liningStyles +
                `min-height:300px;margin-top:${
                  landing.advanced.global.active_header_inside ||
                  landing.advanced.global.active_logo_inside
                    ? '20px'
                    : `${+landing.branding.topMenu.styles.size * 2.5}px`
                };margin-left:${
                  landing.branding.lining_margin ? '10px' : '0'
                };margin-right:${
                  landing.branding.lining_margin ? '10px' : '0'
                };`
              "
              style="padding: 15px"
            >
              <typeFaqPreview
                :content="
                  selectedMenuItem.content[landing.current_lang][4].content
                    .menu[
                    selectedMenuItem.content[landing.current_lang][4]
                      .selectedMenu
                  ].content[4]
                "
              />
            </div>
          </template>
          <template
            v-if="
              selectedMenuItem.content[landing.current_lang][4].content.menu
                .length &&
              page.split('/')[0] === selectedMenuItem.page &&
              +page.split('/')[1] ===
                selectedMenuItem.content[landing.current_lang][4].content.menu[
                  selectedMenuItem.content[landing.current_lang][4].selectedMenu
                ].id &&
              selectedMenuItem.type === 5 &&
              selectedMenuItem.content[landing.current_lang][4].content.menu[
                selectedMenuItem.content[landing.current_lang][4].selectedMenu
              ].type === 6
            "
          >
            <div
              :style="
                liningStyles +
                `min-height:300px;margin-top:${
                  landing.advanced.global.active_header_inside ||
                  landing.advanced.global.active_logo_inside
                    ? '20px'
                    : `${+landing.branding.topMenu.styles.size * 2.5}px`
                };margin-left:${
                  landing.branding.lining_margin ? '10px' : '0'
                };margin-right:${
                  landing.branding.lining_margin ? '10px' : '0'
                };`
              "
            >
              <typeContactPreview
                :content="
                  selectedMenuItem.content[landing.current_lang][4].content
                    .menu[
                    selectedMenuItem.content[landing.current_lang][4]
                      .selectedMenu
                  ].content[5]
                "
              />
            </div>
            <SliderMenuPreview
                :active_slider="
                  selectedMenuItem.content[landing.current_lang][4].content
                    .menu[
                    selectedMenuItem.content[landing.current_lang][4]
                      .selectedMenu
                  ].content[5].active_slider
                "
                :slider_menu="
                  selectedMenuItem.content[landing.current_lang][4].content
                    .menu[
                    selectedMenuItem.content[landing.current_lang][4]
                      .selectedMenu
                  ].content[5].slider
                "
            />
          </template>
          <template
            v-if="
              selectedMenuItem.content[landing.current_lang][4].content.menu
                .length &&
              page.split('/')[0] === selectedMenuItem.page &&
              +page.split('/')[1] ===
                selectedMenuItem.content[landing.current_lang][4].content.menu[
                  selectedMenuItem.content[landing.current_lang][4].selectedMenu
                ].id &&
              selectedMenuItem.type === 5 &&
              selectedMenuItem.content[landing.current_lang][4].content.menu[
                selectedMenuItem.content[landing.current_lang][4].selectedMenu
              ].type === 7
            "
          >
            <div
              :style="
                liningStyles +
                `min-height:300px;margin-top:${
                  landing.advanced.global.active_header_inside ||
                  landing.advanced.global.active_logo_inside
                    ? '20px'
                    : `${+landing.branding.topMenu.styles.size * 2.5}px`
                };margin-left:${
                  landing.branding.lining_margin ? '10px' : '0'
                };margin-right:${
                  landing.branding.lining_margin ? '10px' : '0'
                };`
              "
            >
              <typeEventsPreview
                :content="
                  selectedMenuItem.content[landing.current_lang][4].content
                    .menu[
                    selectedMenuItem.content[landing.current_lang][4]
                      .selectedMenu
                  ].content[6]
                "
              />
            </div>
          </template>
          <template
            v-if="
              selectedMenuItem.content[landing.current_lang][4].content.menu
                .length &&
              page.split('/')[0] === selectedMenuItem.page &&
              +page.split('/')[1] ===
                selectedMenuItem.content[landing.current_lang][4].content.menu[
                  selectedMenuItem.content[landing.current_lang][4].selectedMenu
                ].id &&
              selectedMenuItem.type === 5 &&
              selectedMenuItem.content[landing.current_lang][4].content.menu[
                selectedMenuItem.content[landing.current_lang][4].selectedMenu
              ].type === 8
            "
          >
            <div
              :style="
                liningStyles +
                `min-height:300px;margin-top:${
                  landing.advanced.global.active_header_inside ||
                  landing.advanced.global.active_logo_inside
                    ? '20px'
                    : `${+landing.branding.topMenu.styles.size * 2.5}px`
                };margin-left:${
                  landing.branding.lining_margin ? '10px' : '0'
                };margin-right:${
                  landing.branding.lining_margin ? '10px' : '0'
                };`
              "
            >
              <typeProductPreview
                :content="
                  selectedMenuItem.content[landing.current_lang][4].content
                    .menu[
                    selectedMenuItem.content[landing.current_lang][4]
                      .selectedMenu
                  ].content[7]
                "
              />
            </div>
          </template>
          <template
            v-if="
              selectedMenuItem.content[landing.current_lang][4].content.menu
                .length &&
              page.split('/')[0] === selectedMenuItem.page &&
              +page.split('/')[1] ===
                selectedMenuItem.content[landing.current_lang][4].content.menu[
                  selectedMenuItem.content[landing.current_lang][4].selectedMenu
                ].id &&
              selectedMenuItem.type === 5 &&
              selectedMenuItem.content[landing.current_lang][4].content.menu[
                selectedMenuItem.content[landing.current_lang][4].selectedMenu
              ].type === 9
            "
          >
            <typeWeatherPreview
              :content="
                selectedMenuItem.content[landing.current_lang][4].content.menu[
                  selectedMenuItem.content[landing.current_lang][4].selectedMenu
                ].content[8].content
              "
            />
            <SliderMenuPreview
              :active_slider="
                selectedMenuItem.content[landing.current_lang][4].content.menu[
                  selectedMenuItem.content[landing.current_lang][4].selectedMenu
                ].content[8].active_slider
              "
              :slider_menu="
                selectedMenuItem.content[landing.current_lang][4].content.menu[
                  selectedMenuItem.content[landing.current_lang][4].selectedMenu
                ].content[8].slider
              "
            />
          </template>
          <template
            v-if="
              selectedMenuItem.content[landing.current_lang][4].content.menu
                .length &&
              page.split('/')[0] === selectedMenuItem.page &&
              +page.split('/')[1] ===
                selectedMenuItem.content[landing.current_lang][4].content.menu[
                  selectedMenuItem.content[landing.current_lang][4].selectedMenu
                ].id &&
              selectedMenuItem.type === 5 &&
              selectedMenuItem.content[landing.current_lang][4].content.menu[
                selectedMenuItem.content[landing.current_lang][4].selectedMenu
              ].type === 10
            "
          >
            <typeMapPreview
              :selectedMenuID="selectedMenuItem.id"
              :content="
                selectedMenuItem.content[landing.current_lang][4].content.menu[
                  selectedMenuItem.content[landing.current_lang][4].selectedMenu
                ].content[9]
              "
              :item="
                selectedMenuItem.content[landing.current_lang][4].content.menu[
                  selectedMenuItem.content[landing.current_lang][4].selectedMenu
                ]
              "
            />
          </template>
          <template
            v-if="
              selectedMenuItem.content[landing.current_lang][4].content.menu
                .length &&
              page.split('/')[0] === selectedMenuItem.page &&
              +page.split('/')[1] ===
                selectedMenuItem.content[landing.current_lang][4].content.menu[
                  selectedMenuItem.content[landing.current_lang][4].selectedMenu
                ].id &&
              selectedMenuItem.type === 5 &&
              selectedMenuItem.content[landing.current_lang][4].content.menu[
                selectedMenuItem.content[landing.current_lang][4].selectedMenu
              ].type === 11
            "
          >
            <typeDynamicPreview
              :content="
                selectedMenuItem.content[landing.current_lang][4].content.menu[
                  selectedMenuItem.content[landing.current_lang][4].selectedMenu
                ].content[9]
              "
              :item="
                selectedMenuItem.content[landing.current_lang][4].content.menu[
                  selectedMenuItem.content[landing.current_lang][4].selectedMenu
                ]
              "
            />
          </template>
          <template
            v-if="
              selectedMenuItem.content[landing.current_lang][4].content.menu
                .length &&
              page.split('/')[0] === selectedMenuItem.page &&
              +page.split('/')[1] ===
                selectedMenuItem.content[landing.current_lang][4].content.menu[
                  selectedMenuItem.content[landing.current_lang][4].selectedMenu
                ].id &&
              selectedMenuItem.type === 5 &&
              selectedMenuItem.content[landing.current_lang][4].content.menu[
                selectedMenuItem.content[landing.current_lang][4].selectedMenu
              ].type === 12
            "
          >
            <typeDynamicPreview
              :content="
                selectedMenuItem.content[landing.current_lang][4].content.menu[
                  selectedMenuItem.content[landing.current_lang][4].selectedMenu
                ].content[9]
              "
              :item="
                selectedMenuItem.content[landing.current_lang][4].content.menu[
                  selectedMenuItem.content[landing.current_lang][4].selectedMenu
                ]
              "
            />
          </template>
          <template
            v-if="
              selectedMenuItem.content[landing.current_lang][4].content.menu
                .length &&
              page.split('/')[0] === selectedMenuItem.page &&
              +page.split('/')[1] ===
                selectedMenuItem.content[landing.current_lang][4].content.menu[
                  selectedMenuItem.content[landing.current_lang][4].selectedMenu
                ].id &&
              selectedMenuItem.type === 5 &&
              selectedMenuItem.content[landing.current_lang][4].content.menu[
                selectedMenuItem.content[landing.current_lang][4].selectedMenu
              ].type === 13
            "
          >
            <TypeTrailsPreview
              :selectedMenuID="selectedMenuItem.id"
              :content="
                selectedMenuItem.content[landing.current_lang][4].content.menu[
                  selectedMenuItem.content[landing.current_lang][4].selectedMenu
                ].content[12]
              "
              :item="
                selectedMenuItem.content[landing.current_lang][4].content.menu[
                  selectedMenuItem.content[landing.current_lang][4].selectedMenu
                ]
              "
            />
          </template>
          <div
            class="canvas-socials"
            v-if="
              showSocials.length &&
              selectedMenuItem.page === page &&
              selectedMenuItem.show_social &&
              selectedMenuItem.type !== 11 &&
              selectedMenuItem.type !== 12
            "
            :class="{
              'canvas-socials_tile': landing.socials.style === 'tile',
            }"
          >
            <a
              class="canvas-socials__item"
              v-for="item in landing.socials.list[landing.current_lang].filter(
                (x) => x.active
              )"
              :key="item.id"
              :href="item.link"
              v-external-link
              :style="`width:${
                landing.socials.style === 'tile'
                  ? `${+landing.branding.topMenu.styles.size * 2.5}px`
                  : landing.socials.width + '%'
              };background-color: ${item.color};
                                        border-radius: ${
                                          landing.socials.radius
                                        }px`"
            >
              <span
                v-if="item.icon"
                class="canvas-socials__icon canvas__fill"
                v-html="item.icon"
              ></span>
              {{ item.text }}
            </a>
          </div>
        </div>
        <div v-if="page === 'advanced' && landing.advanced.tab === 12">
          <UnderConstructorPreview />
        </div>
        <div
          v-if="page === 'reviews' && landing.reviews && !landing.free"
          style="flex: 1 1 auto"
        >
          <preview-reviews
            :page="page"
            :landing="landing"
          />
        </div>
        <div v-if="page === 'reviews' && landing.reviews && landing.free">
          <div
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              padding-top: 80px;
            "
          >
            <v-icon color="#cccccc" size="200px" style="margin-right: 20px"
              >mdi-lock</v-icon
            >
          </div>
        </div>
        <template v-if="page === 'home'">
          <div
            class="canvas-socials"
            v-if="landing.socials && showSocials.length"
            :class="{
              'canvas-socials_tile': landing.socials.style === 'tile',
            }"
          >
            <a
              class="canvas-socials__item"
              v-for="item in landing.socials.list[landing.current_lang].filter(
                (x) => x.active
              )"
              :key="item.id"
              :href="item.link"
              v-external-link
              :style="`margin-right:${
                landing.socials.style === 'tile' ? '15px' : '0'
              };width:${
                landing.socials.style === 'tile'
                  ? `${+landing.branding.topMenu.styles.size * 2.5}px`
                  : landing.socials.width + '%'
              };background-color: ${item.color};
                                    border-radius: ${
                                      landing.socials.radius
                                    }px`"
            >
              <!-- <v-icon :color="'#fff'">{{item.icon}}</v-icon> -->
              <span
                class="canvas-socials__icon canvas__fill"
                v-html="item.icon"
              ></span>
              {{ item.text }}
            </a>
          </div>
        </template>

        <template>
          <EmailListPreview :page="page" />
        </template>
        <template
          v-if="
            showPoint !== 11 &&
            page !== 'loader' &&
            page !== 'order' &&
            $store.state.trails.page !== 'map'
          "
        >
          <FooterPreview :page="page" />
        </template>
        <template
          v-if="
            landing.branding.bottomMenu.show &&
            page !== 'intro' &&
            page !== 'reviews' &&
            page !== 'sidebar' &&
            page !== 'loader' &&
            page !== 'lining' &&
            page !== 'underConstructor' &&
            page !== 'favorite' &&
            page !== 'order' &&
            page !== 'auth' &&
            page !== 'topMenu' &&
            page !== 'profile'&&
            page !== 'advanced'&&
            page !== 'menuStyles'
          "
        >
          <BottomMenuPreview />
        </template>
      </div>
    </div>
    <v-alert type="success" class="copied" v-if="copied"> Copied! </v-alert>
    <div style="position: relative" v-if="showOpen&&!isMobile">
      <div style="position: absolute; top: -114px; left: -15px">
        <img src="@/assets/arrow.png" alt="arrow" style="height: 196px" />
      </div>
      <span
        style="display: block; color: #545454; width: 100%; text-align: center"
      >
        Scan it and see your guest app
      </span>
      <div
        class="qr_code"
        style="
          height: 150px;
          width: 150px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 5px;
          background: #fff;
          margin: 10px auto;
        "
      >
        <qr-code
          :text="qrLink"
          :size="128"
          error-level="H"
          style="display: flex; justify-content: center; align-items: center"
        ></qr-code>
      </div>
    </div>
  </div>
</template>

<script>
import "swiper/swiper-bundle.css";
import "swiper/modules/pagination/pagination.min.css";
import SwiperCore, { Pagination } from "swiper";
SwiperCore.use([Pagination]);
import { mapGetters, mapState } from "vuex";
import { defaultIcon } from "../../store/landing";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import BottomBannerPreview from "@/components/Constructor/Preview/Branding/BottomBannerPreview";
import SidebarPreview from "@/components/Constructor/Preview/Branding/SidebarPreview";
import TypeRealEstateDetailPreview from "./Menu/Preview/typeRealEstateDetailPreview";
import SegmentCategoryListPreview from "./Marketing/Preview/SegmentCategoryListPreview";
import SegmentProductListPreview from "./Marketing/Preview/SegmentProductListPreview";
import SegmentProductDetailPreview from "./Marketing/Preview/SegmentProductDetailPreview";
export default {
  props: {
    id: Number,
  },
  name: "ConstructorPreview",
  created() {
    this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  },
  data: () => ({
    isMobile:false,
    swiperOptionsSwipeMenu:{
      grabCursor: true,
      centeredSlides: false,
      slidesPerView:"auto",
      spaceBetween:15
    },
    cartProducts: 0,
    center: { lat: 10, lng: 10 },
    swiperOptions: {
      slidesPerView: "auto",
      spaceBetween: 15,
    },
    swiperMenuOption: {
      slidesPerView: 3,
      spaceBetween: 10,
    },
    swiperOptionsListing: {
      grabCursor: true,
      centeredSlides: true,
      spaceBetween: 100,
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
      },
    },
    active_faq_tab: 0,
    currentTime: 5,
    timer: null,
    payment_method: "",
    sheet_deliver: false,
    sheet: false,
    dialogEvent: false,
    view: "mobile",
    currentIndexOfSlide: null,
    drag: false,
    date: new Date(),
    show_banner: true,
    copied: false,
    show_intro: true,
    active_listing_tab: 0,
    datenow: "",
    intervalTime: "",
  }),
  components: {
    SegmentProductDetailPreview,
    SegmentProductListPreview,
    SegmentCategoryListPreview,
    TypeRealEstateDetailPreview,
    SidebarPreview,
    BottomBannerPreview,
    Swiper,
    SwiperSlide,
    EventsPreview:() => import("@/components/Constructor/Preview/Branding/EventsPreview"),
    typeRealEstateListPreview:() => import("./Menu/Preview/typeRealEstateListPreview.vue"),
    IntroPreview:() => import("@/components/Constructor/Preview/Branding/IntroPreview"),
    MainPageStylesPreview:() => import("@/components/Constructor/Menu/Preview/MainPageStylesPreview"),
    TypeGalleryPreview: () =>
      import("@/components/Constructor/Menu/Preview/typeGalleryPreview.vue"),
    EmailListPreview: () => import("./Preview/Branding/EmailListPreview"),
    SliderMenuPreview: () => import("./Menu/Preview/SliderMenuPreview"),
    PreviewAdvanced: () => import("./Preview/Advanced"),
    PreviewReviews: () => import("./Preview/Reviews"),
    draggable: () => import("vuedraggable"),
    WeatherApp: () => import("./WeatherPreview/WeatherApp"),
    EmailNotificationsPreview: () =>
      import("./Order/EmailNotificationsPreview.vue"),
    UnderConstructorPreview: () =>
      import("./Preview/Advanced/UnderConstructorPreview.vue"),
    BottomMenuPreview: () => import("./Preview/Branding/BottomMenuPreview.vue"),
    StartPreview: () => import("./Preview/Start/Start.vue"),
    HeaderPreview: () => import("./Preview/Branding/HeaderPreview.vue"),
    LogoPreview: () => import("./Preview/Branding/LogoPreview.vue"),
    TextLogoPreview: () => import("./Preview/Branding/TextLogoPreview.vue"),
    FooterPreview: () => import("./Preview/Branding/FooterPreview.vue"),
    LiningPreview: () => import("./Preview/Branding/LiningPreview.vue"),
    SliderPreview: () => import("./Preview/Branding/SliderPreview.vue"),
    typeProductPreview: () => import("./Menu/Preview/typeProductPreview.vue"),
    CartPreview: () => import("./Menu/Preview/CartPreview.vue"),
    FormOrderPreview: () => import("./Menu/Preview/FormOrderPreview.vue"),
    typeHtmlPreview: () => import("./Menu/Preview/typeHtmlPreview.vue"),
    typeListingListPreview: () =>
      import("./Menu/Preview/typeListingListPreview.vue"),
    typeListingDetailPreview: () =>
      import("./Menu/Preview/typeListingDetailPreview.vue"),
    typeFaqPreview: () => import("./Menu/Preview/typeFaqPreview.vue"),
    typeContactPreview: () => import("./Menu/Preview/typeContactPreview.vue"),
    typeWeatherPreview: () => import("./Menu/Preview/typeWeatherPreview.vue"),
    typeEventsPreview: () => import("./Menu/Preview/typeEventsPreview.vue"),
    typeMapPreview: () => import("./Menu/Preview/typeMapPointsPreview.vue"),
    LoaderPreview: () => import("./Preview/Branding/LoaderPreview.vue"),
    typeDynamicPreview: () => import("./Menu/Preview/typeDynamicPreview.vue"),
    TypeTrailsPreview: () =>
      import("@/components/Constructor/Menu/Preview/typeTrailsPreview.vue"),
    TypeRadioPreview: () =>
      import("@/components/Constructor/Menu/Preview/typeRadio"),
    TouristListingListPreview:() =>
      import("@/components/Constructor/Menu/Preview/TouristListingListPreview"),
    TouristListingDetailPreview:() =>
      import("@/components/Constructor/Menu/Preview/TouristListingDetailPreview"),
    PasscodePreview:() =>
      import("@/components/Constructor/Preview/Advanced/PasscodePreview")
  },
  watch: {
    selectedBanner(val) {
      if (val) {
        this.$refs.canvas__scroll.scrollTop = 0;
        this.$refs.canvas__scroll.style.overflow = "hidden";
      } else {
        this.$refs.canvas__scroll.style.overflowY = "scroll";
      }
    },
    "landing.branding.active_tab"(val) {
      if (val !== 8 && val !== 10) {
        this.$refs.canvas__scroll.style.overflowY = "scroll";
      } else {
        this.$refs.canvas__scroll.scrollTop = 0;
        this.$refs.canvas__scroll.style.overflow = "hidden";
      }
    },
    "landing.advanced.global.active_slider"(val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.canvas__scroll.scrollTo(
            0,
            this.$refs.canvas__scroll.scrollHeight
          );
        });
      }
    },
    "landing.advanced.global.show_intro_seconds"(val) {
      if (val) {
        this.currentTime = val;
        clearInterval(this.currentTime);
        this.startTimer();
      }
    },
    "landing.advanced.global.slider_positioning"(val) {
      this.$nextTick(() => {
        if (val === "top") {
          this.$refs.canvas__scroll.scrollTo(0, 0);
        } else {
          this.$refs.canvas__scroll.scrollTo(
            0,
            this.$refs.canvas__scroll.scrollHeight
          );
        }
      });
    },
    "landing.advanced.global.active_intro"() {
      clearInterval(this.currentTime);
      this.startTimer();
    },
    "landing.advanced.global.show_again_intro"() {
      this.show_intro = true;
      clearInterval(this.currentTime);
      this.startTimer();
    },
    currentTime(time) {
      if (time === 0) {
        this.currentTime = this.landing.advanced.global.show_intro_seconds;
      }
    },
  },
  computed: {
    ...mapState("branding", ["intro_pro"]),
    ...mapState("menu", ["selectedMenuItem", "list"]),
    ...mapState('products',['pageProduct']),
    ...mapState([
      "page",
      "menu_active_tab",
      "canvas",
      "selected_langs",
      "landing",
      "listing",
    ]),
    ...mapState("orders", ["order_form", "order"]),
    ...mapState("branding", ["sliderBranding"]),
    ...mapGetters("menu", ["getActiveList"]),
    ...mapState("banners", ["selectedBanner", "bannerPreview", "bannerList"]),
    showOpen() {
      return !(
        (this.page === "order" && this.landing.order.tab === 0) ||
        (this.page === "order" && this.landing.order.tab === 1) ||
        (this.page === "order" && this.landing.order.tab === 2) ||
        (this.page === "order" && this.landing.order.tab === 5) ||
        (this.page === "order" && this.landing.order.tab === 4) ||
        (this.page === "reviews" && this.landing.reviews.tab === 4)
      );
    },
    VUE_APP_LINK() {
      return process.env.VUE_APP_LINK;
    },
    VUE_APP_STORAGE_URL() {
      return process.env.VUE_APP_STORAGE_URL;
    },
    showShare(){
      if(typeof this.$store.state.touristListingDetail.group === 'number'&&typeof this.$store.state.touristListingDetail.item === 'number'){
        return this.selectedMenuItem.content[this.landing.current_lang][15].styles.show_share;
      }
      return false
    },
    showFavoriteIcon() {
      if(typeof this.$store.state.touristListingDetail.group === 'number'&&typeof this.$store.state.touristListingDetail.item === 'number'){
        return this.selectedMenuItem.content[this.landing.current_lang][15].styles.show_favorite;
      }
      const menu = this.list.some((x) => x.type === 4);
      const listingDetailShow =
        this.$store.state.listing.index ||
        this.$store.state.listing.subListing ||
        this.$store.state.listing.index === 0 ||
        this.$store.state.listing.subListing === 0;
      return menu && listingDetailShow;
    },
    showPoint() {
      const candidate = this.list.find((x) => x.page === this.page);
      if (candidate) {
        return candidate.type;
      } else {
        return null;
      }
    },
    showSocials() {
      return this.landing.socials.list[this.landing.current_lang].filter(
        (x) => x.active
      );
    },
    qrLink() {
      const find = this.list.find((x) => x.page === this.page);
      if (this.page === "reviews") {
        return process.env.VUE_APP_LINK + "/m/" + this.id + "/reviews";
      }
      if (this.page === "cart") {
        return process.env.VUE_APP_LINK + "/m/" + this.id + "/cart";
      }
      if (this.page === "favorite") {
        return process.env.VUE_APP_LINK + "/m/" + this.id + "/favorite";
      } else if (!find) {
        const find = this.list.find((x) => x.page === this.page.split("/")[0]);
        if (find) {
          return (
            `${process.env.VUE_APP_LINK}/m/` + this.id + "/submenu/" + find.page
          );
        } else {
          return `${process.env.VUE_APP_LINK}/m/` + this.id;
        }
      } else {
        if (find.type === 1 || find.type === 4) {
          return (
            `${process.env.VUE_APP_LINK}/m/` + this.id + "/menu/" + find.page
          );
        } else if (find.type === 2 || find.type === 3) {
          return `${process.env.VUE_APP_LINK}/m/` + this.id;
        } else if (find.type === 5) {
          return (
            `${process.env.VUE_APP_LINK}/m/` + this.id + "/submenu/" + find.page
          );
        } else if (find.type === 6) {
          return (
            `${process.env.VUE_APP_LINK}/m/` +
            this.id +
            "/menu/" +
            find.page +
            "/faq"
          );
        } else if (find.type === 7) {
          return (
            `${process.env.VUE_APP_LINK}/m/` +
            this.id +
            "/menu/" +
            find.page +
            "/contact"
          );
        } else if (find.type === 8) {
          return (
            `${process.env.VUE_APP_LINK}/m/` +
            this.id +
            "/menu/" +
            find.page +
            "/events"
          );
        } else if (find.type === 9) {
          if (this.$store.state.products.pageProduct === "categoryList") {
            return `${process.env.VUE_APP_LINK}/m/${this.id}/menu/${find.page}/products/category`;
          } else if (
            this.$store.state.products.pageProduct.includes("subcategoriesList")
          ) {
            const link = this.$store.state.products.pageProduct.split("/");
            return `${process.env.VUE_APP_LINK}/m/${this.id}/menu/${find.page}/products/subcategory/${link[1]}`;
          } else if (
            this.$store.state.products.pageProduct.includes("productList")
          ) {
            const link = this.$store.state.products.pageProduct.split("/");
            if (link.length === 2) {
              return `${process.env.VUE_APP_LINK}/m/${this.id}/menu/${find.page}/products/list/${link[1]}`;
            } else {
              return `${process.env.VUE_APP_LINK}/m/${this.id}/menu/${find.page}/products/list/${link[1]}?subcategory=${link[2]}`;
            }
          } else if (
            this.$store.state.products.pageProduct.includes("productDetail")
          ) {
            const link = this.$store.state.products.pageProduct.split("/");
            if (link.length === 3) {
              return `${process.env.VUE_APP_LINK}/m/${this.id}/menu/${find.page}/products/detail/${link[1]}/${link[2]}`;
            } else {
              return `${process.env.VUE_APP_LINK}/m/${this.id}/menu/${find.page}/products/detail/${link[1]}/${link[2]}?subcategory=${link[2]}`;
            }
          } else {
            return `${process.env.VUE_APP_LINK}/m/` + this.id;
          }
        } else if (find.type === 10) {
          return (
            `${process.env.VUE_APP_LINK}/m/` +
            this.id +
            "/menu/" +
            find.page +
            "/weather"
          );
        } else if (find.type === 11) {
          return (
            `${process.env.VUE_APP_LINK}/m/` +
            this.id +
            "/menu/" +
            find.page +
            "/points"
          );
        } else if (find.type === 12) {
          return (
            `${process.env.VUE_APP_LINK}/m/` +
            this.id +
            "/menu/" +
            find.page +
            "/dynamic"
          );
        } else if (find.type === 13) {
          return (
            `${process.env.VUE_APP_LINK}/m/` +
            this.id +
            "/menu/" +
            find.page +
            "/gallery"
          );
        } else if (find.type === 14) {
          return (
            `${process.env.VUE_APP_LINK}/m/` +
            this.id +
            "/menu/" +
            find.page +
            "/trail/list"
          );
        } else {
          return `${process.env.VUE_APP_LINK}/m/` + this.id;
        }
      }
    },
    previewHeight() {
      let block = document.querySelector(".canvas");
      return +block.scrollHeight;
    },
    bottomSlider: function () {
      if (this.sliderBranding) {
        return this.sliderBranding[this.current_lang];
      } else {
        return false;
      }
    },
    topSlider: function () {
      if (this.sliderBranding) {
        return this.sliderBranding[this.current_lang];
      } else {
        return false;
      }
    },
    backgroundAuth: function () {
      if (this.landing.advanced.auth.background.show) {
        if (this.landing.advanced.auth.background.url) {
          return `url(${this.landing.advanced.auth.background.url})`;
        } else {
          return this.landing.advanced.auth.background.color;
        }
      } else {
        return "";
      }
    },

    payment_resident: function () {
      return this.order_form.content[
        this.landing.current_lang
      ].payment_methods_resident.filter((x) => x.value === true);
    },
    payment_guest: function () {
      return this.order_form.content[
        this.landing.current_lang
      ].payment_methods_guest.filter((x) => x.value === true);
    },
    ...mapGetters("landing", ["reviewsSuccessPage", "reviews", "current_lang"]),
    now_deliver: function () {
      return this.$moment().format("ddd DD MMM, HH:mm");
    },
    dateToday: function () {
      return this.$moment().format("DD.MM.YYYY");
    },
    flag: function () {
      const lang = this.landing.advanced.langs.find(
        (x) => x.value === this.landing.advanced.default_lang
      );
      return lang.flag;
    },
    styleObjectPlay: function () {
      return {
        "--color": this.selectedBanner.styles.banner_video_icon_color,
        "--color-hover":
          this.selectedBanner.styles.banner_video_icon_color_hover,
        width: this.selectedBanner.styles.banner_video_icon_size + "px",
        height: this.selectedBanner.styles.banner_video_icon_size + "px",
        left: `calc(50% - ${
          this.selectedBanner.styles.banner_video_icon_size / 2
        }px)`,
        top: `calc(50% - ${
          this.selectedBanner.styles.banner_video_icon_size / 2
        }px)`,
      };
    },
    year() {
      return this.date.getFullYear();
    },
    period() {
      let hour = this.date.getHours();

      return hour > 5 && hour < 18 ? "app--day" : "app--night";
    },
    liningStyles() {
      if (this.landing.branding.bg_lining_img) {
        return `border-radius:${this.landing.branding.lining_radius}px;background-image:url(${this.landing.branding.bg_lining_img});background-size:cover;background-repeat:no-repeat;`;
      } else {
        return `border-radius:${this.landing.branding.lining_radius}px;background-color:${this.landing.branding.bg_lining_color};`;
      }
    },
    langs() {
      let langs = [];

      this.$store.state.langs.forEach((lang) => {
        this.landing.selected_langs.forEach((select_lang) => {
          if (lang.value === select_lang) {
            langs.push(lang);
          }
        });
      });

      return langs;
    },
    paddingStyles() {
      if (
        this.landing.branding.layout === "center" ||
        this.landing.branding.layout === "left"
      ) {
        const size = this.landing.branding.logo_size.height + 70;
        return `padding-top:${size}px`;
      } else {
        return "padding-top:0;";
      }
    },
    canvasStyle() {
      if (
        this.show_intro &&
        this.landing.advanced.global.active_intro &&
        this.page === "intro"
      ) {
        this.$nextTick(() => {
          this.$refs.canvas__scroll.scrollTop = 0;
        });
        if (
          this.landing.advanced.tab === 3 ||
          this.landing.advanced.tab === 4
        ) {
          return `overflow:hidden;color:${this.landing.branding.font_color}`;
        } else {
          return `overflow:hidden;background-color:${
            this.landing.branding.background_preview
              ? "transparent"
              : this.landing.branding.bg_color
          };background-image:${
            this.landing.branding.bg_image
              ? "url(" + this.landing.branding.bg_image + ");"
              : "url(null);"
          }color:${this.landing.branding.font_color}`;
        }
      }
      if (this.selectedMenuItem) {
        if (this.selectedMenuItem.type === 5) {
          if (
            this.selectedMenuItem.content[this.landing.current_lang][4].content
              .menu.length &&
            this.page.split("/")[0] === this.selectedMenuItem.page &&
            +this.page.split("/")[1] ===
              this.selectedMenuItem.content[this.landing.current_lang][4]
                .content.menu[
                this.selectedMenuItem.content[this.landing.current_lang][4]
                  .selectedMenu
              ].id &&
            this.selectedMenuItem.type === 5 &&
            this.selectedMenuItem.content[this.landing.current_lang][4].content
              .menu[
              this.selectedMenuItem.content[this.landing.current_lang][4]
                .selectedMenu
            ].type === 4 &&
            this.$store.state.listing.subListing === null
          ) {
            return `background-color:${
              this.selectedMenuItem.content[this.landing.current_lang][4]
                .content.menu[
                this.selectedMenuItem.content[this.landing.current_lang][4]
                  .selectedMenu
              ].styles.background_listing_preview
                ? "transparent"
                : this.selectedMenuItem.content[this.landing.current_lang][4]
                    .content.menu[
                    this.selectedMenuItem.content[this.landing.current_lang][4]
                      .selectedMenu
                  ].styles.background_listing_color
            };background-image:${
              this.selectedMenuItem.content[this.landing.current_lang][4]
                .content.menu[
                this.selectedMenuItem.content[this.landing.current_lang][4]
                  .selectedMenu
              ].styles.background_listing_image
                ? "url(" +
                  this.selectedMenuItem.content[this.landing.current_lang][4]
                    .content.menu[
                    this.selectedMenuItem.content[this.landing.current_lang][4]
                      .selectedMenu
                  ].styles.background_listing_image +
                  ");"
                : "url(null);"
            }color:${this.landing.branding.font_color}`;
          }
          if (
            this.selectedMenuItem.content[this.landing.current_lang][4]
              .show_background_color &&
            this.selectedMenuItem.page === this.page
          ) {
            return `background-color:${
              this.selectedMenuItem.content[this.landing.current_lang][4]
                .background_submenu_preview
                ? "transparent"
                : this.selectedMenuItem.content[this.landing.current_lang][4]
                    .background_submenu_color
            };background-image:${
              this.selectedMenuItem.content[this.landing.current_lang][4]
                .background_submenu_image
                ? "url(" +
                  this.selectedMenuItem.content[this.landing.current_lang][4]
                    .background_submenu_image +
                  ");"
                : "url(null);"
            }color:${this.landing.branding.font_color}`;
          }
        }
        if (
          this.selectedMenuItem.type === 4 &&
          this.selectedMenuItem.styles.show_background_color
        ) {
          return `background-color:${
            this.selectedMenuItem.styles.background_listing_preview
              ? "transparent"
              : this.selectedMenuItem.styles.background_listing_color
          };background-image:${
            this.selectedMenuItem.styles.background_listing_image
              ? "url(" +
                this.selectedMenuItem.styles.background_listing_image +
                ");"
              : "url(null);"
          }color:${this.landing.branding.font_color}`;
        }
        return `background-color:${
          this.landing.branding.background_preview
            ? "transparent"
            : this.landing.branding.bg_color
        };background-image:${
          this.landing.branding.bg_image
            ? "url(" + this.landing.branding.bg_image + ");"
            : "url(null);"
        }color:${this.landing.branding.font_color}`;
      }
      return `background-color:${
        this.landing.branding.background_preview
          ? "transparent"
          : this.landing.branding.bg_color
      };background-image:${
        this.landing.branding.bg_image
          ? "url(" + this.landing.branding.bg_image + ");"
          : "url(null);"
      }color:${this.landing.branding.font_color}`;
    },
    socials() {
      return this.landing.reviews.socials.list.filter((item) => item.active);
    },
    socialsOrder() {
      return this.order.socials.list.filter((item) => item.active);
    },
  },
  mounted() {
    window.addEventListener("foo-key-localstorage-changed", (event) => {
      let arr = JSON.parse(event.detail.storage);
      this.cartProducts = arr.length;
    });
    this.timeNow();
  },
  methods: {
    liningStylesListing(styles){
      if(styles.lining_show){
        if(styles.lining_global_image){
          return `border-radius:${styles.lining_global_border_radius}px;background-image:url(${styles.lining_global_image});background-size:cover;background-repeat:no-repeat;`;
        }else
        {
          return `border-radius:${styles.lining_global_border_radius}px;background-color:${styles.lining_global_color};`;
        }
      }else{
        return this.liningStyles;
      }
    },
    checkRadius(item,menu){
      if(+item.styles.lining_width === 100){
        if(item.styles.position === 'flex-start flex-start' || item.styles.position === 'flex-end flex-start' || item.styles.position === 'center flex-start'){
          return `border-top-right-radius:${menu.radius}px;border-top-left-radius:${menu.radius}px;`
        }
        if(item.styles.position === 'flex-start flex-end' || item.styles.position === 'flex-end flex-end' || item.styles.position === 'center flex-end'){
          return `border-bottom-right-radius:${menu.radius}px;border-bottom-left-radius:${menu.radius}px;`
        }
      }else{
        return `border-radius:${menu.radius}px;`
      }
    },
    getFileExtension(filename) {
      if (filename) {
        const extension =
          filename.substring(filename.lastIndexOf(".") + 1, filename.length) ||
          filename;
        return extension;
      } else {
        return "";
      }
    },
    setPage(i) {
      this.$store.commit('segmentProducts/setSegmentProduct',null);
      if(this.$store.state.realEstate.previewPage !== 'list'){
        this.$store.state.realEstate.previewPage = 'list';
        return;
      }
      if(typeof this.$store.state.touristListingDetail.group === 'number'&&typeof this.$store.state.touristListingDetail.item === 'number'){
        this.$store.state.touristListingDetail.group = null;
        this.$store.state.touristListingDetail.item = null;
        return;
      }
        if(this.$store.state.events.page === 'detail'){
            this.$store.state.events.page = 'list';
            return;
        }
      if (this.$store.state.trails.page === "points") {
        this.$store.state.trails.page = "list";
        return;
      }
      if (this.$store.state.trails.page === "detail") {
        this.$store.state.trails.page = "points";
        return;
      }
      if (this.$store.state.products.pageProduct !== "categoryList") {
        let pageProduct = this.$store.state.products.pageProduct;
        if (pageProduct.includes("subcategoriesList")) {
          this.$store.commit("products/setPageProducts", `categoryList`);
        }

        if (pageProduct.includes("productList")) {
          let arr = pageProduct.split("/");
          if (arr.length === 3) {
            this.$store.commit(
              "products/setPageProducts",
              `subcategoriesList/${arr[1]}`
            );
          } else {
            this.$store.commit("products/setPageProducts", `categoryList`);
          }
        }
        if (pageProduct.includes("productDetail")) {
          let arr = pageProduct.split("/");
          arr.splice(0, 1);
          arr.splice(arr.length - 1, 1);
          this.$store.commit(
            "products/setPageProducts",
            `productList/${arr.join("/")}`
          );
        }
      } else {
        if (this.page.split("/")[1] === "product" && i !== "cart") {
          this.$store.commit("setPage", this.page.split("/")[0]);
        } else if (this.listing.page) {
          this.$store.commit("setPage", this.listing.page);
        } else {
          if (this.page.split("/")[1]) {
            this.$store.commit("setPage", this.page.split("/")[0]);
          } else {
            this.$store.commit("setPage", "home");
          }
        }
      }
    },
    timeNow() {
      this.datenow = this.$moment().format("HH:mm");
      setInterval(() => {
        this.datenow = this.$moment().format("HH:mm");
      }, 1000);
    },
    htmlData(data, type) {
      if (data) {
        const weatherInd = data.split(" ").indexOf(":weather:");
        let result = data.split(" ");
        if (weatherInd && this.landing.advanced.global.show_weather) {
          const newArr = result;
          newArr[weatherInd] =
            this.landing.advanced.global.weather.week.daily[0].temp.day.toFixed(
              0
            ) + " ℃";
          result = newArr;
        }
        const timeInd = data.split(" ").indexOf(":time:");
        if (timeInd) {
          const newArr = result;
          newArr[timeInd] = this.datenow;
          result = newArr;
        }
        const dateInd = data.split(" ").indexOf(":date:");
        if (dateInd) {
          const newArr = result;
          newArr[dateInd] = this.dateToday;
          result = newArr;
        }
        const user_name = data.split(" ").indexOf(":name:");
        if (
          user_name &&
          this.landing.advanced.global.auth &&
          this.landing.advanced.auth.name &&
          type === "title"
        ) {
          const newArr = result;
          newArr[user_name] = "Agent";
          result = newArr;
        }
        const user_surname = data.split(" ").indexOf(":surname:");
        if (
          user_surname &&
          this.landing.advanced.global.auth &&
          this.landing.advanced.auth.name &&
          type === "title"
        ) {
          const newArr = result;
          newArr[user_surname] = "Smith";
          result = newArr;
        }
        const user_birthday = data.split(" ").indexOf(":birthday:");
        if (
          user_surname &&
          this.landing.advanced.global.auth &&
          this.landing.advanced.auth.name &&
          type === "title"
        ) {
          const newArr = result;
          newArr[user_birthday] = "24.03.1999";
          result = newArr;
        }
        const user_room = data.split(" ").indexOf(":room:");
        if (
          user_surname &&
          this.landing.advanced.global.auth &&
          this.landing.advanced.auth.name &&
          type === "title"
        ) {
          const newArr = result;
          newArr[user_room] = "707";
          result = newArr;
        }

        const user_auth = data.split(" ").indexOf(":auth:");
        if (user_auth && this.landing.advanced.global.auth && !type) {
          const newArr = result;
          newArr[user_auth] = `<a href="#">Login</a>`;
          result = newArr;
        }
        const user_logout = data.split(" ").indexOf(":logout:");
        if (user_logout && this.landing.advanced.global.auth && !type) {
          const newArr = result;
          newArr[user_logout] = `<a href="#">Logout</a>`;
          result = newArr;
        }
        return result.join(" ");
      }
    },
    startTimer() {
      this.timer = setInterval(() => {
        this.currentTime--;
      }, 1000);
    },
    openLanding() {
      const find = this.list.find((x) => x.page === this.page);
      if (this.page === "reviews") {
        window.open(`${process.env.VUE_APP_LINK}/m/` + this.id + "/reviews");
        return;
      }
      if (this.page === "cart") {
        window.open(`${process.env.VUE_APP_LINK}/m/` + this.id + "/cart");
        return;
      }
      if (this.page === "favorite") {
        window.open(`${process.env.VUE_APP_LINK}/m/` + this.id + "/favorite");
      } else if (!find) {
        const find = this.list.find((x) => x.page === this.page.split("/")[0]);
        if (find) {
          window.open(
            `${process.env.VUE_APP_LINK}/m/` + this.id + "/submenu/" + find.page
          );
        } else {
          window.open(`${process.env.VUE_APP_LINK}/m/` + this.id);
        }
      } else {
        if (find.type === 1 || find.type === 4) {
          window.open(
            `${process.env.VUE_APP_LINK}/m/` + this.id + "/menu/" + find.page
          );
        } else if (find.type === 2 || find.type === 3) {
          window.open(`${process.env.VUE_APP_LINK}/m/` + this.id);
        } else if (find.type === 5) {
          window.open(
            `${process.env.VUE_APP_LINK}/m/` + this.id + "/submenu/" + find.page
          );
        } else if (find.type === 6) {
          window.open(
            `${process.env.VUE_APP_LINK}/m/` +
              this.id +
              "/menu/" +
              find.page +
              "/faq"
          );
        } else if (find.type === 7) {
          window.open(
            `${process.env.VUE_APP_LINK}/m/` +
              this.id +
              "/menu/" +
              find.page +
              "/contact"
          );
        } else if (find.type === 8) {
          window.open(
            `${process.env.VUE_APP_LINK}/m/` +
              this.id +
              "/menu/" +
              find.page +
              "/events"
          );
        } else if (find.type === 9) {
          if (this.$store.state.products.pageProduct === "categoryList") {
            window.open(
              `${process.env.VUE_APP_LINK}/m/${this.id}/menu/${find.page}/products/category`
            );
          } else if (
            this.$store.state.products.pageProduct.includes("subcategoriesList")
          ) {
            const link = this.$store.state.products.pageProduct.split("/");
            window.open(
              `${process.env.VUE_APP_LINK}/m/${this.id}/menu/${find.page}/products/subcategory/${link[1]}`
            );
          } else if (
            this.$store.state.products.pageProduct.includes("productList")
          ) {
            const link = this.$store.state.products.pageProduct.split("/");
            if (link.length === 2) {
              window.open(
                `${process.env.VUE_APP_LINK}/m/${this.id}/menu/${find.page}/products/list/${link[1]}`
              );
            } else {
              window.open(
                `${process.env.VUE_APP_LINK}/m/${this.id}/menu/${find.page}/products/list/${link[1]}?subcategory=${link[2]}`
              );
            }
          } else if (
            this.$store.state.products.pageProduct.includes("productDetail")
          ) {
            const link = this.$store.state.products.pageProduct.split("/");
            if (link.length === 3) {
              window.open(
                `${process.env.VUE_APP_LINK}/m/${this.id}/menu/${find.page}/products/detail/${link[1]}/${link[2]}`
              );
            } else {
              window.open(
                `${process.env.VUE_APP_LINK}/m/${this.id}/menu/${find.page}/products/detail/${link[1]}/${link[2]}?subcategory=${link[2]}`
              );
            }
          }
        } else if (find.type === 10) {
          window.open(
            `${process.env.VUE_APP_LINK}/m/` +
              this.id +
              "/menu/" +
              find.page +
              "/weather"
          );
        } else if (find.type === 11) {
          window.open(
            `${process.env.VUE_APP_LINK}/m/` +
              this.id +
              "/menu/" +
              find.page +
              "/points"
          );
        } else if (find.type === 12) {
          window.open(`${process.env.VUE_APP_LINK}/m/` + this.id);
        } else if (find.type === 13) {
          window.open(
            `${process.env.VUE_APP_LINK}/m/` +
              this.id +
              "/menu/" +
              find.page +
              "/gallery"
          );
        } else if (find.type === 14) {
          window.open(
            `${process.env.VUE_APP_LINK}/m/` +
              this.id +
              "/menu/" +
              find.page +
              "/trail/list"
          );
        }
      }
    },
    copyLinkLanding() {
      this.copied = true;
      const find = this.list.find((x) => x.page === this.page);
      if (this.page === "reviews") {
        navigator.clipboard.writeText(
          `${process.env.VUE_APP_LINK}/m/` + this.id + "reviews"
        );
      } else if (!find) {
        navigator.clipboard.writeText(
          `${process.env.VUE_APP_LINK}/m/` + this.id
        );
      } else {
        if (find.type === 1 || find.type === 4) {
          navigator.clipboard.writeText(
            `${process.env.VUE_APP_LINK}/m/` + this.id + "/menu/" + find.page
          );
        } else if (find.type === 2 || find.type === 3) {
          navigator.clipboard.writeText(
            `${process.env.VUE_APP_LINK}/m/` + this.id
          );
        } else if (find.type === 5) {
          navigator.clipboard.writeText(
            `${process.env.VUE_APP_LINK}/m/` + this.id + "/submenu/" + find.page
          );
        } else if (find.type === 6) {
          navigator.clipboard.writeText(
            `${process.env.VUE_APP_LINK}/m/` +
              this.id +
              "/menu/" +
              find.page +
              "/faq"
          );
        } else if (find.type === 7) {
          navigator.clipboard.writeText(
            `${process.env.VUE_APP_LINK}/m/` +
              this.id +
              "/menu/" +
              find.page +
              "/contact"
          );
        } else if (find.type === 8) {
          navigator.clipboard.writeText(
            `${process.env.VUE_APP_LINK}/m/` +
              this.id +
              "/menu/" +
              find.page +
              "/events"
          );
        } else if (find.type === 9) {
          navigator.clipboard.writeText(
            `${process.env.VUE_APP_LINK}/m/` +
              this.id +
              "/menu/" +
              find.page +
              "/products"
          );
        } else if (find.type === 10) {
          navigator.clipboard.writeText(
            `${process.env.VUE_APP_LINK}/m/` +
              this.id +
              "/menu/" +
              find.page +
              "/weather"
          );
        } else if (find.type === 10) {
          window.open(
            `${process.env.VUE_APP_LINK}/m/` +
              this.id +
              "/menu/" +
              find.page +
              "/weather"
          );
        } else if (find.type === 11) {
          navigator.clipboard.writeText(
            `${process.env.VUE_APP_LINK}/m/` +
              this.id +
              "/menu/" +
              find.page +
              "/points"
          );
        } else if (find.type === 12) {
          navigator.clipboard.writeText(
            `${process.env.VUE_APP_LINK}/m/` + this.id
          );
        } else if (find.type === 13) {
          navigator.clipboard.writeText(
            `${process.env.VUE_APP_LINK}/m/` +
              this.id +
              "/menu/" +
              find.page +
              "/gallery"
          );
        }
      }
      setTimeout(() => {
        this.copied = false;
      }, 2000);
    },
    subMenuLink(menu) {
      if (menu.type === 2) {
        return menu.content[1].content;
      } else if (menu.type === 3) {
        return "tel:" + menu.content[2].content;
      } else {
        return "#";
      }
    },
    closeBanner() {
      this.show_banner = false;
    },
    closeIntro() {
      this.show_intro = false;
    },
    setMenu(evt) {
      //
    },
    customListingMenuStyles(item) {
      return `width:calc(${item.styles.width}% - 10px);height:${item.styles.height}px;margin-right: 10px;`;
    },
    customMenuStyles(menu, item) {
      return `color: ${item.styles.color};font-family:'${item.styles.fontFamily}',Inter,sans-serif;font-style:${item.styles.fontStyle};font-weight:${item.styles.fontWeight};font-size:${item.styles.fontSize}px;width:calc(${item.styles.width}% - 10px);margin-right: 10px;`;
    },

    customMenuListingStyles(item) {
      let styles = `
                  border:${item.styles.border_size}px solid ${
        item.styles.border_color
      };
      justify-content:${item.position.split(" ")[1]};
      align-items:${item.position.split(" ")[0]};
      background-repeat: no-repeat;
      background-size: cover;
      background-position:center;
      background-color:${item.styles.background_color};
      width:100%;`;
      if (item.styles.show_icon_up) {
        styles += "flex-direction:column;";
      }

      if (item.styles.show_background) {
        styles += `background-image: url(${item.background});`;
      }
      return styles;
    },
    customMenuLinkStyles(menu, item) {
      let styles = "";
      if (menu.style === "swipe") {
        styles += `background-color:${
          item.styles.background_main_color
        };text-align:unset;font-size:13px;
                  background-image: url('${item.styles.background}');
                  justify-content:${item.styles.position.split(" ")[0]};
                  align-items:${item.styles.position.split(" ")[1]};
                  background-repeat: no-repeat;
                  background-size: cover;
                  background-position:center;`;
      } else {
        styles += `background-color:${
          item.styles.background_main_color
        };text-align:unset;
                  background-image: url('${item.styles.background}');
                  justify-content:${item.styles.position.split(" ")[0]};
                  align-items:${item.styles.position.split(" ")[1]};
                  background-repeat: no-repeat;
                  background-size: cover;
                  background-position:center;`;
      }
      return styles;
    },
    displayType(item, listing) {
      if (item.displayType === "horizontal") {
        return "flex-direction:row;align-items:normal;";
      } else if (item.displayType === "inside") {
        let bg = "";
        if (listing.preview) {
          bg = "transparent";
        } else {
          bg = `url(${listing.main_img}) no-repeat center / 100%`;
        }
        return `background:${bg};
                background-size:100%;
                overflow:hidden;
                text-align:center;
                height:200px;
                border-radius:10px;
                background-position: center center;
                background-repeat:no-repeat;`;
      } else {
        return "flex-direction:column;";
      }
    },
    displayTypeFavorite(item) {
      if (item.displayType === "horizontal") {
        return "flex-direction:row;align-items:normal;";
      } else if (item.displayType === "inside") {
        return `background:url('https://guest.mobi/img/banner.65956d4d.png');
                text-align:center;
                height:200px;
                border-radius:10px;
                background-position: center center;
                background-repeat:no-repeat;
                background-size: cover;`;
      } else {
        return "flex-direction:column;";
      }
    },
    handleImgError(event) {
      event.target.src = defaultIcon;
    },
    setView(view) {
      this.view = view;
      this.$store.commit("setView", this.view);
    },
    dateTimeline(date) {
      return this.$moment(date).format("MMM D");
    },
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: "Lato";
  src: url("../../assets/custom_fonts/Lato-Regular.ttf");
}
.hotel-feedback__form .hotel-reviews__submit {
  width: 100%;
  font-size: 18px;
}
.faq > .faq-wrapper > .accordion {
  border: 1px solid #d4e1e1;
}
.faq > .faq-wrapper > .accordion > .accordion__item {
  border-bottom: 1px solid #d4e1e1;
}
.preview {
  position: sticky;
  padding-bottom: 30px;
  top: 0;
}
.preview__header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: #fafafa;
}

.preview__content {
  display: flex;
  margin-bottom: 75px;
  flex-direction: column;
  padding: 40px 20px;
  min-height: 700px;
  height: 100%;
  align-items: center;
  // background-color: rgba($color: $primary, $alpha: 0.12);
  // background-color: #C8E3EF;
}

.preview__btn {
  width: 48px !important;
  height: 48px !important;
  .v-icon {
    margin: 0 !important;
  }
}

.preview__btn_active {
  background-color: rgba($color: $primary, $alpha: 0.12) !important;
}

.canvas {
  position: relative;
  flex: 1;
  scroll-behavior: smooth;
  max-width: 360px;
  width: 100%;
  max-height: 640px;
  height: 100%;
  margin: 0 auto;
  //padding: 20px 20px 0;

  border-radius: 15px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #fff;
  overflow-y: auto;

  &__success {
    text-align: center;
  }

  &__links {
    text-align: center;
    font-size: 2em;
    margin: 0.67em 0;
    margin-top: 1em;
  }
}

.canvas_lang {
}

.canvas__lang__lite {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 15px;
}
.listing__descr p {
  margin-bottom: 0;
}
.canvas__lang__pro {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 15px;
}

.canvas__select {
  &.v-input {
    color: rgba(0, 0, 0, 0.54);
  }
  &.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
    > .v-input__control
    > .v-input__slot
    fieldset {
    color: rgba(0, 0, 0, 0.54);
  }
  .v-input__slot:hover fieldset {
    color: rgba(0, 0, 0, 0.54) !important;
  }
  .v-icon {
    color: rgba(0, 0, 0, 0.54);
  }
}

.canvas_web {
  max-width: 100%;
  padding: 40px 60px;
  border-radius: 0;
}

.canvas_center {
  .canvas__header {
    top: 0;
    left: 0;
    margin: 0;
  }
  .canvas__logo {
    position: absolute;
    z-index: 1;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.canvas_left {
  .canvas__header {
    top: 0;
    left: 0;
    margin: 0;
  }
  .canvas__logo {
    position: absolute;
    z-index: 1;
    top: 100px;
    left: 20px;
    margin: 0;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.canvas__logo {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  img {
    width: 100%;
    height: 100%;
  }
}

.canvas__header {
  display: flex;
  //   align-items: flex-start;
  justify-content: center;
  //margin: 0 -20px 20px -20px;
  overflow: hidden;
  width: 100%;
  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}
.v-application ul,
.v-application ol {
  padding: 0;
}
.canvas-menu {
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px 5px;
}
.canvas-menu-drag {
  display: flex;
  flex-wrap: wrap;
}

.canvas-menu_tile {
  flex-wrap: wrap;
  .canvas-menu__item {
    width: 50%;
    margin-bottom: 0;
    padding: 0 5px 10px;
  }
  .canvas-menu__link {
    flex-direction: column;
  }
  .canvas-menu__icon {
    margin-right: 0;
    margin-bottom: 10px;
  }
}
.canvas-menu-custom {
  width: 100%;
  & .canvas-menu__item {
    margin-bottom: 0;
    padding: 0 5px 10px;
  }
  & .canvas-menu__link {
    background-repeat: no-repeat;
    background-size: cover;
  }
  .canvas-menu__icon {
    margin-right: 0;
    margin-bottom: 10px;
  }
}
.canvas-menu__item {
  margin-bottom: 10px;
}

.canvas-menu__link {
  display: flex;
  align-items: center;
  text-align: center;
  height: 100%;
  text-decoration: none;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  color: inherit;
}

.canvas-menu__icon {
  width: 36px;
  height: 36px;
  margin-right: 10px;
  &.v-icon {
    // margin-bottom: 10px;
    margin-right: 10px;
    font-size: 36px;
  }
}

.canvas-menu__text {
  color: inherit;
  text-align: center;
}

.canvas-socials {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px 45px;
  .canvas-socials__icon {
    margin-left: 20px;
    margin-right: 10px;
  }
}

.canvas-socials__item {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 5px 0;
  padding: 10px;
  border-radius: inherit;
  text-decoration: none;
  font-size: 14px;
  color: #fff !important;
  transition: all 0.3s;
  cursor: pointer;
}

.canvas-socials_tile {
  flex-direction: row;
  flex-wrap: wrap;
  .canvas-socials__item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 65px;
    height: 65px;
    display: flex;
    margin: 3px;
    font-size: 0;
  }
  .canvas-socials__icon {
    margin: 0;
    height: 30px;
    width: 30px;
    svg {
      height: 30px;
      width: 30px;
    }
  }
}

.canvas-socials__icon {
  width: 24px;
  height: 24px;
}

.canvas__banner {
  position: relative;
  display: block;
  img {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
}

.canvas-footer {
}

.canvas-footer__title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 14px;
}

.canvas-footer__text {
  text-align: center;
  margin-bottom: 20px;
  font-size: 14px;
}

.canvas__submit {
  display: block;
  margin: 10px auto 20px;
}

.canvas__textarea {
  width: 100%;
  height: 100px;
  padding: 10px;
  margin-top: 10px;
  resize: none;
  border-radius: 8px;
  border: 1px solid;
  outline: none;
}

.canvas-reviews__item {
  margin-bottom: 20px;
}

.listing__list {
  background: #fff;
  padding: 8px;
  border-radius: 5px;
}

.listing {
  border-radius: 5px;
  &__play_video {
    cursor: pointer;
    height: 80px;
    width: 80px;
    background: url(~@/assets/icons/play_button.svg);
    &:hover {
      background: url(~@/assets/icons/play_button_hover.svg);
    }
    &_with_img {
      position: absolute;
      top: 150px;
      left: calc(50% - 40px);
    }
    &_without_img {
      margin: 0 auto 20px;
    }
  }
}

.listing__item {
  display: flex;
  cursor: pointer;
  align-items: center;

  & .v-window__prev,
  & .v-window__next {
    display: none;
    top: 50%;
    transform: translateY(-50%);

    & .v-btn {
      width: 30px;
      height: 30px;
    }

    @media (min-width: 800px) {
      display: block;
    }
  }
}

.listing__img {
  max-width: 100%;
  height: 90px !important;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 16px;
}

.listing__descr {
  width: 100%;
  margin-left: 10px;
  padding-top: 5px;
}

.listing__separator {
  border-bottom: 1px solid;
  opacity: 0.25;
}

.listing__name {
  font-weight: 500;
}

.listing__text {
  // padding-bottom: 16px;
  opacity: 0.7;
}

.listing__full-descr {
  margin-top: 10px;
}

.listing .listing-full__img {
  border-radius: 8px;
  height: 200px;
  width: 100%;
  object-fit: cover;
}

.listing-full__name {
  font-size: 24px;
  font-weight: 600;
}

.listing-full__text {
  margin-bottom: 20px !important;
  opacity: 0.7;
}

.listing-full__price {
  margin-bottom: 10px !important;
}

.listing-full__descr {
  margin-bottom: 20px !important;
  opacity: 0.7;
}

.canvas__back {
  border-radius: 8px;
  position: absolute;
  top: 21px;
  width: 35px;
  height: 35px;
  cursor: pointer;
  svg {
    max-width: 100%;
    max-height: 100%;
  }
}

.banner__modal--shadow {
  position: absolute;
  z-index: 9;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
}
.banner__modal {
  position: absolute;
  z-index: 5;
  .banner__modal--close {
    color: #fff;
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;
  }
  &--main {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &--type2 {
      display: block;
    }
  }

  & .play-video {
    cursor: pointer;
    -webkit-mask-image: url(~@/assets/icons/play_button.svg);
    mask-image: url(~@/assets/icons/play_button.svg);
    background-color: var(--color);
    position: absolute;
    &:hover {
      background-color: var(--color-hover);
    }
    &_with_img {
      position: absolute;
      top: 150px;
      left: calc(50% - 40px);
    }
  }
}
.hotel-feedback__form {
  padding: 0 10px;
}
/*.v-timeline-item__divider .v-timeline-item__dot--large {*/
/*  width: 60px;*/
/*  height: 60px;*/
/*}*/
/*.v-timeline-item__divider*/
/*  .v-timeline-item__dot--large*/
/*  .v-timeline-item__inner-dot {*/
/*  width: 50px;*/
/*  height: 50px;*/
/*}*/
/*.v-timeline-item__divider*/
/*  .v-timeline-item__dot--large*/
/*  .v-timeline-item__inner-dot {*/
/*  flex-direction: column;*/
/*  font-size: 11px;*/
/*}*/
/*.canvas .last--v-timeline::before {*/
/*  height: 50%;*/
/*}*/
/*.canvas .first--v-timeline::before {*/
/*  height: 50%;*/
/*  top: 50%;*/
/*}*/
/*.canvas .v-timeline {*/
/*  padding-top: 12px;*/
/*  & .v-timeline-item {*/
/*    padding-bottom: 12px;*/
/*  }*/
/*}*/
.introPreviews {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  z-index: 6;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  .intro--close {
    color: #fff;
    position: absolute;
    right: 5px;
    z-index: 7;
    top: 5px;
    cursor: pointer;
  }
  img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
}
.canvas__fill {
  path {
    fill: #fff;
  }
}
.theme--light.v-tabs-items {
  background: none;
}
.copied {
  position: fixed;
  left: 50%;
  margin-left: -65px;
  top: 5px;
  z-index: 10;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
.slide_listing .listing_tab {
  border-color: #eee;
  color: #000;
  &:hover {
    border-color: #007db5;
    color: #007db5;
    caret-color: #007db5;
    background-color: rgba(255, 255, 255, 0.5);
  }
}
.slide_listing .active_listing_tab {
  border-color: #007db5;
  color: #007db5;
  caret-color: #007db5;
}
.theme--light.v-btn--active:hover::before,
.theme--light.v-btn--active::before {
  opacity: 0;
}
.slide_listing.v-slide-group.v-item-group > .v-slide-group__next,
.slide_listing.v-slide-group.v-item-group > .v-slide-group__prev {
  display: none;
}
.accordion .accordion__title-text {
  margin-bottom: 0;
  margin-right: 0;
}
</style>

<style scoped lang="scss">
.v-card {
  max-width: 95%;
}
</style>
