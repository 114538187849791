import axios from "../plugins/axios";

const ImagesService = {
  async getZipByImagesID(images_id) {
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_API_URL}zip-listing`,
        { ids: images_id }
      );
      return response;
    } catch (e) {
      return e;
    }
  },
  async deleteImage(image_link){
    try{
      const image_id = image_link.split('/')[5];
      const response = await axios.delete(`${process.env.VUE_APP_API_URL}delete-image/${image_id}`);
      return response;
    }catch (e){
      return e;
    }
  }
};
export default ImagesService;
