<template>
<div>
  <div class="constructor__main">
    <div class="constructor__header" :style="`padding: ${isMobile?'30px 5px 10px;':'30px 30px 10px;'}`">
      <v-row align="center">
        <v-col cols="12">
          <h2 class="constructor__heading" >
           Start
          </h2>
          <p class="constructor__text" v-if="!isMobile">
            Welcome to the constructor
          </p>
        </v-col>
      </v-row>
      </div>
    <p class="constructor__text" v-if="isMobile">
      Constructor is not available in the mobile version
    </p>
    <div v-if="!isMobile" class="constructor__text" :style="`padding: ${isMobile?'10px 5px;':'10px 30px;'}`">
        <p>You will be able to create the best solution for your guests, and give them not new impressions of convenience and service.</p>
      <br>
        <p>The constructor consists of the main sections:</p>
        <br>
      <p><strong>Branding</strong> - allows you to manage the overall design concept of your property's entire web application</p>
      <p><strong>Menu</strong> - allows you to customize menu items and their contents</p>
      <p><strong>Reviews</strong> - get feedback from guests and find out their opinion about you</p>
      <p><strong>Order</strong> - allows you to configure the receipt and payment of orders from your guests</p>
      <p><strong>Advanced</strong> - tricky settings for your project</p>

    </div>

    </div>
    <PopupSuccess ref="PopupSuccess" :title="`Your hotel successfully recovered from ${$route.query.snapshot}`" @close="deleteSnapshot"/>
</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import PopupSuccess from "../components/Popup/PopupSuccess";
export default {
  name: "Start",
    components: {PopupSuccess},
    computed: {
    ...mapState(['landing']),
    ...mapGetters('landing',['default']),

  },
  data(){
    return{
      isMobile:false,
      linkTo:[],
    }
  },
    methods:{
        deleteSnapshot(){
          location.href = window.location.origin + window.location.pathname;
        },
      // checkBranding(obj){
      //   for(let i in this.default){
      //     // eslint-disable-next-line no-prototype-builtins
      //     if(obj.hasOwnProperty(i)){
      //       if(Array.isArray(this.default[i])){
      //         for(let index = 0;index<this.default[i].length;index++){
      //           if(Array.isArray(this.default[i])){

      //           }else if(typeof this.default[i][index] === 'object'){
      //             // eslint-disable-next-line no-prototype-builtins
      //             if(obj[i].hasOwnProperty(index)){
      //               if(Array.isArray(this.default[i][index])){
      //                 for(let index1 = 0;index1<this.default[i][index].length;index1++){
      //                   if(Array.isArray(this.default[i][index][index1])){
      //                   }else if(typeof this.default[i][index][index1] === 'object'){
      //                     // eslint-disable-next-line no-prototype-builtins
      //                     if(obj[i][index].hasOwnProperty(index1)){

      //                     }else{
      //                       obj[i][index][index1] = this.default[i][index];
      //                     }
      //                   }
      //                 }
      //               }else if(typeof this.default[i] === 'object'){
      //                 for(let index in this.default[i]){

      //                 }
      //               }else{
      //                 obj[i] = this.default[i];
      //               }
      //             }else{
      //               obj[i] = this.default[i];
      //             }
      //           }
      //         }
      //       }else if(typeof this.default[i] === 'object'){
      //         for(let index in this.default[i]){

      //         }
      //       }else{
      //         obj[i] = this.default[i];
      //       }
      //     }else{
      //       obj[i] = this.default[i];
      //     }
      //   }
      // },
    //   checkBranding(obj){
    //     // eslint-disable-next-line no-empty
    //     if(obj['emailList']){

    //     }else{
    //       obj['emailList'] = JSON.parse(JSON.stringify(this.default.emailList));
    //     }
    //   },
    //   recursive(key,data){
    //     if(Array.isArray(data[key])){
    //       for(let i = 0;i<data[key].length;i++){
    //         this.linkTo.push(key);
    //         this.recursive(key,data[key][i]);
    //       }
    //     }else if(typeof data[key] === 'object'){
    //       this.linkTo.push(key);
    //       this.recursive(key,data[key]);
    //     }else{
    //       console.log(this.linkTo);
    //       return key
    //     }
    // },
    },
  created(){
    this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  },
  mounted(){
      if(this.$route.query.snapshot){
          this.$refs.PopupSuccess.open();
      }
    // this.check(this.landing)
  }
}
</script>

<style scoped>

</style>
