import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VColorPicker } from 'vuetify/lib/components/VColorPicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSlideGroup } from 'vuetify/lib/components/VSlideGroup';
import { VSlideItem } from 'vuetify/lib/components/VSlideGroup';
import { VSlider } from 'vuetify/lib/components/VSlider';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form"},[_c('div',{staticClass:"field"},[_c('label',{staticClass:"label",staticStyle:{"font-weight":"bold","font-size":"20px","display":"block","margin-bottom":"15px"}},[_vm._v("Lining settings")]),_c('p',{staticClass:"label",staticStyle:{"margin-bottom":"15px"}},[_vm._v("Upload image")]),_c('div',{staticClass:"control"},[_c('div',{staticClass:"form lining"},[_c('div',{staticClass:"field",staticStyle:{"margin-bottom":"15px"}},[_c('div',{staticClass:"control"},[_c('vue-dropzone',{ref:"upload",staticStyle:{"margin-bottom":"15px"},attrs:{"id":"upload","options":_vm.dropzoneOptions,"destroyDropzone":false},on:{"vdropzone-mounted":_vm.mountDropzone,"vdropzone-sending":_vm.customEvent,"vdropzone-removed-file":_vm.handleRemove,"vdropzone-success":_vm.handleSuccess}})],1)]),_c('div',{staticClass:"field"},[_c('div',[_c('div',{staticStyle:{"display":"flex","align-items":"center","margin-bottom":"15px"}},[_c('label',{staticClass:"label",staticStyle:{"display":"block","width":"200px","margin-bottom":"0","margin-right":"8px"}},[_vm._v("Background color")]),_c(VMenu,{attrs:{"top":"","nudge-bottom":"98","nudge-left":"16","close-on-content-click":false,"z-index":"21"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"color",style:(("background-color:" + (_vm.branding.bg_lining_color)))},on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c(VCard,[_c(VCardText,{staticClass:"pa-0"},[_c(VColorPicker,{attrs:{"flat":""},on:{"input":_vm.setCanvas},model:{value:(_vm.branding.bg_lining_color),callback:function ($$v) {_vm.$set(_vm.branding, "bg_lining_color", $$v)},expression:"branding.bg_lining_color"}})],1)],1)],1)],1),_c('div',{staticStyle:{"display":"flex","align-items":"center","margin-bottom":"15px"}},[_c('label',{staticClass:"label",staticStyle:{"display":"block","min-width":"200px","margin-bottom":"0"}},[_vm._v("Border radius "),_c('b',[_vm._v(_vm._s(_vm.branding.lining_radius)+"px")])]),_c('div',{staticClass:"slider",staticStyle:{"width":"100%"}},[_c(VSlider,{attrs:{"max":100,"min":0,"hide-details":""},on:{"end":_vm.setCanvas},model:{value:(_vm.branding.lining_radius),callback:function ($$v) {_vm.$set(_vm.branding, "lining_radius", $$v)},expression:"branding.lining_radius"}})],1)]),_c('div',{staticStyle:{"display":"flex","align-items":"center","margin-bottom":"15px"}},[_c('label',{staticClass:"label",staticStyle:{"display":"block","width":"200px","margin-bottom":"0","margin-right":"8px"}},[_vm._v("Lining margin")]),_c(VSwitch,{attrs:{"hide-details":""},on:{"change":_vm.setCanvas},model:{value:(_vm.branding.lining_margin),callback:function ($$v) {_vm.$set(_vm.branding, "lining_margin", $$v)},expression:"branding.lining_margin"}})],1)])]),_c('div',{staticClass:"field",staticStyle:{"overflow":"hidden","margin-bottom":"20px"}},[_c('label',{staticClass:"label",staticStyle:{"font-weight":"bold","font-size":"20px","display":"block","margin-bottom":"15px"}},[_vm._v("Templates")]),_c(VSlideGroup,{attrs:{"show-arrows":"","center-active":""}},_vm._l(([1, 2, 3, 4, 5]),function(i){return _c(VSlideItem,{key:i,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c(VBtn,{staticClass:"mx-2",staticStyle:{"height":"200px","width":"150px"},attrs:{"input-value":active,"active-class":"primary white--text active__lining","depressed":"","small":""},on:{"click":toggle}},[_c('img',{staticStyle:{"height":"200px","width":"150px"},attrs:{"src":(_vm.VUE_APP_LINK + "/lining_templates/" + i + ".jpg"),"alt":""},on:{"click":function($event){return _vm.setImg((_vm.VUE_APP_LINK + "/lining_templates/" + i + ".jpg"))}}})])]}}],null,true)})}),1)],1)])])]),_c('PopupCrop',{ref:"PopupCrop",attrs:{"title":"Change image before upload?"},on:{"confirm":_vm.openEditor,"save":_vm.saveImg,"close":_vm.closeCrop}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }