<template>
  <main class="main_wide">
    <save-edited-settings :dialog="dialog" @close="closeDialog" :to="to" />
    <div v-if="isMobile" style="width:100%;display: flex;justify-content: flex-end">
      <v-icon @click="dialogPreview= true">mdi-eye</v-icon>
    </div>
    <div class="header header_constructor" v-if="!isMobile">
      <v-row>
        <v-col>
          <h1 class="heading" style="margin-bottom: 10px">Constructor</h1>
          <h4 style="font-weight: normal">
            Project: {{ currentName }}
            <div v-if="objects.item.tariff_id === 1" class="tariff_obj">free</div>
            <div v-if="objects.item.tariff_id === 2" class="tariff_obj">
              standart
            </div>
            <div v-if="objects.item.tariff_id === 3" class="tariff_obj">pro</div> id: {{ object }}
          </h4>
        </v-col>
      </v-row>
    </div>
    <div class="constructor">
      <div
        class="constructor__builder"
        :style="`width:${showFull||isMobile ? '100%' : '60%'};height:calc(100% - 48px);`"
      >
        <div style="width:100%;
            background-color: #fafafa;">
          <div v-if="!isMobile"
              ref="”tab”"
              id="header_slider"
              class="constructor__tabs"
              style="
            font-family: Inter, sans-serif;
            cursor: pointer;
            text-transform: uppercase;
            font-size: 0.875rem;
            font-weight: 500;
            letter-spacing: 0.0892857143em;
            color: rgba(0, 0, 0, 0.54);
            display: flex;
            background-color: #fafafa;
          "
              :style="`width:${!showFull ? '100%' : '60%'};`"
          >
            <div
                @click="changePage('start',0)"
                class="header__tab"
                :class="{ activeTab: $store.state.selectedPage === 'start' }"
            >
              Start
            </div>
            <div
                @click="changePageBranding()"
                class="header__tab"
                :class="{ activeTab: $store.state.selectedPage === 'branding' }"
            >
              {{ $t('constructor["Branding"]') }}
            </div>
            <div
                @click="changePage('home',2)"
                class="header__tab"
                :class="{ activeTab: $store.state.selectedPage === 'home' && $store.state.tab === 2 }"
            >
              {{ $t('constructor["Menu"]') }}
            </div>
            <div
                @click="changePage('reviews',3)"
                class="header__tab"
                :class="{ activeTab: $store.state.selectedPage === 'reviews' }"
            >
              {{ $t('constructor["Reviews"]') }}
            </div>
            <div
                @click="changePage('order',4)"
                class="header__tab"
                :class="{ activeTab: $store.state.selectedPage === 'order' }"
            >
              Order
            </div>
            <div
                @click="changePage('advanced',5)"
                class="header__tab"
                :class="{ activeTab: $store.state.selectedPage === 'advanced' }"
            >
              {{ $t('constructor["Advanced"]') }}
            </div>
            <div
                @click="changePage('no_preview',6)"
                class="header__tab"
                :class="{ activeTab: $store.state.selectedPage === 'no_preview' && $store.state.tab === 6 }"
            >
              Upsell
            </div>
          </div>
        </div>
        <div style="height: 100%;">
          <div v-if="$store.state.selectedPage === 'start'">
            <Start />
          </div>
          <div style="height:100%;" v-if="$store.state.selectedPage === 'branding'">
            <ConstructorBranding >
              <div class="constructor__save" v-if="showSaveBig">
                <v-btn
                    @click="dialogSave = true"
                    :disabled="!isValid"
                    color="primary"
                    depressed
                    v-if="showSaveLanding"
                >{{ $t('constructor["Сохранить лендинг"]') }}</v-btn
                >
                <save-landing @save="onSave" :dialog="dialogSave" @closeDialog="closeDialogSave" />
              </div>
            </ConstructorBranding>
          </div>
          <div style="height:100%" v-if="$store.state.selectedPage === 'home' && $store.state.tab === 2">
            <ConstructorMenu>
              <div class="constructor__save" v-if="showSaveBig">
                <v-btn
                    @click="dialogSave = true"
                    :disabled="!isValid"
                    color="primary"
                    depressed
                    v-if="showSaveLanding"
                >{{ $t('constructor["Сохранить лендинг"]') }}</v-btn
                >
                <save-landing @save="onSave" :dialog="dialogSave" @closeDialog="closeDialogSave" />
              </div>
            </ConstructorMenu>
          </div>
          <div style="height:100%" v-if="$store.state.selectedPage === 'reviews'">
            <ConstructorReviews>
              <div class="constructor__save" v-if="showSaveBig">
                <v-btn
                    @click="dialogSave = true"
                    :disabled="!isValid"
                    color="primary"
                    depressed
                    v-if="showSaveLanding"
                >{{ $t('constructor["Сохранить лендинг"]') }}</v-btn
                >
                <save-landing @save="onSave" :dialog="dialogSave" @closeDialog="closeDialogSave" />
              </div>
            </ConstructorReviews>
          </div>
          <div style="height:100%" v-if="$store.state.selectedPage === 'order'">
            <ConstructorOrder >
              <div class="constructor__save" v-if="showSaveBig">
                <v-btn
                    @click="dialogSave = true"
                    :disabled="!isValid"
                    color="primary"
                    depressed
                    v-if="showSaveLanding"
                >{{ $t('constructor["Сохранить лендинг"]') }}</v-btn
                >
                <save-landing @save="onSave" :dialog="dialogSave" @closeDialog="closeDialogSave" />
              </div>
            </ConstructorOrder>
          </div>
          <div style="height:100%" v-if="$store.state.selectedPage === 'advanced'">
            <ConstructorAdvanced >
              <div class="constructor__save" v-if="showSaveBig">
                <v-btn
                    @click="dialogSave = true"
                    :disabled="!isValid"
                    color="primary"
                    depressed
                    v-if="showSaveLanding"
                >{{ $t('constructor["Сохранить лендинг"]') }}</v-btn
                >
                <save-landing @save="onSave" :dialog="dialogSave" @closeDialog="closeDialogSave" />
              </div>
            </ConstructorAdvanced>
          </div>
          <div style="height:100%" v-if="$store.state.selectedPage === 'no_preview' && $store.state.tab === 6">
            <ConstructorMarketing >
              <div class="constructor__save" v-if="showSaveBig">
                <v-btn
                    @click="dialogSave = true"
                    :disabled="!isValid"
                    color="primary"
                    depressed
                    v-if="showSaveLanding"
                >{{ $t('constructor["Сохранить лендинг"]') }}</v-btn
                >
                <save-landing @save="onSave" :dialog="dialogSave" @closeDialog="closeDialogSave" />
              </div>
              </ConstructorMarketing>
          </div>
        </div>
      </div>
      <div class="constructor__preview" v-if="!showFull&&!isMobile">
        <ConstructorPreview :id="+$route.params.id" />
      </div>
    </div>
    <div class="loading" v-if="loading">
      <img
        class="loading__icon"
        src="../assets/img/logo__loader.svg"
        alt=""
        style="height: 100px; width: 100px"
      />
    </div>
    <v-dialog fullscreen v-model="dialogPreview">
      <v-card>
        <v-icon
            style="top:10px;right: 10px;z-index:99;position:absolute;"
            class="close__modal"
            @click="dialogPreview = false"
        >mdi-close</v-icon
        >
        <ConstructorPreview style="min-height:100vh;background-color:rgba(0,125,181,.12);" :id="+$route.params.id" />
      </v-card>
    </v-dialog>
  </main>
</template>

<script>
import store from "@/store";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import ConstructorBranding from "@/components/Constructor/Branding/ConstructorBranding.vue";
import ConstructorMenu from "@/components/Constructor/Menu/ConstructorMenu.vue";
import ConstructorReviews from "@/components/Constructor/ConstructorReviews.vue";
import ConstructorAdvanced from "@/components/Constructor/Advanced/ConstructorAdvanced.vue";
import ConstructorPreview from "@/components/Constructor/ConstructorPreview.vue";
import ConstructorOrder from "@/components/Constructor/ConstructorOrder.vue";
import SaveLanding from "@/components/Constructor/SaveLanding";
import titleMixin from "@/mixins/titleMixin";
import SaveEditedSettings from "../components/Constructor/SaveLanding/SaveEditedSettings.vue";
import Start from "./Start";
import ApiService from "../services/api.services";
import {
    defaultContentMenuItem,
    defaultFormMenuItem,
    defaultEventStyles,
    defaultStyles,
    defaultListingStyles,
    defaultGlobalMenuSettings,
    defaultProductStyles,
    defaultIcon
} from "@/store/landing";
import ConstructorMarketing from "../components/Constructor/ConstructorMarketing";

export default {
  name: "Constructor",
  mixins: [titleMixin],
  title() {
    return this.title_text;
  },
  components: {
    ConstructorMarketing,
    ConstructorBranding,
    ConstructorMenu,
    ConstructorReviews,
    ConstructorAdvanced,
    ConstructorPreview,
    SaveLanding,
    ConstructorOrder,
    SaveEditedSettings,
    Start,
  },
  beforeRouteLeave(to, _from, next) {
    if (this.isDirty) {
      this.to = to;
      this.dialog = true;
    } else {
      next();
    }
  },
  beforeRouteEnter(to, from, next) {
    if (!store.state.objects.list.length) {
      store
        .dispatch("objects/getList")
        .then((data) => {
          next();
        })
        .catch(() => {
          next();
        });
    } else {
      next();
    }
  },
  computed: {
    ...mapState(["objects"]),
    ...mapGetters("landing", ["isValid"]),
    ...mapGetters("landing", ["landing", "isDirty"]),
    ...mapState("banners", ["bannerList"]),
    ...mapState("menu", ["selectedMenuItem"]),
    showSaveBig(){
      if(this.$store.state.page === 'start'){
        return false
      }
      if(this.$store.state.isMobile){
        return false;
      }
      if(this.$store.state.tab === 2 && !this.selectedMenuItem){
        return false;
      }
      if(this.$store.state.tab === 5 && this.landing.advanced.tab === 11){
        return false;
      }
      if(this.$store.state.tab === 5 && this.landing.advanced.tab === 0){
        return false
      }
      return true
    },
    showSaveLanding() {
      if(this.$store.state.tab === 6){
        return false
      }
      if(this.$store.state.page === 'order'&& this.landing.free ||
          this.$store.state.page === 'reviews'&& this.landing.free){
        return false;
      }
      return !(this.$store.state.page === "order" && this.landing.order.tab === 6);
    },
    showFull() {
      return (
        (this.landing.order.tab === 6 && this.$store.state.page === "order") ||
        (this.landing.order.tab === 3 && this.$store.state.page === "order") ||
        (this.landing.advanced.tab === 0 &&
          this.$store.state.page === "advanced") ||
        (this.landing.advanced.tab === 2 &&
          this.$store.state.page === "advanced") ||
        (this.landing.advanced.tab === 4 &&
          this.$store.state.page === "advanced") ||
        (this.landing.advanced.tab === 5 &&
          this.$store.state.page === "advanced") ||
        (this.landing.advanced.tab === 7 &&
          this.$store.state.page === "advanced") ||
        (this.landing.advanced.tab === 8 &&
          this.$store.state.page === "advanced") ||
        (this.landing.advanced.tab === 11 &&
          this.$store.state.page === "advanced") ||
        (this.landing.advanced.tab === 13 &&
          this.$store.state.page === "advanced") ||
        (this.landing.advanced.tab === 14 &&
          this.$store.state.page === "advanced") ||
        (this.landing.advanced.tab === 15 &&
          this.$store.state.page === "advanced") ||
        (this.landing.advanced.tab === 10 &&
          this.$store.state.page === "advanced") ||
          this.$store.state.page === 'no_preview'
      );
    },
    currentName() {
      const candidate = this.objects.list.find(
        (object) => object.id === this.object
      );
      if (candidate) {
        return candidate.name;
      } else {
        return "";
      }
    },
  },
  watch: {
    loading: function (value) {
      if (value) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "unset";
      }
    },
    landing: {
      deep: true,
      handler() {
        if (this.isNeedToWatch) {
          this.$store.commit("landing/setIsDirty", true);
        }
      },
    },
    "$store.state.tab": function (value) {
      if (value === 3) {
        this.$store.state.selectedPage = "reviews"
      }
    },
    "landing.isAbleToWatch": function (value) {
      if (value) {
        this.isNeedToWatch = true;
      }
    },
  },
  data() {
    return {
      dialogSave:false,
      dialogPreview:false,
      sheet:false,
      isMobile:false,
      textArr: [],
      tabs: null,
      object: +this.$route.params.id,
      profi: false,
      free: false,
      isNeedToWatch: false,
      dialog: false,
      to: null,
      title_text: "",
      loading: false,
      way_to: [],
      showSlider: true,
      checkArr: [],
    };
  },
  async created() {
    this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    this.$store.commit("setPage", "start");
    await this.getLanding(this.$route.params.id);
    if (this.$route.params.tab === "email") {
      this.tabs = 6;
    }
    await this.getIcons();
    this.$store.commit("landing/setIsAbleToWatch", true);
    this.$store.state.objects.item = this.$store.state.objects.list.find(
      (x) => x.id === +this.$route.params.id
    );
    this.landing.branding.active_tab = 15;
    if(this.$route.query.notifications){
      this.$store.commit("setPage", "advanced");
      this.$store.state.selectedPage = 'advanced';
      this.landing.advanced.tab = 16;
    }
    if (this.$route.query.bannerID) {
      this.$store.commit("setPage", "home");
      this.$store.state.selectedPage = 'branding';
      this.landing.branding.active_tab = 8;
    }
    if(this.$route.query.introID){
      this.$store.commit("setPage", "intro");
      this.$store.state.selectedPage = 'branding';
      this.landing.branding.active_tab =10;
    }
    if(this.$route.query.bottomBannerID){
      this.$store.commit("setPage", "home");
      this.$store.state.selectedPage = 'branding';
      this.landing.branding.active_tab = 9;
    }
  },
  destroyed() {
    this.$store.commit("landing/setIsAbleToWatch", false);
    this.$store.commit("landing/setIsDirty", false);
  },
  methods: {
    ...mapActions("landing", ["getIcons", "getBannerTypes", "getBanner"]),
    ...mapActions("banners", ["getBannerList"]),
    ...mapMutations(["setMenuActiveTab"]),
    ...mapMutations("landing", [
      "setLandingDomain",
      "setLandingLocation",
      "setLandingTitle",
    ]),
    ...mapMutations("branding", [
      "setAuthTitle",
      "setNoAuthTitle",
      "setFooterText",
      "setSlider",
      "setBottomMenu",
      "setIntroPro",
    ]),
    ...mapMutations("menu", ["setMenuList", "setSelectedMenuItem"]),
    ...mapMutations("banners", ["setCurrentBanner"]),
    closeDialogSave(){
      this.dialogSave = false;
    },
    changePage(page,index) {
      this.$store.state.tab = index;
      this.$store.state.selectedPage = page;
      if (this.selectedMenuItem) {
        const candidate = this.$store.state.menu.list.find(
            (item) => item.id === this.selectedMenuItem.id
        );
        if (candidate) {
          const candidateIndex = this.$store.state.menu.list.indexOf(candidate);
          if (candidateIndex >= 0) {
            this.$store.state.menu.list.splice(
                candidateIndex,
                1,
                JSON.parse(JSON.stringify(this.selectedMenuItem))
            );
          }
        }
      }
      this.setSelectedMenuItem(null);
      this.$store.commit("setPage", page);
    },
    changeTab() {
      if (this.landing.order.tab === 6) {
        this.landing.order.tab = 0;
      }
      this.setMenuActiveTab(0);
      if (this.tabs === 1) {
        this.changePageBranding();
      }
    },
    onSave() {
      this.isNeedToWatch = false;
    },
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    async changeObject(id) {
      await this.$router.push({
        name: "Constructor",
        params: { id: id, lang: this.$route.params.lang },
      });
      this.changePage('start',0)
      this.getLanding(id);
    },
    changePageBranding() {
      if (this.landing.branding.active_tab === 6) {
        this.changePage("lining");
      } else if (this.landing.branding.active_tab === 9) {
        this.changePage("intro");
      } else {
        this.changePage("home");
      }
      this.$store.state.selectedPage = "branding";
      this.$store.state.tab = 1;
    },
    async getLanding(id) {
      this.loading = true;
      this.setSelectedMenuItem(null);
      let current_object = this.objects.list.find((object) => object.id == id);
      if (!current_object) {
        await this.$router.push("/");
      }
      document.title = `${this.$t('constructor["Редактировать лендинг"]')}: ${
        current_object.name
      } (${this.$route.params.id})`;
      if (current_object?.tariff_id === 3) {
        this.profi = true;
      } else {
        this.profi = false;
      }
      if (current_object?.tariff_id === 1) {
        this.free = true;
      } else {
        this.free = false;
      }
      await this.$axios
        .get(`version/${id}`)
        .then(async (data) => {
          if (localStorage.getItem(`version_${id}`)) {
            if (
              localStorage.getItem(`version_${id}`) !== `${data.data.version}`
            ) {
              let keys = await caches.keys();
              for (let i = 0; i < keys.length; i++) {
                await caches.delete(keys[i]);
              }
              this.$store.commit("landing/setVersion", data.data.version);
              localStorage.setItem(`version_${id}`, data.data.version);
            } else {
              this.$store.commit("landing/setVersion", data.data.version);
              localStorage.setItem(`version_${id}`, data.data.version);
            }
          } else {
            this.$store.commit("landing/setVersion", data.data.version);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      const response = await this.$axios.get("get-lending-settings", {
        params: {
          hotel_id: id,
        },
      });

      if (response.data) {
        await this.$axios
          .get(`version?id=${id}`)
          .then(async (data) => {
            if (localStorage.getItem(`version_${id}`)) {
              if (
                localStorage.getItem(`version_${id}`) !== `${data.data.version}`
              ) {
                let keys = await caches.keys();
                for (let i = 0; i < keys.length; i++) {
                  await caches.delete(keys[i]);
                }
                localStorage.setItem(`version_${id}`, data.data.version);
              } else {
                localStorage.setItem(`version_${id}`, data.data.version);
              }
            }
            this.$store.commit("landing/setVersion", data.data.version);
          })
          .catch((err) => {
            console.log(err);
          });
        if (response.data.advanced.global.city_id) {
          const data = await ApiService.post("weathers", {
            city_id: response.data.advanced.global.city_id.id,
          });
          if(!response.data.advanced.global.weather.border_radius && response.data.advanced.global.weather.border_radius !== 0){
            response.data.advanced.global.weather.border_radius = 5;
          }
          response.data.advanced.global.weather.cloudiness =
            data.data.data.clouds.all;
          response.data.advanced.global.weather.windSpeed =
            data.data.data.wind.speed;
          response.data.advanced.global.weather.temperatureHigh = +(+data.data
            .data.main.temp_max).toFixed(2);
          response.data.advanced.global.weather.temperatureLow = +(+data.data
            .data.main.temp_min).toFixed(2);
          response.data.advanced.global.weather.temperatureValue = +(+data.data
            .data.main.temp).toFixed(2);
          response.data.advanced.global.weather.humidity =
            data.data.data.main.humidity;
          response.data.advanced.global.weather.week = data.data.week;
        }
        this.$store.commit("landing/objectIsProfi", this.profi);
        this.$store.commit("landing/objectIsFree", this.free);
        if (!response.data.advanced.global.show_locations) {
          response.data.advanced.global.show_locations =
            response.data.advanced.global.city_id.name;
        }
        if (!response.data.advanced.global.cartPayments) {
          response.data.advanced.global.cartPayments = {
            show: true,
            text: {
              en: "",
            },
            top_text:{
              en:""
            },
            top_text_detail:{
              en:""
            }
          };
        }
        if (!response.data.advanced.global.cartPayments.top_text) {
          response.data.advanced.global.cartPayments.top_text = {en:''};
        }
        if (!response.data.advanced.global.cartPayments.top_text_detail) {
          response.data.advanced.global.cartPayments.top_text_detail = {en:''};
        }
        response.data.advanced.global.lang_style = 'pro';
        this.$store.commit("landing/setAdvanced", response.data.advanced);
        this.landing.current_lang = this.landing.advanced.default_lang;
        this.setLandingDomain(response.data.domain);
        this.setLandingLocation(response.data.advanced.global.show_locations);
        this.setLandingTitle(response.data.advanced.global.title);
        if (!response.data.branding.sidebar_menu) {
          response.data.branding.sidebar_menu = {
            background: "rgba(255,255,255,1)",
            border_color: "rgba(204, 204, 204,1)",
          };
        }
        if(typeof response.data.branding.logo_size.radius === 'undefined'){
          response.data.branding.logo_size.radius = 0;
        }
        if(typeof response.data.branding.sidebar_menu.show_icons === 'undefined'){
          response.data.branding.sidebar_menu.show_icons = true
        }
        if(typeof response.data.branding.sidebar_menu.icon_colors === 'undefined'){
          response.data.branding.sidebar_menu.icon_colors = "rgba(0,0,0,1)"
        }
        if(typeof response.data.branding.event_main_page === 'undefined'){
          response.data.branding.event_main_page = {
            active: false,
            show_type: "bottom",
            border_color: "#cccccc",
            type: "vertical",
            all_events:{
              text:{
                en:"View all"
              },
              link:{
                en:""
              },
              styles:{
                color: "#000",
                fontWeight: "500",
                fontStyle: "normal",
                fontFamily: "Inter",
                fontSize: "16",
              }
            },
            title:{
              en:""
            },
            vertical: {
              height: "220",
              width: "150",
              img_height: "150",
            },
            horizontal: {
              height: "150",
              width: "250",
              img_width: "100",
            },
            background: "rgba(255,255,255,1)",
            bg_group: "rgba(255,255,255,1)",
            radius: 0,
            autoplay:4
          }
        }
        if(typeof response.data.branding.segment_products_styles === 'undefined'){
          response.data.branding.segment_products_styles = JSON.parse(JSON.stringify(defaultProductStyles));
        }
        this.$store.commit("landing/setBranding", response.data.branding);
        this.setAuthTitle(
          JSON.parse(JSON.stringify(response.data.branding.title))
        );
        this.setNoAuthTitle(
          JSON.parse(JSON.stringify(response.data.branding.title_no_auth))
        );
        this.setFooterText(
          JSON.parse(JSON.stringify(response.data.branding.footer_text))
        );
        this.setSlider(
          JSON.parse(JSON.stringify(response.data.branding.slider))
        );
        this.setIntroPro(
          JSON.parse(
            JSON.stringify(
              response.data.branding.intro[this.landing.current_lang].pro
            )
          )
        );
        this.setBottomMenu(
          JSON.parse(JSON.stringify(response.data.branding.bottomMenu))
        );
        for (let i = 0; i < response.data.menu.list.length; i++) {
          let icon = defaultIcon;
          for (let key in defaultStyles) {
            if (typeof response.data.menu.list[i].styles[key] === "undefined") {
              response.data.menu.list[i].styles[key] = defaultStyles[key];
            }
          }
          if(typeof response.data.menu.list[i].icon === 'string'){
            icon = response.data.menu.list[i].icon.split('').join('');
            response.data.menu.list[i].icon = {};
          }
          for (let lang of response.data.advanced.selected_langs) {
            if(!response.data.menu.list[i].icon[lang]){
              response.data.menu.list[i].icon[lang] = response.data.menu.list[i].icon['en']?response.data.menu.list[i].icon['en']:icon;
            }
            if (!response.data.menu.list[i].content[lang]) {
              response.data.menu.list[i].content[lang] =
                response.data.menu.list[i].content["en"];
            }
            for (let indexOfType = 0; indexOfType < 18; indexOfType++) {
              if (!response.data.menu.list[i].content[lang][indexOfType]) {
                response.data.menu.list[i].content[lang][indexOfType] =
                  defaultContentMenuItem[indexOfType];
              }
              if (indexOfType === 0) {
                if (
                  !response.data.menu.list[i].content[lang][indexOfType].form
                    .emails
                ) {
                  response.data.menu.list[i].content[lang][
                    indexOfType
                  ].form.emails = [];
                }
                if (
                  !response.data.menu.list[i].content[lang][indexOfType].form
                    .telegrams
                ) {
                  response.data.menu.list[i].content[lang][
                    indexOfType
                  ].form.telegrams = [];
                }

                if (
                  !response.data.menu.list[i].content[lang][indexOfType].form
                    .type_of_order
                ) {
                  response.data.menu.list[i].content[lang][
                    indexOfType
                  ].form.type_of_order = defaultFormMenuItem.type_of_order;
                }
              }
              if (indexOfType === 4) {
                for (let key in defaultGlobalMenuSettings) {
                  const keys = Object.keys(response.data.menu.list[i].content[lang][indexOfType]);
                  if (
                    !keys.includes(key)
                  ) {
                    response.data.menu.list[i].content[lang][indexOfType][key] =
                      defaultGlobalMenuSettings[key];
                  }
                  for (
                    let submenu = 0;
                    submenu <
                    response.data.menu.list[i].content[lang][indexOfType]
                      .content.menu.length;
                    submenu++
                  ) {
                    for (let key in defaultStyles) {
                      const keys = Object.keys(response.data.menu.list[i].content[lang][indexOfType]
                          .content.menu[submenu].styles)
                      if (
                        !keys.includes(key)
                      ) {
                        response.data.menu.list[i].content[lang][
                          indexOfType
                        ].content.menu[submenu].styles[key] =
                          defaultStyles[key];
                      }
                    }
                  }
                }
                for (
                  let indexSubMenu = 0;
                  indexSubMenu <
                  response.data.menu.list[i].content[lang][indexOfType].content
                    .menu.length;
                  indexSubMenu++
                ) {
                  const keys = Object.keys(response.data.menu.list[i].content[lang][indexOfType]
                      .content.menu[indexSubMenu].styles)
                  for (let key in defaultStyles) {
                    if (
                      !keys.includes(key)
                    ) {
                      response.data.menu.list[i].content[lang][
                        indexOfType
                      ].content.menu[indexSubMenu].styles[key] =
                        defaultStyles[key];
                    }
                  }
                }
              }
              if (indexOfType === 3) {
                const keys = Object.keys(response.data.menu.list[i].content[lang][indexOfType]
                    .styles)
                for (let key in defaultListingStyles) {
                  if (
                    !keys.includes(key)
                  ) {
                    response.data.menu.list[i].content[lang][
                      indexOfType
                    ].styles[key] = defaultListingStyles[key];
                  }
                }
                for (
                  let listingGroup = 0;
                  listingGroup <
                  response.data.menu.list[i].content[lang][indexOfType].content
                    .listing.length;
                  listingGroup++
                ) {
                  if (
                    !response.data.menu.list[i].content[lang][indexOfType]
                      .content.listing[listingGroup].hide
                  ) {
                    response.data.menu.list[i].content[lang][
                      indexOfType
                    ].content.listing[listingGroup].hide = true;
                  }
                  for (
                    let listingIndex = 0;
                    listingIndex <
                    response.data.menu.list[i].content[lang][indexOfType]
                      .content.listing[listingGroup].content.length;
                    listingIndex++
                  ) {
                    if (
                      !response.data.menu.list[i].content[lang][indexOfType]
                        .content.listing[listingGroup].content[listingIndex]
                        .hide
                    ) {
                      response.data.menu.list[i].content[lang][
                        indexOfType
                      ].content.listing[listingGroup].content[
                        listingIndex
                      ].hide = true;
                    }
                    if (
                      !response.data.menu.list[i].content[lang][indexOfType]
                        .content.listing[listingGroup].content[listingIndex]
                        .form.emails
                    ) {
                      response.data.menu.list[i].content[lang][
                        indexOfType
                      ].content.listing[listingGroup].content[
                        listingIndex
                      ].form.emails = [];
                    }
                    if (
                      !response.data.menu.list[i].content[lang][indexOfType]
                        .content.listing[listingGroup].content[listingIndex]
                        .form.telegrams
                    ) {
                      response.data.menu.list[i].content[lang][
                        indexOfType
                      ].content.listing[listingGroup].content[
                        listingIndex
                      ].form.telegrams = [];
                    }
                    if (
                      !response.data.menu.list[i].content[lang][indexOfType]
                        .content.listing[listingGroup].content[listingIndex]
                        .form.type_of_order
                    ) {
                      response.data.menu.list[i].content[lang][
                        indexOfType
                      ].content.listing[listingGroup].content[
                        listingIndex
                      ].form.type_of_order = defaultFormMenuItem.type_of_order;
                    }
                  }
                }
              }

              if (indexOfType === 7) {
                if (
                    !response.data.menu.list[i].content[lang][indexOfType]?.styles
                ) {
                  response.data.menu.list[i].content[lang][indexOfType].styles =
                      JSON.parse(JSON.stringify(defaultEventStyles));
                }
              }
              if (indexOfType === 8) {
                if (
                  !response.data.menu.list[i].content[lang][indexOfType]?.styles
                    ?.category
                ) {
                  response.data.menu.list[i].content[lang][indexOfType] =
                    defaultContentMenuItem[indexOfType];
                }
                if (
                    !response.data.menu.list[i].content[lang][indexOfType]?.styles
                        ?.product.btnColorOpenCart
                ) {
                  response.data.menu.list[i].content[lang][indexOfType].styles
                      .product.btnColorOpenCart = "#000000"
                }
                if (
                    !response.data.menu.list[i].content[lang][indexOfType]?.styles
                        ?.product.btnBgOpenCart
                ) {
                  response.data.menu.list[i].content[lang][indexOfType].styles
                      .product.btnBgOpenCart = "rgba(255,255,255,0.5)"
                }
                if (
                    !response.data.menu.list[i].content[lang][indexOfType]?.styles
                        ?.product.btnBorderOpenCart
                ) {
                  response.data.menu.list[i].content[lang][indexOfType].styles
                      .product.btnBorderOpenCart = "#000000"
                }
                if (
                    !response.data.menu.list[i].content[lang][indexOfType].styles
                        .product.btnNameOpenCart
                ) {
                  response.data.menu.list[i].content[lang][indexOfType].styles
                      .product.btnNameOpenCart = { en: "Open Cart" }
                }
                if (
                    !response.data.menu.list[i].content[lang][indexOfType]?.styles
                        ?.productDetail.btnColorOpenCart
                ) {
                  response.data.menu.list[i].content[lang][indexOfType].styles
                      .productDetail.btnColorOpenCart = "#000000"
                }
                if (
                    !response.data.menu.list[i].content[lang][indexOfType]?.styles
                        ?.productDetail.btnBgOpenCart
                ) {
                  response.data.menu.list[i].content[lang][indexOfType].styles
                      .productDetail.btnBgOpenCart = "rgba(255,255,255,0.5)"
                }
                if (
                    !response.data.menu.list[i].content[lang][indexOfType]?.styles
                        ?.productDetail.btnBorderOpenCart
                ) {
                  response.data.menu.list[i].content[lang][indexOfType].styles
                      .productDetail.btnBorderOpenCart = "#000000"
                }
                if (
                    !response.data.menu.list[i].content[lang][indexOfType]?.styles
                        ?.productDetail.btnNameOpenCart
                ) {
                  response.data.menu.list[i].content[lang][indexOfType].styles
                      .productDetail.btnNameOpenCart = { en: "Open Cart" }
                }
                if(!response.data.menu.list[i].content[lang][indexOfType].styles.productDetail.slider.name){
                  response.data.menu.list[i].content[lang][indexOfType].styles.productDetail.slider.name = defaultProductStyles.productDetail.slider.name;
                }
                if(!response.data.menu.list[i].content[lang][indexOfType].styles.productDetail.slider.price){
                  response.data.menu.list[i].content[lang][indexOfType].styles.productDetail.slider.price = defaultProductStyles.productDetail.slider.price;
                }
                if(!response.data.menu.list[i].content[lang][indexOfType].styles.productDetail.slider.title){
                  response.data.menu.list[i].content[lang][indexOfType].styles.productDetail.slider.title = defaultProductStyles.productDetail.slider.title;
                }
                if(!response.data.menu.list[i].content[lang][indexOfType].styles.category.top_menu){
                  response.data.menu.list[i].content[lang][indexOfType].styles.category.top_menu = defaultProductStyles.category.top_menu;
                }
                if(!response.data.menu.list[i].content[lang][indexOfType].styles.category.categorySlider){
                  response.data.menu.list[i].content[lang][indexOfType].styles.category.categorySlider = defaultProductStyles.category.categorySlider;
                }
                if(typeof response.data.menu.list[i].content[lang][indexOfType].styles.category.category_style === 'undefined'){
                  response.data.menu.list[i].content[lang][indexOfType].styles.category.category_style = 0;
                }
              }


              if (indexOfType === 11) {
                for (
                  let dynamicIndex = 0;
                  dynamicIndex <
                  response.data.menu.list[i].content[lang][indexOfType].content
                    .length;
                  dynamicIndex++
                ) {
                  if (
                    !response.data.menu.list[i].content[lang][indexOfType]
                      .content
                  ) {
                    response.data.menu.list[i].content[lang][
                      indexOfType
                    ].content[dynamicIndex].content =
                      defaultContentMenuItem[indexOfType].content[0];
                  } else {
                    if (
                      response.data.menu.list[i].content[lang][indexOfType]
                        .content[dynamicIndex].content.length === 3
                    ) {
                      response.data.menu.list[i].content[lang][
                        indexOfType
                      ].content[dynamicIndex].content.push(
                        defaultContentMenuItem[indexOfType].content[0]
                          .content[3]
                      );
                    }
                  }
                }
              }
              if(indexOfType === 12){
                if(!response.data.menu.list[i].content[lang][indexOfType].styles.lining){
                  response.data.menu.list[i].content[lang][indexOfType].styles.lining = {
                    color:"rgba(255,255,255,0.5)",
                    radius:5,
                    width:50
                  }
                }
              }
              if (indexOfType === 13) {
                if (!response.data.menu.list[i].content[lang][indexOfType]) {
                  response.data.menu.list[i].content[lang][indexOfType] =
                    defaultContentMenuItem[indexOfType];
                }
                if (
                  !response.data.menu.list[i].content[lang][indexOfType].styles
                ) {
                  response.data.menu.list[i].content[lang][indexOfType].styles =
                    defaultContentMenuItem[indexOfType].styles;
                }
              }
              if(typeof response.data.menu.list[i].content[lang][indexOfType].styleSettings === 'undefined'){
                response.data.menu.list[i].content[lang][indexOfType].styleSettings = false;
              }
            }
          }
        }
        this.setMenuList(response.data.menu.list);
        this.$store.commit("landing/setMenu", response.data.menu);
        this.$store.commit("landing/setSocials", response.data.socials);
        if (!response.data.reviews.emailNotification) {
          response.data.reviews.emailNotification = JSON.parse(
            JSON.stringify(this.landing.reviews.emailNotification)
          );
        }
        for (let lang of response.data.advanced.selected_langs) {
          if (!response.data.reviews.success_page[lang]) {
            response.data.reviews.success_page[lang] =
              response.data.reviews.success_page["en"];
          }
        }
        if (!response.data.reviews.styles) {
          response.data.reviews.styles = JSON.parse(
            JSON.stringify(this.landing.reviews.styles)
          );
        }
        for (let lang of response.data.advanced.selected_langs) {
          if(!response.data.reviews[lang]){
            response.data.reviews[lang] = JSON.parse(JSON.stringify(response.data.reviews['en']));
          }
        }
        this.$store.commit("landing/setReviews", response.data.reviews);
        if (!response.data.reviews.emails) {
          response.data.reviews.emails = [];
          if (response.data.reviews.email) {
            response.data.reviews.emails.push(
              response.data.reviews.emailNotification.email
            );
          }
        }
        this.$store.commit("landing/setSettingsId", response.data.id);
        this.$store.commit("landing/setOrder", response.data.order);
        this.$store.commit("landing/setOrderForm", response.data.order_form);
        this.$store.commit("landing/setCreatedAt", response.data.created_at);
        this.$store.commit("landing/setUpdatedAt", response.data.updated_at);
      }
      else {
        this.$store.commit(
          "landing/setAdvanced",
          this.$store.state.landing.advanced
        );
        this.$store.commit(
          "landing/setBranding",
          this.$store.state.landing.branding
        );
        this.$store.commit(
          "landing/setMenu",
          this.$store.state.landing.menu
        );
        this.$store.commit(
          "landing/setSocials",
          this.$store.state.landing.socials
        );
        this.$store.commit(
          "landing/setReviews",
          this.$store.state.landing.reviews
        );
        this.$store.commit(
          "landing/setOrder",
          this.$store.state.landing.order
        );
        this.$store.commit(
          "landing/setOrderForm",
          this.$store.state.landing.order_form
        );
        this.$store.commit("landing/objectIsProfi", this.profi);
        this.$store.commit("landing/objectIsFree", this.free);
      }
      this.loading = false;
      return response;
    },
  },
};
</script>

<style lang="scss">
.header_constructor {
  padding: 30px 30px;
  .heading {
    margin-bottom: 20px;
  }
}

.constructor {
  display: flex;
  height: 100%;

  &__save {
    display: flex;
    justify-content: flex-end;
    padding: 30px;
  }
}

.constructor__builder {
  width: 60%;
}

.constructor__preview {
  width: 40%;
  position: sticky;
  background-color: rgba($color: $primary, $alpha: 0.12);
}

.constructor__main {
}

.constructor__header {
  padding: 30px;
  border-bottom: 1px solid #e4e4e4;
}

.constructor__heading {
  @include title;
}

.constructor__content {
}

.constructor__text {
  margin-bottom: 0 !important;
}

.constructor__tabs {
  .v-slide-group__prev,
  .v-slide-group__next {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .div {
    width: 25%;
  }
}

.constructor__subtabs {
  .div {
    min-width: 185px;
    justify-content: flex-start;
  }
  .v-window-item {
    padding: 30px;
  }
  .label {
    display: inline-block;
    margin-bottom: 10px;
  }
}

.langs__tabs {
  .div {
    min-width: initial;
  }
  .divs-bar {
    margin: 0 15px;
    border-bottom: 1px solid #c2c2c2;
  }
  .v-window-item {
    // margin: 0 -15px;
    padding: 15px 0 0;
  }
}
.divs .v-slide-group__next--disabled,
.divs .v-slide-group__prev--disabled {
  display: none;
}
.tariff_obj {
  margin-left: 5px;
  display: inline-block;
  padding: 4px 6px;
  text-transform: uppercase;
  border-radius: 0 5px 0 5px;
  background: #007db5;
  font-weight: 600;
  font-size: 12px;
  line-height: 1;
  color: #fff;
}
.loading {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;

  &__icon {
    animation: 1s linear 0s normal none infinite running circle;
  }
  @keyframes circle {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
.langs__tabs .div--active {
  border-bottom: 2px solid;
}
.activeTab {
  color: #007db5;
  border-bottom: 2px solid #007db5;
}
.header__tab {
  height: 48px;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}
</style>
