<template>
<div
    class="productProductPreview"
    v-if="segmentProduct"
    :style="`padding-left:${
      landing.branding.lining_margin ? '10px' : '0'
    };padding-right:${landing.branding.lining_margin ? '10px' : '0'};`"
>
  <div :style="liningStyles+`min-height:300px;margin-top:${segmentProduct.detail_images.length?'0':`${+landing.branding.topMenu.styles.size * 2.5}px`};`" class="productProductPreviewMain">
    <div :style="stylesBlock" class="productProductPreviewMainItem">
      <div
          class="productProductPreviewMainItemImage"
          :style="imgStyles"
          v-if="landing.branding.segment_products_styles.productDetail.show_icon&&segmentProduct.detail_images.length"
      >
        <swiper
            ref="mySwiperDetail"
            :direction="'horizontal'"
            :pagination="{ clickable: true }"
            :options="swiperOptionsListing"
            style="width: 100%; overflow: hidden;position: relative"
            v-if="segmentProduct.detail_images.length"
        >
          <swiper-slide v-for="img in segmentProduct.detail_images.filter(x => x)" :key="img">
            <img
                :src="
              img
            "
                alt=""
                :style="imgStyles"
            />
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination" />
        </swiper>
      </div>

      <div class="productProductPreviewMainItemText" style="text-align:center;padding-top:15px;">
        <div :style="stylesName">
          {{ segmentProduct.name[landing.current_lang] }}
        </div>
      </div>
      <div style="display:flex;width:100%;justify-content:space-between;padding:0 10px;align-items:center;">
        <div class="productProductPreviewMainItemText" :style="stylesPrice" style="flex:1;">
          {{ segmentProduct.price }} {{ landing.advanced.global.currency }}
        </div>
      </div>
      <div style="padding:0 10px;"
           v-html="segmentProduct.description[landing.current_lang]"
      ></div>
      <div style="margin: 15px 0;display:flex;align-items:center;justify-content:space-around;width:100%;">
        <v-btn
            outlined
            small
            dense
            :style="`background:${landing.branding.segment_products_styles.productDetail.btnBgCart};color:${landing.branding.segment_products_styles.productDetail.btnColorCart};border-color:${landing.branding.segment_products_styles.productDetail.btnBorderCart};`"
        >
          {{ landing.branding.segment_products_styles.productDetail.btnNameCart[landing.current_lang] }}
        </v-btn>
        <div>
          or
        </div>
        <v-btn
            outlined
            small
            dense
            :style="`background:${landing.branding.segment_products_styles.productDetail.btnBgOpenCart};color:${landing.branding.segment_products_styles.productDetail.btnColorOpenCart};border-color:${landing.branding.segment_products_styles.productDetail.btnBorderOpenCart};`"
        >
          {{
          landing.branding.segment_products_styles.productDetail.btnNameOpenCart[
          landing.current_lang
          ]
          }}
        </v-btn>
      </div>

    </div>
  </div>
</div>
</template>

<script>
  import { mapState } from "vuex";
  import "swiper/swiper-bundle.css";
  import "swiper/modules/pagination/pagination.min.css";
  import SwiperCore, { Pagination } from "swiper";
  import { Swiper, SwiperSlide } from "vue-awesome-swiper";
  SwiperCore.use([Pagination]);
  export default {
    name: "SegmentProductDetailPreview",
    data() {
      return {
        swiperOptions: {
          slidesPerView: "auto",
          spaceBetween: 15,
        },
        swiperOptionsListing: {
          grabCursor: true,
          centeredSlides: true,
          pagination: {
            el: ".swiper-pagination",
            type: "bullets",
            clickable: true,
          },
        },
      };
    },
    props: ["content"],
    methods: {

    },
    computed: {
      ...mapState(["landing"]),
      ...mapState('segmentProducts',['segmentProduct']),
      liningStyles() {
        let styles = "";
        if (this.landing.branding.bg_lining_img) {
          styles+= `background-image:url(${this.landing.branding.bg_lining_img});background-size:cover;background-repeat:no-repeat;`;
        } else {
          styles+=`background-color:${this.landing.branding.bg_lining_color};`;
        }
        if(this.segmentProduct.detail_images.length){
          styles+=`border-bottom-left-radius:${this.landing.branding.lining_radius}px;border-bottom-right-radius:${this.landing.branding.lining_radius}px;`;
        }else{
          styles+=`border-radius:${this.landing.branding.lining_radius}px;`;
        }
        return styles
      },
      imgStyles() {
        return `
      width:100%;
      height:${this.landing.branding.segment_products_styles.productDetail.img_height}px;
      `;
      },
      stylesBlock() {
        return `
        width:100%;
        height:auto;
        background:transparent;
      `;
      },
      stylesName() {
        return `
        color:${this.landing.branding.segment_products_styles.productDetail.color};
        font-size:${this.landing.branding.segment_products_styles.productDetail.fontSize}px;
        font-style:${this.landing.branding.segment_products_styles.productDetail.fontStyle};
        font-family:${this.landing.branding.segment_products_styles.productDetail.fontFamily};
        font-weight:${this.landing.branding.segment_products_styles.productDetail.fontWeight};
        align-items:flex-start;
      `;
      },
      stylesTitle_slider(){
        return `
        color:${this.landing.branding.segment_products_styles.productDetail.slider.title.color};
        font-size:${this.landing.branding.segment_products_styles.productDetail.slider.title.font_size}px;
        font-weight:${this.landing.branding.segment_products_styles.productDetail.slider.title.font_weight};
        font-style:${this.landing.branding.segment_products_styles.productDetail.slider.title.font_style};
        font-family:${this.landing.branding.segment_products_styles.productDetail.slider.title.font_family};
      `;
      },
      stylesName_slider(){
        return `
        color:${this.landing.branding.segment_products_styles.productDetail.slider.name.color};
        font-size:${this.landing.branding.segment_products_styles.productDetail.slider.name.font_size}px;
        font-weight:${this.landing.branding.segment_products_styles.productDetail.slider.name.font_weight};
        font-style:${this.landing.branding.segment_products_styles.productDetail.slider.name.font_style};
        font-family:${this.landing.branding.segment_products_styles.productDetail.slider.name.font_family};
      `;
      },
      stylesPrice_slider(){
        return `
        color:${this.landing.branding.segment_products_styles.productDetail.slider.price.color};
        font-size:${this.landing.branding.segment_products_styles.productDetail.slider.price.font_size}px;
        font-weight:${this.landing.branding.segment_products_styles.productDetail.slider.price.font_weight};
        font-style:${this.landing.branding.segment_products_styles.productDetail.slider.price.font_style};
        font-family:${this.landing.branding.segment_products_styles.productDetail.slider.price.font_family};
        align-items:flex-end;
      `;
      },
      stylesPrice() {
        return `
        color:${this.landing.branding.segment_products_styles.productDetail.colorPrice};
        font-size:${this.landing.branding.segment_products_styles.productDetail.fontSizePrice}px;
        font-weight:${this.landing.branding.segment_products_styles.productDetail.fontWeightPrice};
        font-style:${this.landing.branding.segment_products_styles.productDetail.fontStylePrice};
        font-family:${this.landing.branding.segment_products_styles.productDetail.fontFamilyPrice};
        align-items:flex-end;
      `;
      },
    },
    components: {
      Swiper,
      SwiperSlide,
    },
  };
</script>

<style scoped lang="scss">
  .productProductPreview {
    &Main {
      min-height: 300px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      &Item {
        font-size: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-weight: 400;
        font-style: normal;
        border-radius: 5px;
        cursor: pointer;
        font-family: Inter, Inter, sans-serif;
        color: rgb(0, 0, 0);

        &Image {
          width: 50px;
          height: 50px;
          margin-bottom: 15px;
        }
        &Text {
          display: flex;
          flex-direction: column;
          width: 100%;
          padding: 0 5px;
        }
      }
    }
  }
</style>
