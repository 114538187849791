import ApiService from "./api.services";

const entities = {
  LendingSetting: 'LendingSetting'
}

const collections = {
  header: 'header', background: 'background', logo: 'logo',footer:'footer'
}

const MediaService = {
  /**
   * 
    * @param {Object} params
    * @param {string} params.entity_name - Название сущности - первый уровень /media-collections
    * @param {number} params.entity_id - ID сущности, обращаю внимание - в случае настроек лендинга отправлять именно ИД настроек, а не отеля!
    * @param {string} params.collection - Название коллекции - на втором уровне /media-collections
    * @param {File} params.file - Файл
    * @param {number} params.listing_img_id - optional
    * @param {number} params.index - Индекс объекта в коллекции (начинается с нуля), нужно передавать как есть на фронте, типа первый элемент листинга - 0, второй - 1 (обязательное поле для коллекций, в которых может быть несколько элементов)
    
    * @returns Promise
  */
  postMedia: async function (params) {
    try {
      const response = await ApiService.post('media', params);
      return response.data;
    } catch (e) {
      return e;
    }
  },
  /**
   * 
    * @param {Object} params
    * @param {string} params.entity_name - Название сущности - первый уровень /media-collections
    * @param {number} params.entity_id - ID сущности, обращаю внимание - в случае настроек лендинга отправлять именно ИД настроек, а не отеля!
    * @param {string} params.collection - Название коллекции - на втором уровне /media-collections
    * @param {File} params.file - Файл
    * @param {number} params.index - Индекс объекта в коллекции (начинается с нуля), нужно передавать как есть на фронте, типа первый элемент листинга - 0, второй - 1 (обязательное поле для коллекций, в которых может быть несколько элементов)
   
    * @returns Promise
  */
  deleteMedia: async function (params) {
    try {
      const response = await ApiService.delete('media', params);

      return response.data;
    } catch (e) {
      return e;
    }
  },
  getMediaCollections: async function () {
    try {
      const response = await ApiService.get('media-collections');

      return response.data;
    } catch (e) {
      return e;
    }
  }
}

export {
  entities,
  collections
}

export default MediaService;