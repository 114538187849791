<template>
  <div>
    <v-dialog max-width="600" v-model="dialog"  @click:outside="$emit('closeDialog')">
        <div class="popup__content">
          <v-btn class="popup__close" small icon @click="$emit('closeDialog')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <h5 class="popup__title">
            {{ $t('popup["Вы уверены, что хотите сохранить данные?"]') }}
          </h5>
          <div class="popup__actions">
            <v-btn
              class="popup__action btn btn_primary"
              color="primary"
              depressed
              @click="dialog.value = false"
            >
              {{ $t('popup["Закрыть"]') }}
            </v-btn>
            <v-btn
              class="popup__action btn btn_primary"
              color="primary"
              depressed
              @click="save"
            >
              {{ $t('popup["Сохранить"]') }}
            </v-btn>
          </div>
        </div>
    </v-dialog>
    <div class="loading" v-if="loader">
      <img
        class="loading__icon"
        src="@/assets/img/logo__loader.svg"
        alt=""
        style="height: 100px; width: 100px; margin-bottom: 50px"
      />
      <div>Please wait a little bit.</div>
      <div>We are saving your project :)</div>
    </div>
    <v-dialog max-width="600" v-model="showAnotherVersion" @click="$emit('closeDialog')">
        <div class="popup__content">
          <v-btn class="popup__close" small icon @click="$emit('closeDialog')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <h5 class="popup__title">
            Your current version is older than the one you have.
          </h5>
          <div class="popup__actions">
            <v-btn
                class="popup__action btn btn_primary"
                color="primary"
                depressed
                @click="reload"
            >
              Update version
            </v-btn>
            <v-btn
                class="popup__action btn btn_primary"
                color="primary"
                depressed
                @click="save"
            >
              {{ $t('popup["Сохранить"]') }}
            </v-btn>
          </div>
        </div>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions,mapGetters, mapMutations, mapState } from "vuex";
import PWAService from "../../../services/pwa.service";
export default {
  data() {
    return {
      loader: false,
      showAnotherVersion:false,
    };
  },
  props:{
    dialog:{
      type:Boolean,
      default:false,
    }
  },
  computed: {
    ...mapGetters("landing", ["isValid"]),
    ...mapState(["landing"]),
    ...mapState('menu',['selectedMenuItem']),
    ...mapState('mainPageStyles',['list','globalSettings','stylesOpen']),
    ...mapState('reviews',['reviews']),
    ...mapState('orders',['order','order_form']),
    ...mapState('banners',['selectedBanner','saveBanners']),
    ...mapState('intro',['introBanner','saveIntro']),
    showSaveLanding() {
      if(this.$store.state.tab === 6){
        return false
      }
        if(this.$store.state.page === 'order'&& this.landing.free ||
        this.$store.state.page === 'reviews'&& this.landing.free){
            return false;
        }
      const candidate = this.landing.menu.list.find(
        (x) => x.page === this.$store.state.page
      );
      if (candidate) {
        if (candidate.type === 11) {
          return false;
        }
      }
      if (this.$store.state.page === "order" && this.landing.order.tab === 6) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    ...mapMutations("landing", ["setIsDirty", "setIsAbleToWatch",'setReviews','setOrder','setOrderForm']),
    ...mapActions("banners",['editBanner']),
    ...mapActions('intro',['putIntroBanner']),
    reload(){
      window.location.reload();
    },
    async save() {
      this.loader = true;
      this.$emit('closeDialog');
      const version = await this.$axios.get(`version?id=${this.$route.params.id}`);
      if(+version.data.version !== +this.landing.version && !this.showAnotherVersion){
        this.loader = false;
        this.showAnotherVersion = true;
        return;
      }
      this.showAnotherVersion = false;
      if(this.landing.advanced.global.pwa){
        await PWAService.putBannerPWA(this.$store.state.advanced.install_settings)
      }
      if(this.saveBanners.length){
        for(let  i = 0;i<this.saveBanners.length;i++){
          await this.editBanner(this.saveBanners[i]);
        }
      }
      if(this.selectedBanner){
        await this.editBanner(this.selectedBanner);
      }
      if(this.saveIntro.length){
        for(let i = 0;i<this.saveIntro.length;i++){
          await this.putIntroBanner(this.saveIntro[i]);
        }
      }
      if(this.introBanner){
        await this.putIntroBanner(this.introBanner);
      }
      if(this.stylesOpen){
        for (let i = 0; i < this.list.length; i++) {
          for (let key in this.list[i].styles) {
            this.landing.menu.list[i].styles[key] = this.list[i].styles[key];
          }
        }
        this.landing.menu.shadowSize = this.globalSettings.shadow_size;
        this.landing.menu.shadowColor = this.globalSettings.shadow_color;
        this.landing.menu.radius = this.globalSettings.radius;
        this.landing.menu.icon_size = this.globalSettings.icon_size;
      }
      if(this.$store.state.loader.loaderState.show){
        await this.$store.dispatch('loader/toServerLoader',this.$store.state.loader.loaderState);
      }
      if(this.selectedMenuItem){
        const candidate = this.landing.menu.list.find(item => item.id === this.selectedMenuItem.id);
        if(candidate){
          const candidateIndex = this.landing.menu.list.indexOf(candidate);
          if(candidateIndex >= 0){
            this.landing.menu.list.splice(candidateIndex,1,JSON.parse(JSON.stringify(this.selectedMenuItem)));
          }
        }
      }
      if(this.order){
        this.setOrder(JSON.parse(JSON.stringify(this.order)));
      }
      if(this.order_form){
        this.setOrderForm(JSON.parse(JSON.stringify(this.order_form)));
      }
      if(this.reviews){
        this.setReviews(JSON.parse(JSON.stringify(this.reviews)));
      }
      await this.$store.dispatch("landing/saveLanding", this.$route.params.id);

      this.$emit("save");
      this.setIsDirty(false);

      this.setIsAbleToWatch(true);

      this.$emit('closeDialog');
      this.loader = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.popup__title {
  margin-bottom: 50px;
  padding: 0 40px;
}
.popup__actions {
  display: flex;
  margin: 0 -10px;
}
.popup__action {
  width: calc(50% - 20px);
  margin: 0 10px;
}

.loading {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  color: #7f7f7f;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;

  &__icon {
    animation: 1s linear 0s normal none infinite running circle;
  }
  @keyframes circle {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
</style>
