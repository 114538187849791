<template>
  <div style="padding:15px;">
    <div v-if="page === 'categories'">
      <div
          style="
        display: flex;
        align-items: center;
        margin-bottom: 15px;
      "
          :style="`justify-content:${styles?'space-between':'flex-end'};`"
      >
        <div v-if="styles">
          <v-btn color="primary" outlined @click="openStyles" small
          ><v-icon>mdi-chevron-left</v-icon>
            <span
            >Back to category</span
            ></v-btn
          >
        </div>
        <v-menu offset-y open-on-hover bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="primary"
                dark
                outlined
                small
                v-bind="attrs"
                v-on="on"
            >
              <v-icon>mdi-cog</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-list-item-title style="cursor: pointer" @click="openStyles">
                {{
                styles ? "Content settings" : "Style settings"
                }}</v-list-item-title
              >
            </v-list-item>
            <v-list-item>
              <v-list-item-title
                  style="cursor: pointer"
              >Cart settings</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <div
          v-if="!styles"
          style="
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
      "
      >
        <label class="label" style="margin-bottom: 0">Categories list</label>
        <v-btn
            class="object__submit btn btn_primary"
            style="float: right"
            color="primary"
            outlined
            small
            solo
            @click="createProductCategory"
        >
          Create category
        </v-btn>
      </div>
      <div style="margin-bottom: 15px" v-if="!styles">
        <v-data-table
            class="table"
            :headers="headers"
            :items="segmentProductCategories"
            :items-per-page="-1"
            hide-default-footer
        >
          <template v-slot:body="{ items }">
            <tbody v-if="items.length">
            <tr v-for="item in items" :key="item.id">
              <td class="d-block d-sm-table-cell" style="text-align: center">
                {{ item.name[landing.current_lang] }}
              </td>
              <td class="d-block d-sm-table-cell" style="text-align: center">
                {{ item.products.length }}
              </td>
              <td class="d-block d-sm-table-cell" style="text-align: center">
                <v-chip
                    class="ma-2"
                    :color="item.active ? 'green' : 'red'"
                    text-color="white"
                    style="cursor: pointer"
                    @click="changeCategoryStatus(item)"
                >
                  {{ item.active ? "Active" : "Inactive" }}
                </v-chip>
              </td>
              <td class="d-block d-sm-table-cell" style="text-align: center">
                <v-menu offset-y open-on-hover bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" dark v-bind="attrs" v-on="on" outlined small>
                      <v-icon>mdi-cog</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                        style="cursor: pointer" @click="openEdit(item)"
                    >
                      <v-list-item-title>Edit</v-list-item-title>
                    </v-list-item>
                    <v-list-item link @click="openCartSettings(item)">
                      <v-list-item-title
                          style="cursor: pointer"
                      >
                        Cart settings
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title
                          style="cursor: pointer; color: red"
                          @click="openDeletePopup(item)"
                      >Delete</v-list-item-title
                      >
                    </v-list-item>

                    <v-list-item link @click="openProducts(item)">
                      <v-list-item-title
                          style="cursor: pointer"
                      >
                        List promos
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
            </tbody>
            <tbody v-if="items.length === 0">
            <tr>
              <td class="d-block d-sm-table-cell"
                  colspan="4"
                  style="text-align: center; padding: 0 15px; font-weight: normal"
              >
                You don't have categories yet, it's time to create them
              </td>
            </tr>
            </tbody>
          </template>
        </v-data-table>
      </div>
      <div style="margin-bottom: 15px" v-if="styles">
        <div style="margin-bottom:15px;">
          <div class="control">
            <label class="label" style="font-weight: bold;font-size:20px;">Name Settings</label>
          </div>
          <div style="padding:0 15px;">
            <div style="display: flex;align-items: center;margin-bottom: 15px;">
              <label class="label" style="width:200px;margin-bottom: 0;">
                Font Color
              </label>
              <div class="control">
                <v-menu
                    top
                    nudge-left="16"
                    nudge-bottom="98"
                    :close-on-content-click="false"
                    v-model="show_text_color_slider"
                >
                  <template v-slot:activator="{ on }">
                    <div
                        class="custom__menu--color"
                        :style="`background-color:${landing.branding.segment_products_styles.category.categorySlider.name.color}`"
                        v-on="on"
                    />
                  </template>
                  <v-card>
                    <v-card-text class="pa-0">
                      <v-color-picker
                          v-model="landing.branding.segment_products_styles.category.categorySlider.name.color"
                          flat
                      />
                    </v-card-text>
                  </v-card>
                </v-menu>
              </div>
            </div>
            <div style="display: flex;align-items: center;margin-bottom: 15px;">
              <label class="label" style="width:200px;margin-bottom: 0;">
                Font Size <b>{{ landing.branding.segment_products_styles.category.categorySlider.name.font_size }}px</b>
              </label>
              <div class="slider" style="width:80%;">
                <v-slider
                    hide-details
                    :max="40"
                    :min="10"
                    v-model="landing.branding.segment_products_styles.category.categorySlider.name.font_size"
                ></v-slider>
              </div>
            </div>
            <div style="display: flex;align-items: center;margin-bottom: 15px;">
              <label class="label" style="width:200px;margin-bottom: 0;">
                Font Weight <b>{{ parseInt(landing.branding.segment_products_styles.category.categorySlider.name.font_weight, 10) / 10 }}%</b>
              </label>
              <div class="slider" style="width:80%;">
                <v-slider
                    hide-details
                    :max="1000"
                    :min="100"
                    v-model="landing.branding.segment_products_styles.category.categorySlider.name.font_weight"
                    :step="100"
                ></v-slider>
              </div>
            </div>
            <div style="display: flex;justify-content: space-between;">
              <div style="display: flex;align-items: center;margin-bottom: 15px;">
                <label class="label" style="width:200px;margin-bottom: 0;">
                  Font Family
                </label>
                <v-select
                    flat
                    dense
                    outlined
                    hide-details
                    placeholder="Select Font Family"
                    :items="fontFamilyTypes"
                    item-text="label"
                    item-value="value"
                    class="canvas__select"
                    v-model="landing.branding.segment_products_styles.category.categorySlider.name.font_family"
                    style="max-width:250px;"
                >
                  <template v-slot:selection="{ item }">
                    <div :style="`font-family:'${item.value}',sans-serif`">
                      {{ item.label }}
                    </div>
                  </template>
                  <template v-slot:item="{ item }">
                    <div :style="`font-family:'${item.value}',sans-serif`">
                      {{ item.label }}
                    </div>
                  </template>
                </v-select>
              </div>
            </div>
          </div>
        </div>
        <div style="margin-bottom:15px;">
          <div class="control">
            <label class="label" style="font-weight: bold;font-size:20px;">Image Settings</label>
          </div>
          <div style="padding:0 15px;">
            <div style="display: flex;align-items: center;margin-bottom: 15px;">
              <label class="label" style="width:200px;margin-bottom: 0;">
                Height <b>{{ landing.branding.segment_products_styles.category.categorySlider.icon.height }}px</b>
              </label>
              <div class="slider" style="width:80%;">
                <v-slider
                    hide-details
                    :max="150"
                    :min="10"
                    v-model="landing.branding.segment_products_styles.category.categorySlider.icon.height"
                ></v-slider>
              </div>
            </div>
            <div style="display: flex;align-items: center;margin-bottom: 15px;">
              <label class="label" style="width:200px;margin-bottom: 0;">
                Width <b>{{ landing.branding.segment_products_styles.category.categorySlider.icon.width }}px</b>
              </label>
              <div class="slider" style="width:80%;">
                <v-slider
                    hide-details
                    :max="150"
                    :min="10"
                    v-model="landing.branding.segment_products_styles.category.categorySlider.icon.width"
                ></v-slider>
              </div>
            </div>
          </div>
        </div>
        <div style="margin-bottom:15px;">
          <div class="control">
            <label class="label" style="font-weight: bold;font-size:20px;">Slider Block Settings</label>
          </div>
          <div style="padding:0 15px;">
            <div style="display: flex;align-items: center;margin-bottom: 15px;">
              <label class="label" style="width:200px;margin-bottom: 0;">
                Height <b>{{ landing.branding.segment_products_styles.category.categorySlider.block.height }}px</b>
              </label>
              <div class="slider" style="width:80%;">
                <v-slider
                    hide-details
                    :max="600"
                    :min="100"
                    v-model="landing.branding.segment_products_styles.category.categorySlider.block.height"
                ></v-slider>
              </div>
            </div>
            <div style="display: flex;align-items: center;margin-bottom: 15px;">
              <label class="label" style="width:200px;margin-bottom: 0;">
                Width <b>{{ landing.branding.segment_products_styles.category.categorySlider.block.width }}px</b>
              </label>
              <div class="slider" style="width:80%;">
                <v-slider
                    hide-details
                    :max="600"
                    :min="100"
                    v-model="landing.branding.segment_products_styles.category.categorySlider.block.width"
                ></v-slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="page === 'products'">
      <ProductsList @openCategoryList="openCategoryList"/>
    </div>
    <div v-if="page==='cartSettings'&&cartSettings&&segmentProductCategory">
      <div style="
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
      " >
        <div style="flex:1;">
          <v-btn
              class="object__submit btn btn_primary"
              color="primary"
              outlined
              small
              solo
              @click="closeCartSettings"
          >
            <v-icon>mdi-chevron-left</v-icon> Back to category list
          </v-btn>
        </div>
      </div>
      <div style="
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
      " >
        <div class="label"> Cart settings</div>
      </div>
      <div style="margin-bottom: 15px;">
        <div style="display: flex;flex-direction: column;margin-bottom: 15px;">
          <label class="label" style="margin-bottom: 15px;">Emails for send order</label>
          <div v-for="(email,emailIndex) in arrEmails" :key="emailIndex">
            <v-text-field v-model="segmentProductCategory.settings.emails[email]" dense outlined style="max-width: 400px;" append-outer-icon="mdi-close-circle-outline" @click:append-outer="deleteEmail(emailIndex,segmentProductCategory.settings.emails)"></v-text-field>
          </div>
          <v-btn color="primary" small outlined @click="addEmail(segmentProductCategory.settings.emails)" style="max-width: 150px;" >+Add email</v-btn>
        </div>
        <div style="display: flex;flex-direction: column;">
          <label class="label" style="margin-bottom: 15px;">Telegram users for send order</label>
          <div v-for="user in arrTelegrams" :key="user">
            <v-select style="max-width: 400px;" :value="segmentProductCategory.settings.telegrams[user]" item-text="first_name" item-disabled="disabled" item-value="id" return-object :items="telegramUsersAvaliable(segmentProductCategory.settings.telegrams[user],segmentProductCategory.settings.telegrams)"  dense outlined append-outer-icon="mdi-close-circle-outline" @click:append-outer="deleteUser(user,segmentProductCategory.settings.telegrams)">
              <template slot="selection" slot-scope="data">
                {{data.item.first_name}} {{data.item.last_name}}
              </template>
              <template v-slot:item="{ item }">
                {{item.first_name}} {{item.last_name}}
              </template>
            </v-select>
          </div>
          <v-btn color="primary" small outlined @click="addUser(segmentProductCategory.settings.telegrams,enabledTelegramUsers)" style="max-width: 150px;" :disabled="enabledTelegramUsers.length === 0">+Add user</v-btn>
          <div v-if="enabledTelegramUsers.length === 0">
            You need create more users <span @click="goToTelegramUsers" style="color:#007aff;cursor: pointer;">here</span>
          </div>
        </div>
      </div>
      <v-btn
          small
          outlined
          style="margin-bottom: 10px; max-width: 360px"
          color="primary"
          @click="editCategoryConfirm(segmentProductCategory)"
      >Save</v-btn
      >
    </div>
    <PopupError :title="error" ref="PopupError" />
    <PopupDelete
        ref="PopupDeleteCategory"
        :title="`Are your sure about delete ${deleteItem?deleteItem.name[landing.current_lang]:''}?`"
        @confirm="deleteCategory"
    />
    <PopupSuccess ref="PopupSuccess" :title="'Success!'" :content="successMessage" />
    <v-dialog v-model="dialogCreateCategory" v-if="createdCategory" max-width="750">
      <v-card>
        <v-card-title
        ><span>Create category</span>
          <v-icon
              style="position: absolute; top: 10px; right: 10px"
              @click="closeDialogCreate"
          >mdi-close</v-icon
          ></v-card-title
        >
        <div style="padding: 30px">
          <v-tabs v-model="activeLang" style="margin-bottom: 30px">
            <v-tab
                v-for="(lang, i) in landing.selected_langs"
                :key="i"
                @change="changeLang(lang)"
            >
              <img
                  :src="landing.advanced.langs.find((x) => x.value === lang).flag"
                  alt=""
                  style="width: 20px; height: 15px; margin-right: 5px"
              />
              {{ lang }}
            </v-tab>
            <v-tab-item
                style="padding-top: 15px"
                v-for="(lang, i) in landing.selected_langs"
                :key="i"
            >
              <div class="label" style="margin-bottom: 15px">Category name</div>
              <v-text-field
                  outlined
                  dense
                  hide-details
                  style="max-width: 50%"
                  v-model="createdCategory.name[lang]"
                  placeholder="Your category name"
              ></v-text-field>
              <div
                  style="
              display: flex;
              padding-top: 15px;
              align-items: center;
              justify-content: space-evenly;
            "
              >
                <div style="flex: 1; margin-bottom: 15px">
                  <div class="label" style="margin-bottom: 10px">
                    Choose your image
                  </div>
                  <vue-dropzone
                      id="createDropzone"
                      :options="dropzoneOptions"
                      :destroyDropzone="false"
                      ref="createDropzone"
                      @vdropzone-sending="customEvent"
                      @vdropzone-success="handleSuccess"
                      @vdropzone-removed-file="handleRemove"
                  >
                  </vue-dropzone>
                </div>
              </div>
            </v-tab-item>
          </v-tabs>

        </div>

        <v-card-actions class="justify-end">
          <v-btn
              small
              outlined
              style="margin-bottom: 10px; max-width: 360px"
              color="primary"
              @click="createNewCategory"
          >Create</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogEditCategory" v-if="editCategory" eager max-width="750">
      <v-card>
        <v-card-title
        ><span>Edit category</span>
          <v-icon
              style="position: absolute; top: 10px; right: 10px"
              @click="closeDialogEdit"
          >mdi-close</v-icon
          ></v-card-title
        >
        <div style="padding: 30px">
          <v-tabs v-model="activeLang" style="margin-bottom: 30px">
            <v-tab
                v-for="(lang, i) in landing.selected_langs"
                :key="i"
                @change="changeLang(lang)"
            >
              <img
                  :src="landing.advanced.langs.find((x) => x.value === lang).flag"
                  alt=""
                  style="width: 20px; height: 15px; margin-right: 5px"
              />
              {{ lang }}
            </v-tab>
            <v-tab-item
                style="padding-top: 15px"
                v-for="(lang, i) in landing.selected_langs"
                :key="i"
            >
              <div class="label" style="margin-bottom: 15px">Category name</div>
              <v-text-field
                  outlined
                  dense
                  hide-details
                  style="max-width: 50%"
                  v-model="editCategory.name[lang]"
                  placeholder="Your category name"
              ></v-text-field>
              <div
                  style="
              display: flex;
              padding-top: 15px;
              align-items: center;
              justify-content: space-evenly;
            "
              >
                <div style="flex: 1; margin-bottom: 15px">
                  <div class="label" style="margin-bottom: 10px">
                    Choose your image
                  </div>
                  <vue-dropzone
                      id="editDropzone"
                      :options="dropzoneOptions"
                      :destroyDropzone="false"
                      :ref="`editDropzone_${lang}`"
                      @vdropzone-mounted="dropzoneEditMount(lang)"
                      @vdropzone-sending="customEvent"
                      @vdropzone-success="handleSuccessEdit"
                  >
                  </vue-dropzone>
                </div>
              </div>
            </v-tab-item>
          </v-tabs>

        </div>

        <v-card-actions class="justify-end">
          <v-btn
              small
              outlined
              style="margin-bottom: 10px; max-width: 360px"
              color="primary"
              @click="editCategoryConfirm(editCategory)"
          >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import {mapState,mapActions,mapMutations} from 'vuex'
  import vueDropzone from "vue2-dropzone";
  import "vue2-dropzone/dist/vue2Dropzone.min.css";
  import ProductsList from "./PromoProducts/ProductsList";
  export default {
    name: "PromoSettings",
    components:{
      ProductsList,
      PopupDelete:() => import('@/components/Popup/PopupDelete'),
      PopupError:() => import('@/components/Popup/PopupError'),
      PopupSuccess:() => import('@/components/Popup/PopupSuccess'),
      vueDropzone,
    },
    data(){
      return{
        cartSettings:false,
        fontFamilyTypes: [
          { id: 0, value: "Arial", label: "Arial" },
          { id: 1, value: "SF UI Text", label: "SF UI Text" },
          { id: 2, value: "Proxima Nova Bl", label: "Proxima Nova Bl" },
          { id: 3, value: "Myriad Pro", label: "Myriad Pro" },
          { id: 4, value: "HelveticaNeueCyr", label: "HelveticaNeueCyr" },
          { id: 5, value: "Gotham Pro", label: "Gotham Pro" },
          { id: 6, value: "Geometria", label: "Geometria" },
          { id: 7, value: "DIN Pro", label: "DIN Pro" },
          { id: 8, value: "Circe", label: "Circe" },
          { id: 9, value: "Bebas Neue", label: "Bebas Neue" },
          { id: 10, value: "Acrom", label: "Acrom" },
          { id: 11, value: "Inter", label: "Inter" },
        ],
        show_text_color_slider:false,
        dropzoneOptions: {
          url: () => {
            return process.env.VUE_APP_API_URL + "upload-image";
          },
          maxfiles: 3,
          thumbnailMethod: "contain",
          acceptedfiles: "image/*",
          addRemoveLinks: true,
        },
        styles:false,
        successMessage:'',
        error:"",
        page:'categories',
        headers: [
          { text: "Name", value: "name", align: "center", sortable: true },
          {
            text: "Promo count",
            value: "products",
            align: "center",
            sortable: true,
          },
          {
            text: "Status",
            value: "active",
            align: "center",
            sortable: true,
          },
          { text: "Options", align: "center", sortable: true },
        ],
        deleteItem:null,
        editCategory:null,
        dialogEditCategory:false,
        createdCategory:null,
        dialogCreateCategory:false,
        defaultCategory:{
          name: { en: "" },
          hotel_id: "",
          active:1,
          image:{en:""},
          settings:{
            emails:[],
            telegrams:[]
          }
        }
      }
    },
    computed:{
      ...mapState('segmentProducts',['segmentProductCategories','segmentProductCategory']),
      ...mapState(['landing']),
      activeLang: {
        set: function (val) {},
        get: function () {
          return this.landing.advanced.selected_langs.indexOf(
            this.landing.current_lang
          );
        },
      },
      arrTelegrams(){
        let arr = []
        for(let i = 0;i<this.segmentProductCategory.settings.telegrams.length;i++){
          arr.push(i);
        }
        return arr
      },
      arrEmails(){
        let arr = []
        for(let i = 0;i<this.segmentProductCategory.settings.emails.length;i++){
          arr.push(i);
        }
        return arr
      },
      enabledTelegramUsers(){
        return this.$store.state.telegramUsers .reduce((acc,item) => {
          const candidateIndex = this.segmentProductCategory.settings.telegrams.find(x => x.id === item.id);
          if(!candidateIndex){
            acc.push(item);
          }
          return acc
        },[])
      },
    },
    methods:{
      ...mapActions('segmentProducts',['getSegmentProductCategories','postSegmentProductCategory','putSegmentProductCategory','deleteSegmentProductCategory']),
      ...mapMutations('segmentProducts',['setSegmentProductCategory']),
      addEmail(arr){
        arr.push("");
      },
      deleteEmail(index,arr){
        arr.splice(index,1);
      },
      telegramUsersAvaliable(user,arr){
        return this.$store.state.telegramUsers.reduce((acc,item) => {
          if(user){
            if(item.id === user.id){
              acc.push(item);
            }
          }
          if (!arr.some(e => e.telegram_key === item.telegram_key)) {
            acc.push(item);
          }
          return acc
        },[])
      },
      goToTelegramUsers(){
        this.$store.state.tab = 4;
        this.landing.order.tab = 6;
        this.$store.commit('setPage','order');
      },
      closeCartSettings(){
        this.cartSettings = false;
        this.page = 'categories';
        this.setSegmentProductCategory(null);
      },
      addUser(arr,enabledArr){
        let item = JSON.parse(JSON.stringify(enabledArr[0]));
        item.disabled = true;
        arr.push(item);
      },
      openCartSettings(item){
        if(!item.settings){
          item.settings = {
            emails:[],
            telegrams:[]
          };
        }
        this.setSegmentProductCategory(item);
        this.cartSettings = true;
        this.page = `cartSettings`;
      },
      deleteUser(index,arr){
        arr.splice(index,1);
      },
      openCategoryList(){
        this.page = 'categories';
        this.setSegmentProductCategory(null);
      },
      openProducts(item){
        this.setSegmentProductCategory(item);
        this.page = 'products';
      },
      dropzoneEditMount(lang){
        if(this.$refs[`editDropzone_${lang}`]){
          this.$refs[`editDropzone_${lang}`][0].removeAllFiles();
          if(this.editCategory.image[lang]){
            let mockFile = {
              accepted: true,
              kind: "image",
              preview:true
            };
            this.$refs[`editDropzone_${lang}`][0].manuallyAddFile(mockFile, this.editCategory.image[lang]);
            this.$refs[`editDropzone_${lang}`][0].dropzone.emit("thumbnail", mockFile, this.editCategory.image[lang]);
            this.$refs[`editDropzone_${lang}`][0].dropzone.emit("complete", mockFile);
          }
        }
      },
      async editCategoryConfirm(category){
        await this.putSegmentProductCategory(category);
        this.successMessage = `Category ${category.name[this.landing.current_lang]} edited successfully!`;
        this.dialogEditCategory = false;
        this.$refs.PopupSuccess.open();
      },
      closeDialogEdit(){
        this.editCategory = false;
      },
      async createNewCategory(){
        this.createdCategory.hotel_id = +this.$route.params.id;
        await this.postSegmentProductCategory(this.createdCategory);
        this.successMessage = 'New category created successfully!';
        this.dialogCreateCategory = false;
        this.$refs.PopupSuccess.open();
      },
      handleSuccessEdit(file){
        this.editCategory.image[this.landing.current_lang] = file.xhr.response;
      },
      openEdit(item){
        this.editCategory = JSON.parse(JSON.stringify(item));
        this.dialogEditCategory = true;
        this.$nextTick(() => {
          for(let lang in item.image){
            if(item.image[lang] && this.$refs[`editDropzone_${lang}`]){
              let mockFile = {
                accepted: true,
                kind: "image",
              };
              this.$refs[`editDropzone_${lang}`][0].dropzone.removeAllFiles();
              this.$refs[`editDropzone_${lang}`][0].manuallyAddFile(mockFile, item.image[lang]);
              this.$refs[`editDropzone_${lang}`][0].dropzone.emit("thumbnail", mockFile, item.image[lang]);
              this.$refs[`editDropzone_${lang}`][0].dropzone.emit("complete", mockFile);
            }
          }
        });
      },
      handleSuccess(file){
        this.createdCategory.image[this.landing.current_lang] = file.xhr.response;
      },
      handleRemove(){
        this.createdCategory.image[this.landing.current_lang] = "";
      },
      async customEvent(_file, xhr, formData) {
        xhr.setRequestHeader(
          "Authorization",
          `Bearer ${this.$store.state.token}`
        );
        formData.append("lending_settings_id", this.landing.settings_id);
        formData.append("image", _file);
      },
      changeLang(lang) {
        this.$store.commit("landing/setCurrentLang", lang);
        if(this.$refs[`editDropzone_${lang}`]) {
          this.dropzoneEditMount(lang);
        }
      },
      openDeletePopup(item){
        this.deleteItem = JSON.parse(JSON.stringify(item));
        this.$refs.PopupDeleteCategory.open();
      },
      async deleteCategory(){
        await this.deleteSegmentProductCategory(this.deleteItem.id);
        this.successMessage = `Category ${this.deleteItem.name[this.landing.current_lang]} deleted successfully`;
        this.$refs.PopupSuccess.open();
      },
      openStyles() {
        this.styles = !this.styles;
      },
      async createProductCategory() {
        this.createdCategory = JSON.parse(JSON.stringify(this.defaultCategory));
        this.dialogCreateCategory = true;
      },
      closeDialogCreate() {
        this.dialogCreateCategory = false;
      },
      async changeCategoryStatus(item) {
        try {
          if (item.active) {
            item.active = 0;
          } else {
            item.active = 1;
          }
          await this.putSegmentProductCategory(item);
          this.successMessage = `Category ${item.name[this.landing.current_lang]} ${item.active?'activated':'deactivated'} successfully`;
          this.$refs.PopupSuccess.open();
        } catch (e) {
          this.error = e.message;
          this.$refs.PopupError.open();
        }
      },
    },
    async mounted(){
      await this.getSegmentProductCategories(this.$route.params.id);
    }
  }
</script>

<style scoped>

</style>
