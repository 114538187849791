import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"20%","display":"flex","flex-direction":"column","margin-right":"1%","margin-bottom":"15px","border":"1px solid #ccc","border-radius":"10px"}},[_c('div',{staticStyle:{"display":"flex","align-items":"center","justify-content":"space-between","padding":"15px 15px 30px 15px","border-bottom":"1px solid #ccc"}},[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('div',{staticStyle:{"font-size":"18px","font-weight":"bold","margin-right":"15px"}},[_vm._v(" "+_vm._s(_vm.segment.name)+" ")])]),_c('div',{staticStyle:{"display":"flex","justify-content":"space-evenly","align-items":"center"}},[_c('div',[_c(VSwitch,{staticStyle:{"padding":"0","margin":"0"},attrs:{"hide-details":"","value":_vm.segment.active},on:{"change":function($event){return _vm.toggleActiveSwitch(_vm.segment)}},model:{value:(_vm.segment.active),callback:function ($$v) {_vm.$set(_vm.segment, "active", $$v)},expression:"segment.active"}})],1),_c(VMenu,{attrs:{"offset-y":"","open-on-hover":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"primary","dark":"","small":"","icon":""}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"size":"30"}},[_vm._v("mdi-cog")])],1)]}}])},[_c(VList,[_c(VListItem,{attrs:{"link":""},on:{"click":function($event){return _vm.$emit('editSegment',_vm.segment)}}},[_c(VListItemTitle,[_vm._v("Edit")])],1),_c(VListItem,{attrs:{"link":""},on:{"click":function($event){return _vm.toggleActive(_vm.segment)}}},[_c(VListItemTitle,[_vm._v(_vm._s(_vm.segment.active?'Disable':'Active'))])],1),_c(VListItem,{attrs:{"link":""}},[_c(VListItemTitle,{staticStyle:{"color":"red"},on:{"click":function($event){return _vm.$emit('deleteSegment',_vm.segment)}}},[_vm._v("Delete")])],1)],1)],1)],1)]),_c('div',{staticStyle:{"padding":"15px"}},[_c('div',{staticStyle:{"display":"flex","padding":"5px","border-radius":"5px","font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.segment.small_description)+" ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }