import $axios from "../plugins/axios";

const LangService = {
    async addLanguageProducts(payload){
        try{
            const {data} = await $axios.post(`add-language-products`,payload);
            return data;
        }catch (e) {
            return e;
        }
    },
    async addLanguageEvents(payload){
        try{
            const {data} = await $axios.post(`add-language-events`,payload);
            return data;
        }catch (e) {
            return e;
        }
    },
    async addLanguagePoints(payload){
        try{
            const {data} = await $axios.post(`add-language-points`,payload);
            return data;
        }catch (e) {
            return e;
        }
    },
    async addLanguageRoute(payload){
        try{
            const {data} = await $axios.post(`add-language-routes`,payload);
            return data;
        }catch (e) {
            return e;
        }
    },
    async removeLanguageProducts(payload){
        try{
            const {data} = await $axios.post(`remove-language-products`,payload);
            return data;
        }catch (e) {
            return e;
        }
    },
    async removeLanguageEvents(payload){
        try{
            const {data} = await $axios.post(`remove-language-events`,payload);
            return data;
        }catch (e) {
            return e;
        }
    },
    async removeLanguagePoints(payload){
        try{
            const {data} = await $axios.post(`remove-language-points`,payload);
            return data;
        }catch (e) {
            return e;
        }
    },
    async removeLanguageRoutes(payload){
        try{
            const {data} = await $axios.post(`remove-language-routes`,payload);
            return data;
        }catch (e) {
            return e;
        }
    }
};
export default LangService;