import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VColorPicker } from 'vuetify/lib/components/VColorPicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSlideGroup } from 'vuetify/lib/components/VSlideGroup';
import { VSlideItem } from 'vuetify/lib/components/VSlideGroup';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"form"},[_c('div',{staticClass:"field"},[_c('label',{staticClass:"label",staticStyle:{"display":"block","margin-bottom":"15px","font-weight":"bold","font-size":"20px"}},[_vm._v("Background settings")]),_c('p',{staticClass:"label",staticStyle:{"display":"block","margin-bottom":"15px"}},[_vm._v("Upload image")]),_c('div',{staticClass:"control",staticStyle:{"margin-bottom":"15px"}},[_c('vue-dropzone',{ref:"upload",attrs:{"id":"upload","options":_vm.dropzoneOptions,"destroyDropzone":false},on:{"vdropzone-mounted":_vm.mountDropzone,"vdropzone-sending":_vm.customEvent,"vdropzone-removed-file":_vm.handleRemove,"vdropzone-success":_vm.handleSuccess}})],1)]),_c('div',{staticClass:"field",staticStyle:{"display":"flex","align-items":"center","margin-bottom":"15px"}},[_c('label',{staticClass:"label",staticStyle:{"margin-bottom":"0","width":"200px"}},[_vm._v("Background color")]),_c(VMenu,{attrs:{"top":"","nudge-bottom":"98","nudge-left":"16","z-index":"21","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({style:(_vm.swatchBgStyle)},on))]}}]),model:{value:(_vm.menu_2),callback:function ($$v) {_vm.menu_2=$$v},expression:"menu_2"}},[_c(VCard,[_c(VCardText,{staticClass:"pa-0"},[_c(VColorPicker,{attrs:{"flat":""},on:{"input":_vm.setCanvas},model:{value:(_vm.branding.bg_color),callback:function ($$v) {_vm.$set(_vm.branding, "bg_color", $$v)},expression:"branding.bg_color"}})],1)],1)],1)],1)]),_c('div',{staticStyle:{"margin-bottom":"15px","overflow":"hidden"}},[_c('label',{staticClass:"label",staticStyle:{"font-weight":"bold","font-size":"20px","display":"block","margin-bottom":"15px"}},[_vm._v("Templates")]),_c(VSlideGroup,{attrs:{"show-arrows":"","center-active":""}},_vm._l((_vm.background_templates),function(i){return _c(VSlideItem,{key:i,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c(VBtn,{staticClass:"mx-2",staticStyle:{"height":"200px","width":"150px"},attrs:{"input-value":active,"active-class":"primary white--text active__lining","depressed":"","small":""},on:{"click":toggle}},[_c('img',{staticStyle:{"height":"200px","width":"150px"},attrs:{"src":(_vm.imgLink + "/background_templates/" + (i + 1) + ".jpg"),"alt":""},on:{"click":function($event){return _vm.setImg((_vm.imgLink + "/background_templates/" + (i + 1) + ".jpg"))}}})])]}}],null,true)})}),1)],1),_c('PopupCrop',{ref:"PopupCrop",attrs:{"title":"Change image before upload?"},on:{"confirm":_vm.openEditor,"save":_vm.saveImg,"close":_vm.closeCrop}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }