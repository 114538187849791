<template>
  <div>
    <div style="display: flex;align-items: center;margin-bottom: 15px;" :style="`justify-content:${styles?'space-between':'flex-end'};`">
      <div v-if="styles" style="display: flex; align-items: center;">
        <v-btn color="primary" outlined  @click="openStyles" small
        ><v-icon>mdi-chevron-left</v-icon>
          <span>Back to promo list</span></v-btn
        >
      </div>
      <v-menu offset-y open-on-hover bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              color="primary"
              dark
              outlined
              small
              v-bind="attrs"
              v-on="on"
          >
            <v-icon>mdi-cog</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-title style="cursor: pointer"
                               @click="openStyles"
            > {{styles ?'Content settings' : 'Style settings'}}</v-list-item-title
            >
          </v-list-item>
          <v-list-item>
            <v-list-item-title style="cursor: pointer"
            >Cart settings</v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <div
        v-if="!styles"
        style="
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
      "
    >
      <div>
        <v-btn color="primary" outlined small @click="$emit('openCategoryList')"
        ><v-icon>mdi-chevron-left</v-icon>
          <span
          >Back to categories list</span
          ></v-btn
        >
      </div>
      <v-btn
          class="object__submit btn btn_primary"
          style="float: right"
          color="primary"
          small
          outlined
          solo
          @click="createProductOpen"
      >
        Create promo
      </v-btn>
    </div>
    <div style="margin-bottom: 15px" v-if="!styles">
      <v-data-table
          :headers="headersProduct"
          hide-default-footer
          :items="segmentProductCategory.products"
          class="table"
          :items-per-page="-1"
      >
        <template v-slot:body="{ items }">
          <tbody v-if="items.length">
          <tr v-for="item in items" :key="item.id">
            <td class="d-block d-sm-table-cell" style="text-align: center">
              <img
                  :src="
                    item.main_image
                      ? item.main_image
                      : require('@/assets/none.png')
                  "
                  alt=""
                  style="width: 50px; height: 50px"
              />
            </td>
            <td class="d-block d-sm-table-cell" style="text-align: center">
              {{ item.name[landing.current_lang] }}
            </td>
            <td class="d-block d-sm-table-cell" style="text-align: center">
              {{ item.price }} {{landing.advanced.global.currency}}
            </td>
            <td class="d-block d-sm-table-cell" style="text-align: center">
              {{ item.discount_price }} {{landing.advanced.global.currency}}
            </td>
            <td class="d-block d-sm-table-cell" style="text-align: center">
              <v-chip
                  class="ma-2"
                  :color="item.active ? 'green' : 'red'"
                  text-color="white"
                  style="cursor: pointer"
                  @click="changeProductStatus(item)"
              >
                {{ item.active ? "Active" : "Inactive" }}
              </v-chip>
            </td>
            <td class="d-block d-sm-table-cell" style="text-align: center">
              <v-menu offset-y open-on-hover bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" dark v-bind="attrs" v-on="on" outlined small>
                    <v-icon>mdi-cog</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item link @click="openEdit(item)"
                      style="cursor: pointer"
                  >
                    <v-list-item-title>Edit</v-list-item-title>
                  </v-list-item>
                  <v-list-item link @click="openDelete(item)">
                    <v-list-item-title
                        style="cursor: pointer; color: red"
                    >Delete</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
          </tbody>
          <tbody v-if="items.length === 0">
          <tr>
            <td class="d-block d-sm-table-cell"
                colspan="7"
                style="text-align: center; padding: 0 15px; font-weight: normal"
            >
              You don't have promos yet, it's time to create them
            </td>
          </tr>
          </tbody>
        </template>
      </v-data-table>
    </div>
    <div style="margin-bottom: 15px"  v-if="styles">

      <v-tabs v-model="active_tab">
        <v-tab>Promo list page settings</v-tab>
        <v-tab>Promo detail page settings</v-tab>
        <v-tab-item style="padding-left:15px;padding-top:15px;">
          <div>
            <div style="margin-bottom:15px;">
              <div class="control">
                <label class="label" style="font-weight: bold;font-size:20px;">Promo list block settings</label>
              </div>
              <div style="padding:0 15px;">
                <div style="display:flex;align-items:center;margin-bottom:15px;">
                  <label class="label" style="width:200px;margin-bottom:0;">
                    Width <b>{{ landing.branding.segment_products_styles.product.width }}%</b>
                  </label>
                  <div class="slider" style="width:80%;">
                    <v-slider
                        hide-details
                        :max="100"
                        :min="33"
                        v-model="landing.branding.segment_products_styles.product.width"
                    ></v-slider>
                  </div>
                </div>
                <div style="display:flex;align-items:center;margin-bottom:15px;">
                  <label class="label" style="width:200px;margin-bottom:0;">
                    Height <b>{{ landing.branding.segment_products_styles.product.height }}%</b>
                  </label>
                  <div class="slider" style="width:80%;">
                    <v-slider
                        hide-details
                        :max="600"
                        :min="30"
                        v-model="landing.branding.segment_products_styles.product.height"
                    ></v-slider>
                  </div>
                </div>
              </div>
            </div>
            <div style="margin-bottom:15px;">
              <div class="control">
                <label class="label" style="font-weight: bold;font-size:20px;">Name settings</label>
              </div>
              <div style="padding:0 15px;">
                <div style="display: flex;align-items: center;margin-bottom: 15px;">
                  <label class="label" style="width:200px;margin-bottom:0;">
                    Font Color
                  </label>
                  <div class="control">
                    <v-menu
                        top
                        nudge-left="16"
                        nudge-bottom="98"
                        :close-on-content-click="false"
                        v-model="show_text_color"
                    >
                      <template v-slot:activator="{ on }">
                        <div
                            class="custom__menu--color"
                            :style="`background-color:${landing.branding.segment_products_styles.product.color}`"
                            v-on="on"
                        />
                      </template>
                      <v-card>
                        <v-card-text class="pa-0">
                          <v-color-picker
                              v-model="landing.branding.segment_products_styles.product.color"
                              flat
                          />
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </div>
                </div>
                <div style="display:flex;align-items:center;margin-bottom:15px;">
                  <label class="label" style="width:200px;margin-bottom:0;">
                    Font Size <b>{{ landing.branding.segment_products_styles.product.fontSize }}px</b>
                  </label>
                  <div class="slider" style="width:80%;">
                    <v-slider
                        hide-details
                        :max="40"
                        :min="10"
                        v-model="landing.branding.segment_products_styles.product.fontSize"
                    ></v-slider>
                  </div>
                </div>
                <div style="display:flex;align-items:center;margin-bottom:15px;">
                  <label class="label" style="width:200px;margin-bottom:0;">
                    Font Weight <b>{{ parseInt(landing.branding.segment_products_styles.product.fontWeight, 10) / 10 }}%</b>
                  </label>
                  <div class="slider" style="width:80%;">
                    <v-slider
                        hide-details
                        :max="1000"
                        :min="100"
                        v-model="landing.branding.segment_products_styles.product.fontWeight"
                        :step="100"
                    ></v-slider>
                  </div>
                </div>
                <div style="display: flex;justify-content: space-between;">
                  <div style="display:flex;align-items:center;margin-bottom:15px;">
                    <label class="label" style="width:200px;margin-bottom:0;">
                      Font Style <b>{{ landing.branding.segment_products_styles.product.fontStyle }}</b>
                    </label>
                    <div class="control">
                      <v-radio-group hide-details row v-model="landing.branding.segment_products_styles.product.fontStyle">
                        <v-radio
                            v-for="(type,typeIndex) in fontStyleTypes"
                            :key="type.id"
                            :label="type.label"
                            :value="type.value"
                            :style="`margin-left:${typeIndex?'15px':0}`"
                        >
                        </v-radio>
                      </v-radio-group>
                    </div>
                  </div>
                  <div style="display:flex;align-items:center;margin-bottom:15px;">
                    <label class="label" style="width:200px;margin-bottom:0;">
                      Font Family
                    </label>

                    <v-select
                        flat
                        dense
                        outlined
                        hide-details
                        placeholder="Select Font Family"
                        :items="fontFamilyTypes"
                        item-text="label"
                        item-value="value"
                        class="canvas__select"
                        v-model="landing.branding.segment_products_styles.product.fontFamily"
                        style="max-width:250px;"
                    >
                      <template v-slot:selection="{ item }">
                        <div :style="`font-family:'${item.value}',sans-serif`">
                          {{ item.label }}
                        </div>
                      </template>
                      <template v-slot:item="{ item }">
                        <div :style="`font-family:'${item.value}',sans-serif`">
                          {{ item.label }}
                        </div>
                      </template>
                    </v-select>
                  </div>
                </div>
              </div>
            </div>
            <div style="margin-bottom:15px;">
              <div class="control" >
                <label class="label" style="font-weight: bold;font-size:20px;">Image Settings</label>
              </div>
              <div style="padding:0 15px;">
                <div
                    class="control"
                    style="display:flex;align-items:center;margin-bottom:15px;"
                >
                  <label class="label" style="margin-bottom: 0;width:200px;">Show Image</label>
                  <v-switch v-model="landing.branding.segment_products_styles.product.show_icon" hide-details>
                  </v-switch>
                </div>
                <div v-if="landing.branding.segment_products_styles.product.show_icon">
                  <div style="display:flex;align-items:center;margin-bottom:15px;">
                    <label class="label" style="width:200px;margin-bottom:0;">
                      Height <b>{{ landing.branding.segment_products_styles.product.img_height }}</b> px</label>
                    <div class="slider" style="width:80%;">
                      <v-slider
                          hide-details
                          :min="10"
                          :max="600"
                          v-model="landing.branding.segment_products_styles.product.img_height"
                      ></v-slider>
                    </div>
                  </div>
                  <div style="display:flex;align-items:center;margin-bottom:15px;">
                    <label class="label" style="width:200px;margin-bottom:0;">
                      Width <b>{{ landing.branding.segment_products_styles.product.img_width }}</b> %</label>
                    <div class="slider" style="width:80%;">
                      <v-slider
                          hide-details
                          :min="10"
                          :max="100"
                          v-model="landing.branding.segment_products_styles.product.img_width"
                      ></v-slider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style="margin-bottom:15px;">
              <div class="control" >
                <label class="label" style="font-weight: bold;font-size:20px;">Price settings</label>
              </div>
              <div style="padding:0 15px;">
                <div style="display: flex;align-items: center;margin-bottom: 15px;">
                  <label class="label" style="width:200px;margin-bottom:0;">
                    Font Color
                  </label>
                  <div class="control">
                    <v-menu
                        top
                        nudge-left="16"
                        nudge-bottom="98"
                        :close-on-content-click="false"
                        v-model="show_text_price_color"
                    >
                      <template v-slot:activator="{ on }">
                        <div
                            class="custom__menu--color"
                            :style="`background-color:${landing.branding.segment_products_styles.product.colorPrice};`"
                            v-on="on"
                        />
                      </template>
                      <v-card>
                        <v-card-text class="pa-0">
                          <v-color-picker
                              v-model="landing.branding.segment_products_styles.product.colorPrice"
                              flat
                          />
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </div>
                </div>
                <div style="display:flex;align-items:center;margin-bottom:15px;">
                  <label class="label" style="width:200px;margin-bottom:0;">
                    Font Size <b>{{ landing.branding.segment_products_styles.product.fontSizePrice }}px</b>
                  </label>
                  <div class="slider" style="width:80%;">
                    <v-slider
                        hide-details
                        :max="40"
                        :min="10"
                        v-model="landing.branding.segment_products_styles.product.fontSizePrice"
                    ></v-slider>
                  </div>
                </div>
                <div style="display:flex;align-items:center;margin-bottom:15px;">
                  <label class="label" style="width:200px;margin-bottom:0;">
                    Font Weight <b>{{ parseInt(landing.branding.segment_products_styles.product.fontWeightPrice, 10) / 10 }}%</b>
                  </label>
                  <div class="slider" style="width:80%;">
                    <v-slider
                        hide-details
                        :max="1000"
                        :min="100"
                        v-model="landing.branding.segment_products_styles.product.fontWeightPrice"
                        :step="100"
                    ></v-slider>
                  </div>
                </div>
                <div style="display: flex;justify-content: space-between;">
                  <div style="display:flex;align-items:center;margin-bottom:15px;">
                    <label class="label" style="width:200px;margin-bottom:0;">
                      Font Style <b>{{ landing.branding.segment_products_styles.product.fontStylePrice }}</b>
                    </label>
                    <div class="control">
                      <v-radio-group hide-details row v-model="landing.branding.segment_products_styles.product.fontStylePrice">
                        <v-radio
                            v-for="(type,typeIndex) in fontStyleTypes"
                            :key="type.id"
                            :label="type.label"
                            :value="type.value"
                            :style="`margin-left:${typeIndex?'15px':0}`"
                        >
                        </v-radio>
                      </v-radio-group>
                    </div>
                  </div>
                  <div style="display:flex;align-items:center;margin-bottom:15px;">
                    <label class="label" style="width:200px;margin-bottom:0;">
                      Font Family
                    </label>

                    <v-select
                        flat
                        dense
                        outlined
                        hide-details
                        placeholder="Select Font Family"
                        :items="fontFamilyTypes"
                        item-text="label"
                        item-value="value"
                        class="canvas__select"
                        v-model="landing.branding.segment_products_styles.product.fontFamilyPrice"
                        style="max-width:250px;"
                    >
                      <template v-slot:selection="{ item }">
                        <div :style="`font-family:'${item.value}',sans-serif`">
                          {{ item.label }}
                        </div>
                      </template>
                      <template v-slot:item="{ item }">
                        <div :style="`font-family:'${item.value}',sans-serif`">
                          {{ item.label }}
                        </div>
                      </template>
                    </v-select>
                  </div>
                </div>
              </div>
            </div>
            <div style="margin-bottom:15px;">
              <div class="control" >
                <label class="label" style="font-weight: bold;font-size:20px;">Button cart settings</label>
              </div>
              <div style="padding:0 15px;">
                <div style="display: flex;align-items: center;margin-bottom:15px;">
                  <label
                      class="label"
                      style="width:200px;margin-bottom: 0;"
                  >Font Color</label
                  >
                  <v-menu
                      top
                      nudge-left="16"
                      nudge-bottom="98"
                      :close-on-content-click="false"
                      v-model="btnColorCart"
                  >
                    <template v-slot:activator="{ on }">
                      <div
                          class="colorpicker"
                          :style="
                      `background-color:${landing.branding.segment_products_styles.product.btnColorCart}`
                    "
                          v-on="on"
                      />
                    </template>
                    <v-card>
                      <v-card-text class="pa-0">
                        <v-color-picker
                            v-model="landing.branding.segment_products_styles.product.btnColorCart"
                            @input="btnColorCart = false"
                            flat
                        />
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </div>
                <div style="display: flex;align-items: center;margin-bottom:15px;">
                  <label
                      class="label"
                      style="width:200px;margin-bottom: 0;"
                  >Background Color</label
                  >
                  <v-menu
                      top
                      nudge-left="16"
                      nudge-bottom="98"
                      :close-on-content-click="false"
                      v-model="btnBgCart"
                  >
                    <template v-slot:activator="{ on }">
                      <div
                          class="colorpicker"
                          :style="
                      `background-color:${landing.branding.segment_products_styles.product.btnBgCart}`
                    "
                          v-on="on"
                      />
                    </template>
                    <v-card>
                      <v-card-text class="pa-0">
                        <v-color-picker
                            v-model="landing.branding.segment_products_styles.product.btnBgCart"
                            @input="btnBgCart = false"
                            flat
                        />
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </div>
                <div style="display: flex;align-items: center;margin-bottom:15px;">
                  <label
                      class="label"
                      style="width:200px;margin-bottom: 0;"
                  >Border Color</label
                  >
                  <v-menu
                      top
                      nudge-left="16"
                      nudge-bottom="98"
                      :close-on-content-click="false"
                      v-model="btnBorderCart"
                  >
                    <template v-slot:activator="{ on }">
                      <div
                          class="colorpicker"
                          :style="
                      `background-color:${landing.branding.segment_products_styles.product.btnBorderCart}`
                    "
                          v-on="on"
                      />
                    </template>
                    <v-card>
                      <v-card-text class="pa-0">
                        <v-color-picker
                            v-model="landing.branding.segment_products_styles.product.btnBorderCart"
                            @input="btnBorderCart = false"
                            flat
                        />
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </div>
                <v-tabs
                    v-model="activeLang"
                >
                  <v-tab
                      v-for="(selected_lang, i) in landing.advanced.selected_langs"
                      :key="i"
                      @change="changeLang(selected_lang)" style="border-bottom:1px solid #fafafa"
                  >
                    <img :src="landing.advanced.langs.find(x => x.value === selected_lang).flag" alt="" style="width:20px;height:15px;margin-right:5px;">
                    {{ selected_lang }}
                  </v-tab>
                  <v-tab-item
                      v-for="(selected_lang, i) in landing.advanced.selected_langs"
                      :key="i"
                  >
                    <div style="display: flex;align-items: center;margin-bottom:15px;">
                      <label
                          class="label"
                          style="width:200px;margin-bottom: 0;"
                      >Button Name</label
                      >
                      <v-text-field
                          style="max-width:250px;"
                          outlined
                          solo
                          flat
                          hide-details
                          dense
                          v-model="landing.branding.segment_products_styles.product.btnNameCart[selected_lang]"
                      ></v-text-field>
                    </div>
                  </v-tab-item>
                </v-tabs>
              </div>
            </div>




            <div style="margin-bottom:15px;">

              <div class="control" >
                <label class="label" style="font-weight: bold;font-size:20px;">Button more settings</label>
              </div>
              <div style="padding:0 15px;">
                <div style="display: flex;align-items: center;margin-bottom:15px;">
                  <label
                      class="label"
                      style="width:200px;margin-bottom: 0;"
                  >Font Color</label
                  >
                  <v-menu
                      top
                      nudge-left="16"
                      nudge-bottom="98"
                      :close-on-content-click="false"
                      v-model="btnColorMore"
                  >
                    <template v-slot:activator="{ on }">
                      <div
                          class="colorpicker"
                          :style="
                              `background-color:${landing.branding.segment_products_styles.product.btnColorMore}`
                            "
                          v-on="on"
                      />
                    </template>
                    <v-card>
                      <v-card-text class="pa-0">
                        <v-color-picker
                            v-model="landing.branding.segment_products_styles.product.btnColorMore"
                            @input="btnColorMore = false"
                            flat
                        />
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </div>
                <div style="display: flex;align-items: center;margin-bottom:15px;">
                  <label
                      class="label"
                      style="width:200px;margin-bottom: 0;"
                  >Background Color</label
                  >
                  <v-menu
                      top
                      nudge-left="16"
                      nudge-bottom="98"
                      :close-on-content-click="false"
                      v-model="btnBgMore"
                  >
                    <template v-slot:activator="{ on }">
                      <div
                          class="colorpicker"
                          :style="
                              `background-color:${landing.branding.segment_products_styles.product.btnBgMore}`
                            "
                          v-on="on"
                      />
                    </template>
                    <v-card>
                      <v-card-text class="pa-0">
                        <v-color-picker
                            v-model="landing.branding.segment_products_styles.product.btnBgMore"
                            @input="btnBgCart = false"
                            flat
                        />
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </div>
                <div style="display: flex;align-items: center;margin-bottom:15px;">
                  <label
                      class="label"
                      style="width:200px;margin-bottom: 0;"
                  >Border Color</label
                  >
                  <v-menu
                      top
                      nudge-left="16"
                      nudge-bottom="98"
                      :close-on-content-click="false"
                      v-model="btnBorderMore"
                  >
                    <template v-slot:activator="{ on }">
                      <div
                          class="colorpicker"
                          :style="
                              `background-color:${landing.branding.segment_products_styles.product.btnBorderMore}`
                            "
                          v-on="on"
                      />
                    </template>
                    <v-card>
                      <v-card-text class="pa-0">
                        <v-color-picker
                            v-model="landing.branding.segment_products_styles.product.btnBorderMore"
                            @input="btnBorderMore = false"
                            flat
                        />
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </div>
                <v-tabs
                    v-model="activeLang"
                >
                  <v-tab
                      v-for="(selected_lang, i) in landing.advanced.selected_langs"
                      :key="i"
                      @change="changeLang(selected_lang)" style="border-bottom:1px solid #fafafa"
                  >
                    <img :src="landing.advanced.langs.find(x => x.value === selected_lang).flag" alt="" style="width:20px;height:15px;margin-right:5px;">
                    {{ selected_lang }}
                  </v-tab>
                  <v-tab-item
                      v-for="(selected_lang, i) in landing.advanced.selected_langs"
                      :key="i"
                  >
                    <div style="display: flex;align-items: center;margin-bottom:15px;">
                      <label
                          class="label"
                          style="width:200px;margin-bottom: 0;"
                      >Button Name</label
                      >
                      <v-text-field
                          style="max-width:250px;"
                          outlined
                          solo
                          flat
                          hide-details
                          dense
                          v-model="landing.branding.segment_products_styles.product.btnNameMore[selected_lang]"
                      ></v-text-field>
                    </div>
                  </v-tab-item>
                </v-tabs>
              </div>
            </div>
          </div>
        </v-tab-item>
        <v-tab-item style="padding-left:15px;padding-top:15px;">

          <div>
            <div style="margin-bottom: 15px;">

              <div class="control">
                <label class="label" style="font-weight: bold;font-size:20px;">Name settings</label>
              </div>
              <div style="padding:0 15px;">
                <div style="display: flex;align-items: center;margin-bottom:15px;">
                  <label class="label" style="margin-bottom: 0;width:200px;">
                    Font Color
                  </label>
                  <div class="control">
                    <v-menu
                        top
                        nudge-left="16"
                        nudge-bottom="98"
                        :close-on-content-click="false"
                        v-model="show_text_color_detail"
                    >
                      <template v-slot:activator="{ on }">
                        <div
                            class="custom__menu--color"
                            :style="`background-color:${landing.branding.segment_products_styles.productDetail.color}`"
                            v-on="on"
                        />
                      </template>
                      <v-card>
                        <v-card-text class="pa-0">
                          <v-color-picker
                              v-model="landing.branding.segment_products_styles.productDetail.color"
                              flat
                          />
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </div>
                </div>
                <div style="display: flex;align-items: center;margin-bottom:15px;">
                  <label class="label" style="margin-bottom: 0;width:200px;">
                    Font Size <b>{{ landing.branding.segment_products_styles.productDetail.fontSize }}px</b>
                  </label>
                  <div class="slider" style="width:80%;">
                    <v-slider
                        hide-details
                        :max="40"
                        :min="10"
                        v-model="landing.branding.segment_products_styles.productDetail.fontSize"
                    ></v-slider>
                  </div>
                </div>
                <div style="display: flex;align-items: center;margin-bottom:15px;">
                  <label class="label" style="margin-bottom: 0;width:200px;">
                    Font Weight <b>{{ parseInt(landing.branding.segment_products_styles.productDetail.fontWeight, 10) / 10 }}%</b>
                  </label>
                  <div class="slider" style="width:80%;">
                    <v-slider
                        hide-details
                        :max="1000"
                        :min="100"
                        v-model="landing.branding.segment_products_styles.productDetail.fontWeight"
                        :step="100"
                    ></v-slider>
                  </div>
                </div>
                <div style="display: flex;justify-content: space-between;">
                  <div style="display: flex;align-items: center;margin-bottom:15px;">
                    <label class="label" style="margin-bottom: 0;width:200px;">
                      Font Style <b>{{ landing.branding.segment_products_styles.productDetail.fontStyle }}</b>
                    </label>
                    <div class="control">
                      <v-radio-group hide-details row v-model="landing.branding.segment_products_styles.productDetail.fontStyle">
                        <v-radio
                            v-for="(type,typeIndex) in fontStyleTypes"
                            :key="type.id"
                            :label="type.label"
                            :value="type.value"
                            :style="`margin-left:${typeIndex?'15px':0}`"
                        >
                        </v-radio>
                      </v-radio-group>
                    </div>
                  </div>
                  <div style="display: flex;align-items: center;margin-bottom:15px;">
                    <label class="label" style="margin-bottom: 0;width:200px;">
                      Font Family
                    </label>
                    <v-select
                        flat
                        dense
                        outlined
                        hide-details
                        placeholder="Select Font Family"
                        :items="fontFamilyTypes"
                        item-text="label"
                        item-value="value"
                        class="canvas__select"
                        v-model="landing.branding.segment_products_styles.productDetail.fontFamily"
                        style="max-width:250px;"
                    >
                      <template v-slot:selection="{ item }">
                        <div :style="`font-family:'${item.value}',sans-serif`">
                          {{ item.label }}
                        </div>
                      </template>
                      <template v-slot:item="{ item }">
                        <div :style="`font-family:'${item.value}',sans-serif`">
                          {{ item.label }}
                        </div>
                      </template>
                    </v-select>
                  </div>
                </div></div>
            </div>
            <div style="margin-bottom:15px;">
              <div class="control">
                <label class="label" style="font-weight: bold;font-size:20px;">Image Settings</label>
              </div>
              <div style="padding:0 15px;">
                <div style="display:flex;align-items:center;margin-bottom:15px;">
                  <label class="label" style="margin-bottom: 0;width:200px;">
                    Show Image</label>
                  <v-switch v-model="landing.branding.segment_products_styles.productDetail.show_icon" hide-details>
                  </v-switch>
                </div>
                <div v-if="landing.branding.segment_products_styles.productDetail.show_icon" style="display: flex;align-items: center;margin-bottom:15px;">
                  <label class="label" style="margin-bottom: 0;width:200px;">Height <b>{{ landing.branding.segment_products_styles.productDetail.img_height }}</b> px</label>
                  <div class="slider" style="width:80%;">
                    <v-slider
                        hide-details
                        :min="10"
                        :max="600"
                        v-model="landing.branding.segment_products_styles.productDetail.img_height"
                    ></v-slider>
                  </div>
                </div>
              </div>
            </div>
            <div style="margin-bottom:15px;">
              <div class="control">
                <label class="label" style="font-weight: bold;font-size:20px;">Price settings</label>
              </div>
              <div style="padding: 0 15px;">
                <div
                    style="display:flex;align-items:center;margin-bottom:15px;"
                >
                  <label class="label" style="margin-bottom: 0;width:200px;">
                    Font Color
                  </label>
                  <div class="control">
                    <v-menu
                        top
                        nudge-left="16"
                        nudge-bottom="98"
                        :close-on-content-click="false"
                        v-model="show_text_price_color_detail"
                    >
                      <template v-slot:activator="{ on }">
                        <div
                            class="custom__menu--color"
                            :style="`background-color:${landing.branding.segment_products_styles.productDetail.colorPrice}`"
                            v-on="on"
                        />
                      </template>
                      <v-card>
                        <v-card-text class="pa-0">
                          <v-color-picker
                              v-model="landing.branding.segment_products_styles.productDetail.colorPrice"
                              flat
                          />
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </div>
                </div>
                <div style="display:flex;align-items:center;margin-bottom:15px;">
                  <label class="label" style="margin-bottom: 0;width:200px;">
                    Font Size <b>{{ landing.branding.segment_products_styles.productDetail.fontSizePrice }}px</b>
                  </label>
                  <div class="slider" style="width:80%;">
                    <v-slider
                        hide-details
                        :max="40"
                        :min="10"
                        v-model="landing.branding.segment_products_styles.productDetail.fontSizePrice"
                    ></v-slider>
                  </div>
                </div>
                <div style="display:flex;align-items:center;margin-bottom:15px;">
                  <label class="label" style="margin-bottom: 0;width:200px;">
                    Font Weight <b>{{ parseInt(landing.branding.segment_products_styles.productDetail.fontWeightPrice, 10) / 10 }}%</b>
                  </label>
                  <div class="slider" style="width:80%;">
                    <v-slider
                        hide-details
                        :max="1000"
                        :min="100"
                        v-model="landing.branding.segment_products_styles.productDetail.fontWeightPrice"
                        :step="100"
                    ></v-slider>
                  </div>
                </div>
              </div>
            </div>
            <div style="margin-bottom:15px;">
              <div class="control">
                <label class="label" style="font-weight: bold;font-size:20px;">Button cart settings</label>
              </div>
              <div style="padding:0 15px;">
                <div style="display:flex;align-items:center;margin-bottom:15px;">
                  <label class="label" style="margin-bottom: 0;width:200px;">Font Color</label
                  >
                  <v-menu
                      top
                      nudge-left="16"
                      nudge-bottom="98"
                      :close-on-content-click="false"
                      v-model="btnColorCart_detail"
                  >
                    <template v-slot:activator="{ on }">
                      <div
                          class="colorpicker"
                          :style="
                      `background-color:${landing.branding.segment_products_styles.productDetail.btnColorCart}`
                    "
                          v-on="on"
                      />
                    </template>
                    <v-card>
                      <v-card-text class="pa-0">
                        <v-color-picker
                            v-model="landing.branding.segment_products_styles.productDetail.btnColorCart"
                            @input="btnColorCart_detail = false"
                            flat
                        />
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </div>
                <div style="display:flex;align-items:center;margin-bottom:15px;">
                  <label class="label" style="margin-bottom: 0;width:200px;">Background Color</label
                  >
                  <v-menu
                      top
                      nudge-left="16"
                      nudge-bottom="98"
                      :close-on-content-click="false"
                      v-model="btnBgCart_detail"
                  >
                    <template v-slot:activator="{ on }">
                      <div
                          class="colorpicker"
                          :style="
                      `background-color:${landing.branding.segment_products_styles.productDetail.btnBgCart}`
                    "
                          v-on="on"
                      />
                    </template>
                    <v-card>
                      <v-card-text class="pa-0">
                        <v-color-picker
                            v-model="landing.branding.segment_products_styles.productDetail.btnBgCart"
                            @input="btnBgCart_detail = false"
                            flat
                        />
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </div>
                <div style="display:flex;align-items:center;margin-bottom:15px;">
                  <label class="label" style="margin-bottom: 0;width:200px;">Border Color</label
                  >
                  <v-menu
                      top
                      nudge-left="16"
                      nudge-bottom="98"
                      :close-on-content-click="false"
                      v-model="btnBorderCart_detail"
                  >
                    <template v-slot:activator="{ on }">
                      <div
                          class="colorpicker"
                          :style="
                      `background-color:${landing.branding.segment_products_styles.productDetail.btnBorderCart}`
                    "
                          v-on="on"
                      />
                    </template>
                    <v-card>
                      <v-card-text class="pa-0">
                        <v-color-picker
                            v-model="landing.branding.segment_products_styles.productDetail.btnBorderCart"
                            @input="btnBorderCart_detail = false"
                            flat
                        />
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </div>
                <v-tabs
                    v-model="activeLang"
                >
                  <v-tab
                      v-for="(selected_lang, i) in landing.advanced.selected_langs"
                      :key="i"
                      @change="changeLang(selected_lang)" style="border-bottom:1px solid #fafafa"
                  >
                    <img :src="landing.advanced.langs.find(x => x.value === selected_lang).flag" alt="" style="width:20px;height:15px;margin-right:5px;">
                    {{ selected_lang }}
                  </v-tab>
                  <v-tab-item
                      v-for="(selected_lang, i) in landing.advanced.selected_langs"
                      :key="i"
                  >
                    <div style="display:flex;align-items:center;margin-bottom:15px;">
                      <label class="label" style="margin-bottom: 0;width:200px;">Button Name</label
                      >
                      <v-text-field
                          style="max-width:250px;"
                          outlined
                          solo
                          flat
                          hide-details
                          dense
                          v-model="landing.branding.segment_products_styles.productDetail.btnNameCart[selected_lang]"
                      ></v-text-field>
                    </div>
                  </v-tab-item>
                </v-tabs>
              </div>
            </div>
            <div style="margin-bottom:15px;">
              <div class="control">
                <label class="label" style="font-weight: bold;font-size:20px;">Button open cart settings</label>
              </div>
              <div style="padding:0 15px;">
                <div style="display:flex;align-items:center;margin-bottom:15px;">
                  <label class="label" style="margin-bottom: 0;width:200px;">Font Color</label>
                  <v-menu
                      top
                      nudge-left="16"
                      nudge-bottom="98"
                      :close-on-content-click="false"
                      v-model="btnColorOpenCart_detail"
                  >
                    <template v-slot:activator="{ on }">
                      <div
                          class="colorpicker"
                          :style="
                      `background-color:${landing.branding.segment_products_styles.productDetail.btnColorOpenCart}`
                    "
                          v-on="on"
                      />
                    </template>
                    <v-card>
                      <v-card-text class="pa-0">
                        <v-color-picker
                            v-model="landing.branding.segment_products_styles.productDetail.btnColorOpenCart"
                            flat
                        />
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </div>
                <div style="display:flex;align-items:center;margin-bottom:15px;">
                  <label class="label" style="margin-bottom: 0;width:200px;">Background Color</label
                  >
                  <v-menu
                      top
                      nudge-left="16"
                      nudge-bottom="98"
                      :close-on-content-click="false"
                      v-model="btnBgOpenCart_detail"
                  >
                    <template v-slot:activator="{ on }">
                      <div
                          class="colorpicker"
                          :style="
                      `background-color:${landing.branding.segment_products_styles.productDetail.btnBgOpenCart}`
                    "
                          v-on="on"
                      />
                    </template>
                    <v-card>
                      <v-card-text class="pa-0">
                        <v-color-picker
                            v-model="landing.branding.segment_products_styles.productDetail.btnBgOpenCart"
                            flat
                        />
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </div>
                <div style="display:flex;align-items:center;margin-bottom:15px;">
                  <label class="label" style="margin-bottom: 0;width:200px;">Border Color</label
                  >
                  <v-menu
                      top
                      nudge-left="16"
                      nudge-bottom="98"
                      :close-on-content-click="false"
                      v-model="btnBorderOpenCart_detail"
                  >
                    <template v-slot:activator="{ on }">
                      <div
                          class="colorpicker"
                          :style="
                      `background-color:${landing.branding.segment_products_styles.productDetail.btnBorderOpenCart}`
                    "
                          v-on="on"
                      />
                    </template>
                    <v-card>
                      <v-card-text class="pa-0">
                        <v-color-picker
                            v-model="landing.branding.segment_products_styles.productDetail.btnBorderOpenCart"
                            flat
                        />
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </div>
                <v-tabs
                    v-model="activeLang"
                >
                  <v-tab
                      v-for="(selected_lang, i) in landing.advanced.selected_langs"
                      :key="i"
                      @change="changeLang(selected_lang)" style="border-bottom:1px solid #fafafa"
                  >
                    <img :src="landing.advanced.langs.find(x => x.value === selected_lang).flag" alt="" style="width:20px;height:15px;margin-right:5px;">
                    {{ selected_lang }}
                  </v-tab>
                  <v-tab-item
                      v-for="(selected_lang, i) in landing.advanced.selected_langs"
                      :key="i"
                  >
                    <div style="display:flex;align-items:center;margin-bottom:15px;">
                      <label class="label" style="margin-bottom: 0;width:200px;">Button Name</label
                      >
                      <v-text-field
                          style="max-width:250px;"
                          outlined
                          solo
                          flat
                          hide-details
                          dense
                          v-model="landing.branding.segment_products_styles.productDetail.btnNameOpenCart[selected_lang]"
                      ></v-text-field>
                    </div>
                  </v-tab-item>
                </v-tabs>
              </div>
            </div>
          </div>
        </v-tab-item>
      </v-tabs>
    </div>
    <!--Create new product dialog-->
    <v-dialog
        v-model="dialogCreateProduct" v-if="createProduct"
        max-width="710"
        @click:outside="createProductHide"
    >
      <v-card style="min-height: 700px">
        <v-toolbar style="width: 100%">
          <div>Create promo</div>
          <v-icon
              small
              depressed
              outlined
              style="position: absolute; right: 20px; top: 20px; font-size: 20px"
              @click="createProductHide"
              size="20px"
          >mdi-close</v-icon
          >
        </v-toolbar>
        <div>
          <v-tabs>
            <v-tab> General </v-tab>
            <v-tab>More</v-tab>
            <v-tab>Segments</v-tab>
            <v-tab-item style="padding-top: 15px">
              <div style="padding: 15px">
                <div style="margin-bottom: 15px">
                  <div class="label" style="margin-bottom: 15px">
                    Choose your promo image list
                  </div>
                  <vue-dropzone
                      style="margin-bottom: 15px"
                      id="createProductDropzone"
                      :options="dropzoneOptions"
                      :destroyDropzone="false"
                      ref="createProductDropzone"
                      @vdropzone-removed-file="handleRemoveCreateProduct"
                      @vdropzone-sending="customEvent"
                      @vdropzone-success="handleSuccessCreateProduct"
                  >
                  </vue-dropzone>
                </div>
                <div style="margin-bottom: 15px">
                  <div class="label" style="margin-bottom: 15px">
                    Choose your promo image detail
                  </div>
                  <vue-dropzone
                      style="margin-bottom: 15px"
                      id="createProductDropzoneDetail"
                      :options="dropzoneOptions"
                      :destroyDropzone="false"
                      ref="createProductDropzoneDetail"
                      @vdropzone-removed-file="handleRemoveCreateProductDetail"
                      @vdropzone-sending="customEvent"
                      @vdropzone-success="handleSuccessCreateProductDetail"
                  >
                  </vue-dropzone>
                </div>
                <v-tabs  v-model="activeLang">
                  <v-tab
                      v-for="(lang, i) in landing.selected_langs"
                      :key="i"
                      @change="changeLang(lang)"
                  >
                    <img
                        :src="
                        landing.advanced.langs.find((x) => x.value === lang).flag
                      "
                        alt=""
                        style="width: 20px; height: 15px; margin-right: 5px"
                    />
                    {{ lang }}
                  </v-tab>

                  <v-tab-item
                      style="padding-top: 15px"
                      v-for="(lang, i) in landing.selected_langs"
                      :key="i"
                  >
                    <div class="label" style="margin-bottom: 15px">
                      Promo name
                    </div>
                    <v-text-field
                        style="margin-bottom: 15px"
                        outlined
                        dense
                        hide-details
                        v-model="createProduct.name[lang]"
                        placeholder="Your promo name"
                    ></v-text-field>
                    <div class="label" style="margin: 15px 0">
                      Promo small description
                    </div>
                    <Editor
                        api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
                        :init="options"
                        v-model="createProduct.small_description[lang]"
                    >
                    </Editor>
                    <div class="label" style="margin: 15px 0">
                      Promo description
                    </div>
                    <Editor
                        api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
                        :init="options"
                        v-model="createProduct.description[lang]"
                    >
                    </Editor>
                    <div style="display: flex;align-items: center;margin: 15px 0">
                      <label class="label" style="display: block;margin-bottom: 0;margin-right: 15px;">Active tab</label>
                      <v-switch hide-details style="margin: 0;padding:0" @change="changeActiveTabText(createProduct,active_tab_text_create)" v-model="active_tab_text_create">

                      </v-switch>
                    </div>
                    <div v-if="active_tab_text_create" style="display: flex;align-items: center;margin: 15px 0">
                      <label class="label" style="display: block;margin-bottom: 0;margin-right: 15px;">Active tab text</label>
                      <v-text-field outlined dense hide-details style="margin: 0;padding:0" v-model="createProduct.tab_text[landing.current_lang]">

                      </v-text-field>
                    </div>
                  </v-tab-item>
                </v-tabs>
              </div>
            </v-tab-item>
            <v-tab-item style="padding-top: 15px">
              <div style="display: flex; flex-wrap: wrap; padding: 15px">
                <div style="flex: 1; padding-right: 15px">
                  <div class="label" style="margin-bottom: 15px">Price</div>
                  <v-currency-field
                      style="margin-bottom: 15px"
                      outlined
                      :decimal-length="2"
                      dense
                      type="number"
                      hide-details
                      v-model="createProduct.price"
                      placeholder="Your promo price"
                  />
                </div>
                <div style="flex: 1; padding-left: 15px">
                  <div class="label" style="margin-bottom: 15px">Discount price</div>
                  <div class="control">
                    <v-currency-field
                        style="margin-bottom: 15px"
                        outlined
                        :decimal-length="2"
                        dense
                        type="number"
                        hide-details
                        v-model="createProduct.discount_price"
                        placeholder="Your promo discount price"
                    />
                  </div>
                </div>
              </div>
              <div style="display: flex; flex-wrap: wrap; padding: 15px">
                <div style="flex: 1; padding-right: 15px">
                  <div class="label" style="margin-bottom: 15px">
                    Video link
                  </div>
                  <v-text-field
                      style="margin-bottom: 15px"
                      outlined
                      dense
                      hide-details
                      v-model="createProduct.video"
                      placeholder="Your video link"
                  />
                </div>
                <div style="flex: 1; padding-right: 15px">
                  <div class="label" style="margin-bottom: 15px">
                    Hidden ID
                  </div>
                  <v-text-field
                      style="margin-bottom: 15px"
                      outlined
                      dense
                      hide-details
                      v-model="createProduct.hidden_id"
                      placeholder="Your hidden id"
                  />
                </div>
              </div>
            </v-tab-item>
            <v-tab-item style="padding: 15px">
              <div style="display:flex;align-items: center;flex:1;margin-bottom:15px;">
                <label class="label" style="display:block;margin-bottom:0;margin-right:15px;width:200px;">Selected Segments</label>
                <v-combobox
                    hide-details
                    v-model="createdSelectedSegments"
                    class="canvas__select"
                    item-text="text"
                    item-value="value"
                    :items="showSegments"
                    multiple
                    outlined
                    dense

                >
                </v-combobox>
              </div>
            </v-tab-item>
          </v-tabs>
        </div>
        <v-card-actions class="justify-end">
          <v-btn
              outlined
              small
              @click="createProductConfirm"
              style="margin-bottom: 20px; max-width: 360px"
              color="primary"
          >Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Create new product dialog-->
    <!--Create new product dialog-->
    <v-dialog
        v-model="dialogEditProduct" v-if="editProduct"
        max-width="710"
        @click:outside="createProductHide"
    >
      <v-card style="min-height: 700px">
        <v-toolbar style="width: 100%">
          <div>Edit promo</div>
          <v-icon
              small
              depressed
              outlined
              style="position: absolute; right: 20px; top: 20px; font-size: 20px"
              @click="createProductHide"
              size="20px"
          >mdi-close</v-icon
          >
        </v-toolbar>
        <div>
          <v-tabs>
            <v-tab> General </v-tab>
            <v-tab>More</v-tab>
            <v-tab>Segments</v-tab>
            <v-tab-item style="padding-top: 15px">
              <div style="padding: 15px">
                <div style="margin-bottom: 15px">
                  <div class="label" style="margin-bottom: 15px">
                    Choose your promo image list
                  </div>
                  <vue-dropzone
                      style="margin-bottom: 15px"
                      id="editProductDropzone"
                      :options="dropzoneOptions"
                      :destroyDropzone="false"
                      ref="editProductDropzone"
                      @vdropzone-sending="customEvent"
                      @vdropzone-success="handleSuccessEditProduct"
                      @vdropzone-mounted="handleMountEditProduct"
                      @vdropzone-removed-file="handleRemoveEditProduct"
                  >
                  </vue-dropzone>
                </div>
                <div style="margin-bottom: 15px">
                  <div class="label" style="margin-bottom: 15px">
                    Choose your promo image detail
                  </div>
                  <vue-dropzone
                      style="margin-bottom: 15px"
                      id="editProductDropzoneDetail"
                      :options="dropzoneOptions"
                      :destroyDropzone="false"
                      ref="editProductDropzoneDetail"
                      @vdropzone-sending="customEvent"
                      @vdropzone-success="handleSuccessEditProductDetail"
                      @vdropzone-mounted="handleMountEditProductDetail"
                      @vdropzone-removed-file="handleRemoveEditProductDetail"
                  >
                  </vue-dropzone>
                </div>
                <v-tabs  v-model="activeLang">
                  <v-tab
                      v-for="(lang, i) in landing.selected_langs"
                      :key="i"
                      @change="changeLang(lang)"
                  >
                    <img
                        :src="
                        landing.advanced.langs.find((x) => x.value === lang).flag
                      "
                        alt=""
                        style="width: 20px; height: 15px; margin-right: 5px"
                    />
                    {{ lang }}
                  </v-tab>

                  <v-tab-item
                      style="padding-top: 15px"
                      v-for="(lang, i) in landing.selected_langs"
                      :key="i"
                  >
                    <div class="label" style="margin-bottom: 15px">
                      Promo name
                    </div>
                    <v-text-field
                        style="margin-bottom: 15px"
                        outlined
                        dense
                        hide-details
                        v-model="editProduct.name[lang]"
                        placeholder="Your promo name"
                    ></v-text-field>
                    <div class="label" style="margin: 15px 0">
                      Promo small description
                    </div>
                    <Editor
                        api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
                        :init="options"
                        v-model="editProduct.small_description[lang]"
                    >
                    </Editor>
                    <div class="label" style="margin: 15px 0">
                      Promo description
                    </div>
                    <Editor
                        api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
                        :init="options"
                        v-model="editProduct.description[lang]"
                    >
                    </Editor>
                    <div style="display: flex;align-items: center;margin: 15px 0">
                      <label class="label" style="display: block;margin-bottom: 0;margin-right: 15px;">Active tab</label>
                      <v-switch hide-details style="margin: 0;padding:0" @change="changeActiveTabText(editProduct,active_tab_text_edit)" v-model="active_tab_text_edit">

                      </v-switch>
                    </div>
                    <div v-if="active_tab_text_edit" style="display: flex;align-items: center;margin: 15px 0">
                      <label class="label" style="display: block;margin-bottom: 0;margin-right: 15px;">Active tab text</label>
                      <v-text-field outlined dense hide-details style="margin: 0;padding:0" v-model="editProduct.tab_text[landing.current_lang]">
                      </v-text-field>
                    </div>
                  </v-tab-item>
                </v-tabs>
              </div>
            </v-tab-item>
            <v-tab-item style="padding-top: 15px">
              <div style="display: flex; flex-wrap: wrap; padding: 15px">
                <div style="flex: 1; padding-right: 15px">
                  <div class="label" style="margin-bottom: 15px">Price</div>
                  <v-currency-field
                      style="margin-bottom: 15px"
                      outlined
                      :decimal-length="2"
                      dense
                      type="number"
                      hide-details
                      v-model="editProduct.price"
                      placeholder="Your promo price"
                  />
                </div>
                <div style="flex: 1; padding-left: 15px">
                  <div class="label" style="margin-bottom: 15px">Discount price</div>
                  <div class="control">
                    <v-currency-field
                        style="margin-bottom: 15px"
                        outlined
                        :decimal-length="2"
                        dense
                        type="number"
                        hide-details
                        v-model="editProduct.discount_price"
                        placeholder="Your promo discount price"
                    />
                  </div>
                </div>
              </div>
              <div style="display: flex; flex-wrap: wrap; padding: 15px">
                <div style="flex: 1; padding-right: 15px">
                  <div class="label" style="margin-bottom: 15px">
                    Video link
                  </div>
                  <v-text-field
                      style="margin-bottom: 15px"
                      outlined
                      dense
                      hide-details
                      v-model="editProduct.video"
                      placeholder="Your video link"
                  />
                </div>
                <div style="flex: 1; padding-right: 15px">
                  <div class="label" style="margin-bottom: 15px">
                    Hidden ID
                  </div>
                  <v-text-field
                      style="margin-bottom: 15px"
                      outlined
                      dense
                      hide-details
                      v-model="editProduct.hidden_id"
                      placeholder="Your hidden id"
                  />
                </div>
              </div>
            </v-tab-item>
            <v-tab-item style=" padding: 15px">
              <div style="display:flex;align-items: center;flex:1;margin-bottom:15px;">
                <label class="label" style="display:block;margin-bottom:0;margin-right:15px;width:200px;">Selected Segments</label>
                <v-combobox
                    hide-details
                    v-model="editedSelectedSegments"
                    class="canvas__select"
                    item-text="text"
                    item-value="id"
                    :items="showSegments"
                    multiple
                    outlined
                    dense
                    flat
                >
                </v-combobox>
              </div>
            </v-tab-item>
          </v-tabs>
        </div>
        <v-card-actions class="justify-end">
          <v-btn
              outlined
              small
              @click="editProductConfirm"
              style="margin-bottom: 20px; max-width: 360px"
              color="primary"
          >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Create new product dialog-->
    <PopupError :title="error" ref="PopupError" />
    <PopupSuccess ref="PopupSuccess" :title="'Success!'" :content="successMessage" />
    <PopupDelete
        ref="PopupDelete"
        :title="`Are your sure about delete ${deleteItem?deleteItem.name[landing.current_lang]:''}?`"
        @confirm="deleteProductConfirm"
    />
  </div>
</template>

<script>
  import {mapState,mapActions} from 'vuex'
  import Compressor from "compressorjs";
  import vueDropzone from "vue2-dropzone";
  import "vue2-dropzone/dist/vue2Dropzone.min.css";
  export default {
    name: "ProductsList",
    components:{
      PopupSuccess:() => import("@/components/Popup/PopupSuccess"),
      PopupError:() => import('@/components/Popup/PopupError'),
      PopupDelete:() => import("@/components/Popup/PopupDelete"),
      vueDropzone,
    },
    computed:{
      ...mapState('segmentProducts',['segmentProductCategory']),
      ...mapState(['landing']),
      ...mapState('segments',['segments']),
      showSegments(){
        return this.segments.reduce((acc,item) => {
          let pushedObj = {
            text:item.name,
            value:item.id,
          }
          acc.push(pushedObj);
          return acc;
        },[])
      },
      activeLang: {
        set: function (val) {},
        get: function () {
          return this.landing.advanced.selected_langs.indexOf(
            this.landing.current_lang
          );
        },
      },
    },
    methods:{
      ...mapActions('segmentProducts',['putSegmentProduct','postSegmentProduct','deleteSegmentProduct']),
      ...mapActions('segments',['getSegments']),
      async editProductConfirm(){
        this.editProduct.segment_ids = this.editedSelectedSegments.reduce((acc,item) => {
          acc.push(item.value);
          return acc;
        },[]);
        await this.putSegmentProduct(this.editProduct);
        this.successMessage = `Promo ${this.editProduct.name[this.landing.current_lang]} edited successfully`;
        this.dialogEditProduct = false;
        this.$refs.PopupSuccess.open();
      },
      openEdit(item){
        this.active_tab_text_edit = !!item.active_tab_text;
        this.editProduct = JSON.parse(JSON.stringify(item));
        this.editedSelectedSegments = JSON.parse(JSON.stringify(this.segments.reduce((acc,item) => {
          if(this.editProduct.segment_ids.indexOf(item.id) >= 0){
            const candidate = {
              text:item.name,
              value:item.id
            };
            acc.push(candidate);
          }
          return acc;
        },[])));
        if(this.$refs.editProductDropzoneDetail){
          this.handleMountEditProductDetail();
        }
        if(this.$refs.editProductDropzone){
          this.handleMountEditProduct();
        }
        this.dialogEditProduct = true;
      },
      async createProductConfirm(){
        this.createProduct.segment_ids = this.createdSelectedSegments.reduce((acc,item) => {
          acc.push(item.value);
          return acc;
        },[]);
        this.createProduct.segment_product_category_id = this.segmentProductCategory.id;
        await this.postSegmentProduct(this.createProduct);
        this.successMessage = 'New promo created successfully!';
        this.dialogCreateProduct = false;
        this.$refs.PopupSuccess.open();
        this.createdSelectedSegments = [];
      },
      createProductOpen(){
        this.editedSelectedSegments = [];
        if(this.$refs.createProductDropzoneDetail){
          let elements =
            this.$refs.createProductDropzoneDetail.$el.getElementsByClassName("dz-preview");
          while (elements.length > 0) {
            elements[0].parentNode.removeChild(elements[0]);
          }
        }
        if(this.$refs.createProductDropzone){
          let elements =
            this.$refs.createProductDropzone.$el.getElementsByClassName("dz-preview");
          while (elements.length > 0) {
            elements[0].parentNode.removeChild(elements[0]);
          }
        }
        this.active_tab_text_create = false;
        this.createProduct = JSON.parse(JSON.stringify(this.defaultProduct));
        this.dialogCreateProduct = true;
      },
      changeLang(lang) {
        this.$store.commit("landing/setCurrentLang", lang);
      },
      handleSuccessCreateProduct(file){
        this.createProduct.main_image = file.xhr.response;
      },
      handleSuccessCreateProductDetail(file){
        this.createProduct.detail_images.push(file.xhr.response);
      },
      handleRemoveCreateProduct(){
        this.createProduct.main_image = "";
      },
      handleRemoveCreateProductDetail(file){
        if(file?.xhr?.response){
          const candidateIndex = this.createProduct.detail_images.indexOf(file.xhr.response);
          if(candidateIndex>=0){
            this.createProduct.detail_images.splice(candidateIndex,1);
          }
        }
      },
      handleSuccessEditProduct(file){
        this.editProduct.main_image = file.xhr.response;
      },
      handleRemoveEditProduct(){
        this.editProduct.main_image = "";
      },
      handleSuccessEditProductDetail(file){
        this.editProduct.detail_images.push(file.xhr.response);
      },
      handleRemoveEditProductDetail(file){
        if(file?.xhr?.response){
          const candidateIndex = this.editProduct.detail_images.indexOf(file.xhr.response);
          if(candidateIndex>=0){
            this.editProduct.detail_images.splice(candidateIndex,1);
          }
        }
      },
      handleMountEditProduct(){
        let elements =
          this.$refs.editProductDropzone.$el.getElementsByClassName("dz-preview");
        while (elements.length > 0) {
          elements[0].parentNode.removeChild(elements[0]);
        }
        if(this.editProduct.main_image){
            let mockFile = {
              accepted: true,
              kind: "image",
            };
            this.$refs.editProductDropzone.manuallyAddFile(mockFile, this.editProduct.main_image);
            this.$refs.editProductDropzone.dropzone.emit("thumbnail", mockFile, this.editProduct.main_image);
            this.$refs.editProductDropzone.dropzone.emit("complete", mockFile);
        }
      },
      handleMountEditProductDetail(){
        let elements =
          this.$refs.editProductDropzoneDetail.$el.getElementsByClassName("dz-preview");
        while (elements.length > 0) {
          elements[0].parentNode.removeChild(elements[0]);
        }
        if(this.editProduct.detail_images.length){
          for(let i = 0;i<this.editProduct.detail_images.length;i++){
            let mockFile = {
              accepted: true,
              kind: "image",
              xhr:{
                response:this.editProduct.detail_images[i]
              },
            };
            this.$refs.editProductDropzoneDetail.manuallyAddFile(mockFile, this.editProduct.detail_images[i]);
            this.$refs.editProductDropzoneDetail.dropzone.emit("thumbnail", mockFile, this.editProduct.detail_images[i]);
            this.$refs.editProductDropzoneDetail.dropzone.emit("complete", mockFile);
          }
        }
      },
      createProductHide(){
        this.dialogCreateProduct = false;
      },
      async deleteProductConfirm(){
          await this.deleteSegmentProduct(this.deleteItem.id);
          this.successMessage = `Promo ${this.deleteItem.name[this.landing.current_lang]} deleted successfully!`;
          this.$refs.PopupSuccess.open();
      },
      async changeCategoryStatus(item) {
        try {
          if (item.active) {
            item.active = 0;
          } else {
            item.active = 1;
          }
          await this.putSegmentProduct(item);
          this.successMessage = `Promo ${item.name[this.landing.current_lang]} ${item.active?'activated':'deactivated'} successfully`;
          this.$refs.PopupSuccess.open();
        } catch (e) {
          this.error = e.message;
          this.$refs.PopupError.open();
        }
      },
      openStyles(){
        this.styles = !this.styles;
      },
      async customEvent(_file, xhr, formData) {
        xhr.setRequestHeader(
          "Authorization",
          `Bearer ${this.$store.state.token}`
        );
        formData.append("lending_settings_id", this.landing.settings_id);
        formData.append("image", _file);
      },
      async changeProductStatus(item){
        try {
          if (item.active) {
            item.active = 0;
          } else {
            item.active = 1;
          }
          await this.putSegmentProduct(item);
          this.successMessage = `Promo ${item.name[this.landing.current_lang]} ${item.active?'activated':'deactivated'} successfully`;
          this.$refs.PopupSuccess.open();
        } catch (e) {
          this.error = e.message;
          this.$refs.PopupError.open();
        }
      },
      changeActiveTabText(item,val){
        if(val){
          item.active_tab_text = 1;
        }else{
          item.active_tab_text = 0;
        }
      },
      openDelete(item){
        this.deleteItem = JSON.parse(JSON.stringify(item));
        this.$refs.PopupDelete.open();
      }
    },
    data(){
      return{
        editedSelectedSegments:[],
        createdSelectedSegments:[],
        editProduct:null,
        active_tab_text_create:false,
        options: {
          images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
          images_file_types: "jpg,svg,webp,gif,png",
          images_upload_handler: async (blobInfo, success, failure) => {
            let formData = new FormData();
            let _file = new File([blobInfo.blob()], blobInfo.filename(), {
              lastModified: new Date().getTime(),
              type: blobInfo.blob().type,
            });
            formData.append(
              "lending_settings_id",
              this.$store.state.landing.settings_id
            );
            formData.append("image", _file);
            formData.append("file", _file);
            const { data } = await this.$axios.post(
              `${process.env.VUE_APP_API_URL}upload-image`,
              formData
            );
            success(data);
          },
          plugins:
            "print preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount  imagetools textpattern noneditable help formatpainter permanentpen pageembed charmap quickbars emoticons advtable export",

          mobile: {
            images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
            images_file_types: "jpg,svg,webp,gif,png",
            images_upload_handler: async (blobInfo, success, failure) => {
              let formData = new FormData();
              let _file = new File([blobInfo.blob()], blobInfo.filename(), {
                lastModified: new Date().getTime(),
                type: blobInfo.blob().type,
              });
              formData.append(
                "lending_settings_id",
                this.$store.state.landing.settings_id
              );
              formData.append("image", _file);
              formData.append("file", _file);
              const { data } = await this.$axios.post(
                `${process.env.VUE_APP_API_URL}upload-image`,
                formData
              );
              success(data);
            },
            plugins:
              "print preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount textpattern noneditable help formatpainter pageembed charmap quickbars emoticons advtable",
          },
          menu: {
            tc: {
              title: "Comments",
              items: "addcomment showcomments deleteallconversations",
            },
          },
          menubar: "file edit view insert format tools table tc help",
          toolbar:
            "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | numlist bullist checklist | forecolor backcolor casechange   | charmap emoticons | insertfile image | custom_button",
          autosave_ask_before_unload: true,min_height:200,
          autosave_interval: "30s",
          autosave_prefix: "{path}{query}-{id}-",
          autosave_restore_when_empty: false,
          autosave_retention: "2m",
          image_advtab: true,
          link_list: [
            { title: "My page 1", value: "https://www.tiny.cloud" },
            { title: "My page 2", value: "http://www.moxiecode.com" },
          ],
          image_list: [
            { title: "My page 1", value: "https://www.tiny.cloud" },
            { title: "My page 2", value: "http://www.moxiecode.com" },
          ],
          image_class_list: [
            { title: "None", value: "" },
            { title: "Some class", value: "class-name" },
          ],
          importcss_append: true,
          templates: [
            {
              title: "New Table",
              description: "creates a new table",
              content:
                '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td class="d-block d-sm-table-cell"> </td><td class="d-block d-sm-table-cell"> </td></tr></table></div>',
            },
            {
              title: "Starting my story",
              description: "A cure for writers block",
              content: "Once upon a time...",
            },
            {
              title: "New list with dates",
              description: "New List with dates",
              content:
                '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
            },
          ],
          template_cdate_format: "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
          template_mdate_format: "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
          height: 250,
          image_caption: true,
          quickbars_selection_toolbar:
            "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
          noneditable_noneditable_class: "mceNonEditable",
          toolbar_mode: "sliding",
          content_style: ".mymention{ color: gray; }",
          contextmenu: "link image imagetools table configurepermanentpen",
          skin: "oxide",

          setup:function(editor){
            editor.ui.registry.addButton('custom_button', {
              text: 'Add Button',
              onAction: function() {
                // Open a Dialog
                editor.windowManager.open({
                  title: 'Add custom button',
                  body: {
                    type: 'panel',
                    items: [{
                      type: 'input',
                      name: 'button_label',
                      label: 'Button label',
                      flex: true
                    },{
                      type: 'input',
                      name: 'button_href',
                      label: 'Button href',
                      flex: true
                    },{
                      type: 'selectbox',
                      name: 'button_target',
                      label: 'Target',
                      items: [
                        {text: 'None', value: ''},
                        {text: 'New window', value: '_blank'},
                        {text: 'Self', value: '_self'},
                        {text: 'Parent', value: '_parent'}
                      ],
                      flex: true
                    },{
                      type: 'selectbox',
                      name: 'button_rel',
                      label: 'Rel',
                      items: [
                        {text: 'No value', value: ''},
                        {text: 'Alternate', value: 'alternate'},
                        {text: 'Help', value: 'help'},
                        {text: 'Manifest', value: 'manifest'},
                        {text: 'No follow', value: 'nofollow'},
                        {text: 'No opener', value: 'noopener'},
                        {text: 'No referrer', value: 'noreferrer'},
                        {text: 'Opener', value: 'opener'}
                      ],
                      flex: true
                    },{
                      type: 'selectbox',
                      name: 'button_style',
                      label: 'Style',
                      items: [
                        {text: 'Success', value: 'success'},
                        {text: 'Info', value: 'info'},
                        {text: 'Warning', value: 'warning'},
                        {text: 'Error', value: 'error'}
                      ],
                      flex: true
                    }]
                  },
                  onSubmit: function (api) {

                    var html = '<a href="'+api.getData().button_href+'" class="v-btn v-btn--outlined theme--light v-size--small '+api.getData().button_style+'--text" rel="'+api.getData().button_rel+'" target="'+api.getData().button_target+'">'+api.getData().button_label+'</a>';

                    // insert markup
                    editor.insertContent(html);

                    // close the dialog
                    api.close();
                  },
                  buttons: [
                    {
                      text: 'Close',
                      type: 'cancel',
                      onclick: 'close'
                    },
                    {
                      text: 'Insert',
                      type: 'submit',
                      primary: true,
                      enabled: false
                    }
                  ]
                });
              }
            });
          },
          content_css: ["/custom_tinymce.css"],
        },
        deleteItem:null,
        dropzoneOptions: {
          url: () => {
            return process.env.VUE_APP_API_URL + "upload-image";
          },
          maxFiles: 3,
          thumbnailMethod: "contain",
          acceptedFiles: "image/*",
          addRemoveLinks: true,
          transformFile:function(file,done){
            if(file.compress){
              done(file);
            }else{
              new Compressor(file, {
                quality: 0.6,
                mimeType:'image/webp',
                convertSize:0,
                convertTypes:['image/jpeg','image/png','image/jpg'],
                success:(result) => {
                  this.removeFile(file);
                  result.compress = true;
                  this.addFile(result);
                  done(result)
                },
                error(e) {
                  console.log(e);
                }
              })
            }
          }
        },
        successMessage:"",
        dialogCreateProduct:false,
        createProduct:null,
        styles:false,
        active_tab_text_edit:false,
        dialogEditProduct:false,
        headersProduct: [
          { text: "Image", value: "main_file", align: "center" },
          { text: "Name", value: "name", align: "center", sortable: true },
          { text: "Price", value: "price", align: "center", sortable: true },
          { text: "Discount price", value: "discount_price", align: "center", sortable: true },
          { text: "Status", value: "active", align: "center", sortable: true },
          { text: "Options", align: "center", sortable: true },
        ],
        defaultProduct:{
          segment_product_category_id:0,
          name:{en:''},
          description:{en:''},
          small_description:{en:''},
          detail_images:[],
          video:'',
          main_image:'',
          hidden_id:'',
          active:1,
          price:0,
          discount_price:0,
          active_tab_text:0,
          tab_text:{en:""},
          segment_ids:[]
        },
        error:"",
        show_title_color_slider:false,
        show_name_color_slider:false,
        show_price_color_slider:false,
        slider_background:false,
        slider_border_color:false,
        btnColorOpenCart:false,
        btnBgOpenCart:false,
        btnBorderOpenCart:false,
        btnColorOpenCart_detail:false,
        btnBgOpenCart_detail:false,
        btnBorderOpenCart_detail:false,
        show_text_color_detail:false,
        show_text_price_color_detail: false,
        btnColorCart_detail:false,
        btnBorderCart_detail:false,
        btnBgCart_detail:false,
        active_tab:0,
        show_text_color: false,
        show_text_price_color: false,
        btnColorCart:false,
        btnBorderCart:false,
        btnBgCart:false,
        btnColorMore:false,
        btnBorderMore:false,
        btnBgMore:false,
        fontStyleTypes: [
          { id: 1, value: "normal", label: "Normal" },
          { id: 0, value: "italic", label: "Italic" },
        ],
        fontFamilyTypes: [
          { id: 0, value: "Arial", label: "Arial" },
          { id: 1, value: "SF UI Text", label: "SF UI Text" },
          { id: 2, value: "Proxima Nova Bl", label: "Proxima Nova Bl" },
          { id: 3, value: "Myriad Pro", label: "Myriad Pro" },
          { id: 4, value: "HelveticaNeueCyr", label: "HelveticaNeueCyr" },
          { id: 5, value: "Gotham Pro", label: "Gotham Pro" },
          { id: 6, value: "Geometria", label: "Geometria" },
          { id: 7, value: "DIN Pro", label: "DIN Pro" },
          { id: 8, value: "Circe", label: "Circe" },
          { id: 9, value: "Bebas Neue", label: "Bebas Neue" },
          { id: 10, value: "Acrom", label: "Acrom" },
          { id: 11, value: "Inter", label: "Inter" },
        ],
        positionTypes: [
          { id: 0, valueHorizontal: "flex-start" },
          { id: 1, valueHorizontal: "center" },
          { id: 2, valueHorizontal: "flex-end" },
        ],
      }
    },
    async mounted(){
      await this.getSegments(this.$route.params.id);
    }
  }
</script>

<style scoped>

</style>
