<template>
  <div>
    <label class="label" style="font-weight:bold;font-size:20px;display:block;margin-bottom:15px;">Top menu settings</label>
    <div style="display:flex;align-items:center;margin-bottom:15px;">
      <label class="label" style="display:block;margin-bottom:0;min-width:200px;"
        >Icon size <b>{{ landing.branding.topMenu.styles.size }} px</b></label
      >
      <div class="slider" style="width:100%;">
        <v-slider
          hide-details
          :max="40"
          :min="2"
          v-model="landing.branding.topMenu.styles.size"
        ></v-slider>
      </div>
    </div>
    <div style="display:flex;align-items:center;margin-bottom:15px;">
      <label class="label" style="display:block;margin-bottom:0;min-width:200px;"
      >Border radius <b>{{ landing.branding.topMenu.styles.radius }} px</b></label
      >
      <div class="slider" style="width:100%;">
        <v-slider
            hide-details
            :max="100"
            :min="0"
            v-model="landing.branding.topMenu.styles.radius"
        ></v-slider>
      </div>
    </div>
      <div style="display: flex;align-items: center;margin-bottom: 15px;">
        <label
          class="label"
          style="margin-bottom: 0;margin-right: 8px;display:block;min-width:200px;"
        >
          Icon color
        </label>
        <div class="control">
          <v-menu
            top
            nudge-left="16"
            nudge-bottom="98"
            :close-on-content-click="false"
            v-model="show_text_color"
          >
            <template v-slot:activator="{ on }">
              <div
                class="custom__menu--color"
                :style="`background-color:${landing.branding.topMenu.styles.color}`"
                v-on="on"
              />
            </template>
            <v-card>
              <v-card-text class="pa-0">
                <v-color-picker v-model="landing.branding.topMenu.styles.color" flat />
              </v-card-text>
            </v-card>
          </v-menu>
        </div>
      </div>
      <div style="display: flex;align-items: center;margin-bottom: 30px;">
        <label
          class="label"
          style="margin-bottom: 0;margin-right: 8px;min-width:200px;display:block;"
        >
          Background color
        </label>
        <div class="control">
          <v-menu
            top
            nudge-left="16"
            nudge-bottom="98"
            :close-on-content-click="false"
            v-model="show_background_color"
          >
            <template v-slot:activator="{ on }">
              <div
                class="custom__menu--color"
                :style="`background-color:${landing.branding.topMenu.styles.background}`"
                v-on="on"
              />
            </template>
            <v-card>
              <v-card-text class="pa-0">
                <v-color-picker v-model="landing.branding.topMenu.styles.background" flat />
              </v-card-text>
            </v-card>
          </v-menu>
        </div>
      </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name:"TopMenu",
    data(){
        return{
          show_text_color:false,
          show_background_color:false,
          show_sidebar_background_color:false,
          show_sidebar_border_color:false,
        }
    },
    mounted(){
    },
    computed:{
        ...mapState(['landing'])
    }
}
</script>

<style>

</style>